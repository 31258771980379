import * as React from "react";
import { List } from "semantic-ui-react";

import "semantic-ui-less/semantic.less";
import { DocumentsListItemProps } from "./interfaces.d";

export default class extends React.Component<
  { items: DocumentsListItemProps[] },
  {}
> {
  public render(): JSX.Element {
    const { items } = this.props;
    return (
      <List divided relaxed>
        {items.map((item, i) => (
          <List.Item key={i}>
            {item.status === "completed" && <List.Icon name="check" />}
            {item.status === "active" && <List.Icon name="clock outline" />}
            {item.status === "attention" && <List.Icon name="attention" />}
            {!item.status && <List.Icon name="circle outline" />}
            <List.Content>{item.name}</List.Content>
          </List.Item>
        ))}
      </List>
    );
  }
}
