import { $sce } from "ngimport";

import { UserService } from "Services";
import { ModalUtilities, UtilityFunctions } from "Utilities";

import "./../modals/RemarkModal";

function shipmentsSingleBookingRemarksController(
  $scope: any,
  $uibModal: ng.ui.bootstrap.IModalService
) {
  const vm = this;

  /** Initial values for the controller */
  vm.orderByField = "CreatedDate";
  vm.reverse = true;

  vm.trustAsHtml = function trustAsHtml(str: string): any {
    return $sce.trustAsHtml(str);
  };

  $scope.editCreateRemark = function editCreateRemark(remarkObj: any): void {
    const modalInstance = ModalUtilities.showLargeModal("remarkModal", {
      remark: function remark() {
        const currentUser = UserService.getUserProfile();
        if (!currentUser) return;
        return (
          remarkObj || {
            JobReference: $scope.booking.JobReference,
            CreatedBy: currentUser.Access.LoginID,
            CreatedByName: currentUser.User.Username,
            LastChangedBy: currentUser.Access.LoginID,
            LastChangedByName: currentUser.User.Username,
            RemarkText: null
          }
        );
      }
    });

    modalInstance.then(() => {
      // reload data after update/post
      $scope.$emit("reloadRemarks");
    });
  };
}

shipmentsSingleBookingRemarksController.$inject = ["$scope", "$uibModal"];
export default shipmentsSingleBookingRemarksController;
