import { action } from "mobx";

import { USER_ROLES } from "Constants/UserRoles";
import { UserService, ShipmentService, TranslationService } from "Services";
import { ModalUtilities, WindowUtilities } from "Utilities";
import GeneralLoader from "Utilities/GeneralLoader";

class ShipmentDropdownService {
  private copyCallback: Function | undefined = undefined;
  getShipmentMenuItems = (shipment: any, copyCallback?: Function): any => {
    let booking: any;
    let editCopy: any;
    let docs: any;
    if (copyCallback) {
      this.copyCallback = copyCallback;
    }

    return {
      then: (callback: Function) => {
        return new Promise((resolve, reject) => {
          ShipmentService.getFilteredBookingActions(shipment).then(
            (data: any) => {
              // TODO task:5441
              // Filtering out claim report for the time being
              data = data.filter(
                (item: { TypeID: number }) => item.TypeID !== 80
              );

              booking = this.setBooking(shipment, data);

              callback(this.combine(booking, editCopy, docs));

              booking && editCopy && docs && resolve();
            }
          );

          ShipmentService.getBookingAllowedActions(shipment.JobReference).then(
            (data: any) => {
              editCopy = this.setEdit(shipment, data);

              callback(this.combine(booking, editCopy, docs));

              booking && editCopy && docs && resolve();
            }
          );

          ShipmentService.getBookingPrintMenuDocuments(
            shipment.JobReference
          ).then((data: any) => {
            docs = this.setDocs(shipment, data);

            callback(this.combine(booking, editCopy, docs));

            booking && editCopy && docs && resolve();
          });

          callback([]);
        });
      },
    };
  };

  private combine(booking: any, editCopy: any, docs: any) {
    const divider: any = "divider";

    return [].concat(booking, divider, editCopy, divider, docs);
  }

  private setBooking = (shipment: any, actions: any[]): any[] => {
    return actions.map((action: any) => {
      const popupUrl = ShipmentService.buildBookingActionQueryUrl(
        shipment.JobReference,
        action
      );

      return {
        label: `{{::"${action.translate}" | translate}}`,
        onClick: function onClick(event: Event) {
          event.preventDefault();
          WindowUtilities.openPopup(popupUrl, shipment.JobReference, 1020, 750);
        },
      };
    });
  };

  private setEdit = (shipment: any, data: any): any[] => {
    const vgmLabelText = !this.cutoffDateHasPassed(shipment.CutoffDate)
      ? "LABEL_REGISTER_VGM"
      : "LABEL_VIEW_VGM";

    const editCopy: any[] = [
      {
        label: `{{::"${vgmLabelText}" | translate}}`,
        onClick: function onClick(event: Event) {
          event.preventDefault();

          GeneralLoader.increase();
          ShipmentService.getVgmCutOffTimeJobRef({
            JobReference: shipment.JobReference,
          })
            .then((dataVgmCutOffTime: any) => {
              ShipmentService.getVgmInfo(shipment.JobReference)
                .then((data: any) => {
                  data.CutOffDate = dataVgmCutOffTime.CutOffDate;
                  data.Title = TranslationService.translate(
                    "TEXT_VGM_MODAL_TITLE"
                  );
                  const shippingType = ShipmentService.getShippingTypePair(
                    data.ShippingType
                  ).second;
                  const modalOpt =
                    shippingType === "F"
                      ? "showExtraLargeModal"
                      : "showLargeModal";

                  ModalUtilities[modalOpt]("shipmentsVGM", {
                    vgmData: function vgmDataFn() {
                      return data;
                    },
                    shippingType: function shippingTypeFn() {
                      return shippingType;
                    },
                    displayMode: function displayModeFn() {
                      return shippingType;
                    },
                  });
                })
                .finally(() => {
                  GeneralLoader.decrease();
                });
            })
            .catch(() => {});
        },
      },
    ];

    if (data.Edit) {
      editCopy.push({
        label: '{{::"LABEL_EDIT_BOOKING" | translate}}',
        sref: `shipments_createbooking.edit({jobReference: '${shipment.JobReference}'})`,
      });
    }

    if (data.Copy) {
      editCopy.push({
        label: '{{::"LABEL_COPY_BOOKING" | translate}}',
        onClick: (event: Event) => {
          event.preventDefault();
          if (this.copyCallback) {
            this.copyCallback(shipment.JobReference);
          }
        },
      });
    }

    return editCopy;
  };

  private setDocs = (shipment: any, documents: any[]): any[] => {
    const invoiceCount = this.getInvoiceCount(shipment);

    const docs: any[] = (documents || []).map((doc: any) => {
      return {
        label: `{{::"${doc.translate}" | translate}}`,
        iconClass: "fa fa-print fa-fw",
        onClick: function onClick(event: Event) {
          event.preventDefault();
          ShipmentService.openBookingDocument(doc.UID);
        },
      };
    });

    if (invoiceCount > 0 && UserService.isAuthorized(USER_ROLES.FINANCE)) {
      docs.push({
        label: '{{::"DOCTYPE_SINV_PLURAL" | translate}}',
        iconClass: "fa fa-print fa-fw",
        badge: invoiceCount,
        onClick: function onClick(event: Event) {
          event.preventDefault();
          window.location.href = `/shipments/${shipment.JobReference}?tab=invoices`;
        },
      });
    }

    return docs;
  };

  private openPopup = (url: string, windowName: string): void => {
    WindowUtilities.openPopup(url, windowName, 1020, 750);
  };

  private cutoffDateHasPassed = (date: string): boolean | 0 => {
    const parsedDate = Date.parse(date);

    return parsedDate && parsedDate < Date.now();
  };

  /**
   * getInvoiceCount (formerly known as checkInvoiceStatus)
   * Check wether the user is a shipper,consignee,employee or others. after checking
   * the users role it counts the number of invoices for that role and returns the count
   * if the count is larger than 0 we show the invoice action.
   */
  private getInvoiceCount = (booking: any): number => {
    const user = UserService.getUserProfile();
    const selectedCompany = UserService.getSelectedCompany();
    if (!user) return 0;

    const invoicesTotal =
      booking.InvoiceCountShipper +
      booking.InvoiceCountConsignee +
      booking.InvoiceCountOthers;

    if (UserService.isEmployeeOrAgent() && invoicesTotal > 0) {
      return invoicesTotal;
    }

    const partnerCode = selectedCompany.PartnerCode;

    // user is consignee
    if (
      booking.ConsigneeCode === partnerCode &&
      booking.InvoiceCountConsignee > 0
    ) {
      return booking.InvoiceCountConsignee;
    }

    // user is shipper
    if (
      booking.ShipperCode === partnerCode &&
      booking.InvoiceCountShipper > 0
    ) {
      return booking.InvoiceCountShipper;
    }

    return 0;
  };
}

export default new ShipmentDropdownService();
