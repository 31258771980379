import { ShipmentService } from "Services";

function shipmentsSingleBookingHistoryController($scope: any) {
  $scope.textTranslation = function textTranslation(hObj: any) {
    if (hObj.HistoryType === "STATUS") {
      return ShipmentService.workflowStatusText(hObj.RequestType);
    }

    return `REQUESTTYPE_${hObj.RequestType}`;
  };
}

shipmentsSingleBookingHistoryController.$inject = ["$scope"];
export default shipmentsSingleBookingHistoryController;
