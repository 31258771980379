exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index-modules__modal___3NtHL div.button-group {\n  display: flex;\n  justify-content: end;\n}\n.index-modules__modal___3NtHL .content {\n  background: white !important;\n}\n.index-modules__modal___3NtHL .modal-terms-condition-content {\n  padding: 15px;\n  padding-top: 30px;\n  background-color: white !important;\n}\n.index-modules__modal___3NtHL .with-padding-top {\n  padding-top: 30px;\n}\n.index-modules__modal___3NtHL .with-padding-bottom {\n  padding-bottom: 30px;\n}\n.index-modules__modal___3NtHL .with-upper-case {\n  text-transform: uppercase;\n}\n.index-modules__modal___3NtHL .default-text-transform {\n  text-transform: none;\n}\n.index-modules__modal___3NtHL .error-message {\n  font-size: 14px;\n  margin-top: 0px;\n  color: #9f3a38;\n}\n@media (min-width: 768px) and (max-width: 882px) {\n  .index-modules__modal___3NtHL .error-message {\n    margin-top: 20px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"modal": "index-modules__modal___3NtHL"
};