import * as React from "react";
import * as moment from "moment";
import { TruckingService, languages, SamskipNotify } from "Services";
import { WindowUtilities } from "../../shared/utilities/index";
import { TableDataState } from "./interfaces";
import _ from "lodash";
import { SG_REQUEST_TYPES } from "Constants/RequestConstants";

declare const GlobalConfig: GlobalConfig;

enum RequestStatus {
  NOREQ = "NOREQ",
  REGISTERED = "REGISTERED",
  ONHOLD = "ONHOLD",
  INPROGRESS = "INPROGRESS",
  READYTRUCK = "READYTRUCK",
  INTRUCKING = "INTRUCKING",
  ONSITE = "ONSITE",
  CLOSED = "CLOSED",
}

const TruckingStatuses = [
  RequestStatus.INPROGRESS,
  RequestStatus.INTRUCKING,
  RequestStatus.READYTRUCK,
];

export default class TableData extends React.Component<any, TableDataState> {
  state: TableDataState = {
    eta: null,
  };

  oldWebUrl = GlobalConfig.ENV.OldServiceWeb;
  srvWebUrl = GlobalConfig.ENV.ServiceWeb;

  componentDidMount() {
    const {
      onRender,
      data: { RequestID, Status },
    } = this.props;

    if (RequestID && _.includes(TruckingStatuses, Status)) {
      TruckingService.getContainerEtaByRequestId(RequestID)
        .then((eta) => {
          this.setState({ eta });
          onRender();
        })
        .catch(() => {
          this.setState({ eta: null });
          SamskipNotify.displayError(languages("ERROR_INTERNAL_SERVER_ERROR"));
          onRender();
        });
    } else {
      onRender();
    }
  }

  openRequest(requestID: any) {
    const path = `${this.oldWebUrl}/DisplayBookingInquiry.aspx?ReqNo=${requestID}`;
    WindowUtilities.openPopup(path, requestID);
  }

  getRequestStatus(status: string): string {
    if (status === undefined || status === "") {
      return "";
    }
    return languages(`REQUESTSTATUS_${status}`);
  }

  getEstimatedDeliveryTime(eta: any): string {
    if (
      moment(eta.DeliveryEstimateFrom).diff(moment().startOf("day"), "days") < 0
    ) {
      return moment(eta.DeliveryTime).format("DD/MM/YYYY");
    }
    const from = this.roundTime(
      moment(eta.DeliveryEstimateFrom),
      moment.duration(5, "minutes"),
      "ceil"
    );
    const to = this.roundTime(
      moment(eta.DeliveryEstimateTo),
      moment.duration(5, "minutes"),
      "ceil"
    );
    return `${from.format("HH:mm")} - ${to.format("HH:mm")}`;
  }

  getRequestStatusInfo(): string {
    let { Status } = this.props.data;
    const { RequestType, DateCreated, DateClosed } = this.props.data;

    if (Status === "" || Status === undefined) {
      Status = RequestStatus.NOREQ;
    }
    let result: string = this.getStatusMessage(Status, RequestType);
    if (Status === RequestStatus.REGISTERED) {
      result += ` ${moment(DateCreated).format("HH:mm")}`;
    } else if (
      RequestType === SG_REQUEST_TYPES.DRREQCNTIMP &&
      (Status === RequestStatus.INPROGRESS ||
        Status === RequestStatus.READYTRUCK ||
        Status === RequestStatus.INTRUCKING) &&
      this.state.eta !== null
    ) {
      result += ` ${this.getEstimatedDeliveryTime(this.state.eta)}`;
    } else if (Status === RequestStatus.CLOSED) {
      result += ` ${moment(DateClosed).format("DD/MM[']YY [kl.] HH:mm")}`;
    }
    return result;
  }

  // Try to get a message translation for a specific request type, if it does not exists default to generic messages
  getStatusMessage = (status: string, type: string) => {
    const typeStatusKey = `REQUESTSTATUS_DESCRIPTION_${status}_${type}`;
    const defaultKey = `REQUESTSTATUS_DESCRIPTION_${status}`;
    let message = languages(typeStatusKey);
    if (message === typeStatusKey) {
      message = languages(defaultKey);
    }
    return message;
  };

  // Generic function to round moment timestamps
  private roundTime(
    date: moment.Moment,
    duration: moment.Duration,
    method: string
  ) {
    return moment(Math[method](+date / +duration) * +duration);
  }

  render() {
    if (this.props.isLoading) {
      return null;
    }
    const data = this.props.data;

    return (
      <tr className="gutter">
        <td className="col-lg-1 col-md-1 col-sm-1">
          <a
            href={`${this.srvWebUrl}/containers/${data.ContainerNumber}`}
            target="_blank"
          >
            {data.ContainerNumber}
          </a>
        </td>
        <td className="col-lg-1 col-md-1 col-sm-1">{data.ContainerType}</td>
        <td className="col-lg-3 col-md-3 col-sm-3">{data.Description}</td>
        {this.props.isPODIS && (
          <>
            <td className="col-lg-1 col-md-1 col-sm-1">
              <a
                href={`${this.oldWebUrl}/DisplayBookingInquiry.aspx?ReqNo=${data.RequestID}`}
                target="_blank"
                onClick={() => this.openRequest(data.RequestID)}
              >
                {this.getRequestStatus(data.Status)}
              </a>
            </td>
            <td className="col-lg-3 col-md-3 col-sm-3">
              {this.getRequestStatusInfo()}
            </td>
          </>
        )}
        <td className="col-lg-1 col-md-1 col-sm-1">{data.Quantity}</td>
        <td className="col-lg-1 col-md-1 col-sm-1">{data.UnitOfMeasure}</td>
        <td className="col-lg-1 col-md-1 col-sm-1">
          {Math.floor(data.Weight).toLocaleString()} kg
        </td>
      </tr>
    );
  }
}
