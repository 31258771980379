import { Modal } from "Component";
import React from "react";
// import { Button } from "semantic-ui-react";
import { Button } from "Component";
import { languages } from "Services";

import styles from "./index.modules.less";

interface InstructionsConfirmModalProps {
  open: boolean;
  onSubmit: Function;
  onClose: Function;
}

export default (props: InstructionsConfirmModalProps) => {
  const renderContent = () => {
    return (
      <div>
        <p>{languages("TEXT_SUBMIT_INSTRUCTIONS_REMINDER")}</p>
        <div className={`footer button-group`}>
          <Button onClick={() => props.onClose()}>
            {languages("LABEL_CANCEL")}
          </Button>
          <Button primary onClick={() => props.onSubmit()}>
            {languages("LABEL_PROCEED")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={props.open}
      header={<h2>{languages("LABEL_SUBMIT_BOOKING_INSTRUCTION")}</h2>}
      content={renderContent()}
      additionalStyles={styles.modal}
    ></Modal>
  );
};
