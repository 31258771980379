import React from "react";
import parse from "html-react-parser";
import { Modal } from "Component";
import { Loader, Radio } from "semantic-ui-react";
import { Button } from "Component";
import { languages, UserService } from "Services";
import styles from "./index.modules.less";
import "semantic-ui-less/semantic.less";
import { $uiRouter } from "Bootstrap/angular";

export default class extends React.Component<
  {
    userProfile: UserProfile;
    type: "create" | "copy";
    bookingPartners: { type?: "CEE" | "SHP"; partner: Company }[];
    routeDashboard: any;
    onSelect: Function;
  },
  {
    open: boolean;
    selectedPartner: { type?: "CEE" | "SHP"; partner: Company };
    disableProceedButton: boolean;
    isRouting: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      open:
        this.props.type === "create"
          ? this.props.userProfile.Access.CPNotify === "Y" &&
            (this.props.userProfile.Access.UserType === "E" ||
              this.props.userProfile.Companies.length > 0)
          : !this.props.bookingPartners.some(
              (bookingPartner) =>
                bookingPartner.partner.PartnerCode ===
                this.props.userProfile.SelectedCompany.PartnerCode
            ),
      selectedPartner: { partner: this.props.userProfile.SelectedCompany },
      disableProceedButton:
        this.props.type === "copy" &&
        (this.props.userProfile.Access.UserType === "E" ||
          this.props.bookingPartners.every((bookingPartner) =>
            this.props.userProfile.Companies.some(
              (userCompany) =>
                userCompany.PartnerCode === bookingPartner.partner.PartnerCode
            )
          ))
          ? true
          : false,
      isRouting: false,
    };
  }

  renderHeader() {
    return (
      <h2>
        {this.props.type === "copy"
          ? languages("LABEL_PLEASE_NOTICE")
          : languages("LABEL_SELECT_COMPANY")}
      </h2>
    );
  }

  closeAndRoute() {
    this.setState({ open: false }, this.props.routeDashboard);
  }

  async setCompany(bookingPartner: { type?: "CEE" | "SHP"; partner: Company }) {
    this.setState({ isRouting: true });
    await UserService.switchCompany(bookingPartner.partner).then(() => {
      this.props.onSelect();
      $uiRouter.stateService.reload();
    });
  }

  componentDidMount() {
    // Checks if the user has access to both companies
    if (
      !(
        this.props.userProfile.Access.UserType === "E" ||
        this.props.bookingPartners.every((bookingPartner) =>
          this.props.userProfile.Companies.some(
            (userCompany) =>
              userCompany.PartnerCode === bookingPartner.partner.PartnerCode
          )
        )
      )
    ) {
      try {
        this.setState({
          selectedPartner: this.props.bookingPartners.filter((bookingPartner) =>
            this.props.userProfile.Companies.some(
              (userCompany) =>
                userCompany.PartnerCode === bookingPartner.partner.PartnerCode
            )
          )[0],
          disableProceedButton: false,
        });
      } catch (e) {}
    }
  }

  renderContent() {
    if (this.props.type === "copy") {
      return (
        <div className={styles.container}>
          {this.props.userProfile.Access.UserType === "E" ||
          this.props.bookingPartners.every((bookingPartner) =>
            this.props.userProfile.Companies.some(
              (userCompany) =>
                userCompany.PartnerCode === bookingPartner.partner.PartnerCode
            )
          ) ? (
            <>
              {parse(
                languages(
                  "TEXT_SELECT_COMPANY_REMINDER",
                  undefined,
                  this.props.userProfile.SelectedCompany.PartnerCode
                )
              )}
              <p className={styles.pSelectComapny}>
                {languages("TEXT_PLEASE_SELECT_COMPANY")}
              </p>
              <div>
                {this.props.bookingPartners.map((bookingPartner, i) => (
                  <Radio
                    className="baseFormat"
                    key={i}
                    label={`${bookingPartner.partner.FullName}(${bookingPartner.partner.PartnerCode})`}
                    value={bookingPartner.partner.PartnerCode}
                    checked={
                      this.state.selectedPartner.partner.PartnerCode ===
                      bookingPartner.partner.PartnerCode
                    }
                    onChange={() =>
                      this.setState({
                        selectedPartner: bookingPartner,
                        disableProceedButton: false,
                      })
                    }
                  />
                ))}
              </div>
              <div className={styles.note}>
                <span>{languages("TEXT_CHANGE_COMPANY_NOTE")}</span>
              </div>
            </>
          ) : (
            <>
              <p>{languages("TEXT_SELECT_COMPANY_REMINDER_COPY")}</p>
              {parse(
                languages(
                  "TEXT_CHANGE_COMPANY",
                  undefined,
                  this.props.bookingPartners.filter((bookingPartner) =>
                    this.props.userProfile.Companies.some(
                      (userCompany) =>
                        userCompany.PartnerCode ===
                        bookingPartner.partner.PartnerCode
                    )
                  )[0].partner.PartnerCode
                )
              )}
            </>
          )}
          <div className="footer">
            <div className="left">
              <Button onClick={() => this.closeAndRoute()}>
                {languages("LABEL_CANCEL")}
              </Button>
            </div>
            <div className="right">
              <Button
                onClick={() => this.setCompany(this.state.selectedPartner)}
                primary
                disabled={this.state.disableProceedButton}
              >
                {!this.state.isRouting ? (
                  languages("LABEL_PROCEED")
                ) : (
                  <Loader active inline inverted size={"small"} />
                )}
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.container}>
        {parse(
          languages(
            "TEXT_SELECT_COMPANY_REMINDER",
            undefined,
            this.props.userProfile.SelectedCompany.PartnerCode
          )
        )}
        <div className="footer">
          <div className="left">
            <span className={styles.closeButton}>
              <Button
                className={styles.button}
                onClick={() => this.setState({ open: false })}
              >
                {languages("LABEL_CLOSE")}
              </Button>
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal
        dimmer
        open={this.state.open}
        header={this.renderHeader()}
        content={this.renderContent()}
      />
    );
  }
}
