import { Modal } from "Component";
import React from "react";
import { Button } from "Component";
import { languages } from "Services";
import TermsAndConditionsBulletinList from "../TermsAndConditionsBulletList/index";

import styles from "./index.modules.less";

interface TermsAndConditionModalProps {
  open: boolean;
  onSubmit: Function;
  onClose: Function;
  onCheckboxClick: Function;
  showErrorMessage: boolean;
}

export default (props: TermsAndConditionModalProps) => {
  const renderContent = () => {
    return (
      <div>
        <div className={`modal-terms-condition-content`}>
          <div className={`with-padding-bottom`}>
            <TermsAndConditionsBulletinList
              terms={languages("TEXT_BOOKING_TERMS_AND_CONDITIONS_V1")}
            ></TermsAndConditionsBulletinList>
          </div>
          <div>
            <input
              type="checkbox"
              className="css-checkbox"
              name="samskip-approveterms"
              id="areTermsAndConditionsAccepted"
              onClick={() => props.onCheckboxClick()}
            />
            <label
              htmlFor="areTermsAndConditionsAccepted"
              className="css-label lite-gray-check smaller smaller-no-uppercase with-soft-black"
            >
              <strong>
                <span>{languages("LABEL_ACCEPT_TERMS")}</span>
              </strong>
            </label>
            {props.showErrorMessage && (
              <p className={`error-message`}>
                {languages("ERROR_REQUIREDFIELD")}
              </p>
            )}
          </div>
        </div>
        <div className={`footer button-group`}>
          <Button
            onClick={() => props.onClose()}
            id="cancel-modal-btn"
            content={languages("LABEL_CANCEL")}
          />
          <Button
            onClick={() => props.onSubmit()}
            id="'createBooking'"
            tab-index="::$ctrl.tabIndex.submitButton"
            content={languages("LABEL_ACCEPT_AND_APPROVE")}
            primary={true}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={props.open}
      header={
        <h2 className="default-text-transform with-upper-case">
          {languages("LABEL_TERMS_AND_CONDITIONS")}
        </h2>
      }
      content={renderContent()}
      additionalStyles={styles.modal}
      closeIcon={true}
      size="small"
    ></Modal>
  );
};
