import * as React from "react";
import { Popup } from "semantic-ui-react";
import { languages } from "Services/TranslationService";

export default class CheckboxIcon extends React.Component<
  { iconClass: string; content: string },
  {}
> {
  render() {
    return (
      <Popup
        position="top center"
        trigger={
          this.props.iconClass ? (
            <i className={this.props.iconClass}></i>
          ) : (
            <li>°C</li>
          )
        }
        content={this.props.content}
      ></Popup>
    );
  }
}
