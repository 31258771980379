exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index-modules__container___3Iw6n {\n  display: flex;\n  flex-direction: column;\n}\n.index-modules__button___1gS3M {\n  margin-top: 5px;\n}\n.index-modules__note___3H_N- {\n  font-size: 12px !important;\n  margin: 10px 0px 5px 0px;\n}\n.index-modules__pSelectComapny___2RvwZ {\n  margin-bottom: 0px !important;\n}\n.index-modules__closeButton___1khct {\n  margin-top: 5px !important;\n  float: left;\n}\n", ""]);

// exports
exports.locals = {
	"container": "index-modules__container___3Iw6n",
	"button": "index-modules__button___1gS3M",
	"note": "index-modules__note___3H_N-",
	"pSelectComapny": "index-modules__pSelectComapny___2RvwZ",
	"closeButton": "index-modules__closeButton___1khct"
};