import React, { useEffect, useRef, useState } from "react";
import { Popup, Button } from "semantic-ui-react";
import { PopupModal } from "Component";
import "semantic-ui-less/semantic.less";
import styles from "./index.less";
import { languages, TranslationService } from "Services";
import PartnerInfoForm from "./PartnerInfoForm";
import { reaction } from "mobx";

interface PartnerInfoPopupProps {
  address: any;
  saveAddress: Function;
  options: any[];
  optionLabelFields: string[];
  open: boolean;
  searchable: boolean;
  newPartner: boolean;
  forceCorrectData: boolean;
  additionalRequiredFields: string[];
  onClose: Function;
}

export default (props: PartnerInfoPopupProps) => {
  const contextRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState(
    TranslationService.getSelectedLanguage()
  );

  const { onClose, saveAddress, ...rest } = props;

  const renderHeader = () => {
    return (
      <>
        <span className="title">{languages("LABEL_CHANGE_REGISTRATION")}</span>
        <a className="close" onClick={handleClose}>
          <i className="fa fa-times"></i>
        </a>
      </>
    );
  };

  useEffect(() => {
    reaction(
      () => TranslationService.getSelectedLanguage(),
      () => setLanguage(TranslationService.getSelectedLanguage())
    );
  }, []);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  // Popup handlers
  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  const onSave = (address: any) => {
    props.saveAddress(address);
    handleClose();
  };

  return (
    <>
      <div ref={contextRef}></div>
      <PopupModal
        context={contextRef}
        content={
          <PartnerInfoForm
            onClose={handleClose}
            saveAddress={onSave}
            {...rest}
          />
        }
        header={renderHeader()}
        size="large"
        open={open}
        basic
        position="right center"
        offset={[20, 0]}
        wide
      />
    </>
  );
};
