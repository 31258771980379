import { Button } from "Component";
import React from "react";
import { Popup } from "semantic-ui-react";
import { languages } from "Services/TranslationService";

interface VoyagePickerTooltipProps {
  content: string;
  position?:
    | "top left"
    | "top right"
    | "bottom right"
    | "bottom left"
    | "right center"
    | "left center"
    | "top center"
    | "bottom center"
    | undefined;
  tabIndex: number;
  onClick: Function;
  disabled: boolean;
}

export default class VoyagePickerButton extends React.Component<
  VoyagePickerTooltipProps,
  {}
> {
  render() {
    return (
      <Popup
        trigger={
          <div>
            <Button
              id="selectVoyage"
              disabled={this.props.disabled}
              onClick={() => this.props.onClick()}
              tabIndex={this.props.tabIndex}
              iconClass="fa fa-ship fa-fw"
            >
              {languages("LABEL_SELECT")}
            </Button>
          </div>
        }
        content={this.props.content}
        position={this.props.position}
        disabled={!this.props.disabled}
        size="large"
      ></Popup>
    );
  }
}
