import * as angular from "angular";

import * as modalTemplate from "./registrationSuccessModal.html";

const RegistrationSuccess: ng.IComponentOptions = {
  template: `${modalTemplate}`,
  bindings: {
    close: "&",
    resolve: "<"
  },
  controller: class RegistrationSuccess {
    private close: Function;
    private resolve: {
      jobReference: string;
      resultText: string;
      infoText: string;
    };

    resultText: string;
    jobReference: string;
    infoText: string;

    constructor() {
      this.resultText = this.resolve.resultText;
      this.jobReference = this.resolve.jobReference;
      this.infoText = this.resolve.infoText;
    }

    createNewBooking = (value: boolean) => {
      this.close({ $value: { newBooking: value } });
    };
  }
};

angular
  .module("serviceWebApp")
  .component("registrationSuccess", RegistrationSuccess);
