import { VALIDATION_REGEX } from "Constants/ValidationConstants";
import * as yup from "yup";

const multipleEmailSchema = yup
  .string()
  .test("emails", "ERROR_EMAIL_INVALID", (value) => {
    if (value === null || value === undefined) {
      return false; // Reject null or undefined values
    }

    const emails = value.split(";");

    if (emails.length > 1 && emails.some((email) => email.trim() === "")) {
      return false; // Reject if there are empty strings between semicolons
    }

    if (
      emails.length > 1 &&
      (emails[0].trim() === "" || emails[emails.length - 1].trim() === "")
    ) {
      return false; // Reject if it starts or ends with a semicolon
    }

    return emails.every((email) => {
      return yup.string().email().isValidSync(email.trim());
    });
  });

const phoneNumberSchema = yup
  .string()
  .matches(VALIDATION_REGEX.phoneNumberRegex, "ERROR_PHONE_INVALID");

export { multipleEmailSchema, phoneNumberSchema };
