import * as moment from "moment";
import { saveAs } from "file-saver";
import { Array } from "es6-shim";
import { UIRouter, StateService, Transition } from "@uirouter/core";
import * as _ from "lodash";
import { USER_ROLES } from "Constants/UserRoles";
import {
  UserService,
  SamskipNotify,
  ShipmentService,
  FinanceService,
  ShipmentDocumentService,
  TranslationService,
} from "Services";
import {
  ArrayUtilities,
  UtilityFunctions,
  WindowUtilities,
  DateUtilities,
  ModalUtilities,
} from "Utilities";
import { $uiRouter } from "Bootstrap/angular";
import { DocumentFile } from "Shipments/documentModal/interfaces";
import { SINGLEBOOKING_TABS } from "./tabs";
import { $location } from "ngimport";
import GeneralLoader from "Utilities/GeneralLoader";

declare var GlobalConfig: GlobalConfig;

function shipmentsSingleBookingController(
  $scope: any,
  $transition$: Transition,
  booking: any,
  favoriteBookings: any[]
) {
  const vm = this;

  const $state: StateService = $transition$.router.stateService;
  const currentState: string = $transition$.to().name || "";
  const currentStateParams: any = $transition$.params();

  $scope.currentUser = UserService.getUserProfile();
  const { PartnerCode } = UserService.getSelectedCompanyObservable();
  const oldWebUrl = GlobalConfig.ENV.OldServiceWeb;
  const jobReference = booking.JobReference;
  const hasFinanceAccess: boolean = UserService.isAuthorized(
    USER_ROLES.FINANCE
  );
  $scope._canEditVgm = !cutoffDateHasPassed(booking.CutoffDate);

  $scope.$watch(
    () => {
      return $location.search()["tab"];
    },
    (newVal: any, oldVal: any) => {
      $scope.activeTab = getActiveTab(SINGLEBOOKING_TABS[newVal]);
    }
  );

  // Go through each detail line in booking
  // and calculate a unique key (JobReference + Detail Sequence)
  booking.Details.forEach((item: any) => {
    item.key = booking.JobReference + item.DetailSequence;
  });

  booking._shippingType = ShipmentService.convertShippingTypeToLongFormat(
    booking.ShippingType
  );
  booking._bolType = ShipmentService.convertBOLTypeToLongFormat(
    booking.BOLType
  );
  booking._voyages = ShipmentService.createVoyageList(booking);

  // Booking object
  $scope.booking = booking;

  // Holds the data for each of the tabs
  $scope.tabData = {};

  // Assign detail lines to 'details' tabData
  $scope.tabData.details = booking.Details;

  // Indicator of if the booking has bc or not.
  $scope.isBc =
    $scope.booking.BuyersConsol && $scope.booking.BuyersConsol === "Y";
  // Tells if we are waiting for the buyers console flag (http wait)
  $scope.isBcLoading = true;

  // getActiveTab contains logic for selecting tabs based on restrictions
  $scope.activeTab = getActiveTab(
    SINGLEBOOKING_TABS[$location.search()["tab"]]
  );

  FinanceService.getBookingPaymentStatus(
    booking.JobReference,
    ShipmentService.isLocalPort(booking.POD.PointCode)
      ? booking.Consignee.PartnerCode
      : booking.Shipper.PartnerCode
  ).then((result) => {
    booking._isPayed = result.isPayed;
    booking._isCustomerCashPayer = result.isCustomerCashPayer;
  });

  // Initial actions that user can perform (see 'initUserActions')
  $scope.userActions = {};

  $scope.servercall = {
    remarks: false,
    units: false,
    requests: false,
    history: false,
    details: false,
    documents: false,
  };

  $scope.booking._isFavorite = ArrayUtilities.includes(
    favoriteBookings,
    $scope.booking.JobReference
  );

  // Create an empty address object
  $scope.booking.Addresses = [];

  // Set the title of the HTML document
  document.title = $scope.booking.JobReference;

  $scope.remarksCount = $scope.booking.MemoCount;

  $scope.showModal = false;

  $scope.toggleCustomsModal = function toggleCustomsModal() {
    $scope.showModal = !$scope.showModal;
  };

  $scope.showDocumentModal = false;
  $scope.showCopyModal = false;

  // Function that displays voyage reference properly
  $scope.displayVoyageReference = function displayVoyageReference(
    bookingObj: any
  ): string {
    if (!bookingObj) return "";
    let str;
    if (bookingObj.LoadVoyageReference === bookingObj.DischVoyageReference) {
      str = bookingObj.LoadVoyageReference;
    } else {
      str = `${bookingObj.LoadVoyageReference} - ${bookingObj.DischVoyageReference}`;
    }
    return str;
  };

  $scope.openRequest = function openRequest(requestId: number): void {
    if (requestId) {
      const path = `${oldWebUrl}/DisplayBookingInquiry.aspx?ReqNo=${requestId}`;
      UtilityFunctions.iframeModal(path, $scope);
    }
  };

  /**
   * DateTime formatting functionality and validation
   */
  function validDateTime(dateString: string): boolean {
    return !!dateString && dateString !== "1970-01-01T00:00:00";
  }

  $scope.formatDate = function formatDate(dateStr: string): string {
    const m = moment(dateStr);
    return validDateTime(dateStr) && m.isValid() ? m.format("DD.MM.YYYY") : "";
  };

  $scope.formatDateTime = function formatDateTime(
    dateTimeStr: string,
    dateFormat: string
  ): string {
    const m = moment(dateTimeStr);
    return validDateTime(dateTimeStr) && m.isValid()
      ? m.format(dateFormat || "DD.MM.YY - HH:mm:ss")
      : "";
  };

  $scope.orderTransport = function orderTransport() {
    WindowUtilities.openPopup(
      `${oldWebUrl}/AddBookingInQuiry.aspx?RequestType=DELIVREQ&BookNos=${$scope.booking.JobReference}`,
      `DELIVREQ${$scope.booking.JobReference}`
    );
  };

  $scope.downloadExcelBookingInfo = function downloadExcelBookingInfo() {
    const reportType = $scope.currentTab;

    ShipmentService.excelBookingInfo(
      $scope.booking.JobReference,
      reportType
    ).then((data: any) => {
      const out = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(out, `Shipment_${$scope.booking.JobReference}_${reportType}.xlsx`);
    });
  };

  /**
   * Open a popup using an existing action from the $scope.bookingActions
   * array using the TYPEMAP property.
   * @param  {string} typeMap The TYPEMAP key to find in $scope.bookingActions
   */
  $scope.openActionPopupByTypeMap = function openActionPopupByTypeMap(
    typeMap: string
  ): void {
    const action = $scope.bookingActions.find(
      (it: any) => it.TypeMap === typeMap
    );
    if (action) {
      $scope.openActionPopup(action);
    }
  };

  $scope.openActionPopup = function openActionPopup(action: any): void {
    const popupUrl = ShipmentService.buildBookingActionQueryUrl(
      $scope.booking.JobReference,
      action
    );

    WindowUtilities.openPopup(
      popupUrl,
      `${action.TypeMap}${$scope.booking.JobReference}`
    );
  };

  $scope.openPrintActionPopup = function openPrintActionPopup(
    action: any
  ): void {
    WindowUtilities.openPopup(
      `${oldWebUrl}/DorisDispBookPDF.aspx?PrintAction=${action.DocumentCode}&BookNos=${$scope.booking.JobReference}`,
      `${action.DocumentCode}${$scope.booking.JobReference}`
    );
  };

  /**
   * Handles getting booking data for that specific tab
   * Logic was changed so all this function does it save the current tab
   * @param  {Number} id ID of tab
   */
  $scope.getBookingData = function getBookingData(id: string): void {
    $location.search("tab", id);
    $scope.currentTab = id;
  };

  // load actions for bookings
  $scope.bookingActions = [
    {
      text: "Sæki gögn",
      translate: "LABEL_FETCHING_ACTIONS",
    },
  ];

  $scope.bookingPrintActions = [
    {
      text: "Sæki gögn",
      translate: "LABEL_FETCHING_ACTIONS",
    },
  ];

  /**
   * Get a list of job documents for for print-menus
   */
  ShipmentService.getBookingPrintMenuDocuments(
    $scope.booking.JobReference
  ).then(
    (data: any) => {
      $scope.printDocs = data;

      if (hasFinanceAccess) {
        // Set $scope.arrivalNotice as the arrival notice document object
        // if it's contained in succData, otherwise it will remain undefined,
        // and the arrival notice button remains disabled
        $scope.arrivalNotice = data.find((it: any) => it.Type === "SARN");
      }
    },
    (errData: any) => {
      console.error(errData);
    }
  );

  ShipmentService.getBookingDocumentTypes(
    $scope.booking.JobReference,
    PartnerCode
  ).then((data: any) => {
    $scope.bookingPrintActions = [];
    data.forEach((printAction: any) => {
      printAction.text = "transl_missing";
      printAction.translate = `DOCTYPE_${printAction.DocumentCode}`;
      $scope.bookingPrintActions.push(printAction);
    });
  });

  /**
   * Open a document that has been attached to a given booking.
   * @param  {object} doc Object with metadata about the document to open
   */
  $scope.openDocument = function openDocument(doc: any): void {
    if (doc.Type === "SINV") {
      // Open booking invoices
      ShipmentService.openBookingInvoices($scope.booking.JobReference);
    } else {
      // Open other documents
      ShipmentService.openBookingDocument(doc.UID);
    }
  };

  $scope.statusClass = ShipmentService.statusClass;

  $scope.statusText = ShipmentService.statusText;

  ShipmentService.getFilteredBookingActions(
    $scope.booking,
    UserService.isEmployeeOrAgent()
  ).then((data: any) => {
    // TODO task:5833
    // Filtering out claim report for the time being
    data = data.filter((item: { TypeID: number }) => item.TypeID !== 80);
    $scope.bookingActions = data;
  });

  // Set user's actions for the booking
  function initUserActions(): void {
    $scope.userActions.viewFinancialInformation = hasFinanceAccess;

    const isAdmin = UserService.isGlobalAdmin();
    const isEmployee = UserService.isEmployeeOrAgent();
    const isBusinessUser = UserService.isAuthorized(USER_ROLES.BUISN);

    // Employee with BUISN or ADMIN role
    if ((isEmployee && isBusinessUser) || isAdmin) {
      $scope.userActions.editBLSurrenderDate = true;
      $scope.userActions.editCustomsClearanceDate = true;
    }

    ShipmentService.getBookingAllowedActions($scope.booking.JobReference).then(
      (data: any) => {
        $scope.userActions.editBooking = data.Edit;
        $scope.userActions.copyBooking = data.Copy;
        $scope.userActions.canRequestCustomsClearance = data.Customs;
      }
    );
  }

  initUserActions();

  /**
   * TAB FUNCTIONALITY BEGINS
   */

  const tabFunctions = {
    remarks: {
      employee: true,
      run: getRemarks,
    },
    units: {
      employee: false,
      run: getUnits,
    },
    buyersConsol: {
      employee: false,
      run: getBuyersConsol,
    },
    requests: {
      employee: false,
      run: getRequests,
    },
    history: {
      employee: false,
      run: getHistory,
    },
    details: {
      employee: false,
      run: getDetails,
    },
    documents: {
      employee: false,
      run: getDocuments,
    },
    invoices: {
      employee: false,
      run: getInvoices,
    },
  };

  /**
   * Get web remarks for a single Doris booking and display in the Remarks-tab
   */
  function getRemarks(): void {
    const name = "remarks";

    $scope.servercall[name] = true;
    ShipmentService.getBookingRemarks($scope.booking.JobReference).then(
      (data: any) => {
        // Go through the result and calculate a unique key (JobReference + Detail Sequence)
        data.forEach((item: any) => {
          item.key = item.JobReference + item.RemarkSequence;
          if (item.RemarkText) {
            item.RemarkTextHTML = UtilityFunctions.htmlFormatText(
              TranslationService.translate(item.RemarkText)
            );
          }
        });

        $scope.tabData[name] = data;
        $scope.remarksCount = data.length;
        $scope.servercall[name] = false;
      }
    );
  }

  $scope.$on("reloadRemarks", getRemarks);

  /**
   * Get units for a single Doris booking and display in the Units-tab
   */
  function getUnits(): void {
    const name = "units";

    $scope.servercall[name] = true;

    // Get booking's registered units
    ShipmentService.getUnitsForBooking($scope.booking.JobReference).then(
      (data: any) => {
        $scope.tabData[name] = data;
        $scope.servercall[name] = false;
      }
    );
  }

  /**
   * Get requests for a single Doris booking and display in the Requests-tab
   */
  function getRequests(): void {
    const name = "requests";

    $scope.servercall[name] = true;
    ShipmentService.getRequestsForBooking($scope.booking.JobReference).then(
      (data: any) => {
        $scope.tabData[name] = data;
        $scope.servercall[name] = false;
      }
    );
  }

  // Number of Buyers Consol bookings
  $scope.bcCount = 0;
  /**
   * Gets buyers consol details for a booking (i.e. if the booking has such info).
   */
  function getBuyersConsol(): void {
    const name = "buyersConsol";
    $scope.servercall[name] = true;
    ShipmentService.getBcList($scope.booking.JobReference).then((data: any) => {
      $scope.bcCount = data.length;
      const orderedData = _.orderBy(
        data,
        ["CONTAINER_NUMBER", "LCL_JOB_REFERENCE"],
        ["asc", "asc"]
      );
      $scope.tabData[name] = orderedData;
      $scope.servercall[name] = false;
      $scope.isBcLoading = false;
    });
  }

  /**
   * Get history for a single Doris booking and display in the History-tab
   */
  function getHistory(): void {
    const name = "history";

    $scope.servercall[name] = true;

    // Get booking-history
    ShipmentService.getHistoryForBooking($scope.booking.JobReference).then(
      (data: any) => {
        $scope.tabData[name] = data;
        $scope.servercall[name] = false;
      }
    );

    // Order history-entries
    $scope.orderByField = "DateCreated";
    $scope.reverse = true;
  }

  /**
   * Get details for a single Doris booking and display in the Details-tab
   */
  function getDetails(): void {
    const name = "details";

    $scope.servercall[name] = true;
    ShipmentService.getAddressesForBooking($scope.booking.JobReference).then(
      (data: any) => {
        $scope.booking.Addresses = data;
        // Go through the addresses and add each type to the booking
        data.forEach((item: any) => {
          $scope.booking.Addresses[item.AddressType] = item;
        });
        $scope.servercall[name] = false;
      }
    );
  }

  function getDocuments(): void {
    const name = "documents";
    const dorisDocs: WebDocument[] = [];
    const sdcDocs: WebDocument[] = [];

    Promise.all([
      ShipmentService.getBookingDocuments($scope.booking.JobReference),
      ShipmentDocumentService.getDocuments($scope.booking.JobReference),
    ]).then((values: any[]) => {
      const docs: WebDocument[] = [];
      const dorisDocs: ShippingDocument[] = values[0];
      const sdcDocs: SDCDocument[] = values[1];

      dorisDocs.map((d: ShippingDocument) =>
        docs.push({
          UID: d.UID,
          UploadDate: d.UploadDate,
          Type: d.Type,
          FileType: d.FileType,
          UserName: d.UserName,
          DocSource: "DORIS",
          FileName: d.FileName,
        } as WebDocument)
      );

      sdcDocs.map((d: SDCDocument) => {
        const ext = (d.Name || "").split(".");

        docs.push({
          UID: d.ID,
          UploadDate: d.Created,
          Type: d.Code,
          Description: d.Name,
          FileType: ext[ext.length - 1].toUpperCase(),
          UserName: "-",
          DocSource: "SDC",
        } as WebDocument);
      });

      $scope.tabData[name] = docs;
      $scope.$apply();
    });
  }

  /**
   * Get all invoices related to particular booking
   */
  function getInvoices(): void {
    const name = "invoices";

    if (hasFinanceAccess) {
      $scope.servercall[name] = true;
      FinanceService.getInvoicesByReference(
        "JOB_REFERENCE",
        $scope.booking.JobReference
      ).then((data: FinanceInvoiceHeader[]) => {
        const tdata = formatInvoiceData(data);
        $scope.tabData[name] = tdata;
        $scope.servercall[name] = false;
      });
    }
  }

  /**
   * Format invoice response which needs some changes
   */
  function formatInvoiceData(data: any): void {
    data.forEach((invoice: any) => {
      invoice.InvoiceDate = DateUtilities.formatDate(
        invoice.InvoiceDate,
        "DD.MM.YY"
      );
    });

    return data;
  }

  /**
   * TAB FUNCTIONALITY ENDS
   */
  $scope.isEmployeeOrAgent = UserService.isEmployeeOrAgent();

  // Run data retrieval for all tabs
  for (const key in tabFunctions) {
    if (tabFunctions[key].employee && !$scope.isEmployeeOrAgent) {
      /*
       * Do nothing if the tab is for employees only but the user is not
       * an employee
       */
    } else {
      tabFunctions[key].run();
    }
  }

  $scope.bLSurrenderDate = {
    save: function save(date: string): SamskipPromise<any> {
      return ShipmentService.updateBLSurrenderDate(
        [$scope.booking.JobReference],
        date
      );
    },
    saveSuccess: function saveSuccess(newDate: string): void {
      $scope.booking.BLSurrenderDate = newDate;
    },
    saveError: function saveError(errData: string): void {
      SamskipNotify.displayError(errData);
    },
  };

  $scope.customsClearanceDate = {
    save: function save(date: string): SamskipPromise<any> {
      return ShipmentService.updateCustomsClearanceDate(
        [$scope.booking.JobReference],
        date
      );
    },
    saveSuccess: function saveSuccess(newDate: Date): void {
      $scope.booking.CustomsClearanceDate = newDate;
    },
    saveError: function saveError(errData: string): void {
      SamskipNotify.displayError(errData);
    },
  };

  /**
   * Open VGM modal for managing VGM registrations for a Doris booking
   */
  $scope.openVGMModal = function openVGMModal(): void {
    GeneralLoader.increase();
    ShipmentService.getVgmCutOffTimeJobRef({
      JobReference: jobReference,
    })
      .then((dataVgmCutOffTime: any) => {
        ShipmentService.getVgmInfo(jobReference)
          .then((data: any) => {
            data.CutOffDate = dataVgmCutOffTime.CutOffDate;
            data.Title = TranslationService.translate("TEXT_VGM_MODAL_TITLE");
            const shippingType = ShipmentService.getShippingTypePair(
              data.ShippingType
            ).second;
            const modalOpt =
              shippingType === "F" ? "showExtraLargeModal" : "showLargeModal";

            ModalUtilities[modalOpt]("shipmentsVGM", {
              vgmData: function vgmDataFn() {
                return data;
              },
              shippingType: function shippingTypeFn() {
                return shippingType;
              },
              displayMode: function displayModeFn() {
                return shippingType;
              },
            });
          })
          .finally(() => {
            GeneralLoader.decrease();
          });
      })
      .catch(() => {});
  };

  vm.openDocumentModal = () => {
    vm.showDocumentModal = true;
  };

  vm.onDocumentModalClose = () => {
    vm.showDocumentModal = false;
    $scope.$digest();
  };

  vm.onDocumentUploadSubmit = async (files: DocumentFile[]) => {
    const types = files.map((file: any) => file.docType);

    try {
      await ShipmentDocumentService.postDocuments(
        $scope.booking.JobReference,
        types,
        files
      );

      SamskipNotify.displaySuccess("LABEL_SUCCESS", undefined, "body");
      getDocuments();
      vm.showDocumentModal = false;
    } catch (ex) {
      SamskipNotify.displayError("LABEL_FAILURE", undefined, "body");
    }
  };

  vm.onDocumentUploadSuccess = async (document: any) => {
    const index = $scope.tabData.documents.findIndex(
      (item: WebDocument) => item.UID === document
    );
    if (index >= 0) {
      $scope.tabData.documents.splice(index, 1);
      $scope.$digest();
    }
  };

  $scope.openCopyModal = () => {
    $scope.showCopyModal = true;
  };

  $scope.closeCopyModal = function closeCopyModal() {
    $scope.showCopyModal = false;
    $scope.$digest();
  };

  vm.onCustomsDocumentUploadSuccess = () => {
    SamskipNotify.displaySuccess("TEXT_CUSTOMS_DOCUMENT_UPLOAD_SUCCESS");
  };

  vm.onCustomsDocumentUploadFailure = () => {
    SamskipNotify.displayError("ERROR_CUSTOMS_DOCUMENT_UPLOAD_SUCCESS");
  };

  function cutoffDateHasPassed(date: string) {
    const parsedDate = Date.parse(date);
    return parsedDate && parsedDate < Date.now();
  }

  window.onscroll = function onscroll() {
    handleSinglePageFooterDisplayability();
  };

  /**
   * The single page footer is always at the bottom of the viewport.
   * The main footer is always at the bottom of the page.
   * When scrolling down to the main footer, the single page footer
   * will be blocking the main footer. Then the single page footer should
   * be hidden.
   */
  function handleSinglePageFooterDisplayability() {
    const singlePageFooterElement = document.querySelector(
      ".js-SinglePage-footer"
    );
    if (!singlePageFooterElement) {
      return;
    }
    const mainFooterElement = document.querySelector(".js-MainFooter");
    if (!mainFooterElement) {
      return;
    }

    if (isElementVisible(mainFooterElement, singlePageFooterElement)) {
      hideElement(singlePageFooterElement);
    } else {
      displayElement(singlePageFooterElement);
    }
  }

  /**
   * Checks if a element, or a part of the element, is seen in the viewport
   * where another element can be blocking it.
   * @param elementUnder  Element to be seen
   * @param elementAbove  Element that can be blocking 'elementUnder'
   */
  function isElementVisible(elementUnder: Element, elementAbove: Element) {
    const elementUnderBounding = elementUnder.getBoundingClientRect();
    const elementAboveBounding = elementAbove.getBoundingClientRect();

    if (WindowUtilities.isElementSeenInTheViewport(elementUnder)) {
      if (
        elementUnderBounding.top <=
        window.innerHeight - elementAboveBounding.height
      ) {
        return true;
      }

      return false;
    }

    return false;
  }

  /**
   * Add fadeIn class to an element.
   * Remove faceOut class from  an element.
   * @param element Element to interact with
   */
  function hideElement(element: Element) {
    element.classList.add("is-fadeOut");
    element.classList.remove("is-fadeIn");
  }

  /**
   * Add fadeOut class to an element.
   * Remove fadeIn class from an element.
   * @param element Element to interact with
   */
  function displayElement(element: Element) {
    element.classList.remove("is-fadeOut");
    element.classList.add("is-fadeIn");
  }

  /**
   * Determines what tab should be selected, based on which are available to the user
   * @param tab Index of the tab
   * @returns   The correct tab indexes that should be selected
   */
  function getActiveTab(tab: number) {
    if (
      tab === SINGLEBOOKING_TABS["remarks"] &&
      !UserService.isEmployeeOrAgent()
    ) {
      $state.go("shipments_singlebooking");
      return 0;
    }
    if (tab === SINGLEBOOKING_TABS["buyersConsol"] && !$scope.isBc) {
      $state.go("shipments_singlebooking");
      return 0;
    }
    if (
      tab === SINGLEBOOKING_TABS["invoices"] &&
      !UserService.isAuthorized(USER_ROLES.FINANCE)
    ) {
      $state.go("shipments_singlebooking");
      return 0;
    }
    return tab;
  }

  // A special open function for is necessary because of react2angular weirdness
  $scope.openActionPopupPaymentStatus =
    function openActionPopupPaymentStatus() {
      $scope.openActionPopupByTypeMap("PAYMENTSTATUS");
    };

  $scope.openArrivalNotice = function openArrivalNotice(): void {
    $scope.openDocument($scope.arrivalNotice);
    $scope.$digest();
  };
}

shipmentsSingleBookingController.$inject = [
  "$scope",
  "$transition$",
  "booking",
  "favoriteBookings",
];
export default shipmentsSingleBookingController;
