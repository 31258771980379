import { Dropdown } from "Component";
import React, { useEffect, useState } from "react";
import { DropdownItemProps } from "semantic-ui-react";
import { HSCode } from "Shipments/createbooking/interfaces";

interface HSCodesDropdownProps {
  defaultHsCodes: string[] | undefined;
  hsCodes: HSCode[];
  onChange: (data: any[]) => void;
}
const HSCodesDropdown: React.FC<HSCodesDropdownProps> = ({
  defaultHsCodes,
  hsCodes,
  onChange,
}: HSCodesDropdownProps) => {
  const [hsCodeOptions, setHsCodeOptions] = useState<DropdownItemProps[]>([]);
  const [selectedHSCodes, setSelectedHSCodes] = useState<string[]>([]);

  const generateOptions = (fromArray: HSCode[]) => {
    return fromArray.map((hs: HSCode, i: number) => {
      return {
        key: `hs-${i}`,
        text: `(${hs.HSCode}) ${hs.Description}`,
        value: hs.HSCode,
      } as DropdownItemProps;
    });
  };

  useEffect(() => {
    setHsCodeOptions(generateOptions(hsCodes));
  }, [hsCodes]);

  useEffect(() => {
    if (defaultHsCodes) {
      setSelectedHSCodes(defaultHsCodes);
    }
  }, []);

  const dropdownChange = (selectedValues: string[]) => {
    setSelectedHSCodes(selectedValues);
    onChange(selectedValues);
  };

  return (
    <Dropdown
      search
      multiple
      options={hsCodeOptions}
      onChange={dropdownChange}
      id="HSCodes"
      value={selectedHSCodes}
    />
  );
};

export default HSCodesDropdown;
