import ContainerService from "Services/ContainerService";
import { ShipmentService } from "Services";
import { ArrayUtilities, ObjectUtilities } from "Utilities";
import { Number, Object } from "es6-shim";
import { ValidationResult } from "./interfaces";
import {
  requiredValidator,
  notEmptyStringValidator,
  futureDateValidator,
} from "../../shared/validators/validators";
import { BOOKING_CREATION } from "../../shared/constants/BookingCreationConstants";

class BookingValidationService {
  // All valid container lengths (RESOURCE_SIZE from SCE.package_codes)
  private validContainerLengths: string[] = [
    "10",
    "20",
    "23",
    "30",
    "40",
    "45",
  ];
  // All valid container boxtypes (RESOURCE_TYPE from SCE.package_codes)
  private validContainerBoxTypes: string[] = [
    "BK",
    "BR",
    "DC",
    "FC",
    "FR",
    "HC",
    "HR",
    "OT",
    "PH",
    "PW",
    "RF",
    "TK",
    "UT",
  ];

  private maxTemperature: number = 30;
  private minTemperature: number = -30;

  // Booking Party validation configuration
  private bookingPartyAddressesRequired: number = 2;

  // Shipping type map for registration of Doris bookings,
  // where everything except LCL-LCL is registered and
  // validated as FCL.
  createBookingShippingTypeMap: any = {
    "F F": "F",
    "F L": "F",
    "L F": "F",
    "L L": "L",
    "M M": "M",
  };

  /**
   * Validates a single detail for an FCL-booking (and its dimensions).
   * @param  {Object} detail  A detail-object to validate.
   * @return {bool}           Returns true if the detail-line validates.
   */
  validateSingleFCLDetail = (detail: DetailViewModel): boolean => {
    const requiredStringValues = [
      detail.PackageCode,
      detail.Description,
      detail.CommodityCode,
    ];
    if (!ArrayUtilities.onlyContainsNonEmptyStrings(requiredStringValues)) {
      return false;
    }
    if (!this.isValidContainerType(detail.PackageCode)) {
      return false;
    }
    // A detail cannot be both refrigerated and oversized
    if (detail.OversizeFlag && detail.Refrigerated) {
      return false;
    }
    // 'NumUnits' is always required for FCL details
    if (!detail.NumUnits) {
      return false;
    }
    if (!(Number.isFinite(detail.NumUnits) && detail.NumUnits > 0)) {
      return false;
    }
    // Weight is always required for details of FCL-bookings.
    if (!detail.TotalOriginNetWeight) {
      return false;
    }
    if (
      !(
        Number.isFinite(detail.TotalOriginNetWeight) &&
        detail.TotalOriginNetWeight > 0
      )
    ) {
      return false;
    }
    if (!this.hasDimensions(detail)) {
      return false;
    }

    // The combined weight of a detail's dimensions cannot exceed
    // the detail's 'TotalOriginNetWeight' property
    const totalDimensionWeight = this.getDetailsTotalDimensionWeight(
      detail.Dimensions
    );
    if (totalDimensionWeight > detail.TotalOriginNetWeight) {
      return false;
    }

    // The combined volume of a detail's dimensions cannot exceed the detail's 'Volume' property
    const totalDimensionVolume = this.getDetailsTotalDimensionVolume(
      detail.Dimensions
    );
    if (detail.Volume && totalDimensionVolume > detail.Volume) {
      return false;
    }

    // Check if the HazardFlag for the detail is enabled
    // and if each dimension under the detail has valid
    // hazard lines
    if (
      detail.HazardFlag === true &&
      !detail.Dimensions.every((dimension: any) => {
        return this.validateSingleDimensionHazardLines(
          detail.HazardFlag,
          dimension
        );
      })
    ) {
      return false;
    }

    if (
      detail.OversizeFlag === true &&
      !this.validateDetailOversizeInfo(detail)
    ) {
      return false;
    }

    return detail.Dimensions.every(this.validateSingleDimensionFCL);
  };

  validateSingleLCLDetail = (detail: DetailViewModel): boolean => {
    // 'NumUnits' is always required for LCL details
    if (
      !(
        detail.NumUnits &&
        Number.isFinite(detail.NumUnits) &&
        detail.NumUnits > 0
      )
    ) {
      return false;
    }
    // Weight is always required for details of LCL-bookings.
    if (
      !(
        detail.TotalOriginNetWeight &&
        Number.isFinite(detail.TotalOriginNetWeight) &&
        detail.TotalOriginNetWeight > 0
      )
    ) {
      return false;
    }
    // Volume is always required for details of LCL-bookings.
    if (
      !(detail.Volume && Number.isFinite(detail.Volume) && detail.Volume > 0)
    ) {
      return false;
    }

    return this.validateSingleDimensionHazardLines(
      detail.HazardFlag,
      detail.Dimensions[0]
    );
  };

  validateSingleMTYDetail = (detail: DetailViewModel): boolean => {
    const requiredStringValues = [
      detail.PackageCode,
      detail.Description,
      detail.CommodityCode,
    ];
    if (!ArrayUtilities.onlyContainsNonEmptyStrings(requiredStringValues)) {
      return false;
    }
    // Detail's 'PackageCode' must be a valid container-type
    if (!this.isValidContainerType(detail.PackageCode)) {
      return false;
    }
    // 'NumUnits' is always required for LCL details
    if (
      !(
        detail.NumUnits &&
        Number.isFinite(detail.NumUnits) &&
        detail.NumUnits > 0
      )
    ) {
      return false;
    }
    // MTY-bookings' details should never have these flags set
    if (detail.HazardFlag || detail.OversizeFlag || detail.Refrigerated) {
      return false;
    }

    // Hazardous-, and Oversize-info should not defined for MTY-bookings' details
    return detail.Dimensions.every((dimension: any) => {
      return (
        (dimension.Hazardous == null || dimension.Hazardous.length === 0) &&
        dimension.Oversize == null
      );
    });
  };

  /**
   * Validates a single booking-detail temperature values.
   * @param  {Object} detail          The booking-detail to validate.
   * @return {Boolean}                Returns true if the detail validates.
   */
  validateDetailTemperature = (detail: DetailViewModel): boolean => {
    if (detail.Refrigerated) {
      if (!detail.Temperature && detail.Temperature !== 0) {
        return false;
      }

      if (
        detail.Refrigerated &&
        (!Number.isInteger(detail.Temperature) ||
          detail.Temperature < this.minTemperature ||
          detail.Temperature > this.maxTemperature)
      ) {
        return false;
      }
    }

    return true;
  };

  /**
   * Checks if the 'NumUnits' property has been set for each of the booking's
   * details -- this is one of the minimum requirements that must be fulfilled
   * before an initial booking can be posted to Doris.
   * @param  {Object} booking     The booking-viewmodel
   * @return {Boolean}
   */
  validateBaseCount = (booking: BookingViewModel): boolean => {
    if (!this.hasDetails(booking)) {
      return false;
    }
    return booking.Details.every((it: any) => {
      /* Every dimension that has a container-number must have its
       * NumUnits set to a positive numeric value for the BaseCount
       * to be valid */
      if (this.hasContainerNumbers(it)) {
        return it.Dimensions.every((dimension: any) => {
          if (dimension.ContainerNumber != null) {
            return this.validDimensionNumUnits(dimension);
          }
          return true;
        });
      }
      return Number.isFinite(it.NumUnits) && it.NumUnits > 0;
    });
  };

  /**
   * Checks if NumUnits exist in details. When an empty container is picket
   * (MYT-MYT) the dimensions are not required to have 'NumUnits'.
   * It's enough to check if any detail exist for 'NumUnits' check.
   */
  validateDetailExist = (booking: BookingViewModel): boolean => {
    if (!this.hasDetails(booking)) {
      return false;
    }

    return booking.Details.every((it: DetailViewModel) => {
      return this.validDetailNumUnits(it);
    });
  };

  /**
   * Checks if the 'Description' property has been set for each of the booking's
   * details -- this is one of the minimum requirements that must be fulfilled
   * before an initial booking can be posted to Doris.
   * @param  {Object} booking     The booking-viewmodel
   * @return {Boolean}
   */
  validateBaseDescription = (booking: BookingViewModel) => {
    if (!this.hasDetails(booking)) {
      return false;
    }
    return booking.Details.every((it: any) => {
      return typeof it.Description === "string" && it.Description != null;
    });
  };

  /**
   * Checks if the 'TotalOriginNetWeight' property has been set for each
   * of the booking's details -- this is one of the minimum requirements
   * that must be fulfilled before an initial booking can be posted to Doris.
   * NOTE: For 'MTY-MTY' bookings, this check is irrelevant.
   * @param  {Object} booking     The booking-viewmodel
   * @return {Boolean}
   */
  validateBaseWeight = (booking: BookingViewModel): boolean => {
    let shippingType;
    if (!(booking.ShippingType && this.hasDetails(booking))) {
      return false;
    }
    shippingType = this.createBookingShippingTypeMap[booking.ShippingType];
    if (shippingType === "M") {
      return true;
    }
    return booking.Details.every((it: any) => {
      /* Every dimension that has a container-number must have its
       * weight set to a positive numeric value for the BaseWeight to
       * be valid */
      if (this.hasContainerNumbers(it)) {
        return it.Dimensions.every((dimension: any) => {
          if (dimension.ContainerNumber != null) {
            return this.validDimensionWeight(dimension);
          }
          return true;
        });
      }
      return (
        Number.isFinite(it.TotalOriginNetWeight) && it.TotalOriginNetWeight > 0
      );
    });
  };

  /**
   * Checks if the incoterms-information have been entered for a booking,
   * i.e. that at least POL and POD have been defined and IncoTermsPoint
   * is one of POL, POD, PFD or PLR.
   * This is one of the requirements that must be fullfilled before an
   * initial booking can be posted to Doris.
   * @param  {Object} booking     The booking-viewmodel
   * @return {Boolean}            Returns true if the incoterms-info is valid.
   */
  validateIncoTerms = (booking: BookingViewModel): boolean => {
    if (booking == null) {
      return false;
    }
    if (!(booking.IncoTerms && booking.IncoTermsPoint)) {
      return false;
    }
    const POL = ObjectUtilities.get(booking, "POL.PointCode");
    const POD = ObjectUtilities.get(booking, "POD.PointCode");
    const PFD = ObjectUtilities.get(booking, "PFD.PointCode");
    const PLR = ObjectUtilities.get(booking, "PLR.PointCode");
    // If POL & POD haven't been defined, there is no way to complete
    // registration of IncoTerms
    if (!(POL && POD)) {
      return false;
    }
    // The selected incoterms-point must be one of the booking's selected point/ports
    return ArrayUtilities.includes(
      [POL, POD, PFD, PLR],
      booking.IncoTermsPoint
    );
  };

  /**
   * Checks if the 'NumUnits' property has been set for each of the booking's
   * dimensions -- this is one of the minimum requirements that must be fulfilled
   * before the booking's instructions can be posted to Doris.
   * @param  {Object} booking     The booking-viewmodel
   * @return {Boolean}
   */
  validateInnerPackageCount = (booking: BookingViewModel): boolean => {
    if (!this.hasDetails(booking)) {
      return false;
    }
    return booking.Details.every((detail: any) => {
      return (
        this.hasDimensions(detail) &&
        detail.Dimensions.every(this.validDimensionNumUnits)
      );
    });
  };

  /**
   * Checks if the 'PackageCode' property has been set for each of the booking's
   * dimensions -- this is one of the minimum requirements that must be fulfilled
   * before the booking's instructions can be posted to Doris.
   * @param  {Object} booking     The booking-viewmodel
   * @return {Boolean}
   */
  validateInnerPackageTypes = (booking: BookingViewModel) => {
    if (!this.hasDetails(booking)) {
      return false;
    }
    return booking.Details.every((detail: any) => {
      return (
        this.hasDimensions(detail) &&
        detail.Dimensions.every((dimension: any) => {
          return (
            typeof dimension.PackageCode === "string" &&
            dimension.PackageCode.length >= 3
          );
        })
      );
    });
  };

  validateDetailVolume = (booking: BookingViewModel) => {
    if (!this.hasDetails(booking)) {
      return false;
    } else {
      return booking.Details.every((detail: any) => {
        return (
          detail.Volume && Number.isFinite(detail.Volume) && detail.Volume > 0
        );
      });
    }
  };

  /**
   * Checks if container-numbers have been entered for each dimension.
   * This is required for bookings of shippingtype F F, L F and
   * MTY-MTY. -- this is one of the minimum requirements that must be fulfilled
   * before the booking's instructions can be posted to Doris.
   * @param  {Object} booking     The booking-viewmodel
   * @return {Boolean}
   */
  validateInnerContainerNumbers = (booking: BookingViewModel) => {
    if (!this.hasDetails(booking)) {
      return false;
    }

    const shippingType =
      this.createBookingShippingTypeMap[booking.ShippingType];
    const validShippingType = shippingType === "F" || shippingType === "M";
    return (
      validShippingType &&
      booking.Details.every((detail: any) => {
        return (
          this.hasDimensions(detail) &&
          detail.Dimensions.every((dimension: any) => {
            return this.isValidContainerNumber(dimension.ContainerNumber);
          })
        );
      })
    );
  };

  /**
   * Validates booking hazard-lines (FCL/LCL).
   * Returns the appropriate strategy for validating FCL or LCL
   * hazard-lines
   * @param  {Object} booking     The booking-viewmodel
   * @return {Boolean}
   */
  validateBookingHazardLines = (booking: any): boolean => {
    if (!(booking.ShippingType && this.hasDetails(booking))) {
      return false;
    }
    const shippingType =
      this.createBookingShippingTypeMap[booking.ShippingType];
    if (!shippingType) {
      return false;
    }

    switch (shippingType) {
      case "M":
      case "F":
        return this.validateBookingFCLHazardLines(booking);
      case "L":
        return this.validateBookingLCLHazardLines(booking);
      default:
        return false;
    }
  };

  /**
   * Validates booking oversize-lines.
   * Returns the appropriate strategy for validating over size line.
   * @param {Object} booking      The booking-viewmodel
   * @return {Boolean}
   */
  validateBookingOverSizeLines = (booking: any): boolean => {
    if (!(booking.ShippingType && this.hasDetails(booking))) {
      return false;
    }
    const shippingType =
      this.createBookingShippingTypeMap[booking.ShippingType];
    if (!shippingType) {
      return false;
    }

    switch (shippingType) {
      case "M":
      case "F":
        return this.validateBookingFCLOversizeLines(booking);
      case "L":
      default:
        return false;
    }
  };

  /**
   * Validates hazardous-information for a single dimension-line.
   * If the detail's HazardFlag property is set, each of the detail's
   * dimensions must contain at least one hazardous-row.
   * @param {Object}  hazardFlag  Hazard Flag on the dimension's parent-detail.
   * @param  {Object} dimension   The dimension to validate.
   * @return {bool}               Returns true if the Dimension validates.
   */
  validateSingleDimensionHazardLines = (
    hazardFlag: boolean | undefined,
    dimension: DimensionViewModel
  ): boolean => {
    if (!hazardFlag) {
      return true;
    }
    return (
      Array.isArray(dimension.Hazardous) &&
      dimension.Hazardous.length > 0 &&
      dimension.Hazardous.every(this.validateSingleHazardLine) &&
      this.validateSingleDimensionHazardWeight(dimension)
    );
  };

  /**
   * Validates oversize-information for a single dimension-line.
   * @param {Object}  oversizeFlag    Oversize Flag on the dimension's parent-detail.
   * @param  {Object} dimension       The dimension to validate.
   * @return {bool}                   Returns true if the Dimension validates.
   */
  validateSingleDimensionOversizeLine = (
    dimension: DimensionViewModel
  ): boolean => {
    return dimension.Oversize !== undefined;
  };

  /**
   * Validates a single hazardous-line.
   * @param  {Object} hazardousDtl    Object containing hazardous details.
   * @return {bool}                   Returns true if the model validates.
   */
  validateSingleHazardLine = (hazardousDtl: any): boolean => {
    if (!hazardousDtl.ImdgClass) {
      return false;
    }
    // NetWeight must be a positive value
    if (
      hazardousDtl.NetWeight === null ||
      hazardousDtl.NetWeight === undefined ||
      hazardousDtl.NetWeight < 0
    ) {
      return false;
    }
    // NumUnits must be a positive value
    if (!hazardousDtl.NumUnits || hazardousDtl.NumUnits <= 0) {
      return false;
    }
    if (!hazardousDtl.PackageCode) {
      return false;
    }
    if (!hazardousDtl.UNNumber) {
      return false;
    }
    if (!hazardousDtl.UNVariant) {
      return false;
    }
    // Weight  must be a positive value
    if (
      hazardousDtl.Weight === null ||
      hazardousDtl.Weight === undefined ||
      hazardousDtl.Weight < 0
    ) {
      return false;
    }
    // NetWeight must be less than or equal to (gross) Weight
    if (hazardousDtl.Weight < hazardousDtl.NetWeight) {
      return false;
    }
    return true;
  };

  /**
   * Checks if the combined weight of dimension's hazardous rows
   * exceed the dimension's own gross-weight
   * @param  {Object} dimension       The dimension to check against.
   * @return {Boolean}
   */
  validateSingleDimensionHazardWeight = (dimension: any): boolean => {
    // The combined-weight of dimension's hazardous-rows can never exceed
    // the dimension's own gross-weight.
    const hazardousTotalWeight =
      this.getDimensionHazardousTotalWeight(dimension);

    if (dimension.Weight && hazardousTotalWeight > dimension.Weight) {
      return false;
    }

    return true;
  };

  /**
   * Checks if a hazardous-line can be added to a Dimension's list of
   * hazardous items by checking the dimension's total-weight and the
   * hazardous-line's weights.
   * @param  {Object} dimension       The dimension to check against.
   * @param  {Object} hazardousObj    The hazardous-line to be added.
   * @return {bool}
   */
  canAddHazardLine = (
    dimension: DimensionViewModel,
    hazardousObj: HazardousViewModel
  ): boolean => {
    if (!dimension) {
      console.error(`dimension cannot be ${dimension}`);
      return false;
    }
    if (!hazardousObj) {
      console.error(`hazardousObj cannot be ${hazardousObj}`);
      return false;
    }

    let hazardousWithAddedLine =
      this.getDimensionHazardousTotalWeight(dimension);

    if (hazardousObj.Weight) {
      hazardousWithAddedLine += hazardousObj.Weight;
    }

    if (dimension.Weight === null || dimension.Weight === undefined) {
      dimension.Weight = 0;
    }

    if (dimension.Weight < 0) {
      return false;
    }

    return hazardousWithAddedLine <= dimension.Weight;
  };

  /**
   * Checks if there is any restriction for removing detail.
   * @param  {Object} detail          The detail to check.
   * @param  {Object} shippingType    Shipping type of current booking.
   * @return {bool}
   */
  canRemoveDetail = (
    detail: DetailViewModel,
    shippingType: string
  ): boolean => {
    // Only set remove restrictions to LCL booking
    if (this.createBookingShippingTypeMap[shippingType] !== "L") {
      return true;
    }
    if (detail.Dimensions == null) {
      return true;
    }

    // true when some dimension contains ContainerNumber with some value
    return !detail.Dimensions.some((o: any) => {
      return o.ContainerNumber;
    });
  };

  /**
   * Validates that the required oversize-details have been entered given
   * a type of container. For open-top containers, overheight is required.
   * For flat-racks, one or more oversizes have to be entered (given that
   * the detail's OversizeFlag is set).
   * @param  {string} detailPackageCode   The parent detail's pacakgecode/container-type
   * @param  {object} oversizeObj           Oversizes of a dimension
   * @return {bool}                       Returns true if the object validates
   *                                      for the given container-type.
   */
  validateOversizes = (
    detailPackageCode: string,
    oversizeObj: OversizeViewModel
  ): boolean => {
    if (detailPackageCode === "LCL") {
      return true;
    }

    if (!this.isValidOversizeContainer(detailPackageCode)) {
      return false;
    }

    const boxType = this.getBoxType(detailPackageCode);
    let oversizes = oversizeObj;

    // Set sensible defaults for oversize-object
    const oversizeDefaults = {
      OverHeight: 0,
      OverLengthAfter: 0,
      OverLengthFore: 0,
      OverWidthLeft: 0,
      OverWidthRight: 0,
    };

    oversizes = Object.assign({}, oversizeDefaults, oversizes);
    // Determine which oversize-lengths to consider. For flat-racks, at
    // least one out of five sides must be defined. For open-tops (and
    // 20ft open top w/ one side open (packageCode 20UT)), overheigt
    // must be defined.
    const checkSizes = [oversizes.OverHeight];
    if (boxType === "FR") {
      checkSizes.push(
        oversizes.OverLengthAfter,
        oversizes.OverLengthFore,
        oversizes.OverWidthLeft,
        oversizes.OverWidthRight
      );
    }

    const values = checkSizes.map((it: any) => {
      if (!Number.isFinite(it)) {
        return 0;
      }
      return it;
    });
    // Each value must be a valid number greater than or equal to zero
    // and their sum must be a positive number.
    let allGteZero = true;
    let combinedOversize = 0;

    values.forEach((it: any) => {
      if (it < 0) {
        allGteZero = false;
      }
      combinedOversize += it;
    });

    return allGteZero && combinedOversize > 0;
  };

  /**
   * Checks if a given package-code is for a container that can have oversizes.
   * @param  {string}  packageCode    A valid package-code.
   * @return {Boolean}                Returns if packageCode is a valid container-type
   *                                  and it is for an oversizeable container.
   */
  isValidOversizeContainer = (packageCode: string): boolean => {
    const validOversizeBoxTypes: string[] = ["OT", "FR", "UT", "LCL"];
    let boxType;
    // Check if the detail's packageCode is for a valid oversize-container
    try {
      boxType = this.getBoxType(packageCode);
    } catch (e) {
      return false;
    }

    return ArrayUtilities.includes(validOversizeBoxTypes, boxType);
  };

  /**
   * Checks if a given package-code is for a container that can be refrigerated.
   * @param  {string}  packageCode    A valid package-code.
   * @return {Boolean}                Returns if packageCode is a valid container-type
   *                                  and it is for a container that can be
   *                                  refrigerated/temperature-controlled.
   */
  isValidReeferContainer = (packageCode: string): boolean => {
    const validReeferBoxTypes = ["BR", "HR", "RF"];
    let boxType;
    // Check if the detail's packageCode is for a valid reefer-container
    try {
      boxType = this.getBoxType(packageCode);
    } catch (e) {
      return false;
    }

    return ArrayUtilities.includes(validReeferBoxTypes, boxType);
  };

  /**
   * Validates a given party to a booking (Deciding, Shipper, Consignee or Notifiers).
   * @param  {String} which   Which party to validate. Shipper, Consignee, Notifier, etc.
   * @param  {Object} model   The model to validate.
   * @return {Boolean}        Returns true if the model validates.
   */
  validateBookingParty = (which: string, model: any): boolean => {
    if (model == null) {
      return false;
    }

    const defaultRequiredStringValues = [model.FullName, model.PointCode];

    // Additional checks
    switch (which) {
      case "Deciding":
        defaultRequiredStringValues.push(model.PartnerCode);
        break;
      case "Shipper":
      case "Consignee":
      case "Notifier":
      case "SecondNotifier":
      default:
        // No more fields required for consignees or notifiers,
        // because they could be new partners
        break;
    }

    // The addresses for the booking party
    const defaultRequiredAddresses = [
      model.Address1,
      model.Address2,
      model.Address3,
      model.Address4,
    ];

    // Check if the default required string values only contain non-empty
    // strings and that the addresses contain at least 2 non-empty strings
    return (
      ArrayUtilities.onlyContainsNonEmptyStrings(defaultRequiredStringValues) &&
      ArrayUtilities.containsAtLeastNonEmptyStrings(
        defaultRequiredAddresses,
        this.bookingPartyAddressesRequired
      )
    );
  };

  validateShipperConsignee = (obj: any): ValidationResult => {
    const errors = [] as any;
    let isValid = true;
    if (obj && obj.AddressType === "SHP") {
      for (const key in BOOKING_CREATION.Shipper) {
        if (
          !(requiredValidator(obj[key]) && notEmptyStringValidator(obj[key]))
        ) {
          isValid = false;
          errors.push({
            [key]: true,
          });
        }
      }
    }

    if (obj && obj.AddressType === "CEE") {
      for (const key in BOOKING_CREATION.Consignee) {
        if (
          !(requiredValidator(obj[key]) && notEmptyStringValidator(obj[key]))
        ) {
          isValid = false;
          errors.push({
            [key]: true,
          });
        }
      }
    }
    return {
      errors,
      isValid,
    };
  };

  validatePLRPDF = (address: any, validateDate: boolean): ValidationResult => {
    if (!address) {
      return { errors: [], isValid: true };
    }
    const errors = [] as any;
    let isValid = true;
    const keys =
      address.AddressType === "COL"
        ? BOOKING_CREATION.PLR
        : BOOKING_CREATION.PFD;
    if (address && keys) {
      for (const key in keys) {
        if (
          !(
            requiredValidator(address[key]) &&
            notEmptyStringValidator(address[key])
          )
        ) {
          isValid = false;
          errors.push({
            [key]: true,
          });
        }
      }
      if (!futureDateValidator(new Date(address.RequestDate)) && validateDate) {
        isValid = false;
        if (
          errors.includes(
            errors.find((item: any) => item["RequestDate"] === undefined)
          )
        ) {
          errors.push({
            RequestDate: true,
          });
        }
      }
    }
    return {
      errors,
      isValid,
    };
  };

  validateNotifier = (notifier: any): ValidationResult => {
    const errors = [] as any;
    let isValid = true;
    if (notifier) {
      for (const key in BOOKING_CREATION.Notifier) {
        if (
          !(
            requiredValidator(notifier[key]) &&
            notEmptyStringValidator(notifier[key])
          )
        ) {
          isValid = false;
          errors.push({
            [key]: true,
          });
        }
      }
    }
    return {
      errors,
      isValid,
    };
  };

  validateDimensionRow = (
    booking: BookingViewModel,
    dimensionRow: any
  ): boolean => {
    if (booking) {
      for (const detail of booking.Details) {
        const dimension: any = detail.Dimensions.filter((obj: any) => {
          return obj.$$hashKey === dimensionRow.$$hashKey;
        });
        if (dimension[0] && booking.ShippingType === "L L") {
          return true;
        } else if (dimension[0] && booking.ShippingType === "M M") {
          if (
            !(
              requiredValidator(dimension[0].ContainerNumber) &&
              notEmptyStringValidator(dimension[0].ContainerNumber)
            )
          ) {
            return false;
          }
        } else if (dimension[0]) {
          if (
            !(
              requiredValidator(dimension[0].NumUnits) &&
              notEmptyStringValidator(dimension[0].NumUnits)
            ) ||
            !(
              requiredValidator(dimension[0].PackageCode) &&
              notEmptyStringValidator(dimension[0].PackageCode)
            ) ||
            !(
              requiredValidator(dimension[0].ContainerNumber) &&
              notEmptyStringValidator(dimension[0].ContainerNumber)
            ) ||
            !(
              requiredValidator(dimension[0].Weight) &&
              notEmptyStringValidator(dimension[0].Weight)
            )
          ) {
            return false;
          }
        }
      }
    }
    return true;
  };

  /**
   * Checks if the details for the bill-of-lading/seaway-bill have been
   * set for a booking -- this is required for the booking before the booking
   * can be submitted WITH booking-instructions.
   * @param  {Object} booking     The booking-viewmodel
   * @return {Boolean}            Returns true if the details have been set.
   */
  validateBOLType = (booking: BookingViewModel): boolean => {
    if (!booking) {
      return false;
    }
    if (booking.BOLType === "W") {
      return true;
    }
    // NumOriginalBills and NumCopyBills must be defined for BOLType='B/L'
    if (booking.BOLType === "G") {
      if (!booking.NumOriginalBills || !booking.NumCopyBills) {
        return false;
      }

      return (
        Number.isInteger(booking.NumOriginalBills) &&
        booking.NumOriginalBills > 0 &&
        Number.isInteger(booking.NumCopyBills) &&
        booking.NumCopyBills >= 0
      );
    }
    return false;
  };

  /**
   * Checks if at least one HS code have been set for a booking
   * -- this is required for the booking before the booking
   * can be submitted WITH booking-instructions.
   * @param  {Object} booking     The booking-viewmodel
   * @return {Boolean}            Returns true if at least one HS code have been set.
   */
  validateHSCodes = (booking: BookingViewModel): boolean => {
    if (!booking) {
      return false;
    }

    if (booking.HSCodes && booking.HSCodes.length < 100) {
      return true;
    }
    return false;
  };

  // Private methods

  /** Convenience-method that ensures that details have been added to a booking */
  private hasDetails = (booking: BookingViewModel): boolean => {
    return Array.isArray(booking.Details) && booking.Details.length > 0;
  };

  /** Convenience-method that ensures that dimensions have been added to a booking's details */
  private hasDimensions = (detail: DetailViewModel): boolean => {
    return Array.isArray(detail.Dimensions) && detail.Dimensions.length > 0;
  };

  /**
   * Convenience-method to check if any of a given detail's dimensions has
   * been assigned a container-number.
   * @param  {Object}  detail Dimension-object
   * @return {Boolean}        Returns true if any of the detail's
   *                          dimensions has a container-number.
   */
  private hasContainerNumbers = (detail: DetailViewModel): boolean => {
    return detail.Dimensions.some((dimension: any) => {
      return dimension.ContainerNumber != null;
    });
  };

  /**
   * Convenience-method to check if a dimension's NumUnits is set to a
   * positive numeric value.
   * @param  {Object} dimension   A dimension-object
   * @return {Boolean}
   */
  private validDimensionNumUnits = (dimension: DimensionViewModel): boolean => {
    if (!dimension.NumUnits) {
      return false;
    }
    return Number.isFinite(dimension.NumUnits) && dimension.NumUnits > 0;
  };

  private validDetailNumUnits = (detail: DetailViewModel): boolean => {
    if (!detail.NumUnits) {
      return false;
    }
    return Number.isFinite(detail.NumUnits) && detail.NumUnits > 0;
  };

  /**
   * Convenience-method to check if a dimension's Weight is set to a
   * positive numeric value.
   * @param  {Object} dimension   A dimension-object
   * @return {Boolean}
   */
  private validDimensionWeight = (dimension: DimensionViewModel): boolean => {
    if (!dimension.Weight) {
      return false;
    }
    return Number.isFinite(dimension.Weight) && dimension.Weight > 0;
  };

  /**
   * Validates required properties for a single dimension of an FCL-booking.
   * @param  {Object} dimension   Dimension whose properties to validate.
   * @return {Boolean}
   */
  private validateSingleDimensionFCL = (
    dimension: DimensionViewModel
  ): boolean => {
    const requiredStringValues = [dimension.PackageCode];

    if (!dimension.NumUnits || !dimension.Weight) {
      return false;
    }

    return (
      dimension.NumUnits > 0 &&
      dimension.Weight > 0 &&
      ArrayUtilities.onlyContainsNonEmptyStrings(requiredStringValues)
    );
  };

  /**
   * Validate hazard lines for a FCL booking.
   * Goes through each detail, and each dimension and validates each dimension's hazard lines
   * @param {Object} booking  BookingVM
   * @returns {Boolean}
   */
  private validateBookingFCLHazardLines = (
    booking: BookingViewModel
  ): boolean => {
    if (!this.hasDetails(booking)) {
      return false;
    }

    const hazardousDetails = booking.Details.filter(
      (detail: DetailViewModel) => detail.HazardFlag
    );

    return (
      hazardousDetails.length > 0 &&
      hazardousDetails.every((detail: any) => {
        return detail.Dimensions.every((dimension: any) => {
          return this.validateSingleDimensionHazardLines(
            detail.HazardFlag,
            dimension
          );
        });
      })
    );
  };

  private validateBookingFCLOversizeLines = (
    booking: BookingViewModel
  ): boolean => {
    if (!this.hasDetails(booking)) {
      return false;
    }

    const oversizeDetails = booking.Details.filter(
      (detail: DetailViewModel) => detail.OversizeFlag
    );

    return (
      oversizeDetails.length > 0 &&
      oversizeDetails.every((detail: any) => {
        return detail.Dimensions.some((dimension: any) => {
          return this.validateSingleDimensionOversizeLine(dimension);
        });
      })
    );
  };

  /**
   * Validate hazard lines for a LCL booking.
   * Goes through each detail and validates the first dimension's hazard lines
   * @param {Object} booking  BookingVM
   * @returns {Boolean}
   */
  private validateBookingLCLHazardLines = (
    booking: BookingViewModel
  ): boolean => {
    if (!this.hasDetails(booking)) {
      return false;
    }

    const hazardousDetails = booking.Details.filter(
      (detail: DetailViewModel) => detail.HazardFlag
    );

    return (
      hazardousDetails.length > 0 &&
      hazardousDetails.every((detail: any) => {
        return this.validateSingleDimensionHazardLines(
          detail.HazardFlag,
          detail.Dimensions[0]
        );
      })
    );
  };

  /** Validates oversize info for each of the detail's dimensions */
  private validateDetailOversizeInfo = (detail: DetailViewModel): boolean => {
    return detail.Dimensions.every((dimension: any) => {
      return this.validateOversizes(detail.PackageCode, dimension.Oversize);
    });
  };

  /**
   * Gets the total weight for a list of dimensions.
   * @param  {Array}  dimensions  Array of dimensions.
   * @return {Number}             Returns the dimensions' total weight.
   */
  private getDetailsTotalDimensionWeight = (dimensions: any): number => {
    return ArrayUtilities.sumBy(dimensions, "Weight");
  };

  /**
   * Gets the total volume for a list of dimensions.
   * @param  {Array}  dimensions  Array of dimensions.
   * @return {Number}             Returns the dimensions' total volume.
   */
  private getDetailsTotalDimensionVolume = (dimensions: any): number => {
    return ArrayUtilities.sumBy(dimensions, "Volume");
  };

  /**
   * Gets the total weight of hazardous items that have been added for
   * a dimension.
   * @param  {Object} dimension   The dimension whose weight of hazardous
   *                              items to aggregate.
   * @return {Number}             Returns the hazardous-items' total weight.
   */
  private getDimensionHazardousTotalWeight = (dimension: any): number => {
    return ArrayUtilities.sumBy(dimension.Hazardous, "Weight");
  };

  /**
   * Gets the container boxtype, e.g. FR (flat-rack), OT (open-top), etc.
   * from a packagecode string.
   * @param  {string} packageCode A valid containertype, e.g. 20RF, 40OT,
   *                              40HC, etc.
   * @return {string}             Returns the boxtype for the container if
   *                              the provided packageCode is valid container-type.
   */
  private getBoxType = (packageCode: string): string => {
    if (!this.isValidContainerType(packageCode)) {
      throw new Error(`"${packageCode}" is not a valid container-type`);
    }
    return packageCode.substr(-2, 2);
  };

  /**
   * Checks if a given string is a valid container-type.
   * @param  {string}  testString The string to validate.
   * @return {Boolean}            Returns true if the string is a valid
   *                              container-type e.g. 20RF, 40OT, 40HC, etc.
   */
  private isValidContainerType = (testString: string): boolean => {
    const containerLengthsPattern = `(${this.validContainerLengths.join("|")})`;
    const boxTypesPattern = `(${this.validContainerBoxTypes.join("|")})`;
    const re = new RegExp(`^${containerLengthsPattern}${boxTypesPattern}$`);
    return re.test(testString);
  };

  private isValidContainerNumber = (testString: string): boolean => {
    return ContainerService.isValidContainerNumber(testString);
  };

  // Private methods END
}

export default new BookingValidationService();
