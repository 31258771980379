import { UserService, ShipmentService } from "Services";
import { ArrayUtilities } from "Utilities";

import * as bulkSearchModalTemplate from "Shipments/modal/bulkSearchModal.html";
import * as angular from "angular";
import { $rootScope } from "ngimport";

declare var GlobalConfig: GlobalConfig;

const bulkSearchModal: ng.IComponentOptions = {
  template: `${bulkSearchModalTemplate}`,
  bindings: {
    modalInstance: "<",
    close: "&",
    dismiss: "&",
    resolve: "<",
  },
  controller: class BulkSearchModalController {
    private close: Function;
    private dismiss: Function;

    private foundBookings: any;
    private user: any;
    private selectedCompany: any;
    private dropdownValue: any;
    private textareaValue: any;
    private numberCountTotal: number;
    private numberCountNotFound: number;
    private notFoundBookings: any;
    private foundBookingsCount: any;
    private firstModal: boolean;
    private displayer: any;
    private dropdownValues: any;
    private partnerCode: any;

    $onInit = () => {
      this.user = UserService.getUserProfile();
      this.selectedCompany = UserService.getSelectedCompany();

      if (!this.user) return;

      this.partnerCode = this.selectedCompany.PartnerCode;

      this.dropdownValue = null;
      this.textareaValue = null;

      this.numberCountTotal = 0;
      this.numberCountNotFound = 0;
      this.notFoundBookings = [];
      this.foundBookingsCount = [];
      this.foundBookings = [];
      this.firstModal = true;

      this.displayer = {
        spinner: false,
        notFound: false,
        found: false,
      };

      this.dropdownValues = [
        {
          const: "LABEL_BOOKINGNUMBER",
          value: "JOBREFERENCE",
          findBy: "JobReference",
        },
        {
          const: "LABEL_CONSIGNMENTNUMBER",
          value: "CONSIGNMENTNUMBER",
          findBy: "ConsignmentNumber",
        },
        {
          const: "LABEL_CHASSISNUMBER",
          value: "CHASSISNUMBER",
          findBy: "ChassisNumber",
        },
        {
          const: "LABEL_CONTAINERNUMBER",
          value: "CONTAINERNUMBER",
          findBy: "JobReference",
        },
        {
          const: "LABEL_REFERENCENUMBER",
          value: "REFERENCENUMBER",
          findBt: "ReferenceNumber",
        },
      ];
    };

    // Start searching by search data and type value.
    submit = (): void => {
      this.firstModal = false;
      const searchData = this.splitSearchInput(this.textareaValue);
      this.getData(searchData);
    };

    // Display search results
    confirm = (): void => {
      this.close({ $value: this.foundBookings });
    };

    // Redirect user back to previous modal
    searchAgain = (): void => {
      this.firstModal = !this.firstModal;
      this.notFoundBookings = [];
      $rootScope.$digest();
    };

    /**
     * Finds job reference by consignee name from all found bookings
     * @param  {string} name [ConsigneeName]
     * @return {array}       [JobReference]
     */
    getJobReferenceByConsigneeName = (name: any): object => {
      const bookings: any[] = [];
      angular.forEach(this.foundBookings, (value) => {
        if (value.ConsigneeCompanyName === name) {
          bookings.push(value);
        }
      });

      const jobRefs = bookings.map((item: any) => {
        return item[this.dropdownValue.findBy];
      });

      return jobRefs;
    };

    // Uses search function in ShipmentService to get bulk list by partner code,
    // type value and complete search data.
    private getData = (searchData: string[]): void => {
      this.displayer.spinner = true;
      ShipmentService.searchBulkByList(
        this.partnerCode,
        this.dropdownValue.value,
        searchData
      )
        .then((data: any) => {
          this.seperateNotFoundAndFoundValuesFromResponse(data);
        })
        .catch(() => undefined)
        .then(() => {
          this.displayer.spinner = false;
          $rootScope.$apply();
        });
    };

    private seperateNotFoundAndFoundValuesFromResponse = (data: any): void => {
      this.countFoundAndTotalData(data.Found, data.NotFound);

      this.foundBookings = data.Found;
      this.notFoundBookings = data.NotFound;
      this.foundBookingsCount = this.groupAndCountCompanies(this.foundBookings);

      if (this.notFoundBookings.length > 0) {
        this.displayer.notFound = true;
      }
    };

    private groupAndCountCompanies = (companies: any[]): any => {
      return ArrayUtilities.countBy(companies, "ConsigneeCompanyName");
    };

    private countFoundAndTotalData = (found: any, notFound: any): void => {
      this.numberCountTotal = found.length;
      this.numberCountNotFound = found.length + notFound.length;
    };

    /**
     * Search string is split by following chars: .,;:
     * @param  {string} str [search string from textarea]
     * @return {object}     [search string object]
     */
    private splitSearchInput = (str: string): string[] => {
      const re = /[.,;:\s]/;
      return ArrayUtilities.without(str.split(re), [""]);
    };
  },
};

angular.module("serviceWebApp").component("bulkSearchModal", bulkSearchModal);
