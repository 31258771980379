import * as angular from "angular";
import { Object, Array } from "es6-shim";
import { $filter, $timeout, $interval } from "ngimport";
import { StateService, Transition } from "@uirouter/core";
import { USERWEBSETTINGS } from "Constants/UserWebSettingsConstants";

import "Shipments/createbooking/detailmodal";
import LoadingTextModalService from "Services/LoadingTextModalService";
import BookingValidationService from "Shipments/createbooking/BookingValidationService";

import "./dimensionmodal";
import "./imomodal";
import "./oversizemodal";
import "./registrationSuccessModal";
import "./registrationErrorModal";

import {
  UserService,
  SamskipNotify,
  CompanyService,
  TranslationService,
  ShipmentService,
  ShipmentRegistryService,
  LocationService,
  ShipmentDocumentService,
  ContainerService,
} from "Services";
import {
  ArrayUtilities,
  ModalUtilities,
  UtilityFunctions,
  WindowUtilities,
} from "Utilities";
import { $uiRouter, $uibModal } from "Bootstrap/angular";
import * as _ from "lodash";
import { DropdownItemProps } from "semantic-ui-react";
import { DocumentFile } from "Shipments/documentModal/interfaces";
import { BookingStore } from "Stores";
import { AddressFormDto } from "./addresspopup/interfaces.d";
import { HSCode, Terms } from "./interfaces";
import GeneralLoader from "Utilities/GeneralLoader";

declare var $: any;

function shipmentsCreateBookingController(
  $scope: any,
  $transition$: Transition,
  userCompanies: any[],
  incoTerms: any[],
  shippingTypes: any[],
  commodities: any[],
  containerTypes: any[],
  packageTypes: any[],
  shippingPorts: any[],
  booking: any,
  remark: any,
  allowedReeferTemperatures: any[],
  linkedRequests: any[],
  hsCodes: HSCode[]
) {
  const vm = this;
  const $state: StateService = $uiRouter.stateService;
  const currentState: string = $transition$.to().name || "";
  const currentStateParams: any = $transition$.params();

  const currentUser = UserService.getUserProfile();
  const selectedCompany = UserService.getSelectedCompany();

  const dimensionIcons = {
    needsAttention: "fa-exclamation-circle dimensionInfoIcon",
    valid: "fa-pencil-square-o",
    disabled: "fa-circle-thin",
  };
  vm.submitBookingSpinner = false;

  vm.showTermsConditionErrorMessage = false;

  vm.errors = {};

  vm.RouteToDashboard = function RouteToDashboard() {
    vm.forceRoute = true;
    $state.go("shipments_createbooking_dashboard");
  };

  // When editing draft, interval will be activated to
  // update the draft and then return promise.
  let draftIntervalPromise: any = false;
  const draftIntervalTimer = 30000;
  let draftTimer: any;
  let voyagePickerLocked: boolean;

  const ContainerTypes = ["F", "M"];

  // Keep information for samSaving component that will display
  // messages related to draft saving.
  const saveDraftNotifiers = {
    Nothing: {
      icon: "",
      text: "",
    },
    Saving: {
      icon: "fa fa-spinner fa-spin fa-1x",
      text: "DRAFT_BOOKING_SAVE",
    },
    Saved: {
      icon: "",
      text: "SUCCESS_DRAFT_BOOKING_SAVE",
      id: "",
    },
    Error: {
      icon: "",
      text: "ERROR_DRAFT_BOOKING_SAVE",
    },
  };

  // Show a popup when the user tries to navigate from the form before submitting
  $state.$current.onExit = () => {
    if (!vm.submitted && !vm.forceRoute) {
      return confirm("Changes you made may not be saved.");
    }
  };

  // samSaving component will get notified when
  // changes are made to this object.
  vm.saveDraftNotifier = saveDraftNotifiers.Nothing;

  // Action mode based on the state
  // Create,  Copy, Edit, Template, Quote
  const actionMode = {
    createBooking: () => {
      return $state.is("shipments_createbooking.default");
    },
    bookQuote: () => {
      return $state.is("shipments_createbooking.bookQuote");
    },
    bookTemplate: () => {
      return $state.is("shipments_createbooking.bookTemplate");
    },
    editBooking: () => {
      return $state.is("shipments_createbooking.edit");
    },
    copyBooking: () => {
      return $state.is("shipments_createbooking.copy");
    },
    editDraft: () => {
      return $state.is("shipments_createbooking.draft");
    },
  };
  // Temporary helpers for PLR and PFD
  // Only used for handling state.
  vm._PLR = {};
  vm._PFD = {};

  // Contains tab index for booking page
  vm.tabIndex = {
    shipper: 1,
    receiver: 2,
    notifierEdit: 3,
    notifierRemove: 4,
    notifier: 5,
    secondNotifierEdit: 6,
    secondNotifierRemove: 7,
    secondNotifier: 8,
    quoteReferenceNumber: 9,
    referenceNumber: 10,
    billType: 11,
    numOriginalBills: 12,
    numCopyBills: 13,
    countryOfOrigin: 14,
    comment: 15,
    PLR: 16,
    PLRinput: 17,
    PLRclear: 18,
    PFD: 19,
    PFDinput: 20,
    PFDclear: 21,
    POL: 22,
    POD: 23,
    incoTerms: 24,
    incoTermsPoint: 25,
    pickVoyageReference: 26,
    shippingType: 27,
    detailAdd: 28,
    submitButton: 29,
    submitFinalButton: 30,
  };

  /**
   * These functions should run after everything has been loaded and size of
   * HTML elements is known
   */
  $timeout(() => {
    UtilityFunctions.checkSizes(
      $(".js-ShipmentsCreateBookingSidebar"),
      $(".js-ShipmentsCreateBookingPage")
    );

    UtilityFunctions.catToggle();
    UtilityFunctions.filterSlideControl(
      "js-ShipmentsCreateBookingSidebar-triggerFilter",
      "js-ShipmentsCreateBookingSidebar",
      "LABEL_OPEN_CHECKLIST",
      "LABEL_CLOSE_CHECKLIST"
    );
  });

  // Store the currently selected shipping type
  let selectedShippingType: string;

  // Everytime we access this page, we initalize most of the stuff from initVM()
  function initVM(): void {
    vm.newBooking = (booking || {}) as BookingViewModel;
    vm.newBooking.Remark = remark == null ? null : remark;
    vm.isDraftMode = actionMode.editDraft();
    vm.isEmployee = UserService.isEmployee();

    // Terms & conditions are set to true if a user is an employee
    vm.areTermsAndConditionsAccepted = vm.isEmployee;
    // Set to true if user presses the submit shipping instrucion button
    vm.isSubmitShippingInstruction = false;

    // To control if we are in Quote, Template version of the createPage
    vm.IsTemplateBooking = actionMode.bookTemplate();

    vm.isQuoteBooking = actionMode.bookQuote();

    vm.currentUser = currentUser;

    vm.notifiers = [];

    vm.newBooking.IssueBookingCharge =
      vm.currentUser.Access.UserType === "C" ? false : true;

    selectedShippingType =
      BookingValidationService.createBookingShippingTypeMap[
        vm.newBooking.ShippingType
      ];

    // Initialize "private" properties for the booking's details
    initializeDetails(vm.newBooking.Details);

    // init OtherAddresses array
    if (!booking || !booking.OtherAddresses) {
      vm.newBooking.OtherAddresses = [];
    } else {
      vm.newBooking.OtherAddresses = booking.OtherAddresses.filter(
        (address: any) => {
          let shouldAdd = true;
          // Always remove DCD address from otheraddresses as it is always added again when submitting
          if (address.AddressType === "DCD") {
            shouldAdd = false;
          } else if (actionMode.copyBooking()) {
            // If the address is from an icelandic port and we are copying a booking, remove COL/DEL addresses
            shouldAdd = !(
              UtilityFunctions.isIcelandicPointCode(address.PointCode) &&
              (address.AddressType === "COL" || address.AddressType === "DEL")
            );
          }
          if (shouldAdd) {
            return address;
          }
        }
      );
    }

    // Store orignial address dates when editing for validation purposes
    if (booking) {
      const originalPFD = booking.OtherAddresses.find(
        (a: any) => a.AddressType === "DEL"
      );
      const originalPLR = booking.OtherAddresses.find(
        (a: any) => a.AddressType === "COL"
      );
      vm.originalAddressDates = {
        PFD: originalPFD
          ? {
              date: originalPFD.RequestDate,
              edited: false,
            }
          : null,
        PLR: originalPLR
          ? {
              date: originalPLR.RequsetDate,
              edited: false,
            }
          : null,
      };
    }

    vm.canAddSecondNotifier = booking && booking.Notifier;

    // Fetch the JobReference or QuoteReference from the state params
    vm.jobReference = currentStateParams.jobReference;
    vm.quoteReference = currentStateParams.quoteReference;
    vm.templateId = currentStateParams.templateId;

    // Get the selected partner
    // Everytime we load this page, we are setting the selected partner based on what partner the user has selected this is causing us issues
    // when we want to go into booking that might belong to another partner
    // Looking at the createbooking html you can see that we a dirty react2angular component called Company-Select-Reminder
    // We manage to call it via some callback function so it will trigger a modal to make you select the available companies based from the booking modal
    // So if we want it to pop up, we must set the type to copy, and trust in the CompanySelectReminderCallback

    const selectedPartner = getSelectedPartner();

    // Get the customers based on the shipper
    getCustomers(selectedPartner.PartnerCode);

    getNotifierAddresses(selectedPartner.PartnerCode);

    vm.BookingPartners = [];

    // Sets the initial state of the shipper/consignee toggle
    initializeShipperConsigneeToggle();

    if (actionMode.bookQuote()) {
      vm.isShipper = vm.newBooking.Shipper?.PartnerCode ? true : false;
    }
    // Need to decide if the bookingParty that made the Template is either the Shipper or Consignee
    // The incoming Template will always have a BookingParty, If it's not the shipper, then it's the Consignee.
    if (actionMode.bookTemplate()) {
      vm.isShipper =
        vm.newBooking.Shipper?.PartnerCode === vm.newBooking.BookingParty
          ? true
          : false;
    }

    if (vm.isShipper) {
      vm.TemplateId = actionMode.bookTemplate()
        ? vm.newBooking.TemplateId
        : null;
      // Initialize the shipper as the selected company
      initializeShipper(selectedPartner);
      vm.QuoteReference = actionMode.bookQuote()
        ? vm.newBooking.QuoteReference
        : vm.newBooking.Shipper?.SpecialInstructions;
    } else {
      initializeConsignee(selectedPartner);
      vm.QuoteReference = actionMode.bookQuote()
        ? vm.newBooking.QuoteReference
        : vm.newBooking.Consignee?.SpecialInstructions;
    }

    if (vm.newBooking.BookingPartyType !== "FOR") {
      vm.newBooking.BookingPartyType = vm.isShipper ? "SHP" : "CEE";
      vm.newBooking.BookingParty = vm.isShipper
        ? vm.newBooking.Shipper.PartnerCode
        : vm.newBooking.Consignee.PartnerCode;
    }

    vm.newBooking.Shipper &&
      vm.BookingPartners.push({ type: "SHP", partner: vm.newBooking.Shipper });
    vm.newBooking.Consignee &&
      vm.BookingPartners.push({
        type: "CEE",
        partner: vm.newBooking.Consignee,
      });

    // Check if the booking has a forwarding address and set booking party accordingly
    if (actionMode.copyBooking() || actionMode.editBooking()) {
      if (vm.newBooking.BookingPartyType === "FOR") {
        const forwarder = vm.newBooking.OtherAddresses.find(
          (address: any) => address.AddressType === "FOR"
        );
        if (forwarder) {
          vm.newBooking.BookingPartyType = "FOR";
          vm.newBooking.BookingParty = forwarder.PartnerCode;
          vm.newBooking.Forwarder = forwarder;
        }
      }
    }

    // The array that the samPartnerInfo directive uses for the
    // booking's shippers.
    vm.userCompanies = userCompanies;
    vm.selectedPartner = selectedPartner;

    // Get a list of inco terms (e.g. FOB, CIF, etc.)
    vm.incoTermsList = filterIncoterms();

    vm.portsList = shippingPorts.map((item) => {
      return {
        key: item.PointCode,
        value: item.PointCode,
        text: `${item.FullName}, ${item.Country} (${item.PointCode})`,
      } as DropdownItemProps;
    });

    // Inco terms point list
    vm.incoTermsPointList = [];
    vm.incoTermsPoint = booking ? booking.incoTermsPoint : undefined;

    // Get a list of shipping types (e.g. FCL-FCL, etc.)
    shippingTypes.forEach((item) => {
      const value = [item.First, item.Second].join(" ");
      item._display = ShipmentService.convertShippingTypeToLongFormat(value);
      item._value = value;
    });

    vm.shippingTypesList = shippingTypes;

    // Set the options for BOL-type selection with correct translations
    vm.bolTypeOptions = [
      {
        type: "SWB",
        value: "W",
        label: "LABEL_SEAWAYBILL",
      },
      {
        type: "B/L",
        value: "G",
        label: "LABEL_BILLOFLADING",
      },
    ];

    vm._showDimensionActions = isContainerTypes(selectedShippingType);

    // Fetch documents when editing a booking
    if (actionMode.editBooking()) {
      ShipmentDocumentService.getDocuments(booking.JobReference).then(
        (data) => {
          vm.bookingDocuments = data;
        }
      );
    }

    vm.documentsToUpload = [] as DocumentFile[];
    vm.showDocumentModal = false;
    vm.showTermsConditionsModal = false;

    vm.showAddressPopup = {};
    vm.newAddress = {};

    vm.showInstructionsConfirmModal = false;
    vm.showUnlinkQuoteModal = false;

    // Needed to hold information about containers for FCL bookings
    vm.vgmContainerInfoMap = {};
    vm.vgmEdited = false;
    vm.loadingVGM = false;

    vm.newBooking.IssueExportDocuments = vm.newBooking.IssueExportDocuments
      ? vm.newBooking.IssueExportDocuments
      : false;

    vm.collectionSameAsShipper =
      vm.newBooking.OtherAddresses.length > 0
        ? vm.CompareAddresses(
            vm.newBooking.Shipper,
            vm.newBooking.OtherAddresses.find(
              (address: any) => address.AddressType === "COL"
            )
          )
        : false;

    vm.deliverySameAsConsignee =
      vm.newBooking.OtherAddresses.length > 0
        ? vm.CompareAddresses(
            vm.newBooking.Shipper,
            vm.newBooking.OtherAddresses.find(
              (address: any) => address.AddressType === "DEL"
            )
          )
        : false;

    vm.isVoyagePickerTooltipOpen = false;
    vm.voyagepickerIsOpen = false;
    vm.hsCodeList = hsCodes;

    // if we are getting HS codes, then transform them into list of ids
    if (vm.newBooking.HSCodes) {
      vm.newBooking.HSCodes = vm.newBooking.HSCodes.map(
        // If the element is an object, extract the HSCode property; otherwise, assume it's already an ID
        (hs: HSCode | string) => (typeof hs === "object" ? hs.HSCode : hs)
      );
    }

    resetFocus();
    // Set the initial validation-icons for dimensions
    validateBookingDetails();
    // Set the initial IncoTermsPoint when editing an exisiting booking
    updateIncoTerms();

    setSubmitHandler();

    // When working with a template, we need to know if the Partner is "NEW" or "PREVIOUS" based on what the Template did save
    // We also need to make sure to do the same check for the first Notifier, we don't care about the second notifier at least for now
    // Setting the newAddress as true allows the right modals to pop up
    // At the same time we need to set the incoming template Remarks/Comments

    if (vm.IsTemplateBooking) {
      if (vm.newBooking.Consignee != null && vm.newBooking.Shipper != null) {
        initializePartnerAddress();
      }

      if (vm.newBooking.Notifier != null) {
        initializeNotifierAddress();
      }
      vm.newBooking.Remark = vm.newBooking.BookingTemplateRemark;
    }
  }

  function initializePartnerAddress() {
    if (vm.isShipper) {
      if (vm.newBooking.Consignee.PartnerAddressType === "NEW") {
        vm.newAddress["Consignee"] = true;
      }
    } else {
      if (vm.newBooking.Shipper.PartnerAddressType === "NEW") {
        vm.newAddress["Shipper"] = true;
      }
    }
  }

  function initializeNotifierAddress() {
    if (vm.newBooking.Notifier.PartnerAddressType === "NEW") {
      return (vm.newAddress["Notifier"] = true);
    }
    if (vm.newBooking.Notifier.PartnerAddressType === "PREVIOUS") {
      return (vm.newAddress["Notifier"] = false);
    }
  }

  /**
     *  Gets information about the selected shipper, because it's
     *  different based on if we're creating a new booking or editing/copying.
     *  Create: Selected company
     *  Edit: Shipper in the booking
     *  Copy: Shipper in the booking OR selected company
     *  Quote: Shipper from booking OR selected company 
     *  Template: Shipper from booking OR selected company 

        @return {object} [Shipper object]
     */
  function initializeShipper(partner: any): void {
    if (actionMode.createBooking()) {
      vm.newBooking.Shipper = partner;
    }
    if (vm.isDraftMode || actionMode.copyBooking()) {
      if (vm.newBooking.Shipper == null) {
        vm.newBooking.Shipper = partner;
      }
      return vm.newBooking.Shipper;
    }
    return vm.newBooking.Shipper;
  }

  function initializeConsignee(partner: any): void {
    if (actionMode.createBooking()) {
      vm.newBooking.Consignee = partner;
    }
    if (vm.isDraftMode || actionMode.copyBooking()) {
      if (vm.newBooking.Consignee == null) {
        vm.newBooking.Consignee = partner;
      }
      return vm.newBooking.Consignee;
    }
    return vm.newBooking.Consignee;
  }

  function initializeShipperConsigneeToggle(): void {
    vm.showShipperConsigneeToggle =
      !booking ||
      UtilityFunctions.isBookingParty(selectedCompany.PartnerCode, booking);
    if (booking && vm.showShipperConsigneeToggle) {
      vm.isShipper =
        vm.newBooking.Shipper &&
        selectedCompany.PartnerCode === vm.newBooking.Shipper.PartnerCode;
    } else {
      if (actionMode.createBooking()) {
        // Get Shipper/Consignee toggle button state
        const value = UserService.getLocalWebSettingValue(
          USERWEBSETTINGS.BookingCreation_DefaultShipperConsignee
        );
        // The user is set as the shipper default if there is no websetting found
        vm.isShipper = value === "Shipper" || !value;
      } else if (currentUser && currentUser.Access.UserType === "E") {
        if (
          actionMode.editBooking() ||
          actionMode.copyBooking() ||
          actionMode.bookTemplate()
        ) {
          vm.isShipper = booking.BookingPartyType === "SHP";
        } else if (actionMode.editDraft()) {
          vm.isShipper = booking.BookingParty === booking.Shipper.PartnerCode;
        }
      }
    }
  }

  function getSelectedPartner(): any {
    const selectedShipper =
      userCompanies.find(
        (company: any) => company.PartnerCode === selectedCompany.PartnerCode
      ) || userCompanies[0];
    return selectedShipper;
  }

  /**
   * Set the click-handler for the Create/Update button depending on the
   * current state/url, i.e. whether to create a new booking in Doris or
   * to update an existing one.
   */
  function setSubmitHandler(): void {
    if (
      actionMode.createBooking() ||
      actionMode.copyBooking() ||
      actionMode.bookQuote() ||
      actionMode.bookTemplate() ||
      vm.isDraftMode
    ) {
      vm.submitBooking = createBooking;
      vm.submitBookingText = "LABEL_SUBMIT_BOOKING";
    } else if (actionMode.editBooking()) {
      vm.submitBooking = updateBooking;
      vm.submitBookingText = "LABEL_UPDATE_BOOKING";
    }
  }

  /**
   * Set flag to detemine if COL/DEL should be the same as SHP/CEE addresses
   * @param {string} type     type of address
   * @param {boolean} value   value to be set
   */
  vm.setUseShipperConsigneeFlag = function setUseShipperConsigneeFlag(
    type: string,
    value: boolean
  ) {
    if (type === "PLR") {
      vm.collectionSameAsShipper = value;
    } else {
      vm.deliverySameAsConsignee = value;
    }
    $scope.$digest();
  };

  /**
   * Compare 2 addresses and see if they are the same
   * @param address1    Address to compare
   * @param address2    Address to compare
   */
  vm.CompareAddresses = function CompareAddresses(
    address1: any,
    address2?: AddressFormDto
  ): boolean {
    if (!address1 || !address2) return false;
    const propertiesToCheck = [
      "PartnerCode",
      "Address1",
      "Address2",
      "Address3",
      "Address4",
      "PostCode",
    ];
    const result = propertiesToCheck.every((property) => {
      if (address1[property] === "" && address2[property] === null) return true;
      return address1[property] === address2[property];
    });
    return result;
  };
  /**
   * shipperChanged is called whenever the vm.shipper object has been
   * updated (using the partnerInfo directive). If the shipper's
   * PartnerCode property has changed, we get known consignees for that
   * particular partnercode.
   * @param  {object} newShipper      The updated shipper model.
   */
  vm.shipperChanged = function shipperChanged(newShipper: any): void {
    vm.newBooking.Shipper = newShipper;

    if (vm.isShipper) {
      // Get a list of known customers for the selected shippers partnerCode
      getCustomers(newShipper.PartnerCode);
    }
    if (vm.collectionSameAsShipper) vm.onAddressSubmit(newShipper, "COL");
    $scope.$digest();
  };

  /**
   * consigneeChanged is called whenever the vm.consignee object has been
   * updated (using the partnerInfo directive). If the consignee's
   * PartnerCode property is undefined, we know that the consignee is a
   * new partner.
   * @param  {object} newConsignee      The updated consignee model.
   */
  vm.consigneeChanged = function consigneeChanged(newConsignee: any): void {
    vm.newBooking.Consignee = newConsignee;

    if (!vm.isShipper) {
      // Get a list of known customers for the selected consignees partnerCode
      getCustomers(newConsignee.PartnerCode);
    }
    if (vm.deliverySameAsConsignee) vm.onAddressSubmit(newConsignee, "DEL");
    $scope.$digest();
  };

  /**
   * Search countries
   * @param  {String} searchString Search string (country name)
   * @return {Promise}             Promise with a list of countries
   */
  vm.searchCountryOfOrigin = function searchCountryOfOrigin(
    searchString: string
  ): SamskipPromise<any[]> {
    return ShipmentRegistryService.searchCountries(searchString).then(
      (data: any) => {
        return data;
      }
    );
  };

  /**
   * Triggered when countryOfOrigin typeahead is blurred (removed focus).
   * When the countryOfOrigin has no valid model and the typeahead is not open,
   * we clear the value.
   */
  vm.unfocusCountryOfOrigin = function unfocusCountryOfOrigin(): void {
    if (vm.newBooking.CountryOfOrigin == null) {
      vm.newBooking.CountryOfOrigin = undefined;
    }
  };

  /**
   * Event handler for when value in ports typeaheads has been changed.
   * NOTE: It does not run when a value is selected from the typeaheads,
   * only when the user makes some manual change to the input.
   */
  vm.portsChanged = function portsChanged(): void {
    vm.incoTermsList = filterIncoterms();
    updateIncoTerms();

    // Always reset selected voyage when ports change
    vm.newBooking.VoyageReference = null;
  };

  /**
   * Event handler for when value in place typeaheads has been changed.
   * NOTE: It does not run when a value is selected from the typeaheads,
   * only when the user makes some manual change to the input.
   * @param  {string} placeType   Type of place
   */
  vm.placeChanged = function placeChanged(): void {
    vm.incoTermsList = filterIncoterms();
    updateIncoTerms();
  };

  /**
   * Event handler to display a place
   * @param  {string} placeType   Type of place
   */
  vm.displayPlace = function displayPlace(placeType: string): void {
    vm[`_${placeType}`].toggled = true;

    // Set focus on the element in the next digest loop
    $timeout(() => {
      WindowUtilities.focusByName(placeType);
    });
  };

  /**
   * Event handler when user clears the value in a place typeahead
   * @param  {string} placeType   Type of place
   */
  vm.clearPlace = function clearPlace(placeType: string): void {
    vm.newBooking[placeType] = null;
    vm[`_${placeType}`].toggled = false;
    setFocusOnId(placeType);
    vm.placeChanged(placeType);
  };

  /**
   * Triggered when PLR/PFD typeaheads are blurred (removed focus).
   * When the place has no valid model and the typeahead is not open,
   * we clear the place and "untoggle" it, so the button is displayed.
   * @param  {string} placeType   Type of place
   */
  vm.unfocusPlace = function unfocusPlace(placeType: string): void {
    if (!vm.newBooking[placeType] && !vm[`_${placeType}`].isOpen) {
      vm.clearPlace(placeType);
    }
  };

  /**
   * Event handler when value in inco terms dropdown or some value
   * in place typeaheads has been changed/selected
   * @param  {string} placeType   Type of place
   */
  vm.updateIncoTerms = function (): void {
    updateIncoTerms();
  };

  /**
   * Determines if the voyagePicker should be enabled/disabled
   * NOTE: Runs in each $digest cycle
   * @return {bool}   Returns true if the voyagePicker should be disabled,
   *                          otherwise false.
   */
  vm.voyagePickerDisabled = function voyagePickerDisabled(): boolean {
    let containerNumbers;

    /* If _voyagePickerLocked was set at any point, the voyagePicker should be disabled and
     * STAY disabled. This way we won't have to do any extra work in each $digest cycle
     * (get the shippingType, check if containers have been added, etc.)
     */
    if (voyagePickerLocked) {
      return true;
    }

    // Voyage should not be changed after containers have been added to an LCL-booking
    if (!isContainerTypes(selectedShippingType)) {
      containerNumbers = getAllContainerNumbersFromAllDetails();
      const compactNumbers = ArrayUtilities.compact(containerNumbers);
      if (compactNumbers.length > 0) {
        voyagePickerLocked = true;
      }
    }
    return (
      vm.newBooking.POD == null ||
      vm.newBooking.POL == null ||
      _.isEqual(vm.newBooking.POL, vm.newBooking.POD)
    );
  };

  /**
   * Event handler for when the booking's shipping type is changed.
   */
  vm.shippingTypeChanged = function shippingTypeChanged(): void {
    selectedShippingType =
      BookingValidationService.createBookingShippingTypeMap[
        vm.newBooking.ShippingType
      ];
    vm._showDimensionActions = isContainerTypes(selectedShippingType);
  };

  /**
   * Submit shipping instructions is pressed. IFTMIN is created.
   * Is only done when booking is totally finished.
   */
  vm.addBookingInstructions =
    async function addBookingInstructions(): Promise<any> {
      vm.closeInstructionsConfirmModal();
      LoadingTextModalService.modalON("LABEL_SUBMITTING_BOOKING_INSTRUCTIONS");
      let callback: Function;
      try {
        const newBooking = _.cloneDeep(vm.newBooking);

        // Transform the string list of HSCodes to HSCode object
        newBooking.HSCodes = getHSObjectsFromCode(vm.newBooking.HSCodes);
        addDecidingPartyAddress(newBooking.OtherAddresses);
        setBookingParty(newBooking);
        const instructions = await ShipmentService.addBookingInstructions(
          newBooking
        );
        addNewAddressesToPartner();
        createOrUpdateRemark(instructions);
        await addDocuments(instructions);
        await updateVGM(instructions);
        await ShipmentService.acceptBookingTerms(
          "BOOKING_CREATE",
          instructions
        );

        callback = () => {
          displayCreateOrUpdateSuccess(
            instructions,
            "TEXT_BOOKING_INSTRUCTIONS_X_SENT"
          );
        };

        deleteDraft();
      } catch (e) {
        callback = () => {
          SamskipNotify.displayError("ERROR_CREATE_SHIPPING_INSTRUCTION");
        };
      } finally {
        vm.submitted = true;
        window.onbeforeunload = null;
      }

      LoadingTextModalService.modalOFF()
        .then(() => {
          $timeout(() => {
            callback();
          }, 1000);
        })
        .catch(() => {});
    };

  /**
   * Opens the detail-modal to create a fresh detail-instance.
   */
  vm.addDetail = function addDetail(): void {
    // Open the detail-modal with null explicitly for a new detail
    openDetailModal(null);
  };

  /**
   * Edit currrent detail which is part
   * of newBooking object
   * @param  {object} detail [detail object]
   */
  vm.editDetail = function editDetail(detail: DetailViewModel): void {
    openDetailModal(detail);
  };

  vm.editButton = function editButton(errors: any): string {
    if (errors && errors.length > 0) {
      return "fa fa-circle fa-stack-2x needsAttention";
    }
    return "fa fa-circle fa-stack-2x";
  };

  vm.dimensionEditButton = function dimensionEditButton(
    dimension: any
  ): string {
    if (!vm.checklist.validateDimensionRow(dimension)) {
      return "btn btn-link DimensionActions-button needsAttention";
    }
    return "btn btn-link DimensionActions-button";
  };

  /**
   * Opens a modal to edit a detail-line for the booking
   * @param  {object} detail [detail object]
   * @return {modalInstance} [Modal instance for editing detail]
   */
  function openDetailModal(detail: DetailViewModel | null): void {
    const modalData = {
      backdrop: "static",
      windowClass: "sam-modal ShipmentDetailDimensionModals",
    };

    const resolve = {
      commodities: () => {
        return commodities;
      },
      packageTypes: () => {
        return getPackageOrContainerType();
      },
      currentDetail: () => {
        return detail;
      },
      shippingType: () => {
        return selectedShippingType;
      },
      allowedReeferTemperatures: function _allowedReeferTemperatures() {
        return allowedReeferTemperatures;
      },
      POL: function _POL() {
        return vm.newBooking.POL;
      },
      isBookingFromQuote: function _isBookingFromQuote() {
        return vm.isQuoteBooking;
      },
      isBookingFromTemplate: function _isBookingFromTemplate() {
        return vm.isTemplateBooking;
      },
    };

    const modalInstance = ModalUtilities.showLargeModal(
      "shipmentCreateBookingDetailModal",
      resolve,
      modalData
    );

    modalInstance.then((updatedDetail: DetailViewModel) => {
      const isNewDetail = detail === null;
      const bookingDetail: DetailViewModel = detailsHandler(updatedDetail);
      addDetailToBooking(detail, bookingDetail, isNewDetail);

      validateBookingDetails();
    });

    function addDetailToBooking(
      oldDetail: any,
      newDetail: DetailViewModel,
      isNewDetail: boolean
    ) {
      if (isNewDetail) {
        if (!vm.newBooking.Details) {
          vm.newBooking.Details = [];
        }
        vm.newBooking.Details.push(newDetail);
      } else {
        Object.assign(detail, newDetail);
      }
    }

    function detailsHandler(theDetail: DetailViewModel): DetailViewModel {
      const detail = Object.assign({}, theDetail) as DetailViewModel;

      // We always have to set the weight to 0 if this is a MTY-MTY booking,
      // otherwise DORIS doesn't validate the booking
      if (selectedShippingType === "M") {
        detail.TotalOriginNetWeight = 0;
      }

      // LCL bookings only contains single dimension
      if (selectedShippingType === "L") {
        detail.Dimensions[0] = Object.assign({}, createSingleDimension(detail));
      } else {
        detail.Dimensions = detail.Dimensions.concat(
          createContainerDimensions(detail)
        );
      }

      return detail;

      /**
       * Creates dimensions that are missing for an FCL-detail.
       * Example:
       *  1) A detail's NumUnits is set to 4 but the number of dimensions is 2, then 2 more
       *      dimensions are created.
       *  2) A detail's NumUnits is set to 3 but it has no dimensions, then 3 dimensions will be
       *      created.
       * @param  {Object} parentDetail    A detail to create dimensions for.
       * @return {Array}                  Returns an array of new dimensions.
       */
      function createContainerDimensions(
        parentDetail: DetailViewModel
      ): DimensionViewModel[] {
        const numUnits =
          parentDetail.NumUnits && parentDetail.NumUnits >= 1
            ? parentDetail.NumUnits
            : 1;
        const newDimensions: DimensionViewModel[] = [];
        let missingDimensions: number | undefined;
        let missingWeight: number | undefined;
        let missingVolume: number | undefined;
        let totalWeight: number | undefined;
        let totalVolume: number | undefined;
        let distributedWeight: number | null | undefined;
        let distributedVolume: number | null | undefined;

        parentDetail.Volume = parentDetail.Volume || null;

        // Calculate how many dimensions are missing compared to the
        // detail's NumUnits
        missingDimensions = numUnits - parentDetail.Dimensions.length;

        // Get the total weight and volume of the dimensions that have
        // already been added to the detail
        totalWeight = ArrayUtilities.sumBy(parentDetail.Dimensions, "Weight");
        totalVolume = ArrayUtilities.sumBy(parentDetail.Dimensions, "Volume");

        // Check if weight and/or volume is missing
        missingWeight =
          parentDetail.TotalOriginNetWeight &&
          parentDetail.TotalOriginNetWeight > totalWeight
            ? parentDetail.TotalOriginNetWeight - totalWeight
            : undefined;
        missingVolume =
          parentDetail.Volume && parentDetail.Volume > totalVolume
            ? parentDetail.Volume - totalVolume
            : undefined;

        // Calculate the distributed weight and volume
        distributedWeight = missingWeight
          ? Math.round(missingWeight / missingDimensions)
          : null;
        distributedVolume =
          missingVolume && missingVolume > 0
            ? missingVolume / missingDimensions
            : null;

        for (let i = 0; i < missingDimensions; i += 1) {
          newDimensions.push({
            Weight: distributedWeight,
            Volume: distributedVolume,
          });
        }

        return newDimensions;
      }

      /**
       * Creates single dimension from certain detail properties
       * @param {Object} detail A detail to create dimensions for.
       * @return {Object}       Returns single dimension.
       */
      function createSingleDimension(
        detail: DetailViewModel
      ): DimensionViewModel {
        let newDimension: DimensionViewModel = {
          PackageCode: detail.PackageCode,
          NumUnits: detail.NumUnits,
          Weight: detail.TotalOriginNetWeight,
          Volume: detail.Volume,
        };

        // If we have VGM information make sure to store it as well
        if (detail.Dimensions.length > 0) {
          newDimension.VgmWeight = detail.Dimensions[0].VgmWeight;
          newDimension.VgmCargoWeight = detail.Dimensions[0].VgmCargoWeight;
          newDimension.VgmSignature = detail.Dimensions[0].VgmSignature;
          newDimension.VgmDate = detail.Dimensions[0].VgmDate;
          newDimension.VgmLocation = detail.Dimensions[0].VgmLocation;
          newDimension.VgmMethod = detail.Dimensions[0].VgmMethod;
          newDimension.VgmAuthNo = detail.Dimensions[0].VgmAuthNo;
          newDimension.VgmSender = detail.Dimensions[0].VgmSender;
        }

        return newDimension;
      }
    }
  }

  /**
   * Display confirmation modal and remove detail from newBooking
   * by index if modal is confirmed.
   * @param {number} index of detail in newBooking object
   */
  vm.removeDetail = function removeDetail(index: number): void {
    const modalInstance = UtilityFunctions.confirmModal(
      "TEXT_CONFIRM_DELETE_ENTRY",
      "TEXT_DELETE_DETAIL",
      "LABEL_CANCEL",
      "LABEL_PROCEED"
    );
    modalInstance.then(() => {
      focusElementAboveDetail(index);
      vm.newBooking.Details.splice(index, 1);
      validateBookingDetails();
    });
  };

  /**
   * Dislplay confirmation modal and remove dimension from newBooking
   * by index if modal is confirmed
   * @param  {number} index of dimension in newBooking object
   */
  vm.removeDimension = function removeDimension(
    detailIndex: number,
    dimensionIndex: number
  ): void {
    if (!isContainerTypes(selectedShippingType)) {
      return;
    }

    const modalInstance = UtilityFunctions.confirmModal(
      "TEXT_CONFIRM_DELETE_ENTRY",
      "TEXT_DELETE_DIMENSION",
      "LABEL_CANCEL",
      "LABEL_PROCEED"
    );

    modalInstance.then(() => {
      focusElementAboveDimension(detailIndex, dimensionIndex);
      removeVGMInfo(detailIndex, dimensionIndex);
      vm.newBooking.Details[detailIndex].Dimensions.splice(dimensionIndex, 1);
      updateDetail(vm.newBooking.Details[detailIndex]);
      validateBookingDetails();
    });
  };

  function removeVGMInfo(detailIndex: number, dimensionIndex: number) {
    const containerNumber =
      vm.newBooking.Details[detailIndex].Dimensions[dimensionIndex]
        .ContainerNumber;
    if (containerNumber) {
      vm.vgmContainerInfoMap[containerNumber] = null;
    }
  }

  /**
   * Focus element above dimension, wheather it is detail or dimension.
   * @param  {int} detailIndex    [Index of current detail]
   * @param  {int} dimensionIndex [Index of current dimension]
   */
  function focusElementAboveDimension(
    detailIndex: number,
    dimensionIndex: number
  ): void {
    let focusId = "";
    if (dimensionIndex > 0) {
      focusId = generateDimensionId(detailIndex, dimensionIndex - 1);
    } else {
      focusId = generateDetailId(detailIndex);
    }
    WindowUtilities.focusByName(focusId);
  }

  /**
   * Focus element above detail, wheather it is detail or dimension.
   * @param  {int} detailIndex [Index of current detail]
   */
  function focusElementAboveDetail(detailIndex: number): void {
    let focusId = "addDetail";
    if (detailIndex > 0) {
      if (!isContainerTypes(selectedShippingType)) {
        /*
         * When an LCL-detail is being deleted and we want to set
         * focus to the detail before the deleted detail, we have to
         * check if that detail is active (i.e. the trashcan-icon is
         * enabled). If it is enabled focus is set to the previous
         * detail's remove-icone, otherwise the previous detail's
         * edit-icon.
         */
        if (vm.newBooking.Details[detailIndex - 1]._locked) {
          focusId = `editDetailBtn-${detailIndex - 1}`;
        } else {
          focusId = generateDetailId(detailIndex - 1);
        }
      } else {
        focusId = getLastDimensionFocusIdInDetail(detailIndex - 1);
      }
    }
    WindowUtilities.focusByName(focusId);
  }

  /**
   * Find last dimension in current detail, referenced by 'detailIndex'
   * @param  {int} detailIndex    [Index of current detail]
   * @return {string}             [Id to focus on]
   */
  function getLastDimensionFocusIdInDetail(detailIndex: number): string {
    if (vm.newBooking.Details[detailIndex].Dimensions != null) {
      const newDimensionIndex =
        vm.newBooking.Details[detailIndex].Dimensions.length - 1;
      return generateDimensionId(detailIndex, newDimensionIndex);
    }
    // If no dimension exist, then get focudId for detail above
    return generateDetailId(detailIndex);
  }

  function generateDetailId(detailIndex: number): string {
    const focusId = `detailTrash-${detailIndex}`;
    return focusId;
  }

  function generateDimensionId(
    detailIndex: number,
    dimensionIndex: number
  ): string {
    const focusId = `dimensionTrash-${detailIndex}-${dimensionIndex}`;
    return focusId;
  }

  /**
   * Opens a modal to edit a dimension-line for the booking
   */
  vm.openDimensionModal = function openDimensionModal(
    dimensionObj: DimensionViewModel,
    detailPackageCode: any,
    detail: DetailViewModel
  ): void {
    // You shouldn't be able to open dimension-modal for LCL-bookings
    if (!isContainerTypes(selectedShippingType)) {
      return;
    }

    const modalInstance = ModalUtilities.showExtraLargeModal(
      "dimensionFCL",
      {
        dimension: function _dimension() {
          return dimensionObj;
        },
        containerType: function _containerType() {
          return detailPackageCode;
        },
        packageTypes: function _packageTypes() {
          return packageTypes;
        },
        shippingType: function _shippingType() {
          return selectedShippingType;
        },
        containerNumbers: function _containerNumbers() {
          return getAllContainerNumbersFromAllDetails();
        },
        oversizeFlag: function _oversizeFlag() {
          return detail.OversizeFlag;
        },
      },
      {
        windowClass: "sam-modal ShipmentDetailDimensionModals",
        size: "xlg",
        backdrop: "static",
      }
    );

    // Update the dimension object on submit/save
    modalInstance.then((updatedDimension: any) => {
      Object.assign(dimensionObj, updatedDimension);
      updateDetail(detail);
      validateBookingDetails();
    });
  };

  /**
   * Update detail information compared according to
   * dimensions information in particular detail
   * @param {object} detail   [Detail object]
   */
  function updateDetail(detail: DetailViewModel): void {
    let weight = 0;
    let volume = 0;
    let count = 0;
    detail.Dimensions.forEach((value: any) => {
      weight = weight + value.Weight;
      volume = volume + value.Volume;
      count += 1;
    });
    if (weight > 0) {
      detail.TotalOriginNetWeight = Math.round(weight);
    }
    if (volume > 0) {
      detail.Volume = volume;
    }
    detail.NumUnits = count;
  }

  /**
   * Edit hazard records for a single dimension
   * @param  {object} dimension Object containing a single dimension
   */
  vm.openHazardModal = function openHazardModal(dimension: any): void {
    const modalInstance = ModalUtilities.showExtraLargeModal(
      "imomodal",
      {
        dimension: function _dimension() {
          return dimension;
        },
        packageTypes: function _packageTypes() {
          return packageTypes;
        },
        locked: function _locked() {
          // If any of an LCL-detail's dimensions has received a
          // containernumber, their IMO-details cannot be altered.
          const parent: any = getParentDetail(dimension);
          return (
            !isContainerTypes(selectedShippingType) &&
            parent.Dimensions.some((dim: any) => {
              return dim.ContainerNumber != null;
            })
          );
        },
        shippingType: function _shippingType() {
          return selectedShippingType;
        },
      },
      {
        windowClass: "sam-modal ShipmentsCreateBookingImoModal",
        backdrop: "static",
      }
    );

    modalInstance
      .then(() => this)
      .catch(() => this)
      .then(() => validateBookingDetails());
  };

  /**
   * Event handler when value in inco terms dropdown has been changed
   * We call the API and get allowed locations
   */
  function updateIncoTerms(): void {
    vm.incoTermsPointList = [];
    const tempArr: any[] = [];

    if (vm.newBooking.IncoTerms) {
      // We have selected some inco term in the UI, then we call the API
      // and get allowed locations for the selected inco term
      const selectedIncoTerms = incoTerms.find(
        (it) => it.TermsCode === vm.newBooking.IncoTerms
      );

      if (!selectedIncoTerms.PrimaryIncotermsPoint) {
        // If the inco term doesn't contain any primary, we loop through
        // the 'Allowed' array with >=1 elements and push them into an
        // temporary array
        selectedIncoTerms.AllowedIncotermsPoints.forEach(
          (item: any, idx: number) => {
            // Make sure there is some model selected
            const selectedModel =
              vm.newBooking[selectedIncoTerms.AllowedIncotermsPoints[idx]];
            if (vm.newBooking[selectedIncoTerms.AllowedIncotermsPoints[idx]]) {
              tempArr.push(selectedModel);
            }
          }
        );
      } else {
        // If the inco term cotnains some primary, we fetch the primary
        // and secondary
        const primary = selectedIncoTerms.PrimaryIncotermsPoint;
        const secondary = ArrayUtilities.without(
          selectedIncoTerms.AllowedIncotermsPoints,
          selectedIncoTerms.PrimaryIncotermsPoint
        )[0];

        // If the primary (e.g. PFD) is selected in the UI
        // we use that, otherwise we use the secondary (e.g. POD)
        if (vm.newBooking[primary]) {
          tempArr.push(vm.newBooking[primary]);
        } else if (vm.newBooking[secondary]) {
          tempArr.push(vm.newBooking[secondary]);
        }
      }
      // Get only elements that are unique based on PointCode
      vm.incoTermsPointList = ArrayUtilities.uniqueBy(tempArr, "PointCode");

      // Set the selected inco terms point value as the first one
      // in the array defined above
      let newIncoTermsPointValue = null;
      if (vm.incoTermsPointList.length > 0) {
        newIncoTermsPointValue = vm.incoTermsPointList[0].PointCode;
      }

      // Set the new inco terms point value
      vm.newBooking.IncoTermsPoint = newIncoTermsPointValue;
    } else {
      // We have not selected (or reset) the inco term in the UI,
      // then we reset the 'inco terms point' list and the selected value
      vm.incoTermsPointList = null;
      vm.newBooking.IncoTermsPoint = null;
    }
  }

  /**
   * Filter the incoterms-list depending on the points/ports that have
   * been entered for the booking. Some incoterms are for example only
   * allowed if either PLR or PFD have been defined an should otherwise
   * be omitted from the list incoterms selection.
   * @return {Array} Retuns a filtered array of incoterms
   */
  function filterIncoterms(): any[] {
    let filteredTerms = [].concat(<any>incoTerms) as any;

    // If PLR has been emptied then filter out IncoTerms
    // that only contain PLR in the allowed IncoTermsPoints
    if (vm.newBooking.PLR == null) {
      filteredTerms = filteredTerms.filter((it: any) => {
        return !(
          it.AllowedIncotermsPoints.length === 1 &&
          it.AllowedIncotermsPoints[0] === "PLR"
        );
      });
    }

    // If PFD has been emptied then filter out IncoTerms
    // that only contain PFD in the allowed IncoTermsPoints
    if (vm.newBooking.PFD == null) {
      filteredTerms = filteredTerms.filter((it: any) => {
        return !(
          it.AllowedIncotermsPoints.length === 1 &&
          it.AllowedIncotermsPoints[0] === "PFD"
        );
      });
    }

    // Get the difference of the original array (incoTerms)
    // and the filtered array (filteredTerms) and only grab
    // the 'TermsCode' property value.
    const removedTermCodes = ArrayUtilities.difference(
      incoTerms,
      filteredTerms
    ).map((it: any) => it.TermsCode);

    // Reset the IncoTerms in the dropdown if the value is in the
    // removed terms after filtering above.
    if (
      vm.newBooking.IncoTerms != null &&
      removedTermCodes.indexOf(vm.newBooking.IncoTerms) > -1
    ) {
      vm.newBooking.IncoTerms = null;
    }
    return filteredTerms;
  }

  /**
   * Search shipping locations
   * @param  {String} searchString Search string (point code or name)
   * @return {Promise}             Promise with a list of shipping locations
   */
  vm.searchShippingLocations = function searchShippingLocations(
    searchString: any
  ) {
    return ShipmentRegistryService.searchShippingPorts(searchString).then(
      (data: any) => {
        return data;
      }
    );
  };

  /**
   * Gets a list of known customers for a shipper's partnerCode and
   * sets the customers array to the result of the xhr-request. The
   * customers array is used for the shipper, consignee and notifier lookups.
   * @param  {string} partnerCode     PartnerCode to get a list of known consignees for.
   */
  function getCustomers(partnerCode: any): void {
    if (!partnerCode) {
      return;
    }
    CompanyService.companyCustomers(partnerCode, ["000", "SHP", "CEE"])
      .then((data: any) => {
        vm.customers = data;
        vm.notifiers = vm.notifiers.concat(data);
        if (!data) {
          SamskipNotify.displayError("ERROR_COULD_NOT_GET_CONSIGNEES");
        }
      })
      .catch((data: any) => {
        SamskipNotify.displayError(data);
      });
  }

  /**
   * Gets a list of known notifiers for a given partner code
   * @param partnerCode PartnerCode to get a list of known notifiers for
   */
  function getNotifierAddresses(partnerCode: string): void {
    if (!partnerCode) {
      return;
    }
    CompanyService.companyAddressesByType(partnerCode, ["000", "NOT"])
      .then((data) => {
        vm.notifiers = vm.notifiers.concat(data);
      })
      .catch((data: any) => {
        SamskipNotify.displayError(data);
      });
  }

  /**
   * Callback for when the booking's notifier has changed using the
   * samPartnerinfo directive. If the update notifier is valid, the user
   * is allowed to add a second notifier to the booking.
   * @param {object} notifier  The updated notifier model
   */
  vm.notifierChanged = function notifierChanged(notifier: any): void {
    if (notifier == null) {
      // The notifier was cleared/deleted
      vm.canAddSecondNotifier = false;
      vm.newBooking.Notifier = undefined;
      setFocusWhenDeletingNotifier();
    } else {
      // The new notifier is valid so the user can add the second notifier
      vm.canAddSecondNotifier = true;
      vm.newBooking.Notifier = notifier;
      // Update empty fields to satisfy db requirements
      vm.newBooking.Notifier.Address1 = vm.newBooking.Notifier.Address1
        ? vm.newBooking.Notifier.Address1
        : ".";
      vm.newBooking.Notifier.Address2 = vm.newBooking.Notifier.Address2
        ? vm.newBooking.Notifier.Address2
        : ".";
      vm.newBooking.Notifier.PostCode = vm.newBooking.Notifier.PostCode
        ? vm.newBooking.Notifier.PostCode
        : ".";
      $scope.$digest();
    }
  };

  /**
   * Callback for when the booking's second notifier has been updated
   * (using the samPartnerinfo directive).
   * @param  {object} secondNotifier  The updated notifier model
   */
  vm.secondNotifierChanged = function secondNotifierChanged(
    secondNotifier: any
  ): void {
    if (secondNotifier == null) {
      // The notifier was cleared/deleted
      vm.canAddSecondNotifier = true;
      vm.newBooking.SecondNotifier = undefined;
      setFocusWhenDeletingNotifier();
    } else {
      vm.canAddSecondNotifier = false;
      vm.newBooking.SecondNotifier = secondNotifier;
      vm.newBooking.SecondNotifier.Address1 = vm.newBooking.SecondNotifier
        .Address1
        ? vm.newBooking.SecondNotifier.Address1
        : ".";
      vm.newBooking.SecondNotifier.Address2 = vm.newBooking.SecondNotifier
        .Address2
        ? vm.newBooking.SecondNotifier.Address2
        : ".";
      vm.newBooking.SecondNotifier.PostCode = vm.newBooking.SecondNotifier
        .PostCode
        ? vm.newBooking.SecondNotifier.PostCode
        : ".";
      $scope.$digest();
    }
  };

  /**
   * Find element 'partnerLaunch3', which is notifier edit button and
   * place focus on that element.
   */
  function setFocusWhenDeletingNotifier(): void {
    WindowUtilities.focusByName("partnerLaunch3");
  }

  /**
   * Called when the user is going to add a second notifier for the booking.
   */
  vm.toggleSecondNotifer = function toggleSecondNotifer(): void {
    if (vm.canAddSecondNotifier) {
      // Open the second notifier popup by default
      vm.showSecondNotifierPopup = true;
    }
  };

  /**
   * Callback for when a voyage is selected from the voyages typeahead.
   * @param  {object} item    The item selected from typeahead results
   * @param  {object} model   The item selected from typeahead results
   * @param  {string} label   Displayed value for the selected item
   * @param  {object} event   Event OR a string. We're using a workaround
   *     that enables us catch when the user clicks the "Show more results"
   *     button from the custom typeahead popup template.
   */
  vm.voyageSelected = function voyageSelected(voyage: any): void {
    vm.newBooking.VoyageReference = voyage;
    vm.newBooking.CutoffDate = voyage.CutoffDate;
    if (!isDirectRoute(voyage)) {
      setBookingPOT(voyage.POT);
    } else {
      setBookingPOT(null);
    }
  };

  vm.resetVoyage = function resetVoyage(): void {
    vm.newBooking.VoyageReference = null;
    vm.newBooking.CutoffDate = null;
    setBookingPOT(null);
  };

  /**
   * Checks if a given voyage has a direct route between the booking's
   * POL and POD or if the booking needs to be transshipped at some port
   * to reach the booking's POD.
   * @param  {Object}  voyage A voyage object (selected from the voyagePicker)
   * @return {Boolean}        Returns true if the booking's POD and the
   *                          voyage's POD have the same pointcode.
   */
  function isDirectRoute(voyage: any): boolean {
    if (voyage == null) {
      return false;
    }
    return voyage.POT === null;
  }

  /**
   * Sets the booking's POT to a given port.
   * @param {Object|String}   transshipPort   The port where the booking
   *                                          should be transshipped in
   *                                          order to reach the final POD.
   */
  function setBookingPOT(transshipPort: any): any {
    let port;
    if (typeof transshipPort === "string") {
      port = shippingPorts.find((it: any) => it.PointCode === transshipPort);
    } else if (typeof transshipPort === "object") {
      port = transshipPort;
    }
    vm.newBooking.POT = port == null ? null : port;
  }

  /**
   * [Get package types or container types]
   * @return {object} [containerTypes or packageTypes]
   */
  function getPackageOrContainerType(): any[] {
    if (isContainerTypes(selectedShippingType)) {
      return containerTypes;
    }

    return packageTypes;
  }

  /**
   * Gets different translation in title compared to current state.
   * @return {string} [Correct translation key]
   */
  vm.mainTitleTranslation = function mainTitleTranslation(): string {
    let mainTitleTranslationKey = "MENU_CREATEBOOKING";
    if (actionMode.copyBooking()) {
      mainTitleTranslationKey = "LABEL_COPY_BOOKING";
    } else if (actionMode.editBooking()) {
      mainTitleTranslationKey = "LABEL_EDIT_BOOKING";
    }
    return mainTitleTranslationKey;
  };

  /**
   * Check in each digest cycle if bookingVM.form has been edited.
   * If so, then save bookingVM.form as draft.
   * Only bind the watcher if the feature is enabled
   */
  $scope.$watch(
    <any>angular.bind(vm, () => {
      return vm.form.$pristine;
    }),
    (newValue: any) => {
      // If form is in edit mode and booking is new
      if (
        newValue === false &&
        (actionMode.createBooking() ||
          vm.isDraftMode ||
          actionMode.copyBooking())
      ) {
        // Save draft for the first time after 'draftIntervalTimer' time
        draftTimer = $timeout(() => {
          saveOrUpdateDraft();
        }, draftIntervalTimer);
      }
    }
  );

  function saveOrUpdateDraft(): void {
    // Get correct object from array to notify samSaving component.
    vm.saveDraftNotifier = saveDraftNotifiers.Saving;

    ShipmentService.saveDraft(vm.newBooking).then(
      (data: any) => {
        handleDraftResponseData(data);
      },
      () => {
        vm.saveDraftNotifier = saveDraftNotifiers.Error;
      }
    );
  }

  /**
   * Checks if response from ShipmentService.saveDraft returns
   * insert, replaced or error.
   * @param  {object} data [response data from ShipmentService.saveDraft]
   */
  function handleDraftResponseData(data: any): void {
    // Error when inserting or update
    if (data.Error > 0) {
      vm.saveDraftNotifier = saveDraftNotifiers.Error;
    }
    if (data.Inserted === 1) {
      setDraftMode(data);
      // Insert success
      draftIntervalPromise = $interval(manageDraftInterval, draftIntervalTimer);
      vm.saveDraftNotifier = saveDraftNotifiers.Saved;
    } else if (data.Replaced === 1) {
      // Update success
      // If draft interval promise exist then draft is in edit mode
      // and interval hasn't been triggered yet
      if (!draftIntervalPromise) {
        draftIntervalPromise = $interval(
          manageDraftInterval,
          draftIntervalTimer
        );
      }
      vm.saveDraftNotifier = saveDraftNotifiers.Saved;
    }
  }

  function setDraftMode(data: any): void {
    // If current booking has DraftId, then draft has already been saved
    if (data.GeneratedKeys[0]) {
      vm.newBooking.DraftId = data.GeneratedKeys[0];
      vm.isDraftMode = true;
      saveDraftNotifiers.Saved.id = vm.newBooking.DraftId;
    }
  }

  /**
   * Delete current draft if vm.newBooking.draftId exist
   */
  function deleteDraft(): void {
    if (vm.newBooking.DraftId != null) {
      ShipmentService.deleteDraft(vm.newBooking.DraftId);
    }
  }

  function updateTemplateLastUsed(): void {
    ShipmentService.updateTemplateLastUsedBy(vm.newBooking.TemplateId);
  }

  $scope.$on("$destroy", () => {
    cancelDraftInterval();
    cancelDraftTimer();
  });

  /**
   * Map for the sidebar-checklist. Each checklist-item's key is (almost)
   * a one-to-one mapping to the booking-viewmodel (vm.newBooking) -- some
   * checklist-items are combinations of multiple properties of the booking.
   * Each checklist-item has a validate method which is run each digest-cycle
   * which determines which icon to show for that particular item.
   * It is split into 2 parts:
   *     base:            Requirements when posting the initial booking to Doris.
   *                      When all items in the base have been validated
   *                      successfully, the 'Submit booking' button will be
   *                      enabled.
   *     instructions:    Requirements when posting booking-instructions to Doris.
   *                      For the instructions to be valid, the booking's base
   *                      must also be valid.
   *                      When the instructions-part's items have been validated
   *                      (as well as the base-items), the 'Submit Instructions'
   *                      button will be enabled.
   */
  vm.checklist = {
    base: {
      Shipper: {
        translation: "LABEL_SHIPPER",
        required: true,
        infoText: {
          FullName: "ERROR_FULLNAME_EMPTY",
          Address1: "ERROR_ADDRESS1_EMPTY",
          Address2: "ERROR_ADDRESS2_EMPTY",
          PostCode: "ERROR_POSTCODE_EMPTY",
        },
        validate: function validate(): boolean {
          const values = BookingValidationService.validateShipperConsignee(
            vm.newBooking.Shipper
          );
          vm.errors.Shipper = values.errors;
          return (
            BookingValidationService.validateBookingParty(
              vm.isShipper ? "Deciding" : "Shipper",
              vm.newBooking.Shipper
            ) &&
            BookingValidationService.validateShipperConsignee(
              vm.newBooking.Shipper
            ) &&
            values.isValid
          );
        },
      },
      Consignee: {
        translation: "LABEL_CONSIGNEE",
        required: true,
        infoText: {
          FullName: "ERROR_FULLNAME_EMPTY",
          Address1: "ERROR_ADDRESS1_EMPTY",
          Address2: "ERROR_ADDRESS2_EMPTY",
          PostCode: "ERROR_POSTCODE_EMPTY",
        },
        validate: function validate(): boolean {
          const values = BookingValidationService.validateShipperConsignee(
            vm.newBooking.Consignee
          );
          vm.errors.Consignee = values.errors;
          return (
            BookingValidationService.validateBookingParty(
              !vm.isShipper ? "Deciding" : "Consignee",
              vm.newBooking.Consignee
            ) &&
            BookingValidationService.validateShipperConsignee(
              vm.newBooking.Consignee
            ) &&
            values.isValid
          );
        },
      },
      Notifier: {
        translation: "LABEL_NOTIFIER",
        required: false,
        infoText: {
          FullName: "ERROR_COMPANYNAME_EMPTY",
          ContactName: "ERROR_CONTACTNAME_EMPTY",
          ContactNumber: "ERROR_CONTACTNUMBER_EMPTY",
          ContactEmail: "ERROR_CONTACTEMAIL_EMPTY",
        },
        validate: function validate(): boolean {
          const values = BookingValidationService.validateNotifier(
            vm.newBooking.Notifier
          );
          const res = _.xorBy(
            values.errors,
            vm.errors.Notifier,
            (item: any) =>
              `${Object.keys(item)[0]}-${item[Object.keys(item)[0]]}`
          );
          if (_.uniq(res.map((item) => Object.keys(item)[0])).length > 0) {
            vm.errors.Notifier = values.errors;
          }
          return (
            BookingValidationService.validateBookingParty(
              "Notifier",
              vm.newBooking.Notifier
            ) && values.isValid
          );
        },
      },
      SecondNotifier: {
        translation: "LABEL_NOTIFIER_SECONDARY",
        required: false,
        infoText: {
          FullName: "ERROR_COMPANYNAME_EMPTY",
          ContactName: "ERROR_CONTACTNAME_EMPTY",
          ContactNumber: "ERROR_CONTACTNUMBER_EMPTY",
          ContactEmail: "ERROR_CONTACTEMAIL_EMPTY",
        },
        validate: function validate(): boolean {
          const values = BookingValidationService.validateNotifier(
            vm.newBooking.SecondNotifier
          );
          const res = _.xorBy(
            values.errors,
            vm.errors.SecondNotifier,
            (item: any) =>
              `${Object.keys(item)[0]}-${item[Object.keys(item)[0]]}`
          );
          if (_.uniq(res.map((item) => Object.keys(item)[0])).length > 0) {
            vm.errors.SecondNotifier = values.errors;
          }
          return (
            BookingValidationService.validateBookingParty(
              "SecondNotifier",
              vm.newBooking.SecondNotifier
            ) && values.isValid
          );
        },
      },
      BookingPartyReference: {
        translation: "LABEL_SHIPPERS_REFERENCE",
        required: false,
        validate: function validate(): boolean {
          return vm.newBooking.BookingPartyReference != null;
        },
      },
      CountryOfOrigin: {
        translation: "LABEL_COUNTRY_OF_ORIGIN",
        required: false,
        validate: function validate(): boolean {
          return (
            vm.form.CountryOfOrigin.$valid &&
            vm.newBooking.CountryOfOrigin != null
          );
        },
      },
      PLR: {
        translation: "LABEL_PLACE_OF_RECEIPT",
        required: false,
        infoText: {
          FullName: "ERROR_FULLNAME_EMPTY",
          Address1: "ERROR_ADDRESS1_EMPTY",
          Address2: "ERROR_ADDRESS2_EMPTY",
          PostCode: "ERROR_POSTCODE_EMPTY",
          ContactName: "ERROR_CONTACTNAME_EMPTY",
          ContactNumber: "ERROR_CONTACTNUMBER_EMPTY",
          RequestDate: "ERROR_REQUESTDATE_INVALID",
          AddressNeeded: "ERROR_TRANSPORT_REQUEST_EMPTY",
        },
        validate: function validate(updateErrors: boolean = true): boolean {
          const setErrors = (newErrors: any) => {
            if (!updateErrors) {
              return;
            }
            const res = _.xorBy(
              newErrors,
              vm.errors.PLR,
              (item: any) =>
                `${Object.keys(item)[0]}-${item[Object.keys(item)[0]]}`
            );
            if (_.uniq(res.map((item) => Object.keys(item)[0])).length > 0) {
              vm.errors.PLR = newErrors;
            }
          };
          if (vm.newBooking.PLR) {
            if (
              UtilityFunctions.isIcelandicPointCode(vm.newBooking.PLR.PointCode)
            ) {
              setErrors([]);
              return true;
            }
            const address = vm.newBooking.OtherAddresses.find((item: any) => {
              return item.AddressType === "COL";
            });
            if (!address) {
              const error = [{ ["AddressNeeded"]: true }];
              setErrors(error);
              return false;
            }

            const validateDate = !(
              actionMode.editBooking() &&
              vm.originalAddressDates.PLR &&
              !vm.originalAddressDates.PLR.edited
            );

            const values = BookingValidationService.validatePLRPDF(
              address,
              validateDate
            );
            setErrors(values.errors);
            return values.isValid;
          }
          setErrors([]);
          return false;
        },
        infoIcon: function infoIcon(): boolean {
          return vm.newBooking.PLR && !this.validate(false);
        },
      },
      PFD: {
        translation: "LABEL_PLACE_OF_DELIVERY",
        required: false,
        infoText: {
          FullName: "ERROR_FULLNAME_EMPTY",
          Address1: "ERROR_ADDRESS1_EMPTY",
          Address2: "ERROR_ADDRESS2_EMPTY",
          PostCode: "ERROR_POSTCODE_EMPTY",
          ContactName: "ERROR_CONTACTNAME_EMPTY",
          ContactNumber: "ERROR_CONTACTNUMBER_EMPTY",
          RequestDate: "ERROR_REQUESTDATE_INVALID",
        },
        validate: function validate(): boolean {
          const setErrors = (newErrors: any) => {
            const res = _.xorBy(
              newErrors,
              vm.errors.PFD,
              (item: any) =>
                `${Object.keys(item)[0]}-${item[Object.keys(item)[0]]}`
            );
            if (_.uniq(res.map((item) => Object.keys(item)[0])).length > 0) {
              vm.errors.PFD = newErrors;
            }
          };
          if (vm.newBooking.PFD) {
            if (
              UtilityFunctions.isIcelandicPointCode(vm.newBooking.PFD.PointCode)
            ) {
              setErrors([]);
              return true;
            }
            const address = vm.newBooking.OtherAddresses.find((item: any) => {
              return item.AddressType === "DEL";
            });
            if (!address) {
              return true;
            }

            const validateDate = !(
              actionMode.editBooking() &&
              vm.originalAddressDates.PFD &&
              !vm.originalAddressDates.PFD.edited
            );

            const values = BookingValidationService.validatePLRPDF(
              address,
              validateDate
            );
            setErrors(values.errors);
            return values.isValid;
          }
          setErrors([]);
          return false;
        },
        infoIcon: function infoIcon(): boolean {
          const address = vm.newBooking.OtherAddresses.find((item: any) => {
            return item.AddressType === "DEL";
          });
          if (address) {
            return true;
          }
          return false;
        },
      },
      POL: {
        translation: "LABEL_PORT_OF_LOAD",
        required: true,
        validate: function validate(): boolean {
          return (
            vm.newBooking.POL != null && vm.newBooking.POL.PointCode != null
          );
        },
      },
      POD: {
        translation: "LABEL_PORT_OF_DISCHARGE",
        required: true,
        validate: function validate(): boolean {
          return (
            vm.newBooking.POD != null && vm.newBooking.POD.PointCode != null
          );
        },
      },
      IncoTerms: {
        translation: "LABEL_INCOTERMS",
        required: true,
        validate: function validate(): boolean {
          return BookingValidationService.validateIncoTerms(vm.newBooking);
        },
      },
      VoyageReference: {
        translation: "LABEL_VOYAGEREFERENCE",
        required: true,
        validate: function validate(): boolean {
          // The voyagereference must be a non-empty string
          return vm.newBooking.VoyageReference != null;
        },
      },
      ShippingType: {
        translation: "LABEL_SHIPPINGTYPE",
        required: true,
        validate: function validate(): boolean {
          // ShippingType must be a non-empty string
          return vm.newBooking.ShippingType != null;
        },
      },
      DetailsCount: {
        translation: "LABEL_QUANTITY",
        required: true,
        validate: function validate(): boolean {
          if (vm.newBooking.ShippingType === "M M") {
            return BookingValidationService.validateDetailExist(vm.newBooking);
          }
          return BookingValidationService.validateBaseCount(vm.newBooking);
        },
      },
      DetailsDescription: {
        translation: "LABEL_CARGODESCRIPTION",
        required: true,
        validate: function validate(): boolean {
          return BookingValidationService.validateBaseDescription(
            vm.newBooking
          );
        },
      },
      DetailsGrossWeight: {
        translation: "LABEL_GROSS_WEIGHT",
        required: true,
        validate: function validate(): boolean {
          return BookingValidationService.validateBaseWeight(vm.newBooking);
        },
      },
      HazardousMaterials: {
        translation: "LABEL_HAZARDOUS_MATERIALS",
        required: () => vm.checklist.hasHazardousDetails(),
        showItem: () => vm.checklist.hasHazardousDetails(),
        infoText: {
          HazardousMaterials: "ERROR_HAZARDOUS",
        },
        validate: function validate(): boolean {
          const isValid = BookingValidationService.validateBookingHazardLines(
            vm.newBooking
          );
          vm.errors.HazardousMaterials = !isValid
            ? [{ HazardousMaterials: true }]
            : null;
          return isValid;
        },
      },
    },
    instructions: {
      InnerPackageCount: {
        translation: "LABEL_INNER_PACKAGE_COUNT",
        required: true,
        showItem: () => {
          return vm.checklist.validateShippingType(["F", "L"]);
        },
        validate: function validate(): boolean {
          return BookingValidationService.validateInnerPackageCount(
            vm.newBooking
          );
        },
      },
      Volume: {
        translation: "LABEL_VOLUME",
        required: true,
        showItem: () => vm.newBooking.ShippingType === "L L",
        validate: function validate(): boolean {
          return BookingValidationService.validateDetailVolume(vm.newBooking);
        },
      },
      InnerPackageDescription: {
        translation: "LABEL_INNER_PACKAGE_TYPE",
        required: true,
        showItem: () => vm.checklist.validateShippingType(["F", "L"]),
        validate: function validate(): boolean {
          return BookingValidationService.validateInnerPackageTypes(
            vm.newBooking
          );
        },
      },
      ContainerNumbers: {
        translation: "LABEL_CONTAINERNUMBER_PLURAL",
        required: true,
        showItem: () => vm.checklist.validateShippingType(ContainerTypes),
        validate: function validate(): boolean {
          return BookingValidationService.validateInnerContainerNumbers(
            vm.newBooking
          );
        },
      },
      Oversize: {
        translation: "LABEL_OVERSIZE",
        required: () => vm.checklist.hasOversizeDetails(),
        showItem: () => vm.checklist.hasOversizeDetails(),
        infoText: {
          Oversize: "ERROR_OVERSIZE",
        },
        validate: function validate(): boolean {
          const isValid = BookingValidationService.validateBookingOverSizeLines(
            vm.newBooking
          );
          vm.errors.Oversize = !isValid ? [{ Oversize: true }] : null;
          return isValid;
        },
      },
      BOLType: {
        translation: "LABEL_BILL_TYPE",
        required: true,
        validate: function validate(): boolean {
          return BookingValidationService.validateBOLType(vm.newBooking);
        },
      },
      HSCodes: {
        translation: "LABEL_HSCODE",
        required: false,
        validate: function validate(): boolean {
          return BookingValidationService.validateHSCodes(vm.newBooking);
        },
      },
    },
    getIcon: function getIcon(stage: string, modelName: string): string {
      const model = this[stage][modelName];
      const validateFn = model.validate || (() => undefined);
      if (validateFn() === true) {
        return "fa-check-circle text-success";
      }
      const required =
        typeof model.required === "function"
          ? model.required()
          : model.required;
      if (required || (model.infoIcon && model.infoIcon())) {
        return "fa-exclamation-circle needsInfoIcon";
      }
      return "fa-times-circle";
    },
    showItem: function showItem(item: any): boolean {
      if (item.showItem) {
        return item.showItem();
      }
      return true;
    },
    showInfoText: function showInfoText(key: string): boolean {
      return vm.errors[key] && vm.errors[key].length > 0;
    },
    getInfoText: function getInfoText(value: any, key: string): string[] {
      return vm.errors[key].map(
        (prop: any) => value.infoText[Object.keys(prop)[0]]
      );
    },
    /**
     * baseValid runs each $digest cycle to enable/disable the submit-button
     * for the basic information -- the minimum requirements needed for
     * the booking to be posted to Doris.
     * @return {Boolean}
     */
    baseValid: function baseValid(): boolean {
      const base = this.base;
      let valid = true;

      for (const key in base) {
        const model = base[key];
        if (validateField(model, vm.newBooking[key], vm.form[key]) === false) {
          valid = false;
          break;
        }
      }
      this._baseValid = valid;
      return this._baseValid;
    },
    /**
     * When the minimum required information for a basic Doris-booking,
     * instructionsValid runs each $digest cycle.
     * @return {Boolean}
     */
    instructionsValid: function instructionsValid(): boolean {
      const self = this;
      const instructions = this.instructions;
      // Check the instruction fields that aren't related to base, details or dimentions
      // and need to be validated for the booking-instructions to be valid

      //Key names from the vm.checklist object
      const instructionKeys = ["BOLType", "HSCodes"];
      let valid = true;
      for (const key of instructionKeys) {
        const model = instructions[key];
        if (validateField(model, vm.newBooking[key], vm.form[key]) === false) {
          valid = false;
          break;
        }
      }
      this._instructionsValid = valid;
      return (
        self._baseValid === true &&
        self._dimensionsValid === true &&
        self._detailsValid === true &&
        this._instructionsValid === true
      );
    },

    validateDimensionRow: function validateDimensionRow(
      dimension: any
    ): boolean {
      return BookingValidationService.validateDimensionRow(
        vm.newBooking,
        dimension
      );
    },

    validateShippingType: function validateShippingType(type: any) {
      let visible = true;
      if (type) {
        if (Array.isArray(type)) {
          visible = ArrayUtilities.includes(type, selectedShippingType);
        } else {
          visible = selectedShippingType === type;
        }
      }
      return visible;
    },

    hasOversizeDetails: function hasOversizeDetails() {
      return (
        Array.isArray(vm.newBooking.Details) &&
        vm.newBooking.Details.length > 0 &&
        vm.newBooking.Details.some((it: any) => it.OversizeFlag)
      );
    },

    hasHazardousDetails: function hasHazardousDetails() {
      return (
        Array.isArray(vm.newBooking.Details) &&
        vm.newBooking.Details.length > 0 &&
        vm.newBooking.Details.some((it: any) => it.HazardFlag)
      );
    },
  };

  /**
   * Validates the booking's Details array and sets the flags that are
   * necessary for the form's 'Submit Instructions' button to become enabled.
   * NOTE: This should only be run when changes are made to the details/dimensions
   * via their modals (to minimize the amount of work in each $digest cycle).
   * @return {Boolean}
   */
  function validateBookingDetails(): boolean {
    let detailsValid = true;
    let dimensionsValid = false;
    if (vm.newBooking.Details == null) {
      detailsValid = false;
    } else {
      const validateFn = getDetailValidateFn();

      vm.newBooking.Details.forEach((detail: any) => {
        if (!BookingValidationService.validateDetailTemperature(detail)) {
          detailsValid = false;
        }

        if (!validateFn(detail)) {
          detailsValid = false;
        }

        if (detail.Dimensions && detail.Dimensions.length > 0) {
          dimensionsValid = true;
        }

        detail.Dimensions.forEach((dimension: any) => {
          dimension._oversizeIcon = getDimensionOversizeIcon(detail, dimension);
          dimension._hazardousIcon = getDimensionHazardousIcon(
            detail,
            dimension
          );

          if (!vm.checklist.validateDimensionRow(dimension)) {
            dimensionsValid = false;
          }
        });
      });
    }

    vm.checklist._detailsValid = detailsValid;
    vm.checklist._dimensionsValid = dimensionsValid;

    return detailsValid;

    // Get a validate function for the shipping type.
    // Uses the shipping type map to decide if the type
    // is a FCL type or not, but it needs the shipping type
    // to decide if it's MTY
    function getDetailValidateFn(): Function {
      let dtlValidatorFn;
      switch (selectedShippingType) {
        case "F":
          dtlValidatorFn = BookingValidationService.validateSingleFCLDetail;
          break;
        case "L":
          dtlValidatorFn = BookingValidationService.validateSingleLCLDetail;
          break;
        case "M":
          dtlValidatorFn = BookingValidationService.validateSingleMTYDetail;
          break;
        default:
          dtlValidatorFn = BookingValidationService.validateSingleFCLDetail;
          break;
      }

      return dtlValidatorFn;
    }
  }

  /** Gets the icon-class for dimensions' Oversize-info.
    SHOULD ONLY be called from validateBookingDetails()   */
  function getDimensionOversizeIcon(detail: any, dimension: any): string {
    if (!detail.OversizeFlag) {
      return dimensionIcons.disabled;
    }
    if (
      BookingValidationService.validateOversizes(
        detail.PackageCode,
        dimension.Oversize
      )
    ) {
      return dimensionIcons.valid;
    }
    return dimensionIcons.needsAttention;
  }

  /** Gets the icon-class for dimensions' Hazardous-info.
    SHOULD ONLY be called from validateBookingDetails()   */
  function getDimensionHazardousIcon(detail: any, dimension: any): string {
    if (!detail.HazardFlag) {
      return dimensionIcons.disabled;
    }
    if (
      BookingValidationService.validateSingleDimensionHazardLines(
        detail.HazardFlag,
        dimension
      )
    ) {
      return dimensionIcons.valid;
    }
    return dimensionIcons.needsAttention;
  }

  /**
   * Get container numbers from all dimensions for
   * each detail.
   * @return {array|string}       [container numbers]
   */
  function getAllContainerNumbersFromAllDetails(): string[] {
    let containerNumbers: any = [];
    if (vm.newBooking.Details) {
      vm.newBooking.Details.forEach((value: any) => {
        containerNumbers = containerNumbers.concat(
          value.Dimensions.map((cnt: any) => cnt.ContainerNumber)
        );
      });
    }
    return containerNumbers;
  }

  /**
   * Check if a checklist-item is valid based on it's model
   * @param model The model object that contains the validation details (from the checklist object)
   * @param bookingValue The field's value in the booking view-model
   * @param formValue The field's value in the form
   * @returns {Boolean} Returns true if the model is valid
   */
  function validateField(
    model: any,
    bookingValue: any,
    formValue: any
  ): boolean {
    // The model associated with each checklist-item must be validated if
    // a) the model is required,
    // b) the booking's model is non-empty or
    // c) there is a non-empty form-input for that model (with ng-model)
    let shouldValidate;
    model._required =
      typeof model.required === "function" ? model.required() : model.required;
    shouldValidate =
      model._required ||
      bookingValue != null ||
      (formValue && formValue.$viewValue != null);
    if (shouldValidate && !model.validate()) {
      return false;
    }
    return true;
  }

  /**
   * Concatenate id to parameter.
   * E.g. prefix="Troll"
   * Then TrollID is found after timeout
   * and focus set on that particular
   * element.
   * Timeout is used because sometimes
   * the element hasn't been displayed
   * when focus is set.
   */
  function setFocusOnId(prefix: string): void {
    const id = prefix.concat("id");
    setTimeout(() => {
      WindowUtilities.focusByName(id);
    }, 100);
  }

  function addDecidingPartyAddress(otherAddresses: any[]): void {
    const result = otherAddresses;
    const incoTerm = vm.incoTermsList.find(
      (item: any) => item.TermsCode === vm.newBooking.IncoTerms
    );
    let decidingPartyAddress = null;
    if (incoTerm && incoTerm.BookingParty === "SHP") {
      if (vm.newBooking.BookingPartyType === "FOR") {
        decidingPartyAddress = _.clone(vm.newBooking.Forwarder);
      } else {
        decidingPartyAddress = _.clone(vm.newBooking.Shipper);
      }
    } else if (incoTerm && incoTerm.BookingParty === "CEE") {
      decidingPartyAddress = _.clone(vm.newBooking.Consignee);
    }

    if (decidingPartyAddress) {
      decidingPartyAddress.AddressType = "DCD";
      decidingPartyAddress.SpecialInstructions = null;
      result.push(decidingPartyAddress);
    }
  }

  function setBookingParty(booking: any): void {
    const specialInstructions = vm.QuoteReference
      ? `Quote Reference (UNCONFIRMED): ${vm.QuoteReference};`
      : null;

    const bookingPartyTypeMap = {
      SHP: "Shipper",
      CEE: "Consignee",
      FOR: "Forwarder",
    };

    const getEmailsWithUserEmail = (emails: string, user?: UserProfile) => {
      if (emails && user) {
        // Avoid duplicate email addresses
        if (emails.includes(user.Access.Email)) {
          return emails;
        }
        const newEmails = `${emails}, ${user.Access.Email}`;
        // The database field that holds the email addresses is only 100 bytes long
        if (newEmails.length > 100) {
          return user.Access.Email;
        }
        return newEmails;
      }
      if (!emails && user) {
        return user.Access.Email;
      }
      return null;
    };

    const address: string = bookingPartyTypeMap[vm.newBooking.BookingPartyType];

    if (specialInstructions) {
      booking[address].SpecialInstructions = specialInstructions;
    }
    booking[address].ContactEmail = getEmailsWithUserEmail(
      booking[address].ContactEmail,
      currentUser
    );
  }

  function addNewAddressesToPartner(): void {
    const addresses = _.cloneDeep(vm.newBooking.OtherAddresses);
    vm.newBooking.Notifier ? addresses.push(vm.newBooking.Notifier) : null;
    vm.newBooking.SecondNotifier
      ? addresses.push(vm.newBooking.SecondNotifier)
      : null;

    // Save all new addresses to partner
    addresses.forEach(async (address: any) => {
      if (address.isNew) {
        await CompanyService.addCompanyAddress(address);
      }
    });
  }

  async function updateVGM(jobReference: string): Promise<void> {
    const shippingType = ShipmentService.getShippingTypePair(
      vm.newBooking.ShippingType
    ).second;
    if (shippingType === "M" || !vm.vgmEdited) {
      return;
    }
    const items: VGMItem[] = [];
    const vgm = await ShipmentService.getVgmInfo(jobReference);
    for (const item of vgm.Items) {
      const detail: DetailViewModel =
        vm.newBooking.Details[item.DetailSequence - 1];
      let dimension: DimensionViewModel | null | undefined = null;
      if (shippingType === "F") {
        dimension = detail.Dimensions.find(
          (d) => d.ContainerNumber === item.ContainerNumber
        );
      } else if (shippingType === "L") {
        dimension = detail.Dimensions[0];
      }

      if (dimension) {
        items.push({
          AuthNo: dimension.VgmAuthNo,
          CargoWeight: dimension.VgmCargoWeight,
          ContainerNumber: dimension.ContainerNumber,
          ContainerPayloadWeight: dimension.ContainerNumber
            ? vm.vgmContainerInfoMap[dimension.ContainerNumber].PayloadWeight
            : null,
          ContainerWeight: dimension.ContainerNumber
            ? vm.vgmContainerInfoMap[dimension.ContainerNumber].TareWeight
            : null,
          Date: dimension.VgmDate,
          Description: detail.Description,
          DetailSequence: item.DetailSequence,
          DimensionSequence: item.DimensionSequence,
          EquipmentSequence: item.EquipmentSequence,
          Method: dimension.VgmMethod,
          PackageCode: dimension.PackageCode,
          Signature: dimension.VgmSignature,
          VGMSender: dimension.VgmSender,
          VGMWeight: dimension.VgmWeight,
          Modified: dimension.Modified,
        } as VGMItem);
      }
    }
    await ShipmentService.updateVgmInfo(jobReference, items);
  }

  async function addDocuments(jobReference: string): Promise<void> {
    if (vm.documentsToUpload.length === 0) {
      return Promise.resolve();
    }

    const types = vm.documentsToUpload.map((file: any) => file.docType);

    await ShipmentDocumentService.postDocuments(
      jobReference,
      types,
      vm.documentsToUpload
    );
  }

  /**
   * Create new IFTMBF booking in Doris.
   */
  async function createBooking(): Promise<any> {
    LoadingTextModalService.modalON("LABEL_SUBMITTING_BOOKING");
    let callback: Function;
    try {
      const newBooking = _.cloneDeep(vm.newBooking);

      // Transform the string list of HSCodes to HSCode object
      newBooking.HSCodes = getHSObjectsFromCode(vm.newBooking.HSCodes);
      addDecidingPartyAddress(newBooking.OtherAddresses);
      setBookingParty(newBooking);
      const booking = await ShipmentService.createBooking(newBooking);
      await UserService.setWebSetting(
        USERWEBSETTINGS.BookingCreation_DefaultShipperConsignee,
        vm.isShipper ? "Shipper" : "Consignee"
      );
      addNewAddressesToPartner();
      createOrUpdateRemark(booking);
      await addDocuments(booking);
      await updateVGM(booking);

      if (!UserService.isEmployee()) {
        await ShipmentService.acceptBookingTerms(
          "BOOKING_CREATE",
          booking.toString()
        );
      }

      callback = () => {
        displayCreateOrUpdateSuccess(booking, "TEXT_BOOKING_X_CREATED");
        deleteDraft();
        if (vm.IsTemplateBooking) {
          updateTemplateLastUsed();
        }
      };
    } catch (e) {
      callback = function errorCb() {
        displayCreateOrUpdateError(e.data.Message);
      };
    } finally {
      vm.submitted = true;
      window.onbeforeunload = null;
    }

    LoadingTextModalService.modalOFF()
      .then(() => {
        callback();
      })
      .catch(() => {});
  }

  /**
   * Update existing IFTMBF booking in Doris.
   */
  async function updateBooking(): Promise<any> {
    LoadingTextModalService.modalON("LABEL_UPDATING_BOOKING");
    let callback: Function;
    try {
      const newBooking = _.cloneDeep(vm.newBooking);
      await ShipmentService.acceptBookingTerms(
        "BOOKING_CREATE",
        vm.newBooking.JobReference
      );
      // Transform the string list of HSCodes to HSCode object
      newBooking.HSCodes = getHSObjectsFromCode(vm.newBooking.HSCodes);
      addDecidingPartyAddress(newBooking.OtherAddresses);
      setBookingParty(newBooking);
      const booking = await ShipmentService.updateBooking(
        newBooking,
        newBooking.JobReference
      );
      addNewAddressesToPartner();
      createOrUpdateRemark(booking);
      await addDocuments(booking);
      await updateVGM(booking);
      callback = function successCb() {
        displayCreateOrUpdateSuccess(booking, "TEXT_BOOKING_X_UPDATED");
        deleteDraft();
      };
    } catch (e) {
      callback = function errorCb() {
        displayCreateOrUpdateError(e.data.Message);
      };
    } finally {
      vm.submitted = true;
      window.onbeforeunload = null;
    }

    LoadingTextModalService.modalOFF()
      .then(() => {
        callback();
      })
      .catch(() => {});
  }

  function getHSObjectsFromCode(
    hsCodesArray: string[] | undefined
  ): (HSCode | undefined)[] {
    if (hsCodesArray) {
      const hsObjects = hsCodesArray.map((hsCode) =>
        ArrayUtilities.getObject<HSCode>(hsCodes, hsCode, "HSCode")
      );
      return hsObjects;
    }
    return [];
  }

  function createOrUpdateRemark(jobReference: string): void {
    if (remark == null) {
      createRemark(jobReference);
    } else {
      updateRemark(jobReference);
    }
  }

  function createRemark(jobReference: string): void {
    const newRemark = vm.newBooking.Remark;
    if (newRemark) {
      ShipmentService.createRemark(jobReference, newRemark, undefined);
    }
  }

  /**
   * Update first remark for current jobReference
   * @param {string} jobReference jobRef for current booking
   */
  function updateRemark(jobReference: string): void {
    const newRemark = vm.newBooking.Remark;
    ShipmentService.editRemark(jobReference, 1, newRemark);
  }

  /**
   * Opens a modal after creating or updating a booking/instructions
   * that lets the user choose if he wants to open the booking or if
   * he wants to create a new one.
   * @param  {string}  jobReference   Job Reference
   * @param  {string}  translationKey Translation key
   */
  async function displayCreateOrUpdateSuccess(
    jobRef: string,
    translationKey: string
  ): Promise<void> {
    const resText = TranslationService.translate(translationKey, {
      jobReference: jobRef,
    });

    const infoText = !vm.newBooking.PLR
      ? (await LocationService.hasLocationsInfo(vm.newBooking.POL.PointCode))
        ? TranslationService.translate("TEXT_BOOKING_OPENING_HOURS")
        : null
      : null;

    const modalInstance = ModalUtilities.showModal(
      "registrationSuccess",
      {
        jobReference: function _jobReference() {
          return jobRef;
        },
        resultText: function _resultText() {
          return resText;
        },
        infoText: function _infoText() {
          return infoText;
        },
      },
      {
        windowClass:
          "sam-modal ShipmentsCreateBooking-registrationSuccessModal",
        backdrop: "static",
        keyboard: false,
      }
    );

    modalInstance.then((res: any) => {
      if (res && res.newBooking) {
        $state.go(
          "shipments_createbooking.default",
          {},
          {
            reload: true,
          }
        );
      } else {
        $state.go("shipments_createbooking_dashboard");
      }
    });
  }

  function displayCreateOrUpdateError(translationKey: string): void {
    const resText = TranslationService.translate(translationKey);

    ModalUtilities.showModal(
      "registrationError",
      {
        resultText: function _resultText() {
          return resText;
        },
      },
      {
        windowClass: "sam-modal ShipmentsCreateBooking-registrationErrorModal",
        backdrop: "static",
        keyboard: false,
      }
    );
  }

  /**
   * Set focus on first element in shipments booking.
   */
  function resetFocus(): void {
    WindowUtilities.focusByName("partnerLaunch1");
  }

  /**
   * Initialize "private" properties on an existing booking's details.
   * @param  {Array} details An array of the booking's details.
   */
  function initializeDetails(details: any): void {
    if (!details) {
      return;
    }
    details.forEach((detail: any) => {
      // If there exists a request for an empty container for a given
      // detail, that detail cannot be removed.
      const detailRequests = getActiveRequests("EMPTYCNTEXP").find(
        (it) => it.DetailSequence === detail.DetailSequence
      );
      detail._cannotRemove = detailRequests != null;

      // Initialization of LCL-details
      if (!isContainerTypes(selectedShippingType)) {
        // If a containernumber has been assigned to any of a detail's
        // dimensions, that detail cannot be changed (except for the
        // detail's marks & numbers) and cannot be removed
        detail._locked = detail.Dimensions.some((dim: any) => {
          return dim.ContainerNumber != null;
        });
      }

      // Check if detail can be deleted
      const canBeDeleted = BookingValidationService.canRemoveDetail(
        detail,
        vm.newBooking.ShippingType
      );
      if (!canBeDeleted) {
        detail._cannotRemove = true;
      }

      // Initialize the detail's dimensions.
      initializeDimensions(detail.DetailSequence, detail.Dimensions);
    });
  }

  /**
   * Initialize "private" properties on an existing booking's dimensions.
   * @param  {Number} detailSequence  The parent-detail's DetailSequence.
   * @param  {Array}  dimensions      An array of dimensions to initialize.
   */
  function initializeDimensions(
    detailSequence: number,
    dimensions: any[]
  ): void {
    if (dimensions == null) {
      return;
    }

    dimensions.forEach((it) => {
      // If there exists a request for the given detail that has the
      // same container-number as the current dimension, that
      // dimension's ContainerNumber should not be altered.
      const request = getActiveRequests("EMPTYCNTEXP").find(
        (cnt) =>
          cnt.DetailSequence === detailSequence &&
          cnt.ContainerNumber === it.ContainerNumber
      );
      it._containerImmutable = request && request.ContainerNumber != null;
    });
  }

  /**
   * Filters linkedRequests to requests that have not been cancelled.
   * @param  {String} type    A request-type to filter by (optional).
   * @return {Array}          Returns a new filtered array.
   */
  function getActiveRequests(type: string): any[] {
    return linkedRequests.filter((it) => {
      if (type != null) {
        return it.RequestType === type && it.Status !== "CANCELED";
      }
      return it.Status !== "CANCELED";
    });
  }

  /**
   * When partner is changed, some information has
   * to be clear:
   *     - Consignee
   *     - Notifier
   */
  function partnerChanged(): void {
    if (vm.newBooking.Consignee != null) {
      vm.newBooking.Consignee = null;
    }
    if (vm.newBooking.Notifier != null) {
      vm.newBooking.Notifier = null;
    }
    if (vm.newBooking.SecondNotifier != null) {
      vm.newBooking.SecondNotifier = null;
    }
  }

  /**
   * While booking is in draft state, update draft interval
   * is active.
   * If bookings leaves that state, interval is canceled.
   */
  function manageDraftInterval(): void {
    if (vm.isDraftMode) {
      saveOrUpdateDraft();
    } else {
      cancelDraftInterval();
    }
  }

  /**
   * Cancel a draft interval if it's set
   */
  function cancelDraftInterval(): void {
    if (draftIntervalPromise) {
      $interval.cancel(draftIntervalPromise);
      draftIntervalPromise = false;
    }
  }

  /**
   * Cancel draft timer if timer available
   */
  function cancelDraftTimer(): void {
    if (draftTimer) {
      $timeout.cancel(draftTimer);
    }
  }

  /**
   * Gets a dimension's parent-detail from vm.newBooking.Details.
   * @param  {Object} dimension The dimension whose parent-detail to find.
   * @return {Object}           Returns the detail's parent.
   */
  function getParentDetail(dimension: any): any {
    return vm.newBooking.Details.find((dtl: any) => {
      return dtl.Dimensions.some((dim: any) => {
        return dim === dimension;
      });
    });
  }

  function isContainerTypes(shippingType: string) {
    return ContainerTypes.indexOf(shippingType) !== -1;
  }

  // Set booking store value for state refresh
  vm.CompanySelectReminderCallback =
    function CompanySelectReminderCallback(): void {
      $state.$current.onExit = () => {};
      BookingStore.setBooking(booking);
    };

  vm.onShipperConsigneeChanged = function onShipperConsigneeChanged(
    value: boolean
  ): any {
    if (vm.isShipper !== value) {
      vm.isShipper = value;

      // Swap shipper and consignee
      [vm.newBooking.Shipper, vm.newBooking.Consignee] = [
        vm.newBooking.Consignee,
        vm.newBooking.Shipper,
      ];

      if (vm.newBooking.BookingPartyType !== "FOR") {
        vm.newBooking.BookingPartyType = value ? "SHP" : "CEE";
        vm.newBooking.BookingParty = value
          ? vm.newBooking.Shipper.PartnerCode
          : vm.newBooking.Consignee.PartnerCode;
      }

      // Manually trigger change detection, as changing the vm object does not trigger it
      $scope.$digest();
    }
  };

  /**
   * Returns wether the page is in edit mode
   */
  vm.inEditMode = function inEditMode() {
    return actionMode.editBooking();
  };

  vm.onPlaceSelect = (type: string, value: any) => {
    removeAddress(type);
    vm.newBooking[type] = value;
    vm.placeChanged(type);
    $scope.$digest();
  };

  vm.onPOLSelect = (value: any) => {
    const port = shippingPorts.find((port: any) => port.PointCode === value);
    vm.newBooking.POL = port;
    vm.portsChanged("POL");
    $scope.$digest();
  };

  vm.onPODSelect = (value: any) => {
    const port = shippingPorts.find((port: any) => port.PointCode === value);
    vm.newBooking.POD = port;
    vm.portsChanged("POD");
    $scope.$digest();
  };

  vm.onAddressSubmit = (newAddresses: any, type: string) => {
    var addressType = type === "PLR" ? "COL" : "DEL";
    var temp = vm.newBooking.OtherAddresses.filter(
      (address: any) => address.AddressType !== addressType
    );
    vm.newBooking.OtherAddresses = temp.concat(newAddresses);

    if (newAddresses[0].AddressType === "COL") {
      vm.collectionDate = newAddresses[0].RequestDate;
      if (Date.parse(vm.newBooking.CutoffDate) < vm.collectionDate) {
        vm.resetVoyage();
      }
      if (
        actionMode.editBooking() &&
        vm.originalAddressDates.PLR &&
        !vm.originalAddressDates.PLR.edited &&
        vm.originalAddressDates.PLR.date !== newAddresses[0].RequestDate
      ) {
        vm.originalAddressDates.PLR.edited = true;
      }
    } else if (newAddresses[newAddresses.length - 1].AddressType === "DEL") {
      vm.deliveryDate = newAddresses[newAddresses.length - 1].RequestDate;
      if (
        vm.newBooking.VoyageReference &&
        Date.parse(vm.newBooking.VoyageReference.ETA) > vm.deliveryDate
      ) {
        vm.resetVoyage();
      }
      if (
        actionMode.editBooking() &&
        vm.originalAddressDates.PFD &&
        !vm.originalAddressDates.PFD.edited &&
        vm.originalAddressDates.PFD.date !==
          newAddresses[newAddresses.length - 1].RequestDate
      ) {
        vm.originalAddressDates.PFD.edited = true;
      }
    }
    $scope.$digest();
  };

  vm.onAddressRemove = (type: string) => {
    if (type == "PLR") {
      vm.collectionDate = undefined;
      vm.setUseShipperConsigneeFlag("PLR", false);
    }
    if (type == "PFD") {
      vm.deliveryDate = undefined;
      vm.setUseShipperConsigneeFlag("PFD", false);
    }
    removeAddress(type);

    $scope.$digest();
  };

  vm.onPlaceReset = (type: string) => {
    vm.clearPlace(type);

    // Done to get rid of console error
    setTimeout(() => {
      $scope.$digest();
    });
  };

  function removeAddress(type: string) {
    const addressType: string = type === "PLR" ? "COL" : "DEL";
    vm.newBooking.OtherAddresses = vm.newBooking.OtherAddresses.filter(
      (address: any) => {
        return address.AddressType !== addressType;
      }
    );
  }

  vm.fetchOtherAddressByType = (type: string) => {
    const addressType: string = type === "PLR" ? "COL" : "DEL";
    const addressList: AddressFormDto[] = [];
    vm.newBooking.OtherAddresses.find((address: any) => {
      if (address.AddressType === addressType) {
        addressList.push(address);
      }
    });
    return addressList;
  };

  vm.openVGMModal = async () => {
    const shippingType = ShipmentService.getShippingTypePair(
      vm.newBooking.ShippingType
    ).second;
    let items: VGMItem[] = [];

    const modalOpt =
      shippingType === "F" ? "showExtraLargeModal" : "showLargeModal";

    if (shippingType === "F") {
      items = await prepareFCLItems();
    } else if (shippingType === "L") {
      items = prepareLCLItems();
    }

    GeneralLoader.increase();

    if (vm.getJobReference() != null) {
      ShipmentService.getVgmCutOffTimeJobRef({
        JobReference: vm.newBooking.JobReference,
      })
        .then((data: any) => {
          GeneralLoader.decrease();
          openModal(data);
        })
        .catch(() => {
          GeneralLoader.decrease();
        });
    } else {
      ShipmentService.getVgmCutOffTime({
        VoyageReference: vm.newBooking.VoyageReference.VoyageReference,
        ShippingType: vm.newBooking.ShippingType,
        POL_VISIT_NR: vm.newBooking.VoyageReference.POL_VISIT_NR,
      })
        .then((data: any) => {
          GeneralLoader.decrease();
          openModal(data);
        })
        .catch(() => {
          GeneralLoader.decrease();
        });
    }

    function openModal(data: any) {
      ModalUtilities[modalOpt]("shipmentsVGM", {
        vgmData: function vgmDataFn() {
          return {
            Title: TranslationService.translate("TEXT_VGM_MODAL_TITLE"),
            CutOffDate: data.CutOffDate,
            Items: items,
            submitHandler: vm.onVgmModalSubmit,
          };
        },
        shippingType: function shippingTypeFn() {
          return shippingType;
        },
        displayMode: function displayModeFn() {
          return shippingType;
        },
      });
    }

    async function prepareFCLItems(): Promise<VGMItem[]> {
      const items: VGMItem[] = [];
      vm.loadingVGM = true;
      for (let i = 0; i < vm.newBooking.Details.length; i += 1) {
        const detail = vm.newBooking.Details[i];
        for (let k = 0; k < detail.Dimensions.length; k += 1) {
          const dimension: DimensionViewModel = detail.Dimensions[k];
          if (dimension.ContainerNumber) {
            if (!vm.vgmContainerInfoMap[dimension.ContainerNumber]) {
              const container = await ContainerService.getContainer(
                dimension.ContainerNumber
              );
              vm.vgmContainerInfoMap[dimension.ContainerNumber] = container;
            }
            items.push({
              ContainerNumber: dimension.ContainerNumber,
              ContainerPayloadWeight:
                vm.vgmContainerInfoMap[dimension.ContainerNumber].PayloadWeight,
              ContainerWeight:
                vm.vgmContainerInfoMap[dimension.ContainerNumber].TareWeight,
              CargoWeight: dimension.VgmCargoWeight
                ? dimension.VgmCargoWeight
                : null,
              Date: dimension.VgmDate ? dimension.VgmDate : null,
              Signature: dimension.VgmSignature ? dimension.VgmSignature : null,
              AuthNo: dimension.VgmAuthNo ? dimension.VgmAuthNo : null,
              Method: dimension.VgmMethod ? dimension.VgmMethod : null,
              VGMWeight: dimension.VgmWeight ? dimension.VgmWeight : null,
              DetailSequence: i,
              DimensionSequence: k,
            } as VGMItem);
          }
        }
      }
      vm.loadingVGM = false;
      return items;
    }

    function prepareLCLItems(): VGMItem[] {
      const items: VGMItem[] = [];
      for (let i = 0; i < vm.newBooking.Details.length; i += 1) {
        const detail: DetailViewModel = vm.newBooking.Details[i];
        const dimension: DimensionViewModel = detail.Dimensions[0];
        if (dimension) {
          items.push({
            Description: detail.Description,
            PackageCode: dimension.PackageCode,
            CargoWeight: dimension.VgmCargoWeight
              ? dimension.VgmCargoWeight
              : null,
            Date: dimension.VgmDate ? dimension.VgmDate : null,
            Signature: dimension.VgmSignature ? dimension.VgmSignature : null,
            AuthNo: dimension.VgmAuthNo ? dimension.VgmAuthNo : null,
            DetailSequence: i,
            DimensionSequence: 0,
          } as VGMItem);
        }
      }
      return items;
    }
  };

  vm.onVgmModalSubmit = async (items: VGMItem[]) => {
    const shippingType = ShipmentService.getShippingTypePair(
      vm.newBooking.ShippingType
    ).second;
    for (const item of items) {
      const dimension: DimensionViewModel =
        vm.newBooking.Details[item.DetailSequence].Dimensions[
          item.DimensionSequence
        ];
      dimension.VgmCargoWeight = item.CargoWeight;
      dimension.VgmDate = item.Date;
      dimension.VgmSignature = item.Signature;
      dimension.VgmAuthNo = item.AuthNo;
      dimension.Modified = item.Modified;
      if (shippingType === "F") {
        dimension.VgmMethod = item.Method;
        dimension.VgmWeight = item.VGMWeight;
      }
    }
    vm.vgmEdited = true;
  };

  vm.canEditVGM = () => {
    const hasDimensions = (shippingType: string) => {
      if (!vm.newBooking.Details || vm.newBooking.Details.length === 0) {
        return false;
      }
      for (const detail of vm.newBooking.Details) {
        if (detail.Dimensions && detail.Dimensions.length > 0) {
          if (shippingType === "F") {
            for (const dim of detail.Dimensions) {
              if (dim.ContainerNumber) {
                return true;
              }
            }
          } else {
            return true;
          }
        }
      }
      return false;
    };

    const shippingType = ShipmentService.getShippingTypePair(
      vm.newBooking.ShippingType
    ).second;
    if (
      (shippingType === "F" || shippingType === "L") &&
      hasDimensions(shippingType) &&
      vm.newBooking.CutoffDate
    ) {
      return true;
    }
  };

  // Document Functions
  vm.onDocumentModalSubmit = async (files: DocumentFile[]) => {
    vm.documentsToUpload = vm.documentsToUpload.concat(files);
    vm.showDocumentModal = false;

    $scope.$digest();
  };

  vm.onDocumentModalClose = () => {
    vm.showDocumentModal = false;

    setTimeout(() => {
      $scope.$digest();
    });
  };

  vm.openDocumentModal = () => {
    vm.showDocumentModal = true;
    $scope.$digest();
  };

  // Open the Terms and Conditions modal and, if a user is an employee: submit a booking without opening the modal
  vm.openTermsConditionsModal = () => {
    if (vm.areTermsAndConditionsAccepted) {
      vm.submitBooking();
    } else {
      vm.showTermsConditionsModal = true;
      $scope.$digest();
    }
  };

  // Handle the close action of the Terms and Conditions modal
  vm.onTermsConditionsModalClose = () => {
    vm.areTermsAndConditionsAccepted = false;
    vm.showTermsConditionsModal = false;
    vm.isSubmitShippingInstruction = false;
    vm.showTermsConditionErrorMessage = false;

    setTimeout(() => {
      $scope.$digest();
    });
  };

  // Handle the accept action of the Terms and Conditions modal
  vm.onTermsConditionsModalAccept = () => {
    if (vm.areTermsAndConditionsAccepted) {
      if (vm.isSubmitShippingInstruction) {
        vm.showTermsConditionsModal = false;
        vm.openInstructionsConfirmModal();
      } else {
        vm.showTermsConditionsModal = false;
        vm.submitBooking();
      }
    } else {
      vm.showTermsConditionErrorMessage = true;
      $scope.$digest();
    }
  };

  // Function that hides the error message in the Terms and Condition modal if the checkbox is clicked
  vm.onTermsConditionCheckboxClick = () => {
    vm.areTermsAndConditionsAccepted = !vm.areTermsAndConditionsAccepted;
    if (vm.showTermsConditionErrorMessage) {
      vm.showTermsConditionErrorMessage = false;
      $scope.$digest();
    }
  };

  vm.handleTermsAndConditionModal = (
    isShippingInstructionClicked: boolean = false
  ) => {
    vm.isSubmitShippingInstruction = isShippingInstructionClicked;
    if (vm.areTermsAndConditionsAccepted) {
      if (isShippingInstructionClicked) {
        vm.openInstructionsConfirmModal();
      } else {
        vm.submitBooking();
      }
    } else {
      vm.openTermsConditionsModal();
    }
  };

  vm.handleTermsAndConditionModalInstructions = () => {
    vm.handleTermsAndConditionModal(true);
  };

  vm.handleTermsAndConditionModalSubmit = () => {
    vm.handleTermsAndConditionModal(false);
  };

  vm.removeDocumentToUpload = (file: DocumentFile) => {
    vm.documentsToUpload.splice(vm.documentsToUpload.indexOf(file), 1);
  };

  vm.removeDocument = (file: SDCDocument) => {
    ShipmentService.removeBookingDocument(file.ID).then(() => {
      vm.bookingDocuments.splice(vm.bookingDocuments.indexOf(file), 1);
    });
  };

  vm.getJobReference = (): string | null => {
    if (actionMode.editBooking()) {
      return vm.newBooking.JobReference;
    }
    return null;
  };

  vm.openAddressPopup = (name: string, isNew: boolean) => {
    vm.newAddress[name] = isNew;
    vm.showAddressPopup[name] = true;
  };

  // Special open functions for each popup is necessary because of react2angular weirdness
  // shipper
  vm.openShipperPopup = () => {
    vm.openAddressPopup("Shipper", false);
    $scope.$digest();
  };

  vm.openNewShipperPopup = () => {
    vm.openAddressPopup("Shipper", true);
    $scope.$digest();
  };
  // Consignee
  vm.openConsigneePopup = () => {
    vm.openAddressPopup("Consignee", false);
    $scope.$digest();
  };

  vm.openNewConsigneePopup = () => {
    vm.openAddressPopup("Consignee", true);
    $scope.$digest();
  };
  // Notifier
  vm.openNotifierPopup = () => {
    vm.openAddressPopup("Notifier", false);
    $scope.$digest();
  };

  vm.openNewNotifierPopup = () => {
    vm.openAddressPopup("Notifier", true);
    $scope.$digest();
  };

  // SecondNotifier
  vm.openSecondNotifierPopup = () => {
    vm.openAddressPopup("SecondNotifier", false);
    $scope.$digest();
  };

  vm.openNewSecondNotifierPopup = () => {
    vm.openAddressPopup("SecondNotifier", true);
    $scope.$digest();
  };

  vm.displayPLR = () => {
    vm.displayPlace("PLR");
    $scope.$digest();
  };

  vm.displayPFD = () => {
    vm.displayPlace("PFD");
    $scope.$digest();
  };

  vm.removeAddress = (name: string) => {
    vm.newBooking[name] = null;
  };

  // A special close function for each popup is necessary because of react2angular weirdness
  vm.closeShipperPopup = () => {
    vm.showAddressPopup["Shipper"] = false;
    $scope.$digest();
  };

  vm.closeConsigneePopup = () => {
    vm.showAddressPopup["Consignee"] = false;
    $scope.$digest();
  };

  vm.closeNotifierPopup = () => {
    vm.showAddressPopup["Notifier"] = false;
    $scope.$digest();
  };

  vm.closeSecondNotifierPopup = () => {
    vm.showAddressPopup["SecondNotifier"] = false;
    $scope.$digest();
  };

  vm.openInstructionsConfirmModal = () => {
    vm.showInstructionsConfirmModal = true;
    $scope.$digest();
  };

  vm.closeInstructionsConfirmModal = () => {
    vm.isSubmitShippingInstruction = false;
    vm.areTermsAndConditionsAccepted = false;
    vm.showInstructionsConfirmModal = false;
    $scope.$digest();
  };

  vm.openUnlinkQuoteModal = () => {
    vm.showUnlinkQuoteModal = true;
    $scope.$digest();
  };

  vm.closeUnlinkQuoteModal = () => {
    vm.showUnlinkQuoteModal = false;
    $scope.$digest();
  };

  vm.submitUnlinkQuoteModal = () => {
    vm.isQuoteBooking = false;
    vm.QuoteReference = "";
    vm.closeUnlinkQuoteModal();
    $scope.$digest();
  };

  vm.toggleVoyagePickerOpen = () => {
    vm.voyagepickerIsOpen = !vm.voyagepickerIsOpen;
    $scope.$apply();
  };

  /**
   * Called when the HS codes are changed
   * @param  {string[]} newHSCodes      The selected HS codes.
   */
  vm.hsCodeChanged = function hsCodeChanged(newHSCodes: string[]): void {
    vm.newBooking.HSCodes = newHSCodes;
    $scope.$digest();
  };

  initVM();
}

shipmentsCreateBookingController.$inject = [
  "$scope",
  "$transition$",
  "userCompanies",
  "incoTerms",
  "shippingTypes",
  "commodities",
  "containerTypes",
  "packageTypes",
  "shippingPorts",
  "booking",
  "remark",
  "allowedReeferTemperatures",
  "linkedRequests",
  "hsCodes",
];
export default shipmentsCreateBookingController;
