import * as React from "react";
import * as _ from "lodash";
import { Table, Collapse } from "react-bootstrap";
import { ShipmentService, languages } from "Services";
import { ContainersDropdownState, ContainersDropdownProps } from "./interfaces";
import TableData from "./TableData";
import { UtilityFunctions } from "Utilities";
import { SG_REQUEST_TYPES } from "Constants/RequestConstants";

enum sortStatus {
  ONSITE = "ONSITE",
  INTRUCKING = "INTRUCKING",
  READYTRUCK = "READYTRUCK",
  INPROGRESS = "INPROGRESS",
  ONHOLD = "ONHOLD",
  REGISTERED = "REGISTERED",
  NOREQ = "NOREQ",
  CLOSED = "CLOSED",
}

export default class ContainersDropdown extends React.Component<
  ContainersDropdownProps,
  ContainersDropdownState
> {
  state: ContainersDropdownState = {
    open: false,
    units: [],
    status: [],
    containerData: [],
    isLoading: false,
    hasData: false,
    nodesLoaded: 0,
    isPODIS: false,
  };

  requestTypes = [SG_REQUEST_TYPES.DRREQCNTIMP, SG_REQUEST_TYPES.RELFCLIMP];
  excludedStatuses = ["CANCELED"];

  async updateContainerData() {
    const unitPromise = ShipmentService.getUnitsForBooking(
      this.props.reference
    );
    if (UtilityFunctions.isIcelandicPointCode(this.props.portOfDischarge)) {
      const statusPromise = ShipmentService.getRequestsForBookingByRequestTypes(
        this.props.reference,
        this.requestTypes,
        this.excludedStatuses
      );
      let containerData: any = [];
      await Promise.all([unitPromise, statusPromise]).then(
        ([units, statuses]) => {
          _.map(units, (item) => {
            _.merge(
              item,
              _.find(statuses, { ContainerNumber: item.ContainerNumber })
            );
          });
          containerData = units;
          // Adding key value to sort table data
          _.map(containerData, (item) => {
            if (item.Status === sortStatus.ONSITE) {
              item.SortValue = 1;
              return item;
            }
            if (item.Status === sortStatus.INTRUCKING) {
              item.SortValue = 2;
              return item;
            }
            if (item.Status === sortStatus.READYTRUCK) {
              item.SortValue = 3;
              return item;
            }
            if (item.Status === sortStatus.INPROGRESS) {
              item.SortValue = 4;
              return item;
            }
            if (item.Status === sortStatus.ONHOLD) {
              item.SortValue = 5;
              return item;
            }
            if (item.Status === sortStatus.REGISTERED) {
              item.SortValue = 6;
              return item;
            }
            if (!("Status" in item)) {
              item.SortValue = 7;
              return item;
            }
            if (item.Status === sortStatus.CLOSED) {
              item.SortValue = 8;
              return item;
            }
          });
          const data = _.orderBy(
            containerData,
            ["SortValue", "DeliveryTime", "DateClosed"],
            ["asc", "desc", "desc"]
          );
          this.setState({
            containerData: data,
            hasData: true,
            isPODIS: true,
          });
        }
      );
    }

    Promise.resolve(unitPromise).then((value) => {
      this.setState({ containerData: value, hasData: true });
    });
  }

  onOpen() {
    this.setState({ open: !this.state.open });
  }

  onEnter = () => {
    this.setState({ isLoading: true });
  };

  onEntered = async () => {
    await this.updateContainerData();
  };

  onExited = () => {
    this.setState({ nodesLoaded: 0, hasData: false });
  };

  onChildLoaded = () => {
    this.state.nodesLoaded += 1;
    if (this.state.nodesLoaded === this.state.containerData.length) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { containerData, isLoading, hasData, isPODIS } = this.state;

    return (
      <div>
        <button onClick={() => this.onOpen()} className="btnContainersDropdown">
          {languages("LABEL_CONTAINERS")}
          <i
            className={!this.state.open ? "fa fa-caret-down" : "fa fa-caret-up"}
          ></i>
        </button>
        <Collapse
          in={this.state.open}
          onEnter={this.onEnter}
          onEntered={this.onEntered}
          onExited={this.onExited}
        >
          <div>
            {isLoading && (
              <div className="spinnerWrapper">
                <i className="fa fa-spinner fa-spin spinner"></i>
              </div>
            )}
            {hasData && (
              <Table responsive className={isLoading ? "no-margin" : ""}>
                {!isLoading && (
                  <thead>
                    <tr className="gutter">
                      <th className="col-lg-1 col-md-1 col-sm-1">
                        <span>{languages("LABEL_CONTAINERNUMBER")}</span>
                      </th>
                      <th className="col-lg-1 col-md-1 col-sm-1">
                        <span>{languages("LABEL_TYPE")}</span>
                      </th>
                      <th className="col-lg-3 col-md-3 col-sm-3">
                        <span>{languages("LABEL_CONTENT")}</span>
                      </th>
                      {isPODIS && (
                        <>
                          <th className="col-lg-1 col-md-1 col-sm-1">
                            <span>{languages("LABEL_STATUS")}</span>
                          </th>
                          <th className="col-lg-3 col-md-3 col-sm-3">
                            <span>{languages("LABEL_DESCRIPTION")}</span>
                          </th>
                        </>
                      )}
                      <th className="col-lg-1 col-md-1 col-sm-1">
                        <span>{languages("LABEL_QUANTITY")}</span>
                      </th>
                      <th className="col-lg-1 col-md-1 col-sm-1">
                        <span>{languages("LABEL_UNIT")}</span>
                      </th>
                      <th className="col-lg-1 col-md-1 col-sm-1">
                        <span>{languages("LABEL_WEIGHT")}</span>
                      </th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {containerData.map((item, i: number) => (
                    <TableData
                      data={item}
                      key={i}
                      onRender={this.onChildLoaded}
                      isLoading={this.state.isLoading}
                      isPODIS={isPODIS}
                    />
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        </Collapse>
      </div>
    );
  }
}
