/**
 * Helper-"class" for common paging-functionality across the daashboard-
 * lists. Initially displays a certain number of items and allows the
 * user to display more items.
 * @param {Array}   sourceArr   An array of items to be paged.
 * @param {Number}  pageSize    The number of items to be displayed
 *                              initially (defaults to 20 items).
 */
class DashboardPager {
  source: any[];
  pageSize: number;
  list: any[];
  shown: number;
  total: number;

  constructor(sourceArr: any[], pageSize?: number) {
    this.source = sourceArr || [];
    this.pageSize = pageSize || 20;
    this.list = sourceArr.slice(0, this.pageSize);
    this.shown = this.list.length;
    this.total = sourceArr.length;
  }

  /**
   * Extends the displayed list of items to display a larger chunk of the
   * pager's source-array.
   */
  showMore = (itemCount: number): void => {
    const extendSize = itemCount || 10;
    this.list = this.source.slice(0, this.list.length + extendSize);
    this.shown = this.list.length;
  };

  /**
   * Removes an item both from the template and the pager's source-array.
   * @param {Object}  item    The item to be removed.
   */
  remove = (item: any): void => {
    this.list = this.list.filter(it => it !== item);
    this.source = this.source.filter(it => it !== item);
    this.total = this.source.length;
    this.shown = this.list.length;
  };
}

export default DashboardPager;
