import React, { useState, useEffect } from "react";
import { languages, ShipmentService } from "Services";
import {
  Loader,
  Grid,
  GridColumn as Column,
  GridRow as Row,
  Checkbox,
  Button,
} from "semantic-ui-react";
import { Modal, Button as CustomButton } from "Component";
import styles from "./index.modules.less";
import _ from "lodash";
import { BookingStore } from "Stores";
import { $uiRouter } from "Bootstrap/angular";

declare var GlobalConfig: GlobalConfig;

interface BookingCopyModalProps {
  open: boolean;
  jobReference: string | undefined;
  onClose: Function;
}
interface BookingCopyConfig {
  Shipper: boolean;
  Consignee: boolean;
  Notifier: boolean;
  SecondNotifier: boolean;
  Cargo: boolean;
  Commodity: boolean;
  HSCodes: boolean;
  NumUnits: boolean;
  Volume: boolean;
  Weight: boolean;
  Hazardous: boolean;
  Oversize: boolean;
  POL: boolean;
  POD: boolean;
  PLR: boolean;
  PFD: boolean;
  COL: boolean;
  DEL: boolean;
}

interface FieldLinkItem {
  parent: string | null;
  children: string[];
}

// Map to know which fields are linked together
const FieldLinkMap = {
  Cargo: {
    parent: null,
    children: [
      "Hazardous",
      "Oversize",
      "Commodity",
      "NumUnits",
      "Weight",
      "Volume",
    ],
  } as FieldLinkItem,
  PLR: {
    parent: null,
    children: ["COL"],
  } as FieldLinkItem,
  PFD: {
    parent: null,
    children: ["DEL"],
  } as FieldLinkItem,
  Commodity: {
    parent: "Cargo",
    children: [],
  } as FieldLinkItem,
  NumUnits: {
    parent: "Cargo",
    children: [],
  } as FieldLinkItem,
  Volume: {
    parent: "Cargo",
    children: [],
  } as FieldLinkItem,
  Weight: {
    parent: "Cargo",
    children: [],
  } as FieldLinkItem,
  Hazardous: {
    parent: "Cargo",
    children: [],
  } as FieldLinkItem,
  Oversize: {
    parent: "Cargo",
    children: [],
  } as FieldLinkItem,
};

export default (props: BookingCopyModalProps) => {
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [booking, setBooking] = useState<BookingViewModel | null>(null);
  const [config, setConfig] = useState({
    Shipper: true,
    Consignee: true,
    Notifier: true,
    SecondNotifier: true,
    Cargo: true,
    Commodity: true,
    HSCodes: true,
    NumUnits: true,
    Volume: true,
    Weight: true,
    Hazardous: true,
    Oversize: true,
    POL: true,
    POD: true,
    PLR: true,
    PFD: true,
    COL: true,
    DEL: true,
  } as BookingCopyConfig);

  useEffect(() => {
    setOpen(props.open);
    if (props.open && props.jobReference) {
      ShipmentService.copyBooking(props.jobReference).then(
        (data: BookingViewModel) => {
          setBooking(data);
        }
      );
    }
  }, [props.open]);

  const renderCheckbox = (label: string, id: string, children?: any) => {
    return (
      <>
        <Checkbox
          label={label}
          onChange={(event: any, data: any) => handleToggle(id, data.checked)}
          checked={config[id]}
        />
        {children && <div className="subsection">{children}</div>}
      </>
    );
  };

  const close = () => {
    setBooking(null);
    setOpen(false);
    // Reset config values
    const newConfig = _.clone(config);
    for (const prop in newConfig) {
      newConfig[prop] = true;
    }
    setConfig(newConfig);
    props.onClose();
  };

  const submit = () => {
    const copiedBooking = _.cloneDeep(booking) as any;
    if (copiedBooking) {
      copiedBooking.Shipper = config.Shipper ? copiedBooking.Shipper : null;
      copiedBooking.Consignee = config.Consignee
        ? copiedBooking.Consignee
        : null;
      copiedBooking.Notifier = config.Notifier ? copiedBooking.Notifier : null;
      copiedBooking.SecondNotifier = config.SecondNotifier
        ? copiedBooking.SecondNotifier
        : null;
      copiedBooking.Details = config.Cargo ? copiedBooking.Details : null;
      copiedBooking.POL = config.POL ? copiedBooking.POL : null;
      copiedBooking.POD = config.POD ? copiedBooking.POD : null;
      copiedBooking.PLR = config.PLR ? copiedBooking.PLR : null;
      copiedBooking.PFD = config.PFD ? copiedBooking.PFD : null;
      copiedBooking.HSCodes = config.HSCodes ? copiedBooking.HSCodes : null;

      if (copiedBooking.Details) {
        for (const detail of copiedBooking.Details) {
          detail.HazardFlag = config.Hazardous ? detail.HazardFlag : null;
          detail.OversizeFlag = config.Oversize ? detail.OversizeFlag : null;
          detail.CommodityCode = config.Commodity ? detail.CommodityCode : null;
          detail.Description = config.Commodity ? detail.Description : null;
          detail.NumUnits = config.NumUnits ? detail.NumUnits : null;
          detail.TotalOriginNetWeight = config.Weight
            ? detail.TotalOriginNetWeight
            : null;
          detail.Volume = config.Volume ? detail.Volume : null;
          if (config.NumUnits || isShippingType("L")) {
            for (const dimension of detail.Dimensions) {
              dimension.Hazardous = config.Hazardous
                ? dimension.Hazardous
                : null;
              dimension.Oversize = config.Oversize ? dimension.Oversize : null;
              dimension.Weight = config.Weight ? dimension.Weight : null;
              dimension.NumUnits = config.NumUnits ? dimension.NumUnits : null;
              dimension.Volume = config.Volume ? dimension.Volume : null;
            }
          } else {
            detail.Dimensions = [];
          }
        }
      }
      if (!config.COL && copiedBooking.OtherAddresses) {
        const address = copiedBooking.OtherAddresses.find(
          (item: any) => item.AddressType === "COL"
        );
        if (address) {
          copiedBooking.OtherAddresses.splice(
            copiedBooking.OtherAddresses.indexOf(address),
            1
          );
        }
      }
      if (!config.DEL && copiedBooking.OtherAddresses) {
        const address = copiedBooking.OtherAddresses.find(
          (item: any) => item.AddressType === "DEL"
        );
        if (address) {
          copiedBooking.OtherAddresses.splice(
            copiedBooking.OtherAddresses.indexOf(address),
            1
          );
        }
      }
      // As per request when LoadRef is added, it is not needed to copy the LoadRef when copying booking
      // Thus we iterate the otherAddresses again, and make sure to drop the loadRef in COL and DEL
      if (copiedBooking.OtherAddresses) {
        copiedBooking.OtherAddresses = copiedBooking.OtherAddresses.map(
          (item: any) => {
            if (item.AddressType === "COL" || item.AddressType === "DEL") {
              const { LoadReference, ...rest } = item;
              return rest;
            }
            return item;
          }
        );
      }

      BookingStore.setBooking(copiedBooking);
      $uiRouter.stateService.go("shipments_createbooking.copy", {
        jobReference: props.jobReference,
      });
      setSubmitted(true);
    }
  };

  const handleToggle = (id: string, value: boolean) => {
    const newConfig = _.clone(config);
    newConfig[id] = value;

    if (FieldLinkMap[id]) {
      const parent = FieldLinkMap[id].parent;
      if (parent && value && !newConfig[parent]) {
        newConfig[parent] = true;
      }

      // Also check/uncheck child fields
      const children = FieldLinkMap[id].children;
      if (children) {
        for (const child of children) {
          newConfig[child] = value;
        }
      }
    }

    setConfig(newConfig);
  };

  const hasAddress = (type: string) => {
    if (booking && booking.OtherAddresses) {
      const address = booking.OtherAddresses.find(
        (item) => item.AddressType === type
      );
      return address !== null;
    }
    return false;
  };

  const hasDetailProperty = (property: string) => {
    return (
      booking &&
      booking.Details &&
      booking.Details.some((item) => item[property])
    );
  };

  const isShippingType = (type: "F" | "L"): boolean => {
    if (booking) {
      return (
        ShipmentService.getShippingTypePair(booking.ShippingType).second ===
        type
      );
    }
    return false;
  };

  const renderFooter = () => {
    return (
      <div className="footer">
        <div className="right u-flex">
          <CustomButton onClick={close} disabled={submitted}>
            {languages("LABEL_CANCEL")}
          </CustomButton>

          <CustomButton primary onClick={submit}>
            {submitted ? (
              <Loader active inline="centered" inverted />
            ) : (
              languages("LABEL_PROCEED")
            )}
          </CustomButton>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (booking) {
      return (
        <>
          <Grid>
            <Row>
              <Column width={6}>
                <label>{languages("LABEL_BOOKING_PARTIES_INFO")}</label>
                {booking.Shipper &&
                  renderCheckbox(
                    languages("LABEL_SHIPPER"),
                    "Shipper"
                  )}
                {booking.Consignee &&
                  renderCheckbox(
                    languages("LABEL_CONSIGNEE"),
                    "Consignee"
                  )}
                {booking.Notifier &&
                  renderCheckbox(
                    languages("LABEL_NOTIFIER"),
                    "Notifier"
                  )}
                {booking.SecondNotifier &&
                  renderCheckbox(
                    languages("LABEL_NOTIFIER_SECONDARY"),
                    "SecondNotifier"
                  )}
              </Column>
              <Column width={10}>
                <label>{languages("LABEL_PLACESANDPORTS")}</label>
                {booking.POL &&
                  renderCheckbox(
                    languages("LABEL_PORT_OF_LOAD"),
                    "POL"
                  )}
                {booking.POD &&
                  renderCheckbox(
                    languages("LABEL_PORT_OF_DISCHARGE"),
                    "POD"
                  )}
                {booking.PLR &&
                  renderCheckbox(
                    languages("LABEL_PLACE_OF_RECEIPT"),
                    "PLR",
                    hasAddress("COL") &&
                      renderCheckbox(
                        languages("LABEL_WITH_ADDRESS_OUTSIDE_ICELAND"),
                        "COL"
                      )
                  )}
                {booking.PFD &&
                  renderCheckbox(
                    languages("LABEL_PLACE_OF_DELIVERY"),
                    "PFD",
                    hasAddress("DEL") &&
                      renderCheckbox(
                        languages("LABEL_WITH_ADDRESS_OUTSIDE_ICELAND"),
                        "DEL"
                      )
                  )}
              </Column>
            </Row>
            <Row>
              {((booking.Details && booking.Details.length > 0) ||
                (booking.HSCodes && booking.HSCodes.length > 0)) && (
                <Column>
                  <label>{languages("LABEL_DETAILS")}</label>
                  {booking.HSCodes &&
                    booking.HSCodes.length > 0 &&
                    renderCheckbox(
                      languages("LABEL_HSCODE"),
                      "HSCodes"
                    )}
                  {booking.Details && booking.Details.length > 0 && (
                    <>
                      {renderCheckbox(
                        languages("LABEL_CARGO"),
                        "Cargo",
                        <>
                          {hasDetailProperty("CommodityCode") &&
                            renderCheckbox(
                              languages("LABEL_CARGODESCRIPTION"),
                              "Commodity"
                            )}
                          {hasDetailProperty("NumUnits") &&
                            renderCheckbox(
                              languages("LABEL_QUANTITY"),
                              "NumUnits"
                            )}
                          {hasDetailProperty("TotalOriginNetWeight") &&
                            renderCheckbox(
                              languages("LABEL_WEIGHT"),
                              "Weight"
                            )}
                          {hasDetailProperty("Volume") &&
                            renderCheckbox(
                              languages("LABEL_VOLUME"),
                              "Volume"
                            )}
                          {hasDetailProperty("HazardFlag") &&
                            renderCheckbox(
                              languages("LABEL_HAZARDOUS_MATERIALS"),
                              "Hazardous"
                            )}
                          {hasDetailProperty("OversizeFlag") &&
                            renderCheckbox(
                              languages("LABEL_OVERSIZE"),
                              "Oversize"
                            )}
                        </>
                      )}
                    </>
                  )}
                </Column>
              )}
            </Row>
          </Grid>
          {renderFooter()}
        </>
      );
    }
    return <Loader active size="massive" inline="centered" />;
  };

  return (
    <Modal
      open={open}
      size="tiny"
      header={languages("TEXT_WHAT_TO_COPY")}
      onClose={close}
      content={renderContent()}
      additionalStyles={styles.modal}
    />
  );
};
