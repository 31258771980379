import * as detailmodalTemplate from "Shipments/createbooking/detailmodal/detailmodal.html";
import * as angular from "angular";

import { WindowUtilities } from "Utilities";
import { ShipmentRegistryService } from "Services";
import BookingValidationService from "Shipments/createbooking/BookingValidationService";
import { react2angular } from "react2angular";
import CheckboxIcon from "./checkboxIcon";

declare var GlobalConfig: GlobalConfig;

var VgmAuthNo: string | undefined;

var VgmCargoWeight: number | undefined;

var VgmDate: Date | undefined;

var VgmSignature: string | undefined;

const shipmentCreateBookingDetailModal: ng.IComponentOptions = {
  template: `${detailmodalTemplate}`,
  bindings: {
    modalInstance: "<",
    close: "&",
    dismiss: "&",
    resolve: "<",
  },

  controller: class DetailModalController {
    private close: Function;
    private dismiss: Function;

    private resolve: any;

    private bookingDetail: any;

    // Detail's total-volume can never exceed 999.999.
    private DETAIL_MAX_VOLUME: number;

    // A single dimension's volume can never exceed 99.999
    private DIMENSION_MAX_VOLUME: number;

    private marksAndNumbersBtnVerbs: any;

    private commodities: any;

    private packageTypes: any;

    private isBookingFromQuote: boolean;

    private currentDetail: DetailViewModel;

    private selectedCommodity: any;

    private allowedReeferTemperatures: any;

    private _detailCountMax: number;

    private _volumeRequired: boolean;

    private _weightRequired: boolean;

    private _showQuantityError: boolean;

    private _descriptionMaxLength: number;

    private _collapseMarksAndNumbers: boolean;

    private _locked: boolean;

    private _packageCodeDisabled: boolean;

    private isShippingTypesEmpty: boolean;

    private _buttonDisabled: boolean;

    private _showWarningModal: boolean;

    private hasContainer: boolean;

    $onInit = () => {
      this.initialize();
    };

    private initialize(): void {
      this.currentDetail = this.resolve.currentDetail;
      this.packageTypes = this.resolve.packageTypes;
      this.isBookingFromQuote = this.resolve.isBookingFromQuote;

      // Make sure to store the existing VGM info so it will not be initalized
      // when saving the new detail info.
      this.tempStoreVGMInfo();

      // Bind resolve
      this.commodities = this.resolve.commodities;
      // Detail's total-volume can never exceed 999.999.
      this.DETAIL_MAX_VOLUME = 999.999;
      // A single dimension's volume can never exceed 99.999
      this.DIMENSION_MAX_VOLUME = 99.999;
      // Initialize quantity error to false so it doesnt appear
      this._showQuantityError = false;
      // Possible verbs for the Marks & Numbers button
      this.marksAndNumbersBtnVerbs = {
        register: "LABEL_REGISTER",
        show: "LABEL_SHOW",
        hide: "LABEL_HIDE",
      };

      if (
        this.resolve.shippingType === "F" ||
        this.resolve.shippingType === "M"
      ) {
        this.hasContainer = true;
      } else {
        this.hasContainer = false;
      }
      this.bookingDetail = this.currentDetail || {};
      this.currentDetail = Object.assign({}, this.bookingDetail);
      this.currentDetail.Dimensions = this.currentDetail.Dimensions || [];
      this.selectedCommodity = {
        CommodityCode: this.currentDetail.CommodityCode,
        Description: this.currentDetail.Description,
      };

      this.packageTypes = this.filterPackageTypes(this.resolve.currentDetail);
      this.allowedReeferTemperatures = this.resolve.allowedReeferTemperatures;

      this.initializeEmptyShipmentsType();
      // Maximum for details' NumUnits is 9999 for LCL, 99 for FCL & MTY
      this._detailCountMax = this.resolve.shippingType === "L" ? 9999 : 99;
      // Weight is required for LCL and FCL bookings, not allowed for MTY bookings
      this._weightRequired = this.resolve.shippingType !== "M";
      this._descriptionMaxLength = 30;
      // Start with the marks & numbers collapsed
      this._collapseMarksAndNumbers = true;
      this._buttonDisabled = false;
      this._showWarningModal = false;

      // Disable/enabled fields based on the shippingType
      if (this.resolve.shippingType === "L") {
        // If any of an LCL-detail's dimensions has a set ContainerNumber, lock all inputs
        // (except marks & descriptions) and start with marks & descriptions visible.
        this._locked = this.detailDimensionsHaveContainerNumbers(
          this.resolve.currentDetail
        );

        this._collapseMarksAndNumbers = !this._locked;
      } else if (this.resolve.shippingType === "F") {
        // PackageCode-selection should be disabled for FCL-bookings when container-numbers
        // have been entered for any of the detail's dimensions.
        if (this.resolve.currentDetail) {
          this._packageCodeDisabled = this.detailDimensionsHaveContainerNumbers(
            this.resolve.currentDetail
          );
        } else {
          this._packageCodeDisabled = false;
        }
      }
    }

    // Stores the existing VGM info so it will not be removed when
    // saving the new detail line.
    tempStoreVGMInfo(): void {
      try {
        VgmAuthNo = this.currentDetail.Dimensions[0].VgmAuthNo;
      } catch (e) {}

      try {
        VgmCargoWeight = this.currentDetail.Dimensions[0].VgmCargoWeight;
      } catch (e) {}

      try {
        VgmDate = this.currentDetail.Dimensions[0].VgmDate;
      } catch (e) {}

      try {
        VgmSignature = this.currentDetail.Dimensions[0].VgmSignature;
      } catch (e) {}
    }

    // Reads the VGM info of the current detail line and makes sure to
    // store it properly when saving the new detail line.
    saveVGMInfo(): void {
      try {
        this.currentDetail.Dimensions[0].VgmAuthNo = VgmAuthNo;
        this.currentDetail.Dimensions[0].VgmCargoWeight = VgmCargoWeight;
        this.currentDetail.Dimensions[0].VgmDate = VgmDate;
        this.currentDetail.Dimensions[0].VgmSignature = VgmSignature;
      } catch (e) {
        console.log(e);
      }
    }

    /**
     * Checks what the Quantity typed in is and sets values according to that (only when shipping type is F or M)
     * ok if: 1<QTY<=10
     * warning if: 10<QTY<=50
     * error msg if: QTY >50
     */
    checkQuantity() {
      if (
        this.currentDetail.NumUnits !== null &&
        this.currentDetail.NumUnits > 50 &&
        (this.resolve.shippingType === "F" || this.resolve.shippingType === "M")
      ) {
        this._showQuantityError = true;
        this._buttonDisabled = true;
      }
      if (
        this.currentDetail.NumUnits !== null &&
        this.currentDetail.NumUnits > 10 &&
        this.currentDetail.NumUnits <= 50 &&
        (this.resolve.shippingType === "F" || this.resolve.shippingType === "M")
      ) {
        this._showWarningModal = true;
        this._buttonDisabled = true;
        this._showQuantityError = false;
      }
      if (
        this.currentDetail.NumUnits !== null &&
        this.currentDetail.NumUnits <= 10 &&
        this.currentDetail.NumUnits > 0 &&
        (this.resolve.shippingType === "F" || this.resolve.shippingType === "M")
      ) {
        this._showQuantityError = false;
      }
    }

    /**
     * Exits the warning modal by setting this._showWarningModal to false
     */
    onWarningExit() {
      this._showWarningModal = false;
      this._buttonDisabled = false;
    }

    /**
     * Sets the this._buttonDisabled to false, (save changes button gets disabled)
     */
    checkDisabled() {
      this._buttonDisabled = false;
    }

    /**
     * Closes the detail-modal and returns the new/updated Detail to the
     * calling context/scope.
     */
    submitDetail(): void {
      // this.saveVGMInfo();
      this.close({ $value: this.currentDetail });
    }

    /**
     * Determines the appropriate verb to use for the Marks & Numbers button.
     * The button's text will then be "{VERB} marks and numbers", e.g.
     * "Show marks and numbers", "Register marks and numbers", etc.
     * NOTE: This runs each $digest-cycle (when the modal is open).
     * @return {string}     Returns the appropriate verb for the button,
     *                      based on the state of the current detail.
     */
    getMarksAndNumbersBtnVerb(): string {
      const marksAndNumbersNotSet =
        this.currentDetail.Marks == null &&
        this.currentDetail.MarksDescription == null;
      let label = "";

      if (!this._collapseMarksAndNumbers) {
        label = this.marksAndNumbersBtnVerbs.hide;
      } else if (marksAndNumbersNotSet) {
        label = this.marksAndNumbersBtnVerbs.register;
      } else {
        label = this.marksAndNumbersBtnVerbs.show;
      }
      return label;
    }

    /**
     * Handler for when the marks & numbers panel has been expanded.
     * If marks and numbers have not been set, focus is moved to the first
     * textarea for marks & numbers.
     */
    marksAndNumbersExpanded(): void {
      const marksAndNumbersNotSet =
        this.currentDetail.Marks == null &&
        this.currentDetail.MarksDescription == null;

      if (marksAndNumbersNotSet) {
        WindowUtilities.focusByName("marksAndNumbers");
      }
    }

    /**
     * Handler for when a commodity-type is selected from the typeahead.
     * @param  {Object} commodity   The item selected from the typeahead results.
     */
    commoditySelected(commodity: any): void {
      // Need to truncate the Description to 30 characters because of the
      // input field's maxlength so the form always validates (longer strings
      // could come from the typeahead).
      const description = commodity.Description || "";
      this.currentDetail.Description = description.substring(
        0,
        this._descriptionMaxLength
      );
      this.currentDetail.CommodityCode = commodity.CommodityCode;
    }

    /**
     * Determine whether or not to disable the modal's oversize-checkbox
     * NOTE: This runs each $digest-cycle (when the modal is open).
     * @return {Bool}   Returns true if the checkbox should be disabled.
     */
    oversizeDisabled(): boolean {
      // Disable for shipping types LCL and MTY
      if (
        this.resolve.shippingType === "L" ||
        this.resolve.shippingType === "M"
      ) {
        return true;
      }

      // Either refrigerated or oversize can be enabled at the same time
      if (this.currentDetail && this.currentDetail.Refrigerated) {
        return true;
      }

      if (this.currentDetail && this.currentDetail.PackageCode == null) {
        return false;
      }

      const isRefrigerated = this.currentDetail.Refrigerated === true;
      const invalidOversizeContainer =
        !BookingValidationService.isValidOversizeContainer(
          this.currentDetail.PackageCode
        );

      return (
        this.isShippingTypesEmpty || isRefrigerated || invalidOversizeContainer
      );
    }

    /**
     * Determine whether or not to disable the modal's temperature-checkbox
     * NOTE: This runs each $digest-cycle (when the modal is open).
     * @return {Bool}   Returns true if the checkbox should be disabled.
     */
    refrigeratedDisabled(): boolean {
      // Prevent Temperature to hold its value when
      // refrigerated checkbox is unchecked.
      if (!this.currentDetail.Refrigerated) {
        this.currentDetail.Temperature = null;
      }

      // Either refrigerated or oversize can be enabled at the same time
      if (this.currentDetail.OversizeFlag) {
        return true;
      }
      if (this.currentDetail.PackageCode == null) {
        return false;
      }

      const isOversized = this.currentDetail.OversizeFlag;

      // Only certain containers, where shippingType is 'F' can
      // be refrigerated. All 'L' items can be marked as refrigerated.
      let invalidReeferContainer = false;
      if (this.resolve.shippingType === "F") {
        invalidReeferContainer =
          !BookingValidationService.isValidReeferContainer(
            this.currentDetail.PackageCode
          );
      }
      return this.isShippingTypesEmpty || isOversized || invalidReeferContainer;
    }

    /**
     * Determines if the input for Detail's TotalOriginNetWeight should be
     * disabled or not.
     * @return {bool}
     */
    weightInputDisabled(): boolean {
      return this.weightAndVolumeInputsDisabled();
    }

    /**
     * Determines if the input for Detail's Volume should be disabled.
     * @return {bool}
     */
    volumeInputDisabled(): boolean {
      return this.weightAndVolumeInputsDisabled();
    }

    /**
     * Computes the maximum allowed value for a Detail's volume.
     * NOTE: Runs in each $digest-cycle (when the modal is open).
     * @return {Number}
     */
    maxVolume(): number | undefined {
      const numUnits = this.currentDetail.NumUnits || 1;
      // For FCL-bookings, the max-volume for details depends on the number
      // of dimensions for that detail, but can never exceed the absolute
      // maximum for details (DETAIL_MAX_VOLUME).
      if (this.resolve.shippingType === "F") {
        return Math.min(
          numUnits * this.DIMENSION_MAX_VOLUME,
          this.DETAIL_MAX_VOLUME
        );
      }
      return this.DETAIL_MAX_VOLUME;
    }

    /**
     * Handler for when the detail's handling-flags change (Refrigerated,
     * Oversize, etc). Filters available package types.
     */
    handlingChanged(): void {
      this.packageTypes = this.filterPackageTypes(this.currentDetail);
    }

    /**
     * Search commodities from Registry service
     * @return {object}              [response.data from service]
     */
    private getCommodityForMTY(): any {
      const searchString = "EMPTY CONTAINERS";
      return ShipmentRegistryService.searchCommodities(searchString).then(
        (data: any) => {
          return data[0];
        }
      );
    }

    /**
     * The inputs for the detail's TotalOriginNetWeight and Volume values
     * should be disabled when the current bookin is an MTY-booking, when
     * dimensions have been added to the current detail and when either of
     * the detail's NumUnits and PackageCode have not been set.
     * @return {bool}
     */
    private weightAndVolumeInputsDisabled(): boolean {
      const isMTYBooking = this.resolve.shippingType === "M";
      let numUnitsDefined;
      if (this.currentDetail.NumUnits) {
        numUnitsDefined = this.currentDetail.NumUnits > 0;
      }

      const packageCodeDefined = this.currentDetail.PackageCode != null;
      let isFCLWithDimensions = false;

      if (this.currentDetail && this.currentDetail.Dimensions.length > 0) {
        isFCLWithDimensions = this.resolve.shippingType === "F";
      }

      return (
        isMTYBooking ||
        !numUnitsDefined ||
        !packageCodeDefined ||
        isFCLWithDimensions
      );
    }

    /**
     * Initialize commodity code for current shipment
     * if no shipping type is selected.
     */
    private initializeEmptyShipmentsType(): void {
      if (this.resolve.shippingType === "M") {
        this.getCommodityForMTY()
          .then((commodity: any) => {
            this.currentDetail = Object.assign(this.currentDetail, commodity, {
              Volume: null,
              TotalOriginNetWeight: null,
            });
            this.selectedCommodity = Object.assign(
              this.selectedCommodity,
              commodity
            );
          })
          .finally(() => {
            this.isShippingTypesEmpty = true;
          });
      } else {
        this.isShippingTypesEmpty = false;
      }
    }

    /**
     * Filter allowed package-types based on a detail's current state.
     * Always display all types when shipping type is LCL.
     * @param  {Object} detail  Current detail user is working with.
     * @return {Array}          Returns a new filtered array of package-types
     *                          that are applicable for the detail's current state.
     */
    private filterPackageTypes(detail: any): any[] {
      let result = Object.assign({}, this.resolve.packageTypes);
      result = Object.keys(result).map((key) => result[key]);

      if (this.resolve.shippingType !== "L") {
        result = this.filterRefrigerated(result, detail);
        result = this.filterOversize(result, detail);
        this.filterCurrentDetailPackageCode(result, detail);
      }

      return result;
    }

    private filterRefrigerated(displayedPackages: any[], detail: any): any[] {
      let theDisplayedPackages = displayedPackages;
      if (detail && detail.Refrigerated) {
        theDisplayedPackages = theDisplayedPackages.filter((packageType) => {
          // TODO: Remove when validation can return more detailed errors
          // 45" reefers are not bookable outside of Iceland
          if (
            this.resolve.POL &&
            this.resolve.POL.PointCode.substring(0, 2) !== "IS" &&
            packageType.PackageCode === "45HR"
          ) {
            return false;
          }
          return BookingValidationService.isValidReeferContainer(
            packageType.PackageCode
          );
        });
      } else {
        theDisplayedPackages = theDisplayedPackages.filter((packageType) => {
          return !BookingValidationService.isValidReeferContainer(
            packageType.PackageCode
          );
        });
      }

      return theDisplayedPackages;
    }

    private filterOversize(displayedPackages: any[], detail: any): any[] {
      let theDisplayedPackages = displayedPackages;

      if (detail && detail.OversizeFlag) {
        theDisplayedPackages = theDisplayedPackages.filter((packageType) => {
          return BookingValidationService.isValidOversizeContainer(
            packageType.PackageCode
          );
        });
      }

      return theDisplayedPackages;
    }

    /**
     * Reset package code in current detail if it is not part of 'displayedPackages'.
     * To prevent user to pick some package that isn't be available.
     * @param  {Object} displayedPackages   The detail that determines available package-types.
     * @param  {Object} detail              Current detail user is working with.
     */
    private filterCurrentDetailPackageCode(
      displayedPackages: any[],
      detail: any
    ): void {
      if (detail && detail.PackageCode) {
        if (
          !displayedPackages.find((it) => it.PackageCode === detail.PackageCode)
        ) {
          detail.PackageCode = null;
        }
      }
    }

    private detailDimensionsHaveContainerNumbers(detail: any): boolean {
      if (!detail) {
        return false;
      }
      if (detail.hasOwnProperty("Dimensions")) {
        return this.dimensionsHaveContainerNumbers(detail.Dimensions);
      }
      return false;
    }

    /**
     * Checks for the presence of container-numbers in an array of dimensions.
     * @param  {Array} dimensions   Array of dimension objects.
     * @return {Boolean}            Returns true if any dimension in the array
     *                              has its ContainerNumber-property set.
     */
    private dimensionsHaveContainerNumbers(dimensions: any[]): boolean {
      return dimensions.some((dimension) => {
        return dimension.ContainerNumber != null;
      });
    }
  },
};

angular
  .module("serviceWebApp")
  .component(
    "shipmentCreateBookingDetailModal",
    shipmentCreateBookingDetailModal
  )
  .component(
    "checkboxIcon",
    react2angular(CheckboxIcon, ["iconClass", "content"])
  );
