import * as angular from "angular";
import * as moment from "moment";
import { autorun } from "mobx";
import {
  UIRouter,
  StateService,
  Transition,
  StateDeclaration,
} from "@uirouter/core";
import { $filter, $timeout } from "ngimport";
import { Set } from "es6-shim";

import { MAIN_STATE } from "./index";
import RouteStore from "Stores/RouteStore";
import ShipmentDropdownService from "Shipments/ShipmentDropdownService";
import { USER_ROLES } from "Constants/UserRoles";
import { USERWEBSETTINGS } from "Constants/UserWebSettingsConstants";
import "Shipments/modal/bulkSearchModalComponent";
import "Shipments/modal/bulkUpdateRemarksComponent";
import "Shipments/modal/bulkUpdateDateComponent";
import "Shipments/modal/vgm/ShipmentsVGM";

import * as bulkSearchModalTemplate from "Shipments/modal/bulkSearchModal.html";
import * as bulkUpdateModalTemplate from "Shipments/modal/BulkUpdateDate.html";
import * as bulkUpdateRemarksModalTemplate from "Shipments/modal/bulkUpdateRemarks.html";
import { $uibModal, $uiRouter } from "Bootstrap/angular";
import {
  UserService,
  SamskipNotify,
  TranslationService,
  ShipmentService,
  ShipmentDocumentService,
  FinanceService,
} from "Services";
import {
  ArrayUtilities,
  ModalUtilities,
  UtilityFunctions,
  WindowUtilities,
} from "Utilities";
import GeneralLoader from "Utilities/GeneralLoader";

declare var GlobalConfig: GlobalConfig;
declare var $: any;

function shipmentsController(
  $scope: any,
  $transition$: Transition,
  favoriteBookings: any[]
) {
  const $state: StateService = $uiRouter.stateService;
  const currentState: string = $transition$.to().name || "";
  const currentStateParams: any = $transition$.params();
  const previousState = !RouteStore.previousState
    ? MAIN_STATE
    : RouteStore.previousState;

  let allFavoriteBookings = ArrayUtilities.unique(favoriteBookings);

  // Get current logged in user
  const currentUser = UserService.getUserProfile();
  $scope.currentUser = currentUser;

  this.currentState = currentState;

  // TODO: Refactor into some serice/constant/config with a sensible value
  const VGM_INTEGRATION_DATE = "2016-07-01";

  // Initialization of significant $scope variables
  $scope.shipments = [];
  $scope.voyages = [];
  $scope.itemsSelected = 0;
  $scope.selectedCompany = UserService.getSelectedCompany();

  $scope.resultText = currentStateParams.resultText;
  $scope.filterNamePrefix = currentStateParams.filterNamePrefix;

  $scope.filter = initFilter();
  $scope.FilterBase = initFilter();

  // pagination
  $scope.totalBookings = 0;
  $scope.currentPage = 1;
  $scope.maxSize = 3;

  $scope.jobReferenceToCopy = "";
  $scope.showCopyModal = false;

  $scope.errors = {
    search: {
      minLength: false,
      noCategory: false,
    },
  };

  loadDateSettings();

  autorun(() => {
    const listViewSize = UserService.getWebSettingObservable(
      USERWEBSETTINGS.SelectedListViewSize
    );
    if (!listViewSize) return;
    $scope.listViewSize = UtilityFunctions.getListViewSizeClass(
      listViewSize.StringValue
    );
  });

  // Run data loader functions based on the state we're in
  dataLoader(currentState, currentStateParams);

  /* INTRO START */

  $scope.tutorialSteps = [
    {
      element: ".js-Sidebar-content",
      title: "Filter",
      content: "TUTOR_SEARCH",
      position: "left",
    },
    {
      element: ".Tile-upperActions",
      title: "LABEL_RESULTS",
      content: "TUTOR_PANEL",
      position: "top",
    },
    {
      element: "#myFilterContainer",
      title: "LABEL_MYFILTER",
      content: "TUTOR_MYFILTER",
      position: "left",
    },
  ];

  /* INTRO END */

  $scope.$on("loadMyFilter", handleLoadMyFilter);

  $scope.formatDate = function formatDate(
    date: string,
    dateFormat: string
  ): string {
    const m = moment(date, "YYYY-MM-DD");
    return m.isValid() ? m.format(dateFormat) : "";
  };

  // control size on sidebar
  UtilityFunctions.checkSizes($(".js-Sidebar"), $(".js-ListPage-box"));

  $scope.OldServiceWeb = GlobalConfig.ENV.OldServiceWeb;

  // Search shipments
  // query: Search query string
  // fromOther: boolean stating whether we're coming from another state
  $scope.search = function search(query: string): void {
    if (query && query.length > 0 && query.length < 4) {
      $scope.errors.search.minLength = true;
      return;
    }
    if (query == null || query.length <= 0) {
      getShipments();
    } else {
      searchShipments(query);
    }
  };

  $scope.isEmployee = UserService.isEmployeeOrAgent();

  // TODO: Refactor this beauty!
  $scope.filters = function filters(item: any): boolean {
    let addResult = true;

    if ($scope.filter.statusSelection.value.length > 0) {
      addResult =
        $scope.filter.statusSelection.value.indexOf(item.TileStatus) !== -1;
    }

    if ($scope.filter.shipper.value) {
      if ($scope.filter.shipper.value !== item.ShipperCompanyName) {
        addResult = false;
      }
    }

    if ($scope.filter.consignee.value) {
      if ($scope.filter.consignee.value !== item.ConsigneeCompanyName) {
        addResult = false;
      }
    }

    if (!isEmptyArray($scope.filter.VoyageReference.value)) {
      if (
        !$scope.filter.VoyageReference.value.some(
          (r: any) =>
            [
              item.JobVoyageReference,
              item.LoadVoyageReference,
              item.DischVoyageReference,
            ].indexOf(r) >= 0
        )
      ) {
        addResult = false;
      }
    }

    if (!isEmptyArray($scope.filter.PointFromISOName.value)) {
      if (
        !itemExistInArray(
          $scope.filter.PointFromISOName.value,
          item.PointFromISOName
        )
      ) {
        addResult = false;
      }
    }

    if (!isEmptyArray($scope.filter.PointToISOName.value)) {
      if (
        !itemExistInArray(
          $scope.filter.PointToISOName.value,
          item.PointToISOName
        )
      ) {
        addResult = false;
      }
    }

    if (!isEmptyArray($scope.filter.PointDischISOName.value)) {
      if (
        !itemExistInArray(
          $scope.filter.PointDischISOName.value,
          item.PointDischISOName
        )
      ) {
        addResult = false;
      }
    }

    if (!isEmptyArray($scope.filter.PointLoadISOName.value)) {
      if (
        !itemExistInArray(
          $scope.filter.PointLoadISOName.value,
          item.PointLoadISOName
        )
      ) {
        addResult = false;
      }
    }

    if ($scope.filter.shippingType.value !== "all") {
      if (
        item.ShippingType === null ||
        item.ShippingType.substr(item.ShippingType.indexOf(" ") + 1, 1) !==
          $scope.filter.shippingType.value
      ) {
        addResult = false;
      }
    }

    if ($scope.filter.customsClearance.value !== "all") {
      if (
        $scope.filter.customsClearance.value === "no" &&
        item.CustomsClearanceDate !== null
      ) {
        addResult = false;
      } else if (
        $scope.filter.customsClearance.value === "yes" &&
        item.CustomsClearanceDate === null
      ) {
        addResult = false;
      }
    }

    if ($scope.filter.BLSurrenderDate.value !== "all") {
      if (
        $scope.filter.BLSurrenderDate.value === "no" &&
        item.BLSurrenderDate !== null
      ) {
        addResult = false;
      } else if (
        $scope.filter.BLSurrenderDate.value === "yes" &&
        item.BLSurrenderDate === null
      ) {
        addResult = false;
      }
    }

    if ($scope.filter.incoterms.value != null) {
      if (!($scope.filter.incoterms.value === "0" && item.BOLType === null)) {
        if ($scope.filter.incoterms.value !== item.BOLType) {
          addResult = false;
        }
      }
    }

    if (angular.equals($scope.filter, $scope.FilterBase)) {
      $(".js-ContentHeader-clearFilters").hide();
    } else {
      $(".js-ContentHeader-clearFilters").show();
    }

    if (item.checked && !addResult) {
      delete item.checked;
      $scope.itemsSelected -= 1;

      if ($scope.itemsSelected === 1) {
        $scope.hideMultiActionBar();
      }
    }

    return addResult;
  };

  /*
   * Items Per Page:
   * Everything regarding how many items to display per page
   */

  // Default value
  $scope.itemsPerPage = 10;

  // Get the settings for how many items per list page to display
  const settingsItemsPerListPage = UserService.getLocalWebSettingValue(
    USERWEBSETTINGS.ItemsPerListPage
  );
  if (settingsItemsPerListPage) {
    $scope.itemsPerPage = settingsItemsPerListPage;
  }

  // When items per page has changed, this event is triggered
  // which lets us save settings and fade list in and out
  $scope.$on("itemsPerPageChanged", handleItemsPerPageChanged);

  function handleItemsPerPageChanged(event: any, value: string) {
    // Save the selection on the server
    UserService.setWebSetting(USERWEBSETTINGS.ItemsPerListPage, value);

    // Display loading animation for list
    (<any>angular.element(".js-Tile-wrapper")).fadeTo(100, 0);
    (<any>angular.element(".loaderShipmentsList"))
      .fadeTo(300, 1)
      .delay(600)
      .fadeTo(300, 0, () => {
        (<any>angular.element(".js-Tile-wrapper")).fadeTo(100, 1);
      });
  }

  UtilityFunctions.largeSpinner("largeSpinner");

  $scope.statusClass = ShipmentService.statusClass;
  $scope.statusText = ShipmentService.statusText;

  /**
   * Toggle status in filters.
   * If the statuses exist in filters selected value,
   * we remove them, otherwise we add them.
   * @param  {Number/Array} status Single status or array of statuses
   */
  $scope.toggleStatusSelection = function toggleStatusSelection(
    status: any[]
  ): any {
    let statusArr = status;
    $timeout(() => {
      // Always make the status an array, because we can toggle
      // both a single status and an array of statuses
      if (!Array.isArray(status)) {
        statusArr = [status];
      }

      // Clone the status filter array so we don't modify the scope
      // variable and immediately run digest cycle
      const statusFilterArray: any = ArrayUtilities.copy(
        $scope.filter.statusSelection.value
      );

      // Go through all the statuses in the array
      // and toggle them in the status filter
      statusArr.forEach((item) => {
        const idx = statusFilterArray.indexOf(item);

        if (idx > -1) {
          // Status exists in the filter array, then we remove it
          statusFilterArray.splice(idx, 1);
        } else {
          // Status doesn't exist in the filter array, then we add it
          statusFilterArray.push(item);
        }
      });

      // Change filter array in scope and run digest cycle
      $scope.filter.statusSelection.value = statusFilterArray;
    });
  };

  /**
   * Check if second shipping type is FCL
   * @param  {string} shippingType Shipping type (e.g. FCL-FCL)
   * @return {boolean} Returns true if second shipping type is FCL
   *                           False if second shipping type is LCL
   */
  $scope.shouldShowContainerDropdown = function shouldShowContainerDropdown(
    shippingType: string,
    containers: number
  ) {
    if (/\F$/.test(shippingType) && containers > 0) {
      return true;
    }
  };

  $scope.isBuyersConsol = function isBuyersConsol(
    bcFlag: string,
    bcJobReference: string
  ) {
    if (bcJobReference || bcFlag === "Y") {
      return true;
    }
  };

  $scope.showImportBanner = function showImportBanner(
    pointLoad: string,
    pointDisch: string
  ) {
    if (/^(?!IS)/.test(pointLoad) && /^IS/.test(pointDisch)) {
      return true;
    }
  };

  $scope.showExportBanner = function showExportBanner(
    pointLoad: string,
    pointDisch: string
  ) {
    if (/^IS/.test(pointLoad) && /^(?!IS)/.test(pointDisch)) {
      return true;
    }
  };

  $scope.showDomesticBanner = function showDomesticBanner(
    pointLoad: string,
    pointDisch: string
  ) {
    if (/^IS/.test(pointLoad) && /^IS/.test(pointDisch)) {
      return true;
    }
  };

  // Return is the booking is import, export or domestic shipment
  $scope.getBookingType = function getBookingType(
    pointLoad: string,
    pointDisch: string
  ) {
    if ($scope.showImportBanner(pointLoad, pointDisch)) {
      return "import";
    }
    if ($scope.showExportBanner(pointLoad, pointDisch)) {
      return "export";
    }
    if ($scope.showDomesticBanner(pointLoad, pointDisch)) {
      return "domestic";
    }
  };

  $scope.togglePrintMenu = function togglePrintMenu(open: boolean): void {
    const hiddenTypes = {
      SINV: true,
      SCRN: true,
      SARN: true,
    };
    const bookings = getCheckedShipments().map((it) => it.JobReference);

    $scope.printMenuItems = [];
    if (open) {
      // Display spinner while loading print types
      $scope.printMenuItems.push({
        icon: "fa fa-spinner fa-spin",
        translate: "LABEL_FETCHING_ACTIONS",
        text: "Sæki aðgerðir",
      });

      // Load print types
      ShipmentService.getBookingDocumentTypes(
        bookings,
        $scope.selectedCompany.PartnerCode
      ).then((data: any) => {
        // Remove the spinner when print types have been retrieved
        $scope.printMenuItems.shift();

        if (data && data.length > 0) {
          angular.forEach(data, (docType) => {
            if (!hiddenTypes[docType.DocumentType]) {
              docType.text = "transl_missing";
              docType.href = "";
              docType.type = docType.DocumentCode;
              docType.translate = `DOCTYPE_${docType.DocumentCode}`;
              $scope.printMenuItems.push(docType);
            }
          });
        } else {
          $scope.printMenuItems.splice(-1, 1);
          $scope.printMenuItems.push({
            text: "Engin skjöl fundust",
            click: "",
            translate: "",
          });
        }
      });
    }
  };

  $scope.MultiPrint = function MultiPrint(doctype?: string): void {
    if (!doctype) {
      return;
    }

    const checkedBookings = getCheckedShipments()
      .map((it) => it.JobReference)
      .join(",");

    ShipmentService.openBookingDocuments(doctype, checkedBookings);
  };

  // load actions for bookings
  $scope.Actions = [];
  ShipmentService.getBookingActionsList().then((data: any) => {
    angular.forEach(data, (Action) => {
      if (Number(Action.ForFreight) === 1) {
        // If customer
        if (UserService.isEmployeeOrAgent() === false) {
          // add menuitem if it is for customer.
          if (Action.ForCustomer !== 0) {
            Action.text = "transl_missing";
            Action.href = "";
            Action.click = `sendMultiActionSelect("${Action.Site}")`;
            Action.translate = Action.Key;
            $scope.Actions.push(Action);
          }
        } else {
          Action.text = "transl_missing";
          Action.href = "";
          Action.click = `sendMultiActionSelect("${Action.Site}")`;
          Action.translate = Action.Key;
          $scope.Actions.push(Action);
        }
      }
    });
  });

  $scope.sendMultiActionSelect = function sendMultiActionSelect(
    actionItem?: any
  ): void {
    if (!actionItem) {
      return;
    }

    // Temporary fix to invoke dynamically added menu-items' actions
    // that don't have anything to do with the old service-web
    if (actionItem.localActionCallback) {
      actionItem.localActionCallback();
      return;
    }

    const bookings = getCheckedShipments().map((it) => it.JobReference);

    const action = actionItem.Site;
    if (action === "NULL" || !action) {
      console.warn("aðgerð ekki útfærð");
      return;
    }
    WindowUtilities.openPopup(
      `${GlobalConfig.ENV.OldServiceWeb}/${action}&BookNos=${bookings.join(
        ","
      )}`,
      action
    );
  };

  function filterMultiActions(): void {
    const actions = [];
    const checkedShipments = getCheckedShipments();
    let shippingType;

    const hiddenTypes = {
      REQUESTTYPE_CHNGCUSTCLR: true,
      REQUESTTYPE_CLAIMREPORT: true,
    };

    // For every Menu Item
    for (let i = 0; i < $scope.Actions.length; i += 1) {
      // no constraints for booking
      let noConstraint = true;
      if (!hiddenTypes[$scope.Actions[i].Key]) {
        // for each booking
        for (let j = 0; j < checkedShipments.length; j += 1) {
          // negation of is he in this range he should be inn.
          if (
            !(
              checkedShipments[j].Status >= $scope.Actions[i].RangeFrom &&
              checkedShipments[j].Status <= $scope.Actions[i].RangeTo
            )
          ) {
            // this menu item is invalid.
            noConstraint = false;
            break;
          }

          // status is FCL and action is FCL or status is LCL and action
          // is for LCL Insert Action */
          shippingType = ShipmentService.getShippingTypePair(
            checkedShipments[j].ShippingType
          );
          if (
            !(
              (shippingType.second === "F" && $scope.Actions[i].ForFCL) ||
              (shippingType.second === "L" && $scope.Actions[i].ForLCL)
            )
          ) {
            noConstraint = false;
            break;
          }
        }

        // there are no constraints insert actions to menu.
        if (noConstraint) {
          actions.push($scope.Actions[i]);
        }
      }
    }

    if (UserService.isEmployeeOrAgent()) {
      // Temporary fix to invoke dynamically added menu-items' actions
      // that don't have anything to do with the old service-web
      if (UserService.isAuthorized(USER_ROLES.BUISN)) {
        actions.push({
          translate: "REQUESTTYPE_REGISTER",
          localActionCallback: showBulkUpdateDateModal,
        });
      }
      actions.push({
        translate: "LABEL_ADD_REMARK_PLURAL",
        localActionCallback: showBulkUpdateRemarksModal,
      });
    }

    // if true will show Delivery button.
    $scope.deliveryInMultiSelect = UtilityFunctions.findElement(
      actions,
      "TypeMap",
      "DELIVREQ"
    );
    $scope.multiActionsMenuItems = actions;
  }

  function showBulkUpdateDateModal(): void {
    const resolve = {
      shipments: function shipments() {
        return new Promise((resolve) => {
          resolve(getCheckedShipments());
        });
      },
    };

    ModalUtilities.showLargeModal("bulkUpdateDateModal", resolve).then(() => {
      SamskipNotify.displaySuccess("LABEL_SUCCESS", undefined, "body");
    });
  }

  function showBulkUpdateRemarksModal(): void {
    const resolve = {
      shipments: function shipments() {
        return new Promise((resolve) => {
          resolve(getCheckedShipments());
        });
      },
    };

    ModalUtilities.showLargeModal("bulkUpdateRemarksModal", resolve).then(
      (results: any) => {
        if (results.isSuccess) {
          SamskipNotify.displaySuccess(results.text, undefined, "body");
        }
      }
    );
  }

  $scope.deliveryInMultiSelect = false;

  UtilityFunctions.catToggle();
  UtilityFunctions.filterSlideControl(
    "js-Sidebar-triggerFilter",
    "js-Sidebar",
    "LABEL_OPEN_FILTER",
    "LABEL_CLOSE_FILTER"
  );

  // Calendar stuff ends
  $scope.samExcel = {};

  $scope.samExcel.excelButtonTitle = function excelButtonTitle() {
    return "Excel";
  };

  /**
   * Downloads shipment list in Excel
   * API responds with a Excel file
   * @return {promise} Promise with an Excel file
   */
  $scope.samExcel.downloadExcel =
    function downloadExcel(): SamskipPromise<Blob> {
      const bookings: string[] = $scope.filteredShipments.map(
        (it: any) => it.JobReference
      );
      const partnerCode: string = $scope.selectedCompany.PartnerCode;

      return ShipmentService.bookingsInExcel(partnerCode, bookings);
    };

  /**
   * Generate the filename of the Excel file
   * @return {string} Filename of the Excel file
   */
  $scope.samExcel.excelFileName = function excelFileName(): string {
    return `ShipmentList.${moment().format("YYYY-MM-DD")}`;
  };

  /**
   * Add stars to shipment objects, if they exist in _favoriteBookings array
   * adds a '_starred' boolean property
   */
  function addStars(shipment: any): void {
    shipment._starred = ArrayUtilities.includes(
      allFavoriteBookings,
      shipment.JobReference
    );
  }

  /**
   * Convert shipping type and BOL type to long format
   * which Doris uses, e.g. F F -> FCL-FCL and W -> SWB
   * @param shipmentArray  Array of shipments
   */
  function convertShippingAndBOLType(shipment: any): void {
    shipment._bolType = ShipmentService.convertBOLTypeToLongFormat(
      shipment.BOLType
    );
    shipment._shippingType = ShipmentService.convertShippingTypeToLongFormat(
      shipment.ShippingType
    );
  }

  /**
   * Monitor changes to CustomerGroups.value
   * Can be one if 'Chosen Customer', 'My Customers', 'All Customers'
   */
  $scope.CustGroupSelect = function CustGroupSelect(
    newvalue: string,
    oldvalue: string
  ): void {
    /* globals moment */

    $scope.filter.CustomerGroup.OldValue = oldvalue;

    const dateFrom = moment($scope.dateFrom, "YYYY-MM-DD");
    const dateTo = moment($scope.dateTo, "YYYY-MM-DD");
    const currentRange = Math.abs(dateFrom.diff(dateTo));

    // Max range should evaluate to 4 weeks if GustomerGroup.value === 'M' || 'A'
    const maxRange = moment
      .duration($scope.datePicker.maxRange())
      .asMilliseconds();

    // Display warnings if the selected date range is too large.
    // Otherwise get the requested data.
    if (maxRange > 0 && currentRange > maxRange) {
      $scope.dateTo = dateFrom.add(4, "weeks").format("YYYY-MM-DD");
      SamskipNotify.displayWarning(
        TranslationService.translate("WARNING_DATERANGE_PARTNER_LIMIT_INFO", {
          rangeValue: 4,
          dateValue: `${moment($scope.dateFrom, "YYYY-MM-DD").format(
            "YYYY/MM/DD"
          )}-${moment($scope.dateTo, "YYYY-MM-DD").format("YYYY/MM/DD")}`,
        }),
        TranslationService.translate("WARNING_DATERANGE_PARTNER_LIMIT_TITLE"),
        undefined,
        {
          // react-toastify
          autoClose: 20 * 1000,
        }
      );
    }

    if ($scope.query && $scope.query.length > 0) {
      searchShipments($scope.query);
    } else {
      getShipments();
    }
  };

  $scope.removeMainFilter = function removeMainFilter(): void {
    $state.go("shipments");
    $scope.query = "";
  };

  $scope.removeFilter = function removeFilter(filter: any): void {
    const initialFilter: any = initFilter();
    const predicate = Object.keys(initialFilter).find(
      (i) => initialFilter[i].name === filter.name
    );
    if (!initialFilter || !predicate) return;

    $scope.filter[predicate] = initialFilter[predicate];
  };

  $scope.resetAllFilters = function resetAllFilters(): void {
    const initialFilter: any = initFilter();
    if (!initialFilter) return;

    $scope.filter = initialFilter;

    // Reset error messages
    for (const item in $scope.errors.search) {
      $scope.errors.search[item] = false;
    }
  };

  function getCheckedShipments(): any[] {
    const jobRefs: any[] = [];
    angular.forEach($scope.shipments, (item) => {
      if (item.checked) {
        jobRefs.push(item);
      }
    });
    return jobRefs;
  }

  /**
   *********** Ordering functions *****************
   */

  $scope.selectedPredicate = "JobVoyageReference";
  $scope.selectedPredicateTranslation = "LABEL_VOYAGEREFERENCE";
  const orderBy = $filter("orderBy");
  $scope.reverse = true;

  // For sorting Shipments
  $scope.order = function order(): void {
    $scope.shipments = orderBy(
      $scope.shipments,
      $scope.selectedPredicate,
      $scope.reverse
    );
    setBolTypeFilterOptions($scope.shipments);
  };

  $scope.setPredicate = function setPredicate(
    pred: string,
    translation: string
  ): void {
    $scope.selectedPredicate = pred;
    $scope.selectedPredicateTranslation = translation;
  };

  // Function that displays voyage reference properly
  $scope.displayVoyageReference = function displayVoyageReference(
    booking: any
  ): string {
    let str;
    if (booking.LoadVoyageReference === booking.DischVoyageReference) {
      str = booking.LoadVoyageReference;
    } else {
      str = `${booking.LoadVoyageReference} - ${booking.DischVoyageReference}`;
    }

    return str;
  };

  $scope.showMultiActionBar = function showMultiActionBar(): void {
    // Show the selectedBar if not already visible
    if ($scope.itemsSelected > 1 && !$scope.multiActionBarVisible) {
      $scope.multiActionBarVisible = true;

      $(".selectedBar")
        .css({
          opacity: 0,
          display: "block",
        })
        .fadeTo(600, 1);
    }

    // Always filter the actions available for the selected bookings
    filterMultiActions();
  };
  $scope.hideMultiActionBar = function hideMultiActionBar(): void {
    if ($scope.itemsSelected <= 1 && $scope.multiActionBarVisible) {
      // Disable the $scope flag to indicate that the selectedBar is hidden
      $scope.multiActionBarVisible = false;

      $(".selectedBar").fadeTo(600, 0, () => {
        $(".selectedBar").css("display", "none");
      });
    }
  };

  // Called whenever a single item is checked/unchecked
  $scope.itemChecked = function itemChecked(checked: boolean): void {
    if (checked) {
      $scope.itemsSelected += 1;
    } else {
      $scope.itemsSelected -= 1;
    }

    if ($scope.itemsSelected > 1) {
      $scope.showMultiActionBar();
    } else {
      $scope.hideMultiActionBar();
    }
  };

  /**
   * Called when the user toggles selection of all/none
   * @param  {Boolean} isChecked  Current state of multiselection
   */
  $scope.multiselectToggled = function multiselectToggled(
    isChecked: boolean
  ): void {
    if (isChecked) {
      selectAllShipments();
    } else {
      selectNone();
    }
  };

  /**
   * Check all shipments in the list and display the multi-selection bar
   */
  function selectAllShipments(): void {
    let checked = 0;
    $scope.filteredShipments.forEach((it: any) => {
      it.checked = true;
      checked += 1;
    });
    $scope.itemsSelected = checked;
    $scope.showMultiActionBar();
  }

  /**
   * Uncheck all shipments in the list and hide the multi-selection bar
   */
  function selectNone(): void {
    $scope.filteredShipments.forEach((it: any) => {
      it.checked = false;
    });
    $scope.itemsSelected = 0;
    $scope.hideMultiActionBar();
  }

  /**
   * Datepicker functionality
   */
  $scope.datePicker = {
    /**
     * Function to to call when a new data range is selected via the
     * samDatepicker directive
     * @param  {moment} startDate Start date of the updated date range
     * @param  {moment} endDate   End date of the updated date range
     */
    datesUpdated: function datesUpdated(
      startDate: moment.Moment,
      endDate: moment.Moment
    ): void {
      const formattedStartDate = startDate.format("YYYY-MM-DD");
      const formattedEndDate = endDate.format("YYYY-MM-DD");

      $scope.dateFrom = formattedStartDate;
      $scope.dateTo = formattedEndDate;
      saveDateRange(formattedStartDate, formattedEndDate);

      // Refetch shipments
      if ($scope.query && $scope.query.length > 0) {
        searchShipments($scope.query);
      } else {
        getShipments();
      }
    },
    /**
     * A function that evaluates if the datepicker should be shown or not
     * and displays appropriate warnings if it should not be shown.
     * @return {boolean} Returns false if the datepicker should be shown,
     *                           true if the datepicker should be halted (not shown)
     */
    haltPicker: function haltPicker(): boolean {
      if ($scope.query && $scope.query.length > 0) {
        SamskipNotify.displayWarning("ERROR_SEARCH_FILTER_SELECTED");
        return true;
      }
      return false;
    },
    /**
     * A function to dynamically evaluate the max daterange that the user
     * can select from the datepicker
     */
    maxRange: function datePickerMaxRange(): any {
      if (
        $scope.filter.CustomerGroup.value === "A" &&
        UserService.isEmployeeOrAgent()
      ) {
        // Maximum allowed is 4 weeks when CustomerGroup.value is 'M' or 'A'
        return {
          weeks: 4,
        };
      }
      return null;
    },
  };

  function setBolTypeFilterOptions(shipmentsArray: any[]): void {
    const bolSet: any = new Set(shipmentsArray.map((i) => i.BOLType));
    $scope.bolTypes = [...bolSet].map((bol: string) => {
      return {
        bolType: bol,
        label: ShipmentService.convertBOLTypeToLongFormat(bol),
      };
    });
  }

  // Save chosen date range in web settings
  function saveDateRange(DateFrom: string, DateTo: string): void {
    // Make sure the settings object exists and doesn't return an empty string
    const shipmentSettingsValue = UserService.getLocalWebSettingValue(
      USERWEBSETTINGS.ShipmentsListPage
    );
    const shipmentSettings = shipmentSettingsValue
      ? JSON.parse(shipmentSettingsValue)
      : {};

    shipmentSettings.DateFrom = DateFrom;
    shipmentSettings.DateTo = DateTo;

    UserService.setWebSetting(
      USERWEBSETTINGS.ShipmentsListPage,
      JSON.stringify(shipmentSettings)
    );
  }

  // Filters Selected
  function initFilter(customerGroup?: string): any {
    const filter: any = {};

    filter.shippingType = {
      value: "all",
      name: "SHIPPING_TYPE",
      translate: "LABEL_SHIPMENTTYPE",
    };
    filter.BLSurrenderDate = {
      value: "all",
      name: "SURRENDER_DATE",
      translate: "LABEL_SURRENDERDATE",
    };
    filter.customsClearance = {
      value: "all",
      name: "CUSTOMS",
      translate: "LABEL_CUSTOMSCLEARED",
    };
    filter.CustomerGroup = {
      value: customerGroup || "S",
      name: "CUSTOMER_GROUP",
      translate: "LABEL_CUSTOMERGROUP",
      OldValue: "",
    };
    filter.incoterms = {
      value: null,
      name: "INCOTERMS",
      translate: "LABEL_INCOTERMS",
    };
    filter.PointLoadISOName = {
      value: undefined,
      name: "POINT_LOAD",
      translate: "FILTER_LOAD",
    };
    filter.PointDischISOName = {
      value: undefined,
      name: "POINT_DISCH",
      translate: "FILTER_DISCHARGE",
    };
    filter.PointToISOName = {
      value: undefined,
      name: "POINT_TO",
      translate: "LABEL_POINTTO",
    };
    filter.PointFromISOName = {
      value: undefined,
      name: "POINT_FROM",
      translate: "LABEL_POINTFROM",
    };
    filter.VoyageReference = {
      value: undefined,
      name: "VOYAGE_REFERENCE",
      translate: "LABEL_VOYAGEREFERENCE",
    };
    filter.consignee = {
      value: undefined,
      name: "CONSIGNEE",
      translate: "LABEL_CONSIGNEE",
    };
    filter.shipper = {
      value: undefined,
      name: "SHIPPER",
      translate: "LABEL_SHIPPER",
    };
    filter.statusSelection = {
      value: [],
      name: "STATUS",
      translate: "LABEL_STATUS",
    };
    filter.searchCategorySelection = {
      value: "JobReference",
      name: "SEARCH_CATEGORIES",
      translate: "FILTER_SEARCH_CATEGORIES",
    };

    return filter;
  }

  function initReadyFilter(): any {
    const filter = initFilter();
    filter.statusSelection.value = [5];

    return filter;
  }

  /**
   * Get a list of shipments from server
   * @param  {object} filter Optional filter object
   */
  function getShipments(): void {
    if ($scope.inListAPICall) return;
    if (!currentUser) return;

    const selectedCompany = UserService.getSelectedCompany();

    $(".js-DisplayList").fadeTo(200, 0);
    $(".loaderShipmentsList").fadeTo(400, 1);
    $scope.shipments = [];
    $scope.voyages = [];

    $scope.inListAPICall = true;
    GeneralLoader.increase();
    ShipmentService.getShipmentsList(
      $scope.filter.CustomerGroup.value,
      $scope.dateFrom,
      $scope.dateTo,
      currentUser.Access.LoginID,
      selectedCompany.PartnerCode
    )
      .then(handleData)
      .catch(() => {
        $(".loaderShipmentsList").hide(200);
      })
      .finally(() => {
        $scope.inListAPICall = false;
      });
  }

  /**
   * Get user's favorite shipments from server
   * which he has starred
   */
  function getFavoriteShipments(): SamskipPromise<any> {
    $scope.inListAPICall = true;

    return ShipmentService.getUserFavoriteBookingsFull(
      $scope.currentUser.User.ID
    )
      .then(handleData)
      .then(() => {
        $scope.inListAPICall = false;
      });
  }

  function searchShipments(query?: string): void {
    if (!currentUser) return;
    if ($scope.inSearchAPICall) return;
    if (query === undefined || query.length < 1) {
      $state.go(MAIN_STATE);
      return;
    }

    $(".js-DisplayList").fadeTo(200, 0);
    $(".loaderShipmentsList").fadeTo(400, 1);
    $scope.shipments = [];
    $scope.voyages = [];

    $scope.inSearchAPICall = true;
    GeneralLoader.increase();
    ShipmentService.companySearch(
      $scope.selectedCompany.PartnerCode,
      query.toUpperCase(),
      $scope.filter.CustomerGroup.value,
      $scope.filter.searchCategorySelection.value,
      $scope.dateFrom,
      $scope.dateTo
    )
      .then(handleData)
      .catch(() => {
        $(".loaderShipmentsList").hide(200);
      })
      .finally(() => {
        $scope.inSearchAPICall = false;
        GeneralLoader.decrease();
      });
  }

  function handleData(data: any[]): void {
    GeneralLoader.decrease();
    // some animation
    $(".loaderShipmentsList")
      .clearQueue()
      .fadeTo(400, 0, () => {
        $(".js-DisplayList").fadeTo(500, 1, () => {
          $(".js-ListPage-box").trigger("heightChange");
        });
      });

    const jobReferences = data.map((item) => item.JobReference);

    // Determine which bookings have documents
    Promise.all([
      ShipmentService.getBookingDocumentsBatch(jobReferences),
      ShipmentDocumentService.getDocumentsBatch(jobReferences),
    ]).then((results) => {
      const documentReferences: any = {};
      const dorisDocs: ShippingDocument[] = results[0];
      const sdcDocs: SDCReferenceDocument[] = results[1];

      for (const doc of dorisDocs) {
        documentReferences[doc.JobReference] = true;
      }

      for (const doc of sdcDocs) {
        documentReferences[doc.Reference] = true;
      }

      $scope.shipments.forEach((shipment: any) => {
        shipment._hasDocuments =
          documentReferences[shipment.JobReference] === true;
      });

      $scope.$digest();
    });

    // Fetch allowed actions for bookings
    ShipmentService.getMultipleBookingAllowedActions(jobReferences).then(
      (data) => {
        const customsMap = {};

        for (const item of data) {
          customsMap[item.JobReference] = item.Customs;
        }

        $scope.shipments.forEach((shipment: any) => {
          shipment._canSendCustomsRequest = customsMap[shipment.JobReference];
        });
      }
    );

    const paymentVMs = [] as BookingPaymentStatusVM[];

    data.forEach((shipment) => {
      // Apply stars for shipment data
      addStars(shipment);

      // Convert shipping and BOL type
      convertShippingAndBOLType(shipment);

      const consignmentNumbers = [];
      if (shipment.LoadVoyageConsignmentNumber) {
        consignmentNumbers.push(shipment.LoadVoyageConsignmentNumber);
      }
      if (
        shipment.DischVoyageConsignmentNumber &&
        shipment.DischVoyageConsignmentNumber !==
          shipment.LoadVoyageConsignmentNumber
      ) {
        consignmentNumbers.push(shipment.DischVoyageConsignmentNumber);
      }
      shipment._consignmentNumbers = consignmentNumbers;

      const isImport = ShipmentService.isLocalPort(shipment.PointDisch);
      if (
        isImport
          ? shipment.InvoiceCountConsignee > 0
          : shipment.InvoiceCountShipper > 0
      ) {
        paymentVMs.push({
          JobReference: shipment.JobReference,
          PartnerCode: isImport ? shipment.ConsigneeCode : shipment.ShipperCode,
        } as BookingPaymentStatusVM);
      } else {
        shipment._isPayed = true;
        shipment._isCustomerCashPayer = false;
      }

      shipment._voyages = ShipmentService.createVoyageList(shipment);
    });

    if (paymentVMs.length > 0) {
      FinanceService.getBookingPaymentStatuses(paymentVMs).then((results) => {
        const statuses = {};
        for (const status of results) {
          statuses[status.JobReference] = status;
        }

        $scope.shipments.forEach((shipment: any) => {
          const status = statuses[shipment.JobReference];
          if (status) {
            shipment._isPayed = status.isPayed;
            shipment._isCustomerCashPayer = status.isCustomerCashPayer;
          }
        });
      });
    }

    $scope.shipments = data;

    setBolTypeFilterOptions($scope.shipments);

    // Insert voyages to a seperate array
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].JobVoyageReference !== null) {
        $scope.voyages.push(data[i].JobVoyageReference);
      }

      if (data[i].LoadVoyageReference !== null) {
        $scope.voyages.push(data[i].LoadVoyageReference);
      }

      if (data[i].DischVoyageReference !== null) {
        $scope.voyages.push(data[i].DischVoyageReference);
      }
    }

    $scope.order();

    // pagination
    $scope.totalBookings = $scope.shipments.length;
    $scope.currentPage = 1;

    // Reset items selected
    $scope.itemsSelected = 0;
    $scope.hideMultiActionBar();
  }

  function handleLoadMyFilter(event: any, filter: any): void {
    // clear the query field
    $scope.query = "";

    // set the customerGroup
    $scope.filter.CustomerGroup.value = filter.filter.CustomerGroup.value;

    $scope.dateFrom = filter.dateFrom;
    $scope.dateTo = filter.dateTo;

    // After getting new list of shipments apply filter
    getShipments();
    loadFilter(filter.filter);
  }

  function loadFilter(filter?: string | null): void {
    let newFilter;
    if (filter) {
      newFilter = filter;
    } else {
      const fn =
        currentState === "shipments_ready" ? initReadyFilter : initFilter;
      newFilter = fn($scope.filter.CustomerGroup.value);
    }

    $scope.filter = newFilter;
  }

  function loadDateSettings(): void {
    if (currentStateParams.dateFrom && currentStateParams.dateTo) {
      $scope.dateFrom = currentStateParams.dateFrom;
      $scope.dateTo = currentStateParams.dateTo;
    } else {
      try {
        const listPageSetting = UserService.getLocalWebSettingValue(
          USERWEBSETTINGS.ShipmentsListPage
        );

        if (!listPageSetting) throw new Error("Failed loading settings");

        const shipmentSettings: ListPageDateSettings =
          JSON.parse(listPageSetting);

        if (!shipmentSettings.DateFrom || !shipmentSettings.DateTo) {
          throw new Error("Failed loading settings");
        }

        const mDateFrom = moment(shipmentSettings.DateFrom);
        const mDateTo = moment(shipmentSettings.DateTo);

        if (mDateTo.diff(mDateFrom, "months", true) > 3) {
          $scope.dateFrom = mDateTo.subtract(3, "months").format("YYYY-MM-DD");
        } else {
          $scope.dateFrom = shipmentSettings.DateFrom;
        }

        $scope.dateTo = shipmentSettings.DateTo;
      } catch (e) {
        $scope.dateFrom = moment().subtract(2, "weeks").format("YYYY-MM-DD");
        $scope.dateTo = moment().format("YYYY-MM-DD");
      }
    }
  }

  /**
   * Callback for the samFavorite component when an item in the list is
   * toggled as a favorite shipment.
   * @param  {string}  jobRef    Job reference of the shipment that is
   *      is being toggled as favorite.
   * @param  {Boolean} isChecked Current state of the shipment.
   */
  $scope.favoriteToggled = function favoriteToggled(
    jobRef: string,
    isChecked: boolean
  ): void {
    $scope.shipments.forEach((it: any) => {
      if (it.JobReference === jobRef) {
        it._starred = isChecked === true;
      }
    });

    // Check if the job reference is in the favorite bookings array
    // If it's present, remove it from the array, else add it.
    if (isChecked) {
      allFavoriteBookings.push(jobRef);
      allFavoriteBookings = ArrayUtilities.unique(allFavoriteBookings);
    } else {
      allFavoriteBookings = ArrayUtilities.without(allFavoriteBookings, jobRef);
    }
  };

  $scope.showModalBulkSearchConfirmation =
    function showModalBulkSearchConfirmation(): void {
      ModalUtilities.showLargeModal("bulkSearchModal").then(handleData);
    };

  $scope.getShipmentMenuItems = function getShipmentMenuItems(
    shipment: any
  ): any {
    return ShipmentDropdownService.getShipmentMenuItems(
      shipment,
      openCopyModal
    );
  };

  function openCopyModal(jobReference: string) {
    $scope.jobReferenceToCopy = jobReference;
    $scope.showCopyModal = true;
  }

  $scope.closeCopyModal = function closeCopyModal() {
    $scope.jobReferenceToCopy = null;
    $scope.showCopyModal = false;
    $scope.$digest();
  };

  /**
   * Data loader
   */
  function dataLoader(stateName: string, stateParams: object) {
    const stateFunctions = [
      {
        states: [
          "shipments",
          "shipments_week",
          "shipments_daterange",
          "shipments_ready",
          "shipments_favorites",
          "shipments_search",
        ],
        loader: () => {
          loadFilter();
        },
      },
      {
        states: [
          "shipments",
          "shipments_week",
          "shipments_daterange",
          "shipments_ready",
        ],
        loader: () => {
          getShipments();
        },
      },
      {
        states: [
          "shipments",
          "shipments_week",
          "shipments_daterange",
          "shipments_ready",
        ],
        loader: () => {
          saveDateRange($scope.dateFrom, $scope.dateTo);
        },
      },
      {
        states: ["shipments_favorites"],
        loader: () => {
          getFavoriteShipments();
        },
      },
      {
        states: ["shipments_search"],
        loader: () => {
          $scope.query = currentStateParams.query;
          searchShipments(currentStateParams.query);
        },
      },
    ];

    runDataLoader();

    function runDataLoader() {
      stateFunctions
        .filter((item) => {
          if (item && item.states.indexOf(stateName) !== -1) return item;
        })
        .map((item) => item.loader)
        .forEach((fn) => {
          fn();
        });
    }
  }

  function isEmptyArray(array: any[]) {
    return ArrayUtilities.isEmptyArray(array);
  }

  function itemExistInArray(array: any[], item: any): boolean {
    return ArrayUtilities.existInArray(array, item);
  }
}

shipmentsController.$inject = ["$scope", "$transition$", "favoriteBookings"];
export default shipmentsController;
