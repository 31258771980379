import { SamskipNotify, ShipmentService, TranslationService } from "Services";
import { UtilityFunctions } from "Utilities";

function shipmentsSingleBookingDocumentsController() {
  const vm = this;

  /** Initial values for the controller */
  vm.orderByField = "UploadDate";
  vm.reverse = false;

  vm.openDocument = function openDocument(document: WebDocument) {
    ShipmentService.openBookingDocument(document.UID, document.DocSource);
  };

  vm.removeDocument = function removeDocument(document: WebDocument) {
    return UtilityFunctions.confirmModal(
      TranslationService.translate("TEXT_CONFIRM_DELETE", {
        name: document.FileName ? document.FileName : document.Description
      }),
      "TEXT_IRREVERSIBLE_ACTION",
      "LABEL_CANCEL",
      "LABEL_PROCEED"
    )
      .then(() => {
        SamskipNotify.displaySuccess("TEXT_SUCCESS");
        // Return new promise so that the uid of the deleted document gets returned
        return new Promise((resolve, reject) => {
          ShipmentService.removeBookingDocument(document.UID)
            .then(() => {
              resolve(document.UID);
            })
            .catch(() => {
              reject();
            });
        });
      })
      .catch(() => {
        SamskipNotify.displayError("LABEL_FAILURE");
      });
  };
}

export default shipmentsSingleBookingDocumentsController;
