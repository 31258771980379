exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index-modules__modal___1qAkF div.button-group {\n  display: flex;\n  justify-content: space-between;\n}\n", ""]);

// exports
exports.locals = {
	"modal": "index-modules__modal___1qAkF"
};