import * as moment from "moment";
import { autorun } from "mobx";
import { $timeout } from "ngimport";
import { UIRouter, StateService, Transition } from "@uirouter/core";
import { USERWEBSETTINGS } from "Constants/UserWebSettingsConstants";
import { $uiRouter } from "Bootstrap/angular";
import { USER_ROLES } from "Constants/UserRoles";
import DashboardPager from "Shipments/createbooking/dashboard/DashboardPager";
import FeatureToggleRouter from "Components/samFeatureToggle/FeatureToggleRouter";
import { UserService, SamskipNotify, ShipmentService } from "Services";
import { ArrayUtilities, DateUtilities, UtilityFunctions } from "Utilities";

function shipmentsCreateBookingDashboardController(
  $scope: any,
  incoTerms: any
) {
  const vm = this;
  const $state: StateService = $uiRouter.stateService;
  const theIncoTerms: any = ArrayUtilities.keyBy(incoTerms, "TermsCode") || [];

  const currentUser = UserService.getUserProfile();
  /**
   * Initialization of the dashboard viewmodel
   */
  function initVM(): void {
    if (FeatureToggleRouter.isEnabled("shipmentsCreatebookingQuotes")) {
      fetchQuotes();
    }
    fetchUnconfirmedBookings();
    fetchFavorites();
    fetchDrafts();
    setUserRoles();

    vm.showCopyModal = false;
    vm.jobReferenceToCopy = "";
  }

  autorun(() => {
    const listViewSize = UserService.getWebSettingObservable(
      USERWEBSETTINGS.SelectedListViewSize
    );
    if (!listViewSize) return;
    $scope.listViewSize = UtilityFunctions.getListViewSizeClass(
      listViewSize.StringValue
    );
  });

  /**
   * Fetch unconfirmed bookings and filter response data.
   */
  function fetchUnconfirmedBookings(): void {
    vm.fetchingUnconfirmed = true;
    const currentUser = UserService.getUserProfile();
    const selectedCompany = UserService.getSelectedCompany();
    if (!currentUser) return;
    const partner: string = selectedCompany.PartnerCode;
    ShipmentService.getAllUnconfirmedBookings(partner)
      .then((data: any) => {
        vm.unconfirmed = new DashboardPager(initUnconfirmed(data || []));
      })
      .then(() => {
        vm.fetchingUnconfirmed = false;
      });
  }

  /**
   * Fetch favorite bookings and filter response data.
   */
  function fetchFavorites(): void {
    vm.fetchingFavorites = true;
    const currentUser = UserService.getUserProfile();
    if (!currentUser) return;
    const userID: number = currentUser.User.ID;
    ShipmentService.getUserFavoriteBookingsFull(userID)
      .then((data: any) => {
        // The average number of favorite-bookings pre user when this is
        // written is around 4.7 so we'll use 5 as the pagesize for favorites.
        vm.favorites = new DashboardPager(initFavorites(data) || [], 5);
      })
      .then(() => {
        vm.fetchingFavorites = false;
      });
  }

  /**
   * Fetch draft bookings and filter response data.
   */
  function fetchDrafts(): void {
    vm.fetchingDrafts = true;
    const currentUser = UserService.getUserProfile();
    const selectedCompany = UserService.getSelectedCompany();
    if (!currentUser) return;
    const partner: string = selectedCompany.PartnerCode;
    ShipmentService.getDraftBookingsByPartnerCode(partner)
      .then((data: any) => {
        filterDraftsAndDisplay(data);
      })
      .then(() => {
        vm.fetchingDrafts = false;
      });
  }

  /**
   * Filter all usable drafts and display afterwards
   * @param {Array|Objects} drafts Array of draft objects
   */
  function filterDraftsAndDisplay(drafts: any[]): void {
    const draftIds = drafts.map((draft) => draft.DraftId);

    deleteAllUnusableDrafts(draftIds).then(
      (data: any) => {
        const deletedDraftResult = data;

        // Filter drafts that were deleted by API
        const deletedDrafts = deletedDraftResult.filter((o: any) => {
          return o.DbResult.Deleted > 0;
        });

        // Map DraftIds to an array
        const arrayOfDeletedDraftIds = deletedDrafts.map(
          (it: any) => it.AffectedId
        );

        // Filter deleted drafts from the draft list to display
        const draftsToDisplay = drafts.filter((o) => {
          return !ArrayUtilities.includes(arrayOfDeletedDraftIds, o.DraftId);
        });

        // The average number of drafts per partner is bound to change when
        // the drafts-feature goes live, so let's start with a pagesize of 10.
        vm.drafts = new DashboardPager(initDrafts(draftsToDisplay) || [], 10);
      },
      () => {
        vm.drafts = new DashboardPager(initDrafts(drafts) || [], 10);
      }
    );
  }

  /**
   * Delete all unusable drafts related to the draft ids
   * included in the parameter.
   * @param {array|string} drafts Array of draft ids
   */
  function deleteAllUnusableDrafts(drafts: any[]) {
    return ShipmentService.deleteUnusableDrafts(drafts).then((data: any) => {
      return data;
    });
  }

  /**
   * Fetch quotes bookings and filter response data.
   */
  function fetchQuotes() {
    vm.fetchingQuotes = true;
    const currentUser = UserService.getUserProfile();
    const selectedCompany = UserService.getSelectedCompany();
    if (!currentUser) return;
    const partner: string = selectedCompany.PartnerCode;
    ShipmentService.getActiveQuotes(partner)
      .then((data: any) => {
        // When this is written the average number of quotes per partner-
        // code (for partners that have more than one active quote) is
        // around 3.6 quotes so we'll use 5 as the pagesize for quotes.
        vm.quotes = new DashboardPager(initQuotes(data) || [], 5);
      })
      .then(() => {
        vm.fetchingQuotes = false;
      });
  }

  /**
   * Open a popup with showing a pdf printout from Doris for the given quote.
   * @param  {Object} quote   A quote object containing info about the quote.
   */
  vm.printQuote = function printQuote(quote: any) {
    ShipmentService.openBookingDocument(quote.DocumentId);
  };

  /**
   * Deletes a booking-draft.
   * @param  {Object} draft   The draft to delete
   */
  vm.deleteDraft = function deleteDraft(draft: any) {
    ShipmentService.deleteDraft(draft.DraftId)
      .then(() => {
        vm.drafts.remove(draft);
      })
      .catch((data: any) => {
        SamskipNotify.displayError(data);
      });
  };

  /**
   * Toggles a booking as the user's favorite and (when unstarring) removes
   * it from the template.
   * @param  {Object}     favorite    A booking-object.
   * @param  {Boolean}    starred     A flag that tells us whether the
   *                                  booking was starred/unstarred.
   */
  vm.onFavoriteToggle = function onFavoriteToggle(
    favorite: any,
    starred: boolean
  ) {
    const abortChanceTime = 1500;
    if (!starred) {
      // Set a timer before removing the booking from the list to give
      // the user a chance to cancel his starring/unstarring.
      favorite._timer = $timeout(() => {
        vm.favorites.remove(favorite);
      }, abortChanceTime);
    } else if (favorite._timer) {
      $timeout.cancel(favorite._timer);
    }
  };

  /**
   * Format dates
   * @param  {String} dateString  An unchanged datestring.
   * @return {String}             Returns the formatted datestring.
   */
  vm.formatDate = function formatDate(dateString: string) {
    const m = moment(dateString);
    return m.isValid() ? m.format("DD.MM.YYYY") : "";
  };

  /**
   * Create the proper string to display a booking's voyage-reference.
   * @param  {Object} booking A "booking" object (booking/draft/favorite)
   * @return {String}         Returns a proper representation of the
   *                          booking's voyagereference.
   */
  vm.displayVoyageReference = function displayVoyageReference(booking: any) {
    return ShipmentService.displayVoyageReference(booking);
  };

  /**
   * Gets a translation-key (a string) according to the booking's
   * TileStatus (an integer).
   * @param  {Number} status  The booking's TileStatus.
   * @return {String}         Returns the appropriate translation-key.
   */
  vm.statusText = function statusText(status: number): string {
    return ShipmentService.statusText(status);
  };

  vm.formatToFrom = function formatToFrom(item: any) {
    return ShipmentService.formatToFrom(item);
  };

  /**
   * Creates a string representing a booking's ShippingType and, if
   * applicable, the booking's BOLType using the standard notation that
   * user are used to. E.g. "F F, W" becomes "FCL-FCL, SWB"
   * @param  {Object} booking     A "booking" object (booking/draft/favorite)
   * @return {String}             Returns the new formatted string.
   */
  vm.formatShippingType = function formatShippingType(booking: any) {
    const bolType = ShipmentService.convertBOLTypeToLongFormat(booking.BOLType);
    let result = ShipmentService.convertShippingTypeToLongFormat(
      booking.ShippingType
    );
    if (result != null && bolType != null) {
      result += `, ${bolType}`;
    }
    return result;
  };

  vm.setIsLoading = function setIsLoading(reference: string) {
    vm.isLoading = reference;
  };

  vm.openCopyModal = function openCopyModal(jobReference: string) {
    vm.jobReferenceToCopy = jobReference;
    vm.showCopyModal = true;
  };

  vm.closeCopyModal = function closeCopyModal() {
    vm.jobReferenceToCopy = null;
    vm.showCopyModal = false;
  };

  /**
   * Sets flags for various roles that he user must have in order to
   * perform certain actions or see certain menuitems.
   */
  function setUserRoles() {
    vm.userHasFinanceRole = UserService.isAuthorized(USER_ROLES.FINANCE);
  }

  /**
   * Initializes the list of unconfirmed bookings and orders the items
   * by their CutoffDate.
   * @param  {Array}  unconfirmed An array of booking-objects.
   * @return {Array}              Returns unconfirmed in sorted order.
   */
  function initUnconfirmed(unconfirmed: any[]) {
    return ArrayUtilities.sortBy(unconfirmed, (uc: any) => {
      return DateUtilities.toUnixDate(uc.CutoffDate);
    });
  }

  /**
   * Initializes the list of a user's favorite bookings, adding "private"
   * properties used to render additional information.
   * @param  {Array}  favs    An array of booking-objects, the user's
   *                          starred bookings.
   * @return {Array}          Returns favs with added properties in
   *                          descending order by their DateArrival.
   */
  function initFavorites(favs: any[]) {
    favs.forEach((fav) => {
      fav._starred = true;
    });
    return ArrayUtilities.sortBy(favs, (fav: any) => {
      const date = DateUtilities.toUnixDate(fav.DateArrival);
      if (!date) return;
      return -date;
    });
  }

  /**
   * Initializes the list of a company's quotes, adding "private"
   * properties used to render additional information an in sorted order.
   * @param  {Array}  quotes  An array of quotes.
   * @return {Array}          Returns 'quotes' with added properties.
   */
  function initQuotes(quotes: any[]) {
    quotes.forEach((qt) => {
      qt._incoTermsDescription = theIncoTerms[qt.IncoTerms].Description;
    });
    return ArrayUtilities.sortBy(quotes, (qt: any) => {
      return DateUtilities.toUnixDate(qt.EndDate);
    });
  }

  /**
   * Attach a short DraftId to each draft item and sorts the list.
   * @param {Array} drafts
   * @returns An array with draft items with a short DraftId attached in
   *      sorted order by their last change-time.
   */
  function initDrafts(drafts: any[]) {
    drafts.forEach((item) => {
      if (item.DraftId.indexOf("-") !== -1) {
        item.DraftIdShort = item.DraftId.split("-")[0];
      }
    });
    return ArrayUtilities.sortBy(drafts, (dr: any) => {
      const date = DateUtilities.toUnixDate(dr.DraftUpdateDate);
      if (!date) return;
      return -date;
    });
  }

  initVM();
}

shipmentsCreateBookingDashboardController.$inject = ["$scope", "incoTerms"];
export default shipmentsCreateBookingDashboardController;
