export const SINGLEBOOKING_TABS = {
  cargo: 0,
  remarks: 1,
  units: 2,
  buyersConsol: 3,
  requests: 4,
  history: 5,
  documents: 6,
  invoices: 7,
  details: 8
};
