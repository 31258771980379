import * as angular from "angular";

import BookingValidationService from "Shipments/createbooking/BookingValidationService";
import ContainerService from "Services/ContainerService";
import { Object } from "es6-shim";
import * as modalTemplate from "./oversizemodal.html";

const OversizeModal: ng.IComponentOptions = {
  template: `${modalTemplate}`,
  bindings: {
    dismiss: "&",
    close: "&",
    resolve: "<"
  },
  controller: class OversizeModal {
    private dismiss: Function;
    private close: Function;
    private resolve: {
      resultText: string;
      oversizeDetails: any;
      containerType: any;
      modalTitle: any;
    };
    private oversizeDefaults = {
      OverHeight: 0,
      OverLengthAfter: 0,
      OverLengthFore: 0,
      OverWidthLeft: 0,
      OverWidthRight: 0
    };

    resultText: string;
    containerType: any;
    containerClass: string;
    title: string;
    lengths: any;
    lengthConstraints: object;
    isFlatRack: boolean;

    constructor() {
      const {
        resultText,
        containerType,
        oversizeDetails,
        modalTitle
      } = this.resolve;

      this.resultText = resultText;
      this.containerType = containerType;
      this.isFlatRack = this.hasFlatRack(containerType);
      this.containerClass = this.hasFlatRack(containerType)
        ? "flat-rack"
        : "open-top";
      this.lengths = Object.assign({}, this.oversizeDefaults, oversizeDetails);
      this.lengthConstraints = {
        rangeMin: 0,
        rangeMax: 999
      };
      this.title = modalTitle || "";
    }

    /**
     * Converts the lengths to number and validates the oversize-object.
     * This is needed because there needs to be at least one length with a
     * positive value, and one of the requirements was to have the input
     * fields for oversize-lengths as input[type=text] and not input[type=number]
     * NOTE: This method is called in each $digest-cycle!
     */
    invalidOversizeObject(): boolean {
      for (const key in this.lengths) {
        this.lengths[key] = Number(this.lengths[key]);
      }
      return !BookingValidationService.validateOversizes(
        this.containerType,
        this.lengths
      );
    }

    /**
     * Closes the modal and returns the updated oversize-lengths to the
     * calling controller
     */
    saveChanges(): void {
      this.close({ $value: this.lengths });
    }

    /** Returns true if the dimension's package-code represents a flat-rack
            An exception occurrs when container type is LCL, because oversize
            LCL should be displayed like flat rack.*/
    hasFlatRack(containerTypeArg: any): boolean {
      return (
        ContainerService.parseContainerAttributes(containerTypeArg).boxType ===
        "FR"
      );
    }
  }
};

angular.module("serviceWebApp").component("oversize", OversizeModal);
