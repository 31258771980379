import { ShipmentService } from "Services";
import { ArrayUtilities } from "Utilities";

import * as bulkUpdateDateModalTemplate from "Shipments/modal/BulkUpdateDate.html";
import * as angular from "angular";

declare var GlobalConfig: GlobalConfig;

const bulkUpdateDateModal: ng.IComponentOptions = {
  template: `${bulkUpdateDateModalTemplate}`,
  bindings: {
    modalInstance: "<",
    close: "&",
    dismiss: "&",
    resolve: "<"
  },
  controller: class BulkUpdateDateModalController {
    private close: Function;
    private dismiss: Function;
    private resolve: any;

    private jobRefs: string[];
    private shipmentsList: any[];

    // Display variables
    private displayer = {
      spinner: false,
      errorJobRefs: false,
      successJobRefs: false
    };
    private errorJobRefs: any[];
    private companiesCount: object; // .name .count
    private bookingsTotalCount: number;
    private isSuccess: boolean;
    private successCounter: number;

    $onInit = () => {
      this.shipmentsList = this.resolve.shipments;

      this.initialize();
    };

    private initialize = (): void => {
      this.groupBookingsByCompaniesAndCountForEach(this.shipmentsList);
      this.getJobReferencesFromShipments(this.shipmentsList);
    };

    // Close modal window
    cancel = function cancel(): void {
      this.dismiss();
    };

    postData = function postData(): void {
      this.postBulkUpdateBLSurrenderDate();
    };

    private getJobReferencesFromShipments = (bookings: any[]): void => {
      this.jobRefs = bookings.map(booking => booking.JobReference);
    };

    private groupBookingsByCompaniesAndCountForEach = (
      bookings: any[]
    ): void => {
      this.companiesCount = ArrayUtilities.countBy(bookings, "ConsigneeName");
      this.bookingsTotalCount = bookings.length;
    };

    // Sends all marked bookings to ShipmentService along with date.
    private postBulkUpdateBLSurrenderDate = (): void => {
      const dateTime = new Date();
      this.displayer.spinner = true;
      ShipmentService.updateBLSurrenderDate(
        this.jobRefs,
        dateTime.toISOString()
      )
        .then((data: any) => {
          this.handleResponseFromUpdateSurrenderDate(data);
        })
        .catch(() => undefined)
        .then(() => {
          this.displayer.spinner = false;
        });
    };

    /**
     * Checks if response returned OK or if something went wrong
     * @param  {object} data that comes from service function
     */
    private handleResponseFromUpdateSurrenderDate = (data: any): void => {
      this.isSuccess = this.isDataEmpty(data);
      if (this.isSuccess) {
        this.exitModalAndDisplayToaster();
      } else {
        this.updateBookingsByJobReference(data);
        this.displayer.errorJobRefs = true;
        this.errorJobRefs = data;
      }
    };

    /**
     * Updates booking that are displayed in modal by jobReference list
     * @param  {array} jobRefs [list of job references]
     */
    private updateBookingsByJobReference = (jobRefsArr: string[]): void => {
      const newShipments: string[] = [];

      jobRefsArr.forEach(key => {
        newShipments.push(
          this.shipmentsList.find(it => it.JobReference === key)
        );
      });

      this.shipmentsList = newShipments;
      this.updateSuccessCount(this.bookingsTotalCount, newShipments);
      this.initialize();
    };

    /**
     * Compare all bookings count to list of job references where
     * error occurred.
     * @param  {int} numAllBookings [Counter for all bookings]
     * @param  {array} errorJobRefs   [Array of job references]
     */
    private updateSuccessCount = (
      numAllBookings: number,
      errorJobRefs: string[]
    ): void => {
      this.successCounter = numAllBookings - errorJobRefs.length;

      if (this.successCounter > 0) {
        this.displayer.successJobRefs = true;
      } else {
        this.displayer.successJobRefs = false;
      }
    };

    private exitModalAndDisplayToaster = () => {
      this.close();
    };

    private isDataEmpty = (data: any[]): boolean => {
      return data.length <= 0;
    };
  }
};

angular
  .module("serviceWebApp")
  .component("bulkUpdateDateModal", bulkUpdateDateModal);
