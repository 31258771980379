import * as moment from "moment";
import * as angular from "angular";
import { $uiRouter } from "Bootstrap/angular";
import { react2angular } from "react2angular";

import { Object, Array } from "es6-shim";
import ObjectUtilities from "Utilities/ObjectUtilities";

// tslint:disable: max-line-length

// Controllers
import ShipmentsController from "Shipments/ShipmentsController";
import ShipmentsCreateBookingDashboardController from "Shipments/createbooking/dashboard/ShipmentsCreateBookingDashboardController";
import ShipmentsCreateBookingController from "Shipments/createbooking/ShipmentsCreateBookingController";
import ShipmentsSingleBookingController from "Shipments/singlebooking/ShipmentsSingleBookingController";
import ShipmentsSingleBookingDetailsController from "Shipments/singlebooking/details/ShipmentsSingleBookingDetailsController";
import ShipmentsSingleBookingUnitsController from "Shipments/singlebooking/units/ShipmentsSingleBookingUnitsController";
import ShipmentsSingleBookingRemarksController from "Shipments/singlebooking/remarks/ShipmentsSingleBookingRemarksController";
import ShipmentsSingleBookingRequestsController from "Shipments/singlebooking/requests/ShipmentsSingleBookingRequestsController";
import ShipmentsSingleBookingInfoController from "Shipments/singlebooking/info/ShipmentsSingleBookingInfoController";
import ShipmentsSingleBookingHistoryController from "Shipments/singlebooking/history/ShipmentsSingleBookingHistoryController";
import ShipmentsSingleBookingDocumentsController from "Shipments/singlebooking/documents/ShipmentsSingleBookingDocumentsController";
import ShipmentsSingleBookingInvoiceController from "Shipments/singlebooking/invoices/ShipmentsSingleBookingInvoiceController";

// React Components
import DocumentModal from "./documentModal";
import CustomsRequestModal from "./singlebooking/customsRequestModal";
import ContainersDropdown from "Shipments/containersDropdown";
import Step from "Shipments/steps";
import { Toggle, Dropdown, Button } from "Component";
import PlacesSelector from "Shipments/createbooking/placesselector";
import InfoTab from "./singlebooking/info";
import PartnerInfoPopup from "Components/react/PartnerInfoPopup";
import CompanySelectReminderModal from "Components/react/CompanySelectReminderModal";
import BookingCopyModal from "Components/react/BookingCopyModal";

// Services
import ShipmentDropdownService from "Shipments/ShipmentDropdownService";
import {
  UserService,
  ContainerService,
  ShipmentService,
  ShipmentRegistryService,
} from "Services";

// Templates
import * as shipmentsTpl from "Shipments/partials/shipments.html";
import * as shipmentsDashboardTpl from "Shipments/createbooking/dashboard/dashboard.html";
import * as createbookingTpl from "Shipments/createbooking/partials/createbooking.html";
import * as singleBookingTpl from "Shipments/singlebooking/singlebooking.html";

// Constants
import { USER_ROLES } from "Constants/UserRoles";
import { Transition, UIRouter } from "@uirouter/core";
import ShipmentsSingleBookingBuyersConsolController from "./singlebooking/buyersConsol/ShipmentsSingleBookingBuyersConsolController";
import { SINGLEBOOKING_TABS } from "./singlebooking/tabs";
import { BookingStore } from "Stores";
import _ from "lodash";
import InstructionsConfirmModal from "Components/react/InstructionsConfirmModal";
import VoyagePickerButton from "Components/react/VoyagePickerButton";
import HSCodesDropdown from "Components/react/HSCodesDropdown";
import TermsAndConditionsBulletinList from "Components/react/TermsAndConditionsBulletList";
import UnlinkModal from "Components/react/UnlinkQuoteModal";
import { $window } from "ngimport";
import TermsAndConditionModal from "Components/react/TermsAndConditionModal";

export const MAIN_STATE: string = "shipments";

declare var GlobalConfig: GlobalConfig;

class Shipments {
  constructor() {
    const routes: any[] = [
      {
        name: MAIN_STATE,
        url: `/${MAIN_STATE}`,
        parent: "masterPage",
        template: `${shipmentsTpl}`,
        controller: ShipmentsController,
        controllerAs: "$ctrl",
        params: {
          dateFrom: undefined,
          dateTo: undefined,
          resultText: "LABEL_PERIOD",
          filterNamePrefix: "",
        },
        data: {
          roles: [USER_ROLES.SHIP],
        },
        reloadOnSearch: false,
        resolve: {
          favoriteBookings: getUserFavoriteBookings,
        },
      },
      {
        name: `${MAIN_STATE}_week`,
        url: `/${MAIN_STATE}/week`,
        parent: "masterPage",
        template: `${shipmentsTpl}`,
        controller: ShipmentsController,
        controllerAs: "$ctrl",
        data: {
          roles: [USER_ROLES.SHIP],
        },
        params: {
          dateFrom: undefined,
          dateTo: undefined,
          resultText: "LABEL_PERIOD",
          filterNamePrefix: "TEXT_SHIPMENTSTHISWEEK_PLURAL",
        },
        resolve: {
          favoriteBookings: getUserFavoriteBookings,
        },
        onEnter: [
          "$transition$",
          ($transition$: Transition) => {
            // Check if we have dates in the state's params
            // If we don't, we have to fetch the dates from API
            // and redirect the transition to the state again
            // with the dates in params.

            const toStateParams = $transition$.params();
            if (toStateParams.dateFrom && toStateParams.dateTo) {
              return true;
            }

            const user = UserService.getUserProfile();
            if (!user) return;

            const dateFrom = moment()
              .startOf("isoWeek")
              .subtract(1, "days")
              .format("YYYY-MM-DD");
            const dateTo = moment()
              .endOf("isoWeek")
              .subtract(1, "days")
              .format("YYYY-MM-DD");

            const newDate = { dateFrom, dateTo };

            // Don't mutate state transition params object, just create a new one
            // which overrides old one.
            const newStateParams = Object.assign({}, toStateParams, newDate);

            // Return the new target transition
            return $uiRouter.stateService.target(
              $transition$.to(),
              newStateParams
            );
          },
        ],
      },
      {
        name: `${MAIN_STATE}_daterange`,
        url: `/${MAIN_STATE}/range/:dateFrom/:dateTo`,
        parent: "masterPage",
        template: `${shipmentsTpl}`,
        controller: ShipmentsController,
        controllerAs: "$ctrl",
        data: {
          roles: [USER_ROLES.SHIP],
        },
        params: {
          dateFrom: undefined,
          dateTo: undefined,
          resultText: "LABEL_PERIOD",
          filterNamePrefix: "",
        },
        resolve: {
          favoriteBookings: getUserFavoriteBookings,
        },
      },
      {
        name: `${MAIN_STATE}_search`,
        url: `/${MAIN_STATE}/search/:query`,
        parent: "masterPage",
        template: `${shipmentsTpl}`,
        controller: ShipmentsController,
        controllerAs: "$ctrl",
        data: {
          roles: [USER_ROLES.SHIP],
        },
        params: {
          dateFrom: undefined,
          dateTo: undefined,
          resultText: "LABEL_SEARCHRESULT",
          filterNamePrefix: "",
        },
        resolve: {
          favoriteBookings: getUserFavoriteBookings,
        },
      },
      {
        name: `${MAIN_STATE}_ready`,
        url: `/${MAIN_STATE}/ready`,
        parent: "masterPage",
        template: `${shipmentsTpl}`,
        controller: ShipmentsController,
        controllerAs: "$ctrl",
        data: {
          roles: [USER_ROLES.SHIP],
        },
        params: {
          dateFrom: undefined,
          dateTo: undefined,
          resultText: "TEXT_READYFORDELIVERY_PLURAL",
          filterNamePrefix: "",
        },
        resolve: {
          favoriteBookings: getUserFavoriteBookings,
        },
        onEnter: [
          "$transition$",
          ($transition$: Transition) => {
            // Check if we have dates in the state's params
            // If we don't, we have to fetch the dates from API
            // and redirect the transition to the state again
            // with the dates in params.

            const toStateParams = $transition$.params();
            if (toStateParams.dateFrom && toStateParams.dateTo) return true;

            const user = UserService.getUserProfile();
            const selectedCompany = UserService.getSelectedCompany();
            if (!user) return;

            const start = moment()
              .startOf("isoWeek")
              .subtract(18, "weeks")
              .format("YYYY-MM-DD");
            const end = moment()
              .endOf("isoWeek")
              .add(6, "weeks")
              .format("YYYY-MM-DD");

            return ShipmentService.companyShipments(
              start,
              end,
              selectedCompany.PartnerCode
            ).then((data: any) => {
              const dateFrom = moment()
                .subtract(3, "months")
                .format("YYYY-MM-DD");
              const dateTo = moment().format("YYYY-MM-DD");
              const newDate = { dateFrom, dateTo };

              // Don't mutate state transition params object, just create a new one
              // which overrides old one.
              const newStateParams = Object.assign({}, toStateParams, newDate);

              // Return the new target transition
              return $uiRouter.stateService.target(
                $transition$.to(),
                newStateParams
              );
            });
          },
        ],
      },
      {
        name: `${MAIN_STATE}_favorites`,
        url: `/${MAIN_STATE}/favorites`,
        parent: "masterPage",
        template: `${shipmentsTpl}`,
        controller: ShipmentsController,
        controllerAs: "$ctrl",
        data: {
          roles: [USER_ROLES.SHIP],
        },
        params: {
          dateFrom: undefined,
          dateTo: undefined,
          resultText: "LABEL_FAVORITES",
          filterNamePrefix: "TEXT_FAVORITE_SHIPMENT_PLURAL",
        },
        resolve: {
          favoriteBookings: getUserFavoriteBookings,
        },
      },
      {
        name: `${MAIN_STATE}_createbooking_dashboard`,
        url: `/${MAIN_STATE}/create/dashboard`,
        parent: "masterPage",
        template: `${shipmentsDashboardTpl}`,
        controller: ShipmentsCreateBookingDashboardController,
        controllerAs: "$ctrl",
        data: {
          roles: [USER_ROLES.SHIP],
        },
        resolve: {
          incoTerms: () => {
            return ShipmentRegistryService.getIncoTerms();
          },
        },
      },
      /**
       * The createbooking state is an abstract state and is never invoked
       * directly. All it does is provide its children with the shared
       * dependencies defined in the 'resolve' object-map.
       */
      {
        name: `${MAIN_STATE}_createbooking`,
        url: `/${MAIN_STATE}/create`,
        abstract: true,
        parent: "masterPage",
        template: "<ui-view />",
        data: {
          roles: [USER_ROLES.SHIP],
        },
        onEnter: [
          "$transition$",
          ($transition$: Transition) => {
            window.onbeforeunload = function () {
              return "Are you sure you want to leave this page.";
            };
          },
        ],
        onExit: [
          "$transition$",
          ($transition$: Transition) => {
            window.onbeforeunload = null;
          },
        ],
        resolve: {
          userCompanies: () => {
            const user = UserService.getUserProfile();
            const selectedCompany = UserService.getSelectedCompany();
            if (!user) return;
            // If the user is an employee she only gets a view of the
            // booking creation from the standpoint of the currently
            // selected company.
            if (UserService.isEmployeeOrAgent()) {
              return [selectedCompany];
            }
            // Other users can set the booking's shipper from the list of
            // companies that are assigned to them.
            return user.Companies;
          },
          shippingTypes: () => {
            return ShipmentRegistryService.getShippingTypes();
          },
          commodities: () => {
            return ShipmentRegistryService.getAllShippingCommodities();
          },
          containerTypes: () => {
            return ShipmentRegistryService.getContainerTypes();
          },
          packageTypes: () => {
            return ShipmentRegistryService.getPackageTypes();
          },
          shippingPorts: () => {
            return ShipmentRegistryService.getShippingPorts();
          },
          incoTerms: () => {
            return ShipmentRegistryService.getIncoTerms();
          },
          allowedReeferTemperatures: () => {
            return ContainerService.getAllowedTemperatures();
          },
          // Skip getting linkedRequests by default
          linkedRequests: () => {
            return [];
          },
          hsCodes: () => {
            return ShipmentService.getHSCodes();
          },
        },
      },
      /**
       * createbooking.default is used when users are creating bookings from scratch.
       * Therefore, the booking-viewmodel is not initialized.
       * When the state has been entered and changes made somewhere in its form,
       * a new draft is created with a unique identifier and the user is
       * redirected to /shipments/create/draft/:draftId
       */
      {
        name: `${MAIN_STATE}_createbooking.default`,
        url: "",
        template: `${createbookingTpl}`,
        controller: ShipmentsCreateBookingController,
        controllerAs: "$ctrl",
        resolve: {
          booking: () => undefined,
          remark: () => undefined,
        },
      },
      /**
       * createbooking.edit is used to edit bookings that have been created
       * in Doris. Changes are only saved manually by the user.
       */
      {
        name: `${MAIN_STATE}_createbooking.edit`,
        url: "/:jobReference",
        template: `${createbookingTpl}`,
        controller: ShipmentsCreateBookingController,
        controllerAs: "$ctrl",
        resolve: {
          isEditable: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();

              return new Promise((resolve: Function, reject: Function) => {
                ShipmentService.getBookingAllowedActions(
                  toStateParams.jobReference
                )
                  .then((data: any) => {
                    data.Edit ? resolve() : reject();
                  })
                  .catch(() => {
                    reject();
                  });
              });
            },
          ],
          booking: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();

              return ShipmentService.getBookingVM(toStateParams.jobReference);
            },
          ],
          linkedRequests: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();

              return ShipmentService.getRequestsForBooking(
                toStateParams.jobReference
              );
            },
          ],
          remark: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();

              return ShipmentService.getBookingRemarks(
                toStateParams.jobReference
              ).then((data: any) => {
                if (Array.isArray(data) && data.length > 0) {
                  return data[0].RemarkText;
                }
                return null;
              });
            },
          ],
        },
      },
      /**
       * createbooking.copy is used to copy bookings that have been created
       * in Doris. The booking is then saved with a new JobReference.
       */
      {
        name: `${MAIN_STATE}_createbooking.copy`,
        url: "/:jobReference/copy",
        template: `${createbookingTpl}`,
        controller: ShipmentsCreateBookingController,
        controllerAs: "$ctrl",
        resolve: {
          isCopyable: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();

              return new Promise((resolve: Function, reject: Function) => {
                ShipmentService.getBookingAllowedActions(
                  toStateParams.jobReference
                )
                  .then((data: any) => {
                    data.Copy ? resolve() : reject();
                  })
                  .catch(() => {
                    reject();
                  });
              });
            },
          ],
          booking: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();
              if (BookingStore.booking) {
                const booking = _.cloneDeep(BookingStore.booking);
                BookingStore.setBooking(undefined);
                return booking;
              }
              return ShipmentService.copyBooking(toStateParams.jobReference);
            },
          ],
          remark: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();

              return ShipmentService.getBookingRemarks(
                toStateParams.jobReference
              ).then((data: any) => {
                if (Array.isArray(data) && data.length > 0) {
                  return data[0].RemarkText;
                }
                return null;
              });
            },
          ],
        },
        onEnter: [
          "$transition$",
          ($transition$: Transition) => {
            window.scrollTo({ top: 0 } as ScrollToOptions);
          },
        ],
      },
      /**
       * createbooking.bookquote is used to book from quotes that have been created
       * in Doris.
       */
      {
        name: `${MAIN_STATE}_createbooking.bookQuote`,
        url: "/:quoteReference/book-quote",
        template: `${createbookingTpl}`,
        controller: ShipmentsCreateBookingController,
        controllerAs: "$ctrl",
        resolve: {
          booking: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();

              return ShipmentService.getBookingVMFromQuote(
                toStateParams.quoteReference
              );
            },
          ],

          remark: () => undefined,
        },
      },
      /**
       * createbooking.booktemplate is used to book from templates that have been created
       * This is an attempt to play with the old angular stuff, that I'm not remotely familar with
       * The idea is to fetch the bookingTemplateVM, and populate the bookTemplate
       * If it fails to fetch the data from the API we should cancel/abort the transaction and just route back to the templates page on sw.v2
       */
      {
        name: `${MAIN_STATE}_createbooking.bookTemplate`,
        url: "/:templateId/book-template",
        template: `${createbookingTpl}`,
        controller: ShipmentsCreateBookingController,
        controllerAs: "$ctrl",
        onExit: [
          "$transition$",
          ($transition$: Transition) => {
            window.onbeforeunload = null;
          },
        ],
        resolve: {
          booking: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();
              return ShipmentService.getBookingVMFromTemplate(
                toStateParams.templateId
              )
                .then((booking: any) => {
                  return booking;
                })
                .catch(() => {
                  $transition$.abort();
                  $window.open(
                    `${GlobalConfig.ENV.NewServiceWeb}/shipments/templates`,
                    "_self"
                  );
                });
            },
          ],

          remark: () => undefined,
        },
      },
      /**
       * createbooking.draft is used to edit booking-drafts. Changes to the
       * draft are automatically saved periodically.
       */
      {
        name: `${MAIN_STATE}_createbooking.draft`,
        url: "/draft/:draftId",
        template: `${createbookingTpl}`,
        controller: ShipmentsCreateBookingController,
        controllerAs: "$ctrl",
        params: {
          // Default parameter for the booking-object, see resolve.booking
          booking: null,
        },
        resolve: {
          booking: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();

              // If the state is invoked with the 'booking' parameter and
              // it is a non-empty object, e.g. when the draft was just
              // created. We just pass that to the controller to save us
              // the extra call to fetch the exact same data.
              const draft = toStateParams.booking;
              if (draft && typeof draft === "object") {
                return draft;
              }
              // When the state is WITHOUT the 'booking' parameter, we
              // need to fetch the draft from server.
              return ShipmentService.getDraftBooking(toStateParams.draftId);
            },
          ],
          remark: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();
              // If the state is invoked with the 'booking' parameter and
              // it is a non-empty object, e.g. when the draft was just
              // created. We just pass that to the controller to save us
              // the extra call to fetch the exact same data.
              const remark = ObjectUtilities.get(
                toStateParams,
                "booking.Remark",
                null
              );
              if (remark && typeof remark === "object") {
                return remark;
              }
              // When the state is WITHOUT the 'booking' parameter, we
              // need to fetch the draft from server.
              return ShipmentService.getDraftBooking(
                toStateParams.draftId
              ).then((data: any) => {
                return data.Remark;
              });
            },
          ],
        },
      },
      {
        name: `${MAIN_STATE}_singlebooking`,
        url: `/${MAIN_STATE}/:jobReference`,
        parent: "masterPage",
        template: `${singleBookingTpl}`,
        controller: ShipmentsSingleBookingController,
        controllerAs: "$ctrl",
        resolve: {
          booking: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();

              return ShipmentService.getBookingVM(toStateParams.jobReference);
            },
          ],
          favoriteBookings: getUserFavoriteBookings,
        },
        data: {
          roles: [USER_ROLES.SHIP],
          title: (params: any) => {
            return params.jobReference;
          },
        },
      },
    ];

    routes.forEach((item) => $uiRouter.stateRegistry.register(item));

    function getUserFavoriteBookings(): any {
      const user = UserService.getUserProfile();
      if (!user) return;
      return ShipmentService.getUserFavoriteBookings(user.Access.UserID);
    }
  }
}

angular
  .module("serviceWebApp")
  .run(Shipments)
  .component(
    "documentModal",
    react2angular(DocumentModal, ["reference", "onSubmit", "open", "onClose"])
  )
  .component(
    "customButton",
    react2angular(Button, [
      "id",
      "className",
      "content",
      "disabled",
      "float",
      "iconClass",
      "onClick",
      "primary",
      "warning",
      "type",
    ])
  )
  .component(
    "customsRequestModal",
    react2angular(CustomsRequestModal, [
      "show",
      "bookingNumber",
      "requestorName",
      "requestorEmail",
      "onSuccess",
      "onFailure",
      "documents",
      "onClose",
    ])
  )
  .component(
    "containersDropdown",
    react2angular(ContainersDropdown, ["reference", "portOfDischarge"])
  )
  .component(
    "step",
    react2angular(Step, [
      "jobReference",
      "status",
      "from",
      "to",
      "departureDate",
      "arrivalDate",
      "customsClearanceDate",
      "voyages",
      "bolType",
      "blSurrenderDate",
      "isPayed",
      "isCustomerCashPayer",
      "portOfDischarge",
      "jobStatus",
      "hasDocuments",
      "canSendCustomsRequest",
      "bookingType",
    ])
  )
  .component(
    "customDropdown",
    react2angular(Dropdown, [
      "options",
      "onChange",
      "value",
      "search",
      "noResultsMessage",
      "disabled",
    ])
  )
  .component(
    "toggle",
    react2angular(Toggle, [
      "onLabel",
      "offLabel",
      "onChange",
      "value",
      "bothOn",
    ])
  )
  .component("infoTab", react2angular(InfoTab, ["booking"]))
  .component(
    "placesSelector",
    react2angular(PlacesSelector, [
      "onSelect",
      "type",
      "partner",
      "onAddressSave",
      "removeAddress",
      "resetPlace",
      "fetchAddress",
      "initialValue",
      "jobReference",
      "noResultsMessage",
      "errors",
      "useShipperConsignee",
      "setUseShipperConsigneeFlag",
      "compareAddresses",
      "disabled",
    ])
  )
  .component(
    "partnerInfoPopup",
    react2angular(PartnerInfoPopup, [
      "address",
      "saveAddress",
      "options",
      "optionLabelFields",
      "open",
      "searchable",
      "newPartner",
      "onClose",
      "forceCorrectData",
      "additionalRequiredFields",
    ])
  )
  .component(
    "companySelectReminderModal",
    react2angular(CompanySelectReminderModal, [
      "userProfile",
      "type",
      "bookingPartners",
      "routeDashboard",
      "onSelect",
    ])
  )
  .component(
    "bookingCopyModal",
    react2angular(BookingCopyModal, ["jobReference", "open", "onClose"])
  )
  .component(
    "instructionsConfirmModal",
    react2angular(InstructionsConfirmModal, ["open", "onSubmit", "onClose"])
  )
  .component(
    "voyagePickerButton",
    react2angular(VoyagePickerButton, [
      "position",
      "content",
      "tabIndex",
      "onClick",
      "disabled",
    ])
  )
  .component(
    "hsCodesDropdown",
    react2angular(HSCodesDropdown, ["hsCodes", "onChange", "defaultHsCodes"])
  )
  .component(
    "termsAndConditionsBulletinList",
    react2angular(TermsAndConditionsBulletinList, ["terms"])
  )
  .component(
    "termsAndConditionsModal",
    react2angular(TermsAndConditionModal, [
      "open",
      "onSubmit",
      "onClose",
      "onCheckboxClick",
      "showErrorMessage",
    ])
  )
  .component(
    "unlinkQuoteModal",
    react2angular(UnlinkModal, ["open", "onSubmit", "onClose"])
  )
  .controller(
    "ShipmentsSingleBookingDetailsController",
    ShipmentsSingleBookingDetailsController
  )
  .controller(
    "ShipmentsSingleBookingBuyersConsolController",
    ShipmentsSingleBookingBuyersConsolController
  )
  .controller(
    "ShipmentsSingleBookingUnitsController",
    ShipmentsSingleBookingUnitsController
  )
  .controller(
    "ShipmentsSingleBookingRemarksController",
    ShipmentsSingleBookingRemarksController
  )
  .controller(
    "ShipmentsSingleBookingRequestsController",
    ShipmentsSingleBookingRequestsController
  )
  .controller(
    "ShipmentsSingleBookingInfoController",
    ShipmentsSingleBookingInfoController
  )
  .controller(
    "ShipmentsSingleBookingHistoryController",
    ShipmentsSingleBookingHistoryController
  )
  .controller(
    "ShipmentsSingleBookingDocumentsController",
    ShipmentsSingleBookingDocumentsController
  )
  .controller(
    "ShipmentsSingleBookingInvoiceController",
    ShipmentsSingleBookingInvoiceController
  );
