export const PALLET_STATUS_MAP = {
  0: "INTRANSIT",
  1: "INTRANSIT",
  10: "ININVENTORY",
  30: "PUTAWAY",
  50: "ALLOCATED",
  64: "ALLOCATED",
  95: "DELIVERED",
  96: "DELIVERED",
  99: "DELIVERED"
};

export const ASN_STATUSES = {
  UN_SHIPPED: 0,
  IN_TRANSIT: 10,
  IN_RECEIVING: 30,
  CLOSED: 60,
  VERIFICATION_ATTEMPTED: 85,
  RECEIPT_VERIFIED: 90,
  ALLOCATION_IN_PROGRESS: 91,
  ALLOCATION_COMPLETED: 92,
  CANCELLED: 99
};
