import { List, ListItem, ListItemProps } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { Terms } from "Shipments/createbooking/interfaces";
import parse from "html-react-parser";
interface TermsAndConditionsBulletinListProps {
  terms: string;
}
const TermsAndConditionsBulletinList: React.FC<TermsAndConditionsBulletinListProps> =
  ({ terms }: TermsAndConditionsBulletinListProps) => {
    const [bulletedList, setBulletedList] = useState<Terms[]>([]);

    const getTermsListFromTranslation = (
      translationString: string
    ): Terms[] => {
      if (translationString) {
        const termsSplitArray = translationString.split("\n");
        const tempTermsList: Terms[] = [];
        let newIndex = 0;
        for (const term in termsSplitArray) {
          if (termsSplitArray[term] !== "") {
            tempTermsList.push({
              Key: newIndex,
              Paragraph: termsSplitArray[term],
            });
            newIndex = newIndex + 1;
          }
        }
        return tempTermsList;
      }
      return [];
    };

    useEffect(() => {
      setBulletedList(getTermsListFromTranslation(terms));
    }, [terms]);
    return (
      <List bulleted>
        {bulletedList.map((term) => {
          return (
            <ListItem key={term.Key}>
              {parse("<p>" + `${term.Paragraph}` + "</p>")}
            </ListItem>
          );
        })}
      </List>
    );
  };

export default TermsAndConditionsBulletinList;
