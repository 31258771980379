import { UserService, FinanceService } from "Services";

function shipmentsSingleBookingInvoiceController() {
  const vm = this;

  /** Initial values for the controller */
  vm.orderByField = "InvoiceType";
  vm.reverse = false;

  vm.openInvoice = function openInvoice(invoice: FinanceInvoiceHeader): void {
    const currentUser = UserService.getUserProfile();
    const selectedCompany = UserService.getSelectedCompany();
    if (!currentUser || !selectedCompany) return;

    FinanceService.openInvoice(
      selectedCompany.SapID,
      invoice.SubsysInvoiceId,
      parseInt(invoice.CompanyCode, 10)
    );
  };
}

export default shipmentsSingleBookingInvoiceController;
