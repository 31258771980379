import React, { useState, useEffect } from "react";
import { Modal, /*Button,*/ Row, Col, FormControl } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { DocumentModalProps, DocumentFile } from "./interfaces";
import { languages } from "Services";
import * as _ from "lodash";
import { Button } from "Component";

// TODO: When we rewrite SingleBooking to React
// - Pass the document types as a List<Tuple<Key,Value>>
//   so we allow the parent component to decide types that are allowed to choose from

const DocumentModal = (props: DocumentModalProps) => {
  const [show, setShow] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [files, setFiles] = useState([] as DocumentFile[]);

  const onOpen = () => setShow(true);

  const onClose = () => {
    setFiles([]);
    setShow(false);
    props.onClose();
  };

  const onFile = (newFiles: DocumentFile[]) => {
    setFiles(files.concat(newFiles));
  };

  const onRemoveFile = (index: number) => {
    setFiles(files.filter((_: DocumentFile, i: number) => i !== index));
  };

  const onFileType = (target: HTMLInputElement, index: number) => {
    const newFiles = _.clone(files);
    newFiles[index].docType = target.value;
    setFiles(newFiles);
  };

  const isSubmitEnabled = (files: DocumentFile[]) =>
    !!files.length && files.every((file: DocumentFile) => !!file.docType);

  const onSubmit = async () => {
    setFetching(true);
    await props.onSubmit(files);
    setFetching(false);
  };

  useEffect(() => {
    props.open ? onOpen() : onClose();
  }, [props.open]);

  return (
    <div>
      <Modal bsSize="lg" className="sam-modal" show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-top">
            <span className="sam-head-2">{languages("ROLE_DOCUPLOAD")}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="modal-content-space"
          style={{ backgroundColor: "#f6f7f9" }}
        >
          <Row>
            <Col sm={3}>
              <div>
                <span className="boxTitle">
                  {props.reference
                    ? languages("LABEL_BOOKINGNUMBER")
                    : languages("LABEL_NEW_BOOKING")}
                </span>
                {props.reference}
              </div>
            </Col>
          </Row>
          {files.map((file: any, i: number) => (
            <Row className="sam-file-row" key={i}>
              <Col sm={12} className="sam-file-col">
                <span>{file.name}</span>
                <FormControl
                  componentClass="select"
                  placeholder="select"
                  onChange={(e) => onFileType(e.target as HTMLInputElement, i)}
                  value={file.docType || ""}
                >
                  <option
                    disabled
                    value={""}
                    label={languages("LABEL_SELECTDOCTYPE")}
                  />
                  <option value="CERO" label={languages("DOCTYPE_CERO")} />
                  <option value="CINV" label={languages("DOCTYPE_CINV")} />
                  <option value="EUR1" label={languages("DOCTYPE_EUR1")} />
                  <option value="EXBL" label={languages("DOCTYPE_EXBL")} />
                  <option value="LIND" label={languages("DOCTYPE_LIND")} />
                  <option value="PACK" label={languages("DOCTYPE_PACK")} />
                  <option value="PHTO" label={languages("DOCTYPE_PHTO")} />
                  <option value="REGP" label={languages("DOCTYPE_REGP")} />
                  <option value="SHP" label={languages("DOCTYPE_SHP")} />
                  <option value="T2M" label={languages("DOCTYPE_T2M")} />
                  <option value="VGM" label={languages("DOCTYPE_VGM")} />
                  <option value="ISSD" label={languages("DOCTYPE_ISSD")} />
                  <option value="CATC" label={languages("DOCTYPE_CATC")} />
                  <option value="DGD" label={languages("DOCTYPE_DGD")} />
                  <option value="CUS" label={languages("DOCTYPE_CUS")} />
                  <option value="HLT" label={languages("DOCTYPE_HLT")} />
                  <option value="OTHER" label={languages("DOCTYPE_OTHER")} />
                </FormControl>
                <a className="close" onClick={() => onRemoveFile(i)}>
                  ×
                </a>
              </Col>
            </Row>
          ))}
          <Row>
            <Dropzone
              className="sam-document-upload"
              activeClassName="file-hover"
              onDropAccepted={onFile}
            >
              <p>{languages("TEXT_DRAG_FILES_OR_CLICK_HERE")}</p>
            </Dropzone>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ padding: "15px 15px" }}>
          <Button float="left" type="button" onClick={onClose}>
            {languages("LABEL_CANCEL")}
          </Button>

          <Button
            iconClass={fetching ? "fa fa-spinner fa-pulse" : ""}
            disabled={!isSubmitEnabled(files) || fetching}
            onClick={onSubmit}
            primary
          >
            {!fetching && languages("LABEL_SUBMIT")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DocumentModal;
