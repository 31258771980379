import * as angular from "angular";
import { $document, $rootScope } from "ngimport";

import HazardViewModel from "Shipments/schemas/HazardViewModel";
import { SamskipNotify, ShipmentRegistryService } from "Services";
import { WindowUtilities } from "Utilities";
import BookingValidationService from "Shipments/createbooking/BookingValidationService";
import * as modalTemplate from "./imomodal.html";

const Imomodal: ng.IComponentOptions = {
  template: `${modalTemplate}`,
  bindings: {
    modalInstance: "<",
    close: "&",
    dismiss: "&",
    resolve: "<",
  },
  controller: class Imomodal {
    private close: Function;
    private dismiss: Function;
    private resolve: {
      dimension: any;
      packageTypes: any[];
      locked: any;
      shippingType: any;
    };

    dimension: any;
    packageTypes: any[];
    locked: boolean;
    minWeight: any;
    shippingType: any;
    imoForm: any;
    newItem: any;
    fetchingCodes: boolean;

    constructor() {
      const { dimension, packageTypes, locked, shippingType } = this.resolve;

      if (dimension && !Array.isArray(dimension.Hazardous)) {
        dimension.Hazardous = [];
      }

      this.dimension = dimension;
      this.packageTypes = packageTypes;
      this.locked = locked === true;
      this.minWeight = shippingType === "M" ? 0 : 0.01;
      this.shippingType = shippingType;
      this.fetchingCodes = false;
      this.newItem = {
        NetWeight: null,
        Weight: null,
        NumUnits: null,
      };
    }

    /**
     * Dismiss the modal but only allow dismissal if the form is invalid,
     * which means you shouldn't be able to add a new record
     */
    dismissModal(): void {
      if (!this.imoForm.$invalid) {
        SamskipNotify.displayError(
          "WARNING_CLEANUP_OR_ADD_ENTRY_BEFORE_LEAVING"
        );
      } else {
        this.close();
      }
    }

    /**
     * Triggered when an items is selected in typeahead
     * @param  {Object} item Hazard item
     */
    onSelectHazard(item: any): void {
      this.newItem.HazardType = item;
    }

    /**
     * Add a new hazardous item to the dimension and clear the form
     */
    addItem(): void {
      const newModel = new HazardViewModel(this.newItem);
      // Validate the new hazardous item
      if (!BookingValidationService.validateSingleHazardLine(newModel)) {
        if (newModel.Weight < newModel.NetWeight) {
          SamskipNotify.displayError(
            "WARNING_GROSSWEIGHT_GREATER_THAN_NETWEIGHT"
          );
        }
        return;
      }

      // Check if the line can be added without exceeding the dimension's total-weight
      if (
        !BookingValidationService.canAddHazardLine(this.dimension, newModel)
      ) {
        SamskipNotify.displayError(
          "WARNING_UNIT_WEIGHT_CANNOT_SURPASS_TOTAL_WEIGHT"
        );
        return;
      }

      this.dimension.Hazardous.push(newModel);

      // Clear the form after the new item has been added
      this.newItem = {};

      // Focus on hazard code typeahead
      WindowUtilities.focusByName("HazardCode");

      // Set pristine so that "cleanup/hreinsa" button
      // will be not stay active after adding an item
      this.imoForm.$setPristine();
    }

    /**
     * Remove a hazard item from the dimension
     * @param  {Object} index Index
     */
    removeItem(index: number): void {
      this.dimension.Hazardous.splice(index, 1);
    }

    /**
     * Clear the form
     */
    clearForm = (): void => {
      Object.keys(this.newItem).forEach((key) => {
        // Clear each property individually
        this.newItem[key] = null;
      });

      // Trigger a digest cycle to update the view
      if (!$rootScope.$$phase) {
        $rootScope.$apply();
      }
    };

    /**
     * Search hazardcodes and limit the results to 10 records
     * @param  {String}  Search string (hazard code description or UN number)
     * @return {Promise} Promise with a list of hazardcodes
     */
    searchHazardCodes(searchString: string): any {
      this.fetchingCodes = true;
      return ShipmentRegistryService.searchHazardCodes(searchString, 5)
        .then((data: any) => {
          return data;
        })
        .finally(() => {
          this.fetchingCodes = false;
        });
    }
  },
};

angular.module("serviceWebApp").component("imomodal", Imomodal);
