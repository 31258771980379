import * as React from "react";
import { Icon, Step } from "semantic-ui-react";

import "semantic-ui-less/semantic.less";
import { StepsProps } from "./interfaces.d";

export default class extends React.Component<StepsProps, {}> {
  render() {
    const { title, subtitle, status, ...rest } = this.props;
    return (
      <Step as="div" {...rest} className={`${status ? status : "disabled"}`}>
        {status === "completed" && (
          <Icon name="check circle outline" className="completed" />
        )}
        {status === "active" && <Icon name="clock outline" />}
        {status === "warning" && (
          <Icon name="warning sign" className="warning" />
        )}
        {status === "info" && <Icon name="info circle" className="info" />}
        {status === "attention" && (
          <Icon name="attention" className="attention" />
        )}
        {status === "canceled" && (
          <Icon name="times circle outline" className="canceled" />
        )}
        {!status && <Icon name="circle outline" />}

        <Step.Content>
          <Step.Title className="Step-Title">{title}</Step.Title>
          <Step.Description className="Step-Subtitle">
            {subtitle}
          </Step.Description>
          {this.props.children}
        </Step.Content>
      </Step>
    );
  }
}
