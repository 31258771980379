import * as angular from "angular";
import { Object } from "es6-shim";

import ContainerService from "Services/ContainerService";
import { SamskipNotify, TranslationService } from "Services";

import * as modalTemplate from "./dimensionmodalFCL.html";
import ModalUtilities from "Utilities/ModalUtilities";

const DimensionFCL: ng.IComponentOptions = {
  template: `${modalTemplate}`,
  bindings: {
    modalInstance: "<",
    close: "&",
    dismiss: "&",
    resolve: "<"
  },
  controller: class DimensionFCL {
    private close: Function;
    private dismiss: Function;
    private resolve: {
      dimension: any;
      containerType: any;
      packageTypes: any[];
      shippingType: any;
      containerNumbers: string[];
      oversizeFlag: boolean;
    };

    form: any;
    dimension: any;
    packageTypes: any[];
    containerType: any;
    shippingType: any;
    containerNumbers: any;
    containerAttrs: any;
    typeaheadItems: number;
    containerObject: any;
    containerWarningText: string | null;
    infoText: string | null;
    emptyContainers: boolean;
    sealNumberHidden: boolean;
    oversizeFlag: boolean;
    columnWidths: {
      oversize: number;
      containerNumber: number;
      sealNumber: number;
      quantity: number;
      type: number;
      measurements: number;
    };

    constructor() {
      const {
        dimension,
        packageTypes,
        containerType,
        shippingType,
        containerNumbers,
        oversizeFlag
      } = this.resolve;

      this.containerAttrs = ContainerService.parseContainerAttributes(
        containerType
      );
      this.typeaheadItems = 5;

      this.dimension = Object.assign({}, dimension);
      this.packageTypes = packageTypes;
      this.containerType = containerType;
      this.shippingType = shippingType;
      this.containerNumbers = containerNumbers;
      this.oversizeFlag = oversizeFlag;
      this.containerObject = {
        ContainerNumber:
          dimension.ContainerNumber == null ? null : dimension.ContainerNumber,
        Type: null
      };
      this.containerWarningText = null;
      this.infoText = null;

      if (shippingType === "M") {
        this.emptyContainers = true;
      } else {
        this.emptyContainers = false;
      }

      if (this.containerType === "40FR" || this.containerType === "20FR") {
        this.sealNumberHidden = true;
      } else {
        this.sealNumberHidden = false;
      }

      this.setColumnWidths();
    }

    /**
     * Search containers that are available for transport.
     * The Filter container numbers that have already been used in this particular
     * order. 'containerNumbers' is an array that contains already used
     * container numbers.
     * @param  {string} viewValue   Search-value entered in the container typeahead
     * @return {Promise}            Returns a Promise that resolves to a
     *                              list of matching containers
     */
    searchUsableContainers(viewValue: string): SamskipPromise<any> {
      return ContainerService.searchUsableContainers(
        viewValue,
        this.containerAttrs.length,
        this.containerAttrs.boxType,
        this.typeaheadItems
      )
        .then((data: any) => {
          return data.filter((o: any) => {
            return !(this.containerNumbers.indexOf(o.ContainerNumber) > -1);
          });
        })
        .catch((err: any) => {
          SamskipNotify.displayError(err);
        });
    }

    /**
     * Is executed when container number is choosen from the list in typeahead
     */
    containerNumberSelected(item: any): void {
      this.containerWarningText = null;
      if (this.containerType !== item.Type) {
        this.dimension.ContainerNumber = null;
        this.containerObject = null;
        this.containerWarningText = TranslationService.translate(
          "WARNING_CONTAINER_TYPE",
          {
            dimensionType: item.Type,
            detailType: this.containerType
          }
        );
      } else {
        this.dimension.ContainerNumber = (
          this.containerObject || {}
        ).ContainerNumber;
      }
    }

    /**
     * Closes the modal and returns the updated dimension-object to the
     * calling controller that instantiated the modal
     */
    saveChanges(): void {
      this.close({ $value: this.dimension });
    }

    // Determine widths for columns based on dimension data
    setColumnWidths = (): void => {
      // Initial sizes
      this.columnWidths = {
        oversize: 1,
        containerNumber: 3,
        sealNumber: 3,
        quantity: 1,
        type: 2,
        measurements: 3
      };

      if (this.sealNumberHidden) {
        this.columnWidths.containerNumber += 1;
        this.columnWidths.quantity += 1;
        this.columnWidths.measurements += 1;
      }

      if (this.oversizeFlag) {
        this.columnWidths.measurements -= 1;
      }
    };

    openOversizeModal = (): void => {
      const self = this;
      const modalInstance = ModalUtilities.showLargeModal(
        "oversize",
        {
          oversizeDetails: function _oversizeDetails() {
            return self.dimension.Oversize;
          },
          containerType: function _containerType() {
            return self.containerType;
          },
          modalTitle: function _modalTitle() {
            return null;
          }
        },
        {
          windowClass: "sam-modal ShipmentsCreateBookingOversizeModal",
          backdrop: "static"
        }
      );

      // Update the dimension's oversize-object on submit/save
      modalInstance.then((updatedSizes: any) => {
        this.dimension.Oversize = Object.assign(
          this.dimension.Oversize || {},
          updatedSizes
        );
        this.dimension._oversizeIcon = "fa-pencil-square-o";
        this.infoText = TranslationService.translate(
          "INFO_OVERSIZE_SAVED_ON_MODAL_SAVE"
        );
        this.form.$setDirty();
      });
    };

    validOversize = (): boolean => {
      return (
        !this.oversizeFlag || (this.oversizeFlag && this.dimension.Oversize)
      );
    };
  }
};

angular.module("serviceWebApp").component("dimensionFCL", DimensionFCL);
