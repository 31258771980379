import React from "react";
import { Container, Divider, Icon, Step, StepContent } from "semantic-ui-react";
import { BookingType, StatusStepProps, StepsProps } from "./interfaces.d";
// import styles from "./StatusStep.modules.less";
import icelandFlag from "../../assets/images/iceland-flag.png";
import { ToolTip } from "Component";
import { languages } from "Services/TranslationService";

const StatusStep: React.FC<StatusStepProps> = ({
  title,
  status,
  subtitle,
  bookingType,
  children,
}) => {
  // Show appropriate icons depending on the booking being import, export or domestic shipment
  const renderBookingType = (type: BookingType) => {
    if (type === "import") {
      return (
        <ToolTip value={languages("LABEL_TO_ICELAND")} placement={"top"}>
          <div className="center-container">
            <i
              className={`fa fa-arrow-circle-right shipment-icon margin-right`}
              aria-hidden="true"
            ></i>
            <img src={icelandFlag}></img>
          </div>
        </ToolTip>
      );
    }

    if (type === "export") {
      return (
        <ToolTip value={languages("LABEL_FROM_ICELAND")} placement={"top"}>
          <div className="center-container">
            <img src={icelandFlag} className="margin-right" />
            <i
              className={`fa fa-arrow-circle-right shipment-icon`}
              aria-hidden="true"
            ></i>
          </div>
        </ToolTip>
      );
    }
    return (
      <ToolTip value={languages("LABEL_DOMESTIC_ICELAND")} placement={"top"}>
        <div className="center-container">
          <i
            className={`fa fa-map-marker shipment-icon margin-right`}
            aria-hidden="true"
          ></i>
          <img src={icelandFlag}></img>
        </div>
      </ToolTip>
    );
  };

  return (
    <Step as="div" className={`${status ? status : "disabled"} step-container`}>
      <StepContent>
        <Step.Title className={`Step-Title`}>{title}</Step.Title>
        <Step.Description className={`Step-Subtitle status-step-subtitle`}>
          {status === "completed" && (
            <Icon name="check circle outline" className="completed" />
          )}
          {status === "active" && <Icon name="clock outline" />}
          {status === "warning" && (
            <Icon name="warning sign" className="warning" />
          )}
          {status === "info" && <Icon name="info circle" className="info" />}
          {status === "attention" && (
            <Icon name="attention" className="attention" />
          )}
          {status === "canceled" && (
            <Icon name="times circle outline" className="canceled" />
          )}
          {!status && <Icon name="circle outline" />}
          {subtitle}
        </Step.Description>
        {children}
        <Divider />
        {renderBookingType(bookingType)}
      </StepContent>
    </Step>
  );
};

export default StatusStep;
