import * as React from "react";
import * as ReactDOM from "react-dom";
import { reaction } from "mobx";
import moment from "moment";
import { InfoTabProps, InfoTabState } from "./interfaces";
import { Collapse } from "Component";
import {
  UserService,
  languages,
  TranslationService,
  LocationService,
} from "Services";
import { DateUtilities } from "Utilities";
import styles from "./index.less";

export default class InfoTab extends React.Component<
  InfoTabProps,
  InfoTabState
> {
  state: InfoTabState = {
    isEmployee: false,
    locations: [],
  };

  componentWillMount = () => {
    reaction(
      () => TranslationService.getSelectedLanguage(),
      () => this.setState({})
    );
  };

  componentDidMount = () => {
    this.setState({
      isEmployee: UserService.isEmployeeOrAgent(),
    });
    if (!this.props.booking.PLR) {
      LocationService.getLocationsByPortCode(
        this.props.booking.POL.PointCode
      ).then((values: LocationVM[]) => {
        const locations: LocationVM[] = values;
        for (const location of values) {
          locations[location.Type] = location;
        }
        this.setState({
          locations,
        });
      });
    }
  };

  renderAddress = (address: any, label?: string, partnerCode?: string) => {
    return (
      <div className="col-sm-4">
        {label && <span className="boxTitle">{label}</span>}
        {this.state.isEmployee && partnerCode && (
          <span className="partnerCode">{partnerCode}</span>
        )}
        <div className="larger-title">{address.FullName}</div>
        <div className="lower-info-string larger">
          {address.Address1 ? (
            <>
              {address.Address1} <br />
            </>
          ) : (
            ""
          )}
          {address.Address2 ? (
            <>
              {address.Address2} <br />
            </>
          ) : (
            ""
          )}
          {address.Address3 ? (
            <>
              {address.Address3} <br />
            </>
          ) : (
            ""
          )}
          {address.Address4 ? (
            <>
              {address.Address4} <br />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  renderContact = (address: any) => {
    return (
      <div className="col-sm-4">
        <span className="boxTitle">{languages("LABEL_CONTACT")}</span>
        <div className="lower-info-string larger">
          {address.ContactName}
          {address.ContactNumber && (
            <>
              <br />
              {address.ContactNumber}
            </>
          )}
          {address.ContactEmail && (
            <>
              <br />
              {(address.ContactEmail || [])
                .split(";")
                .map(function (email: any, i: any) {
                  return (
                    <>
                      {" "}
                      {email} <br />
                    </>
                  );
                })}
            </>
          )}
        </div>
      </div>
    );
  };

  renderLocation = (location: LocationVM, label: string) => {
    return (
      <div className="row">
        {label && (
          <span className="boxTitle u-block u-paddingLeft10">{label}</span>
        )}
        <div className="col-sm-4">
          <div className="larger-title">{location.Name}</div>
          <div className="lower-info-string larger">
            {location.Address1}
            <br />
            {location.Address2}
            <br />
            {location.Address3}
          </div>
          <div className={styles.contactInfo}>
            {location.Phone && (
              <>
                <span className="boxTitle">{languages("LABEL_PHONE")}</span>
                {location.Phone}
              </>
            )}
            <br />
            {location.Fax && (
              <>
                <span className="boxTitle">{languages("LABEL_FAX")}</span>
                {location.Fax}
              </>
            )}
            <br />
            {location.Email && (
              <>
                <span className="boxTitle">{languages("LABEL_EMAIL")}</span>
                {location.Email}
              </>
            )}
          </div>
        </div>
        <div
          className="col-sm-8"
          dangerouslySetInnerHTML={{ __html: location.FooterInfo }}
        ></div>
      </div>
    );
  };

  renderDriveRequestTime = (
    date: Date,
    time: number,
    loadReference: string
  ) => {
    const mDate = moment(date)
      .hour(time / 100)
      .minute(time % 100)
      .second(0);
    return (
      <div className="col-sm-4">
        <span className="boxTitle">
          {languages("LABEL_DRIVE_TIME_REQUEST")}
        </span>
        <div className="lower-info-string larger">
          {mDate.format("DD-MM-YYYY")}
          <br />
          {mDate.format("HH:mm")}
          <br />
          {this.renderLoadRef(loadReference)}
        </div>
      </div>
    );
  };

  renderLoadRef = (loadReference: string) => {
    return (
      <div>
        <span className="boxTitle">{languages("LABEL_LOAD_REFERENCE")}</span>
        <div className="lower-info-string larger">
          {loadReference ? loadReference : languages("LABEL_NOT_PROVIDED")}
        </div>
      </div>
    );
  };

  render() {
    const { booking } = this.props;
    const { locations } = this.state;
    return (
      <>
        <Collapse
          title={languages("LABEL_BOOKING_PARTIES_INFO")}
          open={false}
          className={styles.collapse}
        >
          <div className="row ShipmentsSingleBooking-deliveryInfo">
            {this.renderAddress(
              booking.Addresses.SHP,
              languages("LABEL_SHIPPER"),
              booking.Shipper.PartnerCode
            )}
            {this.renderAddress(
              booking.Addresses.CEE,
              languages("LABEL_CONSIGNEE"),
              booking.Consignee.PartnerCode
            )}
            {booking.Addresses.NOT &&
              this.renderAddress(
                booking.Addresses.NOT,
                languages("LABEL_NOTIFIER")
              )}
          </div>
          {booking.BOLType !== "W" && (
            <>
              <hr />
              <div className="row">
                <div className="col-sm-4">
                  <span className="boxTitle">
                    {languages("LABEL_ORIGINALSRECEIVED")}
                  </span>
                  <div className="lower-info-string larger">
                    {DateUtilities.formatDate(booking.BLSurrenderDate)}
                  </div>
                </div>
              </div>
            </>
          )}
        </Collapse>
        <Collapse
          title={languages("LABEL_MARKSANDNUMBERS_ABBR")}
          open={false}
          className={styles.collapse}
        >
          <div className="SingleBooking-marksandnumbersInfo row">
            <div className="col-sm-12">
              <table className="table">
                <tbody>
                  {booking.MarksAndNumbers.map((item: any, i: number) => {
                    return (
                      <tr key={i}>
                        <td className={styles.markAndNumbers}>
                          {item.Description}
                        </td>
                        <td>{item.Marks}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Collapse>
        {booking.Addresses.DEL && (
          <Collapse
            title={languages("LABEL_DEL_INFO")}
            open={false}
            className={styles.collapse}
          >
            {booking.Addresses.filter((i: any) => i.AddressType === "DEL").map(
              (filteredBooking: any) => {
                return (
                  <div className="row ShipmentsSingleBooking-deliveryInfo">
                    {this.renderAddress(filteredBooking)}
                    {booking.Addresses.DEL.ContactName &&
                      this.renderContact(filteredBooking)}
                    {booking.Addresses.DEL.RequestDate &&
                      this.renderDriveRequestTime(
                        filteredBooking.RequestDate,
                        filteredBooking.RequestTime,
                        filteredBooking.LoadReference
                      )}
                  </div>
                );
              }
            )}
          </Collapse>
        )}
        {booking.Addresses.COL && (
          <Collapse
            title={languages("LABEL_COL_INFO")}
            open={false}
            className={styles.collapse}
          >
            {booking.Addresses.filter((i: any) => i.AddressType === "COL").map(
              (filteredBooking: any) => {
                return (
                  <div className="row ShipmentsSingleBooking-deliveryInfo">
                    {this.renderAddress(filteredBooking)}
                    {booking.Addresses.COL.ContactName &&
                      this.renderContact(filteredBooking)}
                    {booking.Addresses.COL.RequestDate &&
                      this.renderDriveRequestTime(
                        filteredBooking.RequestDate,
                        filteredBooking.RequestTime,
                        filteredBooking.LoadReference
                      )}
                  </div>
                );
              }
            )}
          </Collapse>
        )}
        {locations.length > 0 && (
          <Collapse
            title={languages("LABEL_LOAD_TERMINAL_OPENING_HOURS")}
            open={false}
            className={styles.collapse}
          >
            <div className="row ShipmentsSingleBooking-deliveryInfo">
              {locations["GEN"] &&
                this.renderLocation(
                  locations["GEN"],
                  languages("LABEL_GEN_OFFICE")
                )}
              {locations["FCL"] && (
                <>
                  <hr />
                  {this.renderLocation(
                    locations["FCL"],
                    languages("LABEL_FCL_OFFICE")
                  )}
                </>
              )}
              {locations["LCL"] && (
                <>
                  <hr />
                  {this.renderLocation(
                    locations["LCL"],
                    languages("LABEL_LCL_OFFICE")
                  )}
                </>
              )}
            </div>
          </Collapse>
        )}
        {booking?.HSCodes.length > 0 && (
          <Collapse
            title={languages("LABEL_HSCODE_PLURAL")}
            open={false}
            className={styles.collapse}
          >
            <div className="SingleBooking-marksandnumbersInfo row">
              <div className="col-sm-12">
                <table className="table">
                  <tbody>
                    {booking.HSCodes.map((hs: HSCode) => {
                      return (
                        <tr key={hs.HSCode}>
                          <td className={styles.markAndNumbers}>{hs.HSCode}</td>
                          <td>{hs.Description}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Collapse>
        )}
      </>
    );
  }
}
