import React from "react";
import { Button, Modal } from "Component";
import "semantic-ui-less/semantic.less";
import { languages } from "Services";
import styles from "./index.modules.less";

interface UnlinkQuoteModalProps {
  open: boolean;
  onClose: Function;
  onSubmit: Function;
}

const UnlinkQuoteModal = ({
  open,
  onClose,
  onSubmit,
}: UnlinkQuoteModalProps) => {
  const renderContent = () => {
    return (
      <div>
        <p>{languages("TEXT_UNLINK_QUOTE")}</p>
        <div className={`footer button-group`}>
          <Button onClick={() => onClose()}>{languages("LABEL_CANCEL")}</Button>
          <Button primary onClick={() => onSubmit()}>
            {languages("LABEL_UNLINK")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      header={<h2>{languages("LABEL_UNLINK_QUOTE")}</h2>}
      content={renderContent()}
      additionalStyles={styles.modal}
    ></Modal>
  );
};

export default UnlinkQuoteModal;
