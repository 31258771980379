export const WORKFLOW_STATUSES = {
  BOOKING_REGISTERED: 0,
  BOOKING_CONFIRMED: 1,
  COLLECTION_REGISTERED: 2,
  COLLECTION_UNALLOCATED: 3,
  COLLECTION_IN_PROGRESS: 4,
  ARRIVED_IN_POL: 5,
  READY_TO_LOAD: 15,
  TRANSIT_IN_PROGRESS: 20,
  DISCHARGED: 55,
  WAREHOUSE_PROCESSING: 58,
  READY_FOR_DELIVERY: 60,
  DELIVERY_INPROGRESS: 65,
  DELIVERED: 80,
  CANCELED: 99
};
