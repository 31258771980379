import React from "react";

import { Popup } from "semantic-ui-react";

import "semantic-ui-less/semantic.less";

import styles from "./index.less";
import AddressForm from "./AddressForm";

export default class extends React.Component<
  {
    partner: any;
    show: boolean;
    context: any;
    type: string;
    onClose: Function;
    onAddressSave: any;
    fetchAddress: Function;
    pointCode: string;
    setHasAddress: Function;
    useShipperConsignee: boolean;
    setUseShipperConsigneeFlag: Function;
    compareAddresses: Function;
  },
  {}
> {
  render(): JSX.Element {
    return (
      <Popup
        className={styles.popup}
        context={this.props.context}
        content={
          <AddressForm
            partner={this.props.partner}
            onClose={this.props.onClose}
            type={this.props.type}
            saveAddress={this.props.onAddressSave}
            fetchAddress={this.props.fetchAddress}
            pointCode={this.props.pointCode}
            setHasAddress={this.props.setHasAddress}
            useShipperConsignee={this.props.useShipperConsignee}
            setUseShipperConsigneeFlag={this.props.setUseShipperConsigneeFlag}
            compareAddresses={this.props.compareAddresses}
          />
        }
        open={this.props.show}
        basic
        position="right center"
        wide
      />
    );
  }
}
