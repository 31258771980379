import React, { createRef } from "react";
import { Dropdown, DropdownItemProps, Icon, Popup } from "semantic-ui-react";
import { reaction } from "mobx";
import AddressPopup from "../addresspopup";
import styles from "./index.less";
import {
  ShipmentRegistryService,
  TranslationService,
  ShipmentService,
} from "Services";
import { UtilityFunctions } from "Utilities";
import { DropdownSearchAsync, Button } from "Component";

export default class extends React.Component<
  {
    onSelect: Function;
    type: string;
    partner: any;
    onAddressSave: any;
    removeAddress: Function;
    resetPlace: Function;
    fetchAddress: Function;
    initialValue: any;
    jobReference: string;
    noResultsMessage: string;
    errors: any;
    useShipperConsignee: boolean;
    setUseShipperConsigneeFlag: Function;
    compareAddresses: Function;
    disabled: boolean;
  },
  {
    ports: any[];
    options: DropdownItemProps[];
    selectedValue: string;
    showPopup: boolean;
    isOpen: boolean;
    hasAddress: boolean;
    canEditColAddress: boolean;
    showDisabledPopup: boolean;
    isLoadingInitial: boolean;
  }
> {
  contextRef: any = createRef();
  constructor(props: any) {
    super(props);
    const { initialValue } = this.props;
    this.state = {
      ports: [],
      options: [],
      selectedValue: initialValue ? initialValue.PointCode : "",
      showPopup: false,
      isOpen: false,
      hasAddress: false,
      canEditColAddress: true,
      showDisabledPopup: false,
      isLoadingInitial: false,
    };
  }

  async componentDidMount() {
    reaction(
      () => TranslationService.getSelectedLanguage(),
      () => this.setState({})
    );
    const { initialValue, fetchAddress, type, jobReference } = this.props;
    // Only load ports and locations if no initial value is found
    if (!this.props.initialValue) {
      this.setState({ isLoadingInitial: true });
      ShipmentRegistryService.searchShippingPorts(null, "IS").then((data) => {
        this.setState({
          ports: data,
          options: this.createOptions(data),
          isLoadingInitial: false,
          isOpen: true,
        });
      });
    } else {
      this.initializeWithValue(initialValue.PointCode);
      if (
        UtilityFunctions.isIcelandicPointCode(initialValue.PointCode) ||
        (!UtilityFunctions.isIcelandicPointCode(initialValue.PointCode) &&
          fetchAddress(type) !== undefined)
      ) {
        this.setHasAddress(true);
      }
    }
    if (type === "PLR" && jobReference) {
      ShipmentService.allowedColEdit(jobReference).then((data) => {
        this.setState({ canEditColAddress: data });
      });
    }
  }

  initializeWithValue = async (value: string) => {
    this.setState({ isLoadingInitial: true });
    ShipmentRegistryService.searchShippingPorts(value, null).then((data) => {
      let point = data.find((x) => x.PointCode == value)!;
      this.setState({
        options: this.createOptions([point]),
        ports: [point],
        selectedValue: point.PointCode,
        isLoadingInitial: false,
      });
    });
  };

  // Makes a search for entries based on the search input.
  handleSearchChange = async (value: string) => {
    return ShipmentRegistryService.searchShippingPorts(value).then((data) => {
      // Add selectedValue to data data array
      if (
        this.state.selectedValue &&
        !data.some(
          (port: any) => port && port.PointCode === this.state.selectedValue
        )
      ) {
        const selectedPort = this.state.ports.find((port) => {
          return port.PointCode === this.state.selectedValue;
        });
        if (selectedPort) {
          data.push(selectedPort);
        }
      }
      this.setState({
        ports: data,
        options: this.createOptions(data),
      });
    });
  };

  createOptions = (data: Port[]) => {
    return data.map((port: Port) => {
      return {
        key: port.PointCode,
        value: port.PointCode,
        content: `${port.FullName}, ${port.Country} (${port.PointCode})`,
        text: (
          <span className="text-element">
            {`${port.FullName}, ${port.Country} (${port.PointCode})`}
          </span>
        ),
      };
    });
  };

  // Returns the filtered list of elements.
  search = (o: any, v: any) => {
    return o;
  };

  handleSelect = (value: any) => {
    if (this.state.hasAddress && this.state.selectedValue !== value) {
      this.removeAddress();
    }
    if (value) {
      this.props.onSelect(
        this.props.type,
        this.state.ports.find((port) => {
          return port.PointCode === value;
        })
      );
      this.setState({ selectedValue: value });
    } else {
      // when x icon is clicked
      this.reset();
    }
  };

  openPopup = () => {
    this.setState({ showPopup: true });
  };

  closePopup = () => {
    this.setState({ showPopup: false });
  };

  reset = () => {
    this.removeAddress();
    this.props.resetPlace(this.props.type);
    this.setState({ selectedValue: "", showPopup: false });
  };

  removeAddress = () => {
    this.props.removeAddress(this.props.type);
    this.setHasAddress(false);
  };

  setHasAddress = (hasAddress: boolean) => {
    this.setState({ hasAddress });
  };

  onAddressSave = (values: any[]) => {
    this.setState({ hasAddress: true }, () => {
      this.closePopup();
    });
    this.props.onAddressSave(values, this.props.type, this.state);
  };

  shouldShowAddressButton() {
    return (
      this.state.selectedValue &&
      !UtilityFunctions.isIcelandicPointCode(this.state.selectedValue)
    );
  }

  render(): JSX.Element {
    const { type, disabled, errors } = this.props;
    const { showPopup, canEditColAddress, showDisabledPopup } = this.state;
    return (
      <>
        <div
          ref={this.contextRef}
          className={`${styles.dropdown}`}
          onMouseOut={() => this.setState({ showDisabledPopup: false })}
          onMouseOver={() => this.setState({ showDisabledPopup: true })}
        >
          <DropdownSearchAsync
            disabled={!canEditColAddress || disabled}
            noResultsMessage={this.props.noResultsMessage}
            value={this.state.selectedValue}
            searchInput={{ autoFocus: true }}
            placeholder={TranslationService.translate("LABEL_TOWN_CITY")}
            type="text"
            onChange={this.handleSelect}
            search={this.handleSearchChange}
            options={this.state.options}
            onClearOptions={() => {
              this.setState({ options: [] });
            }}
            onClearValue={() => {
              this.setState({
                selectedValue: "",
              });
            }}
            minCharacters={3}
            lazyLoad={true}
            onSearchChange={(value: string) => {
              this.setState({
                selectedValue: value,
              });
            }}
            onOpen={() => this.setState({ isOpen: true })}
            onClose={() => this.setState({ isOpen: false })}
            clearable
            open={this.state.isOpen}
            selectOnNavigation={false}
            loading={this.state.isLoadingInitial}
            className={`${styles.dropdown} ${
              !canEditColAddress || (disabled && styles.disabled)
            }`}
          ></DropdownSearchAsync>
          <AddressPopup
            show={showPopup}
            context={this.contextRef}
            type={type}
            onClose={this.closePopup}
            partner={this.props.partner}
            onAddressSave={this.onAddressSave}
            fetchAddress={this.props.fetchAddress}
            pointCode={this.state.selectedValue}
            setHasAddress={this.setHasAddress}
            useShipperConsignee={this.props.useShipperConsignee}
            setUseShipperConsigneeFlag={this.props.setUseShipperConsigneeFlag}
            compareAddresses={this.props.compareAddresses}
          />
          {canEditColAddress ? null : (
            <Popup
              className={styles.popupdisabled}
              open={showDisabledPopup}
              position="top center"
              content={TranslationService.translate(
                "INFO_HOVER_DRAFT_DATE_DISABLED"
              )}
              on="hover"
              context={this.contextRef}
            />
          )}
        </div>
        <div
          className={`${this.shouldShowAddressButton() ? "" : "u-hidden"} ${
            styles.buttonWrapper
          }`}
        >
          <Button onClick={this.openPopup}>
            {this.state.hasAddress ? <Icon name="edit" /> : null}
            {TranslationService.translate("BTN_DRIVE_REQUEST")}
          </Button>
          {this.state.hasAddress ? (
            <Icon
              name="close"
              onClick={this.removeAddress}
              title={TranslationService.translate(
                "INFO_HOVER_CLEAR_DRIVE_REQUEST"
              )}
            />
          ) : null}
        </div>
      </>
    );
  }
}
