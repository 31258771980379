import { SamskipNotify, ShipmentService } from "Services";

import * as bulkUpdateRemarksModalTemplate from "Shipments/modal/bulkUpdateRemarks.html";
import * as angular from "angular";

declare var GlobalConfig: GlobalConfig;

const bulkUpdateRemarksModal: ng.IComponentOptions = {
  template: `${bulkUpdateRemarksModalTemplate}`,
  bindings: {
    modalInstance: "<",
    close: "&",
    dismiss: "&",
    resolve: "<"
  },
  controller: class BulkUpdateRemarksModalController {
    private close: Function;
    private dismiss: Function;
    private resolve: any;
    private shipments: any[];

    private jobRefs: string[];
    private results: any;
    private textareaValue: any;
    private displayer: any;

    $onInit = () => {
      this.initialize();
    };

    private initialize = (): void => {
      this.shipments = this.resolve.shipments;
      this.results = {
        text: "",
        isSuccess: false
      };
      this.displayer = {
        spinner: false
      };
    };

    submit = function submit(): void {
      this.jobRefs = this.getListOfJobsFromShipments();
      this.postRemarks(this.jobRefs);
    };

    cancel = function cancel(): void {
      this.dismiss();
    };

    private getListOfJobsFromShipments(): any[] {
      return this.shipments.map(it => it.JobReference);
    }

    private postRemarks(jobRefsArr: string[]): void {
      this.displayer.spinner = true;
      ShipmentService.updateBookingsRemark(jobRefsArr, this.textareaValue)
        .then(() => {
          this.quitAndDisplaySuccess();
        })
        .catch((data: any) => {
          this.displayer.spinner = false;
          this.displayFailure(data);
        })
        .then(() => {
          this.displayer.spinner = false;
        });
    }

    private quitAndDisplaySuccess(): void {
      this.setResults("LABEL_SUCCESS", true);
      this.close({ $value: this.results });
    }

    private displayFailure(data: any): void {
      this.setResults(data, false);
      SamskipNotify.displayWarning(this.results.text);
    }

    private setResults(text: string, isSuccess: boolean): void {
      this.results.text = text;
      this.results.isSuccess = isSuccess;
    }
  }
};

angular
  .module("serviceWebApp")
  .component("bulkUpdateRemarksModal", bulkUpdateRemarksModal);
