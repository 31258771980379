import React from "react";
import * as _ from "lodash";
import { Form, Button, Loader, Icon, Checkbox } from "semantic-ui-react";

import DatePicker from "react-datepicker";

import {
  CompanyService,
  languages,
  SamskipNotify,
  TranslationService,
} from "Services";
import moment from "moment-timezone";

import { AddressFormDto } from "./interfaces.d";
import styles from "./index.less";

import {
  requiredValidator,
  notEmptyStringValidator,
  multipleEmailValidator,
  phoneNumberValidator,
  futureDateValidator,
  lengthValidatior,
  futureDateAndNotNextFourHoursValidator,
  validateAll,
} from "../../../shared/validators/validators";
import { FormError } from "Containers/driveRequests/interfaces";
import { Textarea, Dropdown } from "Component";
import { add } from "lodash";

export default class extends React.Component<
  {
    partner: AddressFormDto;
    onClose: Function;
    type: string;
    saveAddress: any;
    fetchAddress: Function;
    pointCode: string;
    setHasAddress: Function;
    useShipperConsignee: boolean;
    setUseShipperConsigneeFlag: Function;
    compareAddresses: Function;
  },
  {
    addresses: any[];
    selectedAddress: any;
    addressList: AddressFormDto[];
    dropdownOptions: any[];
    selectedValues: string[];
    loadingDropdown: boolean;
    formErrors: FormError[][];
    initErrors: FormError[];
    emptyAddress: AddressFormDto;
    useShipperConsignee: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedAddress: 0,
      addresses: [],
      addressList: [],
      emptyAddress: {
        isNew: false,
        PartnerCode: this.props.partner ? this.props.partner.PartnerCode : "",
        FullName: "",
        AddressType: this.props.type === "PLR" ? "COL" : "DEL",
        PointCode: this.props.pointCode,
        PostCode: "",
        Address1: "",
        Address2: "",
        Address3: "",
        Address4: "",
        RequestDate: undefined,
        ContactName: "",
        ContactNumber: "",
        ContactEmail: "",
        LoadPlan: "RD",
        SpecialInstructions: "",
        LoadReference: "",
      },
      dropdownOptions: [],
      selectedValues: [],
      loadingDropdown: false,
      // prettier-ignore
      formErrors: [],
      initErrors: [
        {
          key: "FullName",
          validators: [requiredValidator, notEmptyStringValidator],
          value: false,
        },
        {
          key: "Address1",
          validators: [requiredValidator, notEmptyStringValidator],
          value: false,
        },
        {
          key: "Address2",
          validators: [requiredValidator, notEmptyStringValidator],
          value: false,
        },
        {
          key: "PostCode",
          validators: [
            requiredValidator,
            notEmptyStringValidator,
            lengthValidatior(1),
          ],
          value: false,
        },
        {
          key: "RequestDate",
          validators: [
            requiredValidator,
            this.props.type === "PLR"
              ? futureDateAndNotNextFourHoursValidator
              : futureDateValidator,
          ],
          value: false,
        },
        {
          key: "ContactName",
          validators: [requiredValidator, notEmptyStringValidator],
          value: false,
        },
        {
          key: "ContactEmail",
          validators: [multipleEmailValidator],
          value: false,
        },
        {
          key: "ContactNumber",
          validators: [
            requiredValidator,
            notEmptyStringValidator,
            phoneNumberValidator,
          ],
          value: false,
        },
      ],
      useShipperConsignee: this.props.useShipperConsignee,
    };
  }

  handleValidation = (key: any) => {
    this.setState((prevState) => {
      const formErrors = prevState.formErrors;
      formErrors[this.state.selectedAddress] = prevState.formErrors[
        this.state.selectedAddress
      ].map((item: FormError, i: number) => {
        if (
          i ===
          _.findIndex(prevState.formErrors[this.state.selectedAddress], [
            "key",
            key,
          ])
        ) {
          item.value = !validateAll(
            item.validators,
            _.get(this.state.addressList[this.state.selectedAddress], key)
          );
        }
        return item;
      });
      return {
        ...prevState,
        formErrors,
      };
    });
  };

  hasError = (propName: string): boolean => {
    const formError = this.state.formErrors[this.state.selectedAddress].find(
      (item) => item.key === propName
    );
    if (formError) {
      return formError.value;
    }
    return false;
  };

  onBlur = (propName: string) => {
    this.handleSetState({ [propName]: this.state[propName].trim() });
  };

  async componentDidMount() {
    const addr = this.props.fetchAddress(this.props.type);
    const formErrors = this.state.formErrors;
    if (addr) {
      this.props.setHasAddress(true);
      var temp = [...addr];
      if (temp.length > 0) {
        for (var i = 0; i < temp.length; i = i + 1) {
          this.populateValues(temp[i]);
        }
      } else {
        this.populateValues(this.state.emptyAddress);
      }
    } else {
      this.setState({ loadingDropdown: true });
    }

    if (this.props.partner && this.props.partner.PartnerCode) {
      await CompanyService.companyCustomersAndAddressByType(
        this.props.partner.PartnerCode,
        ["000", this.state.addressList[this.state.selectedAddress].AddressType]
      )
        .then((data) => {
          // Determine if modal should be filled with currently selected Address
          // or a head address of partner
          if (!addr) {
            this.populateValues(
              data.find((address) => address.AddressType === "000")
            );
          }
          formErrors.map((error: any) => {
            this.handleValidation(error.key);
          });
          this.setState({
            addresses: data,
            loadingDropdown: false,
            dropdownOptions: data.map((address) => {
              return {
                key: address.AddressCode,
                value: address.AddressCode,
                text: `${address.FullName}, ${address.Address1}`,
              };
            }),
          });
        })
        .catch((e) => {
          this.setState({ loadingDropdown: false });
        });
    } else {
      this.setState({ loadingDropdown: false });
    }
  }

  populateValues = (address: any) => {
    let addressList = this.state.addressList;
    let formErrors = this.state.formErrors;
    let useShipperConsignee = this.state.useShipperConsignee;
    addressList.push({
      isNew: address.isNew || false,
      FullName: address.FullName || "",
      PostCode: address.PostCode || "",
      Address1: address.Address1 || "",
      Address2: address.Address2 || "",
      Address3: address.Address3 || "",
      Address4: address.Address4 || "",
      RequestDate: address.RequestDate
        ? new Date(address.RequestDate)
        : undefined,
      RequestTime: address.RequestTime ? address.RequestTime : undefined,
      ContactName: address.ContactName || "",
      ContactNumber: address.ContactNumber || "",
      ContactEmail: address.ContactEmail || "",
      SpecialInstructions: address.SpecialInstructions || "",
      PartnerCode: this.props.partner ? this.props.partner.PartnerCode : "",
      AddressType: this.props.type === "PLR" ? "COL" : "DEL",
      PointCode: this.props.pointCode,
      LoadPlan: "RD",
      LoadReference: address.LoadReference,
    });
    formErrors.push(this.state.initErrors);
    if (this.props.compareAddresses(this.props.partner, address)) {
      this.props.setUseShipperConsigneeFlag(this.props.type, true);
      useShipperConsignee = true;
    }
    let selectedValues = this.state.selectedValues;
    selectedValues.push(address.AddressCode);

    this.setState({
      addressList,
      formErrors,
      useShipperConsignee,
      selectedValues,
    });
  };

  resetValues = () => {
    let addressList = this.state.addressList;
    addressList[this.state.selectedAddress] = {
      ...this.state.emptyAddress,
    };
    let selectedValues = this.state.selectedValues;
    selectedValues[this.state.selectedAddress] = "";
    this.setState({ useShipperConsignee: false });
    this.setState({ selectedValues, addressList });
  };

  handleDropdownSelect = (selectedValue: any) => {
    let addressList = this.state.addressList;
    let address = this.state.addresses.find((address) => {
      return address.AddressCode === selectedValue;
    });

    let selectedValues = this.state.selectedValues;
    selectedValues[addressList.length - 1] = selectedValue;

    address.AddressType = this.props.type == "PLR" ? "COL" : "DEL";
    addressList[this.state.selectedAddress] = { ...address };
    this.setState({ addressList, selectedValues });
  };

  handleSetState = (value: any) => {
    let addressList = this.state.addressList;
    addressList[this.state.selectedAddress] = {
      ...this.state.addressList[this.state.selectedAddress],
      ...value,
    };
    this.setState({ addressList });
  };

  validateForm = () => {
    let example = _.cloneDeep(this.state.formErrors);
    var result = true;
    for (let i = 0; i < example.length; i++) {
      const formErrors = example[i];
      for (const formError of formErrors) {
        formError.value = !validateAll(
          formError.validators,
          _.get(this.state.addressList[i], formError.key)
        );
      }
      example[i] = _.map(this.state.formErrors[i], (item) => {
        const formError = _.find(formErrors, (fe) => fe.key === item.key);
        if (formError) {
          item.value = formError.value;
          result = formError.value === true ? false : result;
        }
        return item;
      });
    }
    return result;
  };

  onUseShipperOrConsigneeAddressCheck = (value: any) => {
    let useShipperConsignee = this.state.useShipperConsignee;
    useShipperConsignee = value;
    let addressList = this.state.addressList;
    if (value === true) {
      addressList[this.state.selectedAddress] = {
        ...this.state.emptyAddress,
        ...this.props.partner,
        AddressType: this.props.type === "PLR" ? "COL" : "DEL",
        ContactName: addressList[this.state.selectedAddress].ContactName,
        ContactEmail: addressList[this.state.selectedAddress].ContactEmail,
        ContactNumber: addressList[this.state.selectedAddress].ContactNumber,
        RequestDate: addressList[this.state.selectedAddress].RequestDate,
        SpecialInstructions:
          addressList[this.state.selectedAddress].SpecialInstructions,
      };
    }
    this.props.setUseShipperConsigneeFlag(value);
    this.setState({ useShipperConsignee, addressList });
  };

  validateDates = (type: any, date?: Date, secondDate?: Date) => {
    if (date && secondDate) {
      return date < secondDate;
    }
    return false;
  };

  useShipperConsigneeAvailable() {
    if (this.props.type === "PLR" && this.state.selectedAddress === 0) {
      return true;
    } else if (
      this.props.type === "PFD" &&
      this.state.selectedAddress === this.state.addressList.length - 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  changeFocus = (index: any) => {
    if (index === this.state.selectedAddress) {
      return true;
    } else {
      return false;
    }
  };

  addAddress = () => {
    let formErrors = this.state.formErrors;
    let addressList = this.state.addressList;
    let selectedValues = this.state.selectedValues;
    let selectedAddress = this.state.selectedAddress;

    if (this.props.type === "PLR") {
      selectedValues.push("");
      formErrors.push(_.cloneDeep(this.state.initErrors));
      addressList.push({
        ...this.state.emptyAddress,
        AddressType: this.props.type === "PLR" ? "COL" : "DEL",
      });
    } else {
      selectedValues.unshift("");
      formErrors.unshift(_.cloneDeep(this.state.initErrors));
      addressList.unshift({
        ...this.state.emptyAddress,
        AddressType: this.props.type === "PLR" ? "COL" : "DEL",
      });
    }
    if (this.props.type === "PLR") {
      selectedAddress = this.state.addressList.length - 1;
    } else {
      selectedAddress = 0;
    }

    this.setState({ addressList, formErrors, selectedValues, selectedAddress });
  };

  removeAddress = (index: any) => {
    let addressList = this.state.addressList;
    let address = addressList.indexOf(addressList[index]);
    addressList.splice(address, index);

    if (index === 0) {
      for (let i = 0; i < addressList.length; i++) {
        addressList[i] = _.cloneDeep(addressList[i + 1]);
      }
      addressList.splice(addressList.length - 1);
    }

    let selectedValues = this.state.selectedValues;
    let value = selectedValues.indexOf(index);
    selectedValues.splice(value, index);

    let formErrors = this.state.formErrors;
    let error = formErrors.indexOf(index);
    formErrors.splice(error, index);

    this.setState({
      addressList,
      formErrors,
      selectedValues,
      selectedAddress: 0,
    });
  };

  render() {
    return (
      <div className={styles.form}>
        <div className="popup-header">
          <div>
            <span className="title">
              {languages("LABEL_CHANGE_REGISTRATION")}
            </span>
            <a className="close" onClick={() => this.props.onClose()}>
              <i className="fa fa-times"></i>
            </a>
          </div>
        </div>
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          {this.state.addressList.map((address, index) => (
            <li key={index} className="nav-item">
              <Button
                className={this.changeFocus(index) ? "primary" : ""}
                onClick={() => this.setState({ selectedAddress: index })}
              >
                {languages("LABEL_TRANSPORT_REQUEST_MULTISTOP")} {index + 1}
              </Button>
              {(index !== this.state.addressList.length - 1 &&
                this.props.type === "PFD") ||
              (this.props.type === "PLR" && index !== 0) ? (
                <Icon
                  name="close"
                  onClick={() => this.removeAddress(index)}
                  title={TranslationService.translate(
                    "INFO_HOVER_CLEAR_DRIVE_REQUEST"
                  )}
                />
              ) : null}
            </li>
          ))}
          <li className="nav-item">
            <Button primary onClick={() => this.addAddress()}>
              <Icon name="plus square outline" />
            </Button>
          </li>
        </ul>
        {this.state.addressList.map((address, index) =>
          index === this.state.selectedAddress ? (
            <Form className="popup-content" key={index}>
              {this.props.partner && this.useShipperConsigneeAvailable() ? (
                <Form.Field>
                  <Checkbox
                    label={
                      this.props.type === "PLR"
                        ? languages("LABEL_SAME_AS_SHIPPER")
                        : languages("LABEL_SAME_AS_CONSIGNEE")
                    }
                    checked={this.state.useShipperConsignee}
                    onChange={(e, d) =>
                      this.onUseShipperOrConsigneeAddressCheck(d.checked)
                    }
                  />
                  <span className="separatorLine" />
                </Form.Field>
              ) : null}
              {this.state.dropdownOptions.length > 0 &&
              !(
                (this.state.useShipperConsignee &&
                  this.state.selectedAddress === 0 &&
                  this.props.type === "PLR") ||
                (this.state.useShipperConsignee &&
                  this.state.selectedAddress ===
                    this.state.addressList.length - 1 &&
                  this.props.type === "PFD")
              ) ? (
                <Form.Field>
                  <label>{languages("LABEL_KNOWN_ADDRESSES")}</label>
                  <Dropdown
                    selection
                    search
                    options={this.state.dropdownOptions}
                    onChange={this.handleDropdownSelect}
                    value={this.state.selectedValues[index]}
                  />
                </Form.Field>
              ) : this.state.loadingDropdown ? (
                <Loader active />
              ) : null}
              <Form.Field>
                <label>{languages("LABEL_NAME")}</label>
                <Form.Input
                  disabled={
                    this.state.useShipperConsignee &&
                    this.useShipperConsigneeAvailable()
                  }
                  onBlur={() =>
                    this.handleValidation(
                      `${"FullName"}`
                    )
                  }
                  placeholder={languages("LABEL_NAME")}
                  value={
                    this.state.addressList[this.state.selectedAddress].FullName
                  }
                  onChange={(e: any, value: any) => {
                    this.handleSetState({
                      ["FullName"]:
                        value.value,
                    });
                  }}
                  error={this.hasError(
                    "FullName"
                  )}
                  maxLength={35}
                />
              </Form.Field>
              <Form.Field>
                <label>{languages("LABEL_ADDRESS")}</label>
                <Form.Input
                  disabled={
                    this.state.useShipperConsignee &&
                    this.useShipperConsigneeAvailable()
                  }
                  onBlur={() =>
                    this.handleValidation(
                      `${"Address1"}`
                    )
                  }
                  placeholder={languages("LABEL_ADDRESS")}
                  value={
                    this.state.addressList[this.state.selectedAddress].Address1
                  }
                  onChange={(e: any, value: any) => {
                    this.handleSetState({
                      ["Address1"]:
                        value.value,
                    });
                  }}
                  error={this.hasError(
                    "Address1"
                  )}
                  maxLength={35}
                ></Form.Input>
                <div className="input-row">
                  <Form.Input
                    disabled={
                      this.state.useShipperConsignee &&
                      this.useShipperConsigneeAvailable()
                    }
                    className="col-sm-8"
                    onBlur={() =>
                      this.handleValidation(
                        `${"Address2"}`
                      )
                    }
                    placeholder={languages("LABEL_TOWN_CITY")}
                    value={
                      this.state.addressList[this.state.selectedAddress]
                        .Address2
                    }
                    onChange={(e: any, value: any) => {
                      this.handleSetState({
                        ["Address2"]:
                          value.value,
                      });
                    }}
                    error={this.hasError(
                      "Address2"
                    )}
                  ></Form.Input>
                  <Form.Input
                    disabled={
                      this.state.useShipperConsignee &&
                      this.useShipperConsigneeAvailable()
                    }
                    className="col-sm-4"
                    onBlur={() =>
                      this.handleValidation(
                        `${"PostCode"}`
                      )
                    }
                    placeholder={languages("LABEL_POSTALCODE")}
                    value={
                      this.state.addressList[this.state.selectedAddress]
                        .PostCode
                    }
                    onChange={(e: any, value: any) => {
                      this.handleSetState({
                        ["PostCode"]:
                          value.value,
                      });
                    }}
                    error={this.hasError(
                      "PostCode"
                    )}
                    maxLength={10}
                  ></Form.Input>
                </div>
                <Form.Input
                  disabled={
                    this.state.useShipperConsignee &&
                    this.useShipperConsigneeAvailable()
                  }
                  placeholder={`${languages("LABEL_DISTRICT")}/${languages(
                    "LABEL_COUNTY"
                  )}/${languages("LABEL_POSTBOX")}`}
                  value={
                    this.state.addressList[this.state.selectedAddress].Address3
                  }
                  onChange={(e: any, value: any) => {
                    this.handleSetState({
                      ["Address3"]:
                        value.value,
                    });
                  }}
                  maxLength={35}
                ></Form.Input>
                <Form.Input
                  disabled={
                    this.state.useShipperConsignee &&
                    this.useShipperConsigneeAvailable()
                  }
                  placeholder={languages("LABEL_COUNTRY")}
                  value={
                    this.state.addressList[this.state.selectedAddress].Address4
                  }
                  onChange={(e: any, value: any) => {
                    this.handleSetState({
                      ["Address4"]:
                        value.value,
                    });
                  }}
                  maxLength={35}
                ></Form.Input>
              </Form.Field>
              <Form.Field>
                <label>{languages("LABEL_DRIVE_TIME_REQUEST")}</label>
                <DatePicker
                  className={
                    this.hasError(
                      "RequestDate"
                    )
                      ? "error"
                      : ""
                  }
                  dateFormat="dd/MM/yyyy"
                  showTimeSelect
                  timeFormat="HH:mm"
                  minDate={new Date()}
                  selected={
                    this.state.addressList[this.state.selectedAddress]
                      .RequestDate
                  }
                  onChange={(date: Date) => {
                    if (date) {
                      const requestTime =
                        _.padStart(date.getHours().toString(), 2, "0") +
                        _.padStart(date.getMinutes().toString(), 2, "0");

                      this.handleSetState({
                        ["RequestDate"]:
                          new Date(moment(date).toISOString()),
                      });

                      this.handleSetState({
                        ["RequestTime"]:
                          requestTime,
                      });
                    }
                  }}
                  customInput={
                    <Button>
                      {this.state.addressList[this.state.selectedAddress]
                        .RequestDate
                        ? moment(
                            this.state.addressList[this.state.selectedAddress]
                              .RequestDate
                          ).format("DD-MM-YYYY HH:mm")
                        : "DD-MM-YYYY HH:MM"}
                    </Button>
                  }
                  onBlur={() =>
                    this.handleValidation(
                      `${"RequestDate"}`
                    )
                  }
                  onCalendarClose={() =>
                    this.handleValidation(
                      `${"RequestDate"}`
                    )
                  }
                ></DatePicker>
              </Form.Field>
              <Form.Field>
                <label>{languages("LABEL_LOAD_REFERENCE")}</label>
                <Form.Input
                  value={
                    this.state.addressList[this.state.selectedAddress]
                      .LoadReference
                  }
                  placeholder={languages("PLACEHOLDER_LOAD_REFERENCE")}
                  onChange={(e: any, value: any) => {
                    this.handleSetState({
                      ["LoadReference"]:
                        value.value,
                    });
                  }}
                  maxLength={30}
                ></Form.Input>
              </Form.Field>
              <Form.Field>
                <label>{languages("LABEL_CONTACT")}</label>
                <Form.Input
                  onBlur={() =>
                    this.handleValidation(
                      `${"ContactName"}`
                    )
                  }
                  error={this.hasError(
                    "ContactName"
                  )}
                  placeholder={languages("LABEL_NAME")}
                  value={
                    this.state.addressList[this.state.selectedAddress]
                      .ContactName
                  }
                  onChange={(e: any, value: any) => {
                    this.handleSetState({
                      ["ContactName"]:
                        value.value,
                    });
                  }}
                  maxLength={35}
                ></Form.Input>
                <div className="input-row">
                  <Form.Input
                    className="col-sm-8"
                    onBlur={() =>
                      this.handleValidation(
                        `${"ContactEmail"}`
                      )
                    }
                    error={this.hasError(
                      "ContactEmail"
                    )}
                    placeholder={languages("LABEL_EMAIL")}
                    value={
                      this.state.addressList[this.state.selectedAddress]
                        .ContactEmail
                    }
                    onChange={(e: any, value: any) => {
                      this.handleSetState({
                        ["ContactEmail"]:
                          value.value,
                      });
                    }}
                    maxLength={100}
                  ></Form.Input>
                  <Form.Input
                    className="col-sm-4"
                    onBlur={() =>
                      this.handleValidation(
                        `${"ContactNumber"}`
                      )
                    }
                    error={this.hasError(
                      "ContactNumber"
                    )}
                    placeholder={languages("LABEL_PHONE")}
                    value={
                      this.state.addressList[this.state.selectedAddress]
                        .ContactNumber
                    }
                    onChange={(e: any, value: any) => {
                      this.handleSetState({
                        ["ContactNumber"]:
                          value.value,
                      });
                    }}
                    maxLength={25}
                  ></Form.Input>
                </div>
              </Form.Field>
              <Form.Field>
                <label>{languages("LABEL_ADDITIONAL_INFO")}</label>
                <Form.Input
                  control={Textarea}
                  maxLength={120}
                  value={
                    this.state.addressList[this.state.selectedAddress]
                      .SpecialInstructions
                  }
                  onChange={(value: any) => {
                    this.handleSetState({
                      ["SpecialInstructions"]: value,
                    });
                  }}
                ></Form.Input>
              </Form.Field>
            </Form>
          ) : null
        )}
        <div className="popup-footer">
          <a className="btn btn-link" onClick={() => this.props.onClose()}>
            {languages("LABEL_CANCEL")}
          </a>
          <div className="right-button">
            {this.props.partner &&
            this.props.partner.PartnerCode !== "TEMPAR" ? (
              <Button
                primary
                onClick={() => {
                  this.resetValues();
                }}
              >
                <Icon name="plus square outline" />
                {languages("TEXT_NEW")}
              </Button>
            ) : null}
            <Button
              primary
              onClick={() => {
                if (!this.validateForm()) {
                  const requestDateError = this.state.formErrors[
                    this.state.selectedAddress
                  ].find(
                    (error) =>
                      error.key ===
                      "RequestDate"
                  );
                  if (
                    this.props.type === "PLR" &&
                    requestDateError &&
                    requestDateError.value
                  ) {
                    SamskipNotify.displayWarning(
                      languages("ERROR_COLLECTION_ATLEAST_4_HOURS"),
                      languages("LABEL_ERROR")
                    );
                  }
                  SamskipNotify.displayWarning(
                    languages("ERROR_INVALID_FORM_INPUT"),
                    languages("LABEL_ERROR")
                  );
                  return;
                }
                if (this.state.addressList.length > 1) {
                  let success = true;
                  for (let i = 0; i < this.state.addressList.length; i++) {
                    success = this.validateDates(
                      this.props.type,
                      this.state.addressList[i].RequestDate,
                      this.state.addressList[i + 1].RequestDate
                    );
                    if (success == false) {
                      SamskipNotify.displayWarning(
                        languages("ERROR_INVALID_ADDRESS_SEQUENCE"),
                        languages("LABEL_ERROR")
                      );
                      return;
                    }
                    if (i + 2 == this.state.addressList.length) break;
                  }
                }
                this.props.saveAddress(this.state.addressList);
              }}
            >
              <Icon name="save outline" />
              {languages("LABEL_SAVE")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
