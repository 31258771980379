import * as React from "react";
import { Step, Grid, Popup, Icon } from "semantic-ui-react";

import "semantic-ui-less/semantic.less";
import styles from "./index.less";
import {
  BookingType,
  SamskipStepProps,
  SamskipStepState,
} from "./interfaces.d";
import CustomStep from "./CustomStep";
import DocumentsList from "./DocumentsList";
import * as moment from "moment";
import { ShipmentService, languages, TranslationService } from "Services";
import { WORKFLOW_STATUSES } from "Constants/WorkflowStatusConstants";
import { reaction } from "mobx";
import StatusStep from "./StatusStep";

export default class extends React.Component<
  SamskipStepProps,
  SamskipStepState
> {
  constructor(props: SamskipStepProps) {
    super(props);
    this.state = {
      isImport: ShipmentService.isLocalPort(this.props.portOfDischarge),
    };
  }

  componentWillMount = () => {
    reaction(
      () => TranslationService.getSelectedLanguage(),
      () => this.setState({})
    );
  };

  public render(): JSX.Element {
    const {
      status,
      from,
      to,
      departureDate,
      arrivalDate,
      customsClearanceDate,
      voyages,
      bolType,
      blSurrenderDate,
      jobStatus,
      isPayed,
      isCustomerCashPayer,
      canSendCustomsRequest,
      bookingType,
    } = this.props;

    return (
      <Grid className={styles.steps} stretched stackable>
        <Grid.Column
          tablet="16"
          largeScreen="3"
          widescreen="3"
          className="stepsColumn"
        >
          <Step.Group fluid widths="1" className={styles.status}>
            {this.calculateBookingStatusStep(status, jobStatus, bookingType)}
          </Step.Group>
        </Grid.Column>
        <Grid.Column
          tablet="16"
          largeScreen="13"
          widescreen="13"
          className="stepsColumn"
        >
          <Step.Group fluid widths="6">
            {this.calculateCollectionStep(status)}
            {this.calculateLoadingPortStep(status, from, departureDate)}
            {this.calculateFreightStep(status, voyages)}
            {this.calculateDischargePortStep(status, to, arrivalDate)}
            {this.calculateDocumentsStep(
              status,
              isPayed,
              canSendCustomsRequest,
              bolType,
              isCustomerCashPayer,
              customsClearanceDate,
              blSurrenderDate
            )}
            {this.calculateDeliveryStep(status)}
          </Step.Group>
        </Grid.Column>
      </Grid>
    );
  }

  calculateBookingStatusStep = (
    status: number,
    jobStatus: number | string,
    bookingType: BookingType
  ) => {
    if (status === WORKFLOW_STATUSES.CANCELED) {
      return (
        <StatusStep
          title={languages("LABEL_BOOKINGSTATUS")}
          subtitle={languages("LABEL_CANCELLED")}
          status="canceled"
          bookingType={bookingType}
        />
      );
    }
    if (status >= WORKFLOW_STATUSES.DELIVERED) {
      return (
        <StatusStep
          title={languages("LABEL_BOOKINGSTATUS")}
          subtitle={languages("LABEL_DELIVERED")}
          status="completed"
          bookingType={bookingType}
        />
      );
    }
    if (status >= WORKFLOW_STATUSES.READY_FOR_DELIVERY) {
      return (
        <StatusStep
          title={languages("LABEL_BOOKINGSTATUS")}
          subtitle={languages("TEXT_READYFORDELIVERY")}
          status="active"
          bookingType={bookingType}
        />
      );
    }
    if (status >= WORKFLOW_STATUSES.WAREHOUSE_PROCESSING) {
      return (
        <StatusStep
          title={languages("LABEL_BOOKINGSTATUS")}
          subtitle={languages("LABEL_WAREHOUSEPROCESSING")}
          status="active"
          bookingType={bookingType}
        />
      );
    }
    if (status >= WORKFLOW_STATUSES.DISCHARGED) {
      return (
        <StatusStep
          title={languages("LABEL_BOOKINGSTATUS")}
          subtitle={languages("LABEL_DISCHARGED")}
          status="active"
          bookingType={bookingType}
        />
      );
    }
    if (status >= WORKFLOW_STATUSES.TRANSIT_IN_PROGRESS) {
      return (
        <StatusStep
          title={languages("LABEL_BOOKINGSTATUS")}
          subtitle={languages("LABEL_INTRANSPORT")}
          status="active"
          bookingType={bookingType}
        />
      );
    }
    if (jobStatus === 0 || jobStatus === "0") {
      return (
        <StatusStep
          title={languages("LABEL_BOOKINGSTATUS")}
          subtitle={`${languages("LABEL_BOOKED")} (${languages(
            "LABEL_UNCONFIRMED"
          )})`}
          status="warning"
          bookingType={bookingType}
        />
      );
    }
    return (
      <StatusStep
        title={languages("LABEL_BOOKINGSTATUS")}
        subtitle={`${languages("LABEL_BOOKED")}`}
        status="active"
        bookingType={bookingType}
      />
    );
  };

  calculateCollectionStep = (status: number) => {
    if (status === WORKFLOW_STATUSES.CANCELED) {
      return (
        <CustomStep
          title={languages("LABEL_PREFORWARDING")}
          subtitle={languages("LABEL_INTRANSPORT")}
        />
      );
    }
    if (status > WORKFLOW_STATUSES.COLLECTION_IN_PROGRESS) {
      return (
        <CustomStep
          title={languages("LABEL_PREFORWARDING")}
          subtitle={languages("LABEL_FINISHED")}
          status="completed"
        />
      );
    }
    if (status === WORKFLOW_STATUSES.COLLECTION_IN_PROGRESS) {
      return (
        <CustomStep
          title={languages("LABEL_PREFORWARDING")}
          subtitle={languages("LABEL_INTRANSPORT")}
          status="active"
        />
      );
    }
    return (
      <CustomStep
        title={languages("LABEL_PREFORWARDING")}
        subtitle={languages("LABEL_NOTSTARTED")}
      />
    );
  };

  calculateLoadingPortStep = (
    status: number,
    from: string,
    departureDate: Date
  ) => {
    const formatedDate = moment(departureDate).format("DD.MM.YYYY");
    if (status === WORKFLOW_STATUSES.CANCELED) {
      return (
        <CustomStep
          title={from}
          subtitle={`${languages("LABEL_ETD")} ${formatedDate}`}
        />
      );
    }
    if (status >= WORKFLOW_STATUSES.TRANSIT_IN_PROGRESS) {
      return (
        <CustomStep
          title={from}
          subtitle={`${languages("LABEL_ATD")} ${formatedDate}`}
          status="completed"
        />
      );
    }
    if (status >= WORKFLOW_STATUSES.ARRIVED_IN_POL) {
      return (
        <CustomStep
          title={from}
          subtitle={`${languages("LABEL_ETD")} ${formatedDate}`}
          status="active"
        />
      );
    }
    return (
      <CustomStep
        title={from}
        subtitle={`${languages("LABEL_ETD")} ${formatedDate}`}
      />
    );
  };

  calculateFreightStep = (status: number, voyages: Voyage[]) => {
    const showArrivalDates =
      voyages.some((v) => v.End !== undefined) && voyages.length > 1;
    const renderPopupContent = () => {
      const lines = voyages.map((v) => {
        if (v.End) {
          const date = new Date(v.End.toString());
          return `${v.Reference} : ${date.getDate()}.${
            date.getMonth() + 1
          }.${date.getFullYear()}`;
        }
        return v.Reference;
      });
      return (
        <ul className="list-unstyled">
          {lines.map((l) => (
            <li>{l}</li>
          ))}
        </ul>
      );
    };

    if (status === WORKFLOW_STATUSES.CANCELED) {
      return (
        <CustomStep
          title={`${languages("TEXT_SEAFREIGHT")}`}
          subtitle={`${voyages.map((v) => v.Reference)}`}
        />
      );
    }
    if (status >= WORKFLOW_STATUSES.DISCHARGED) {
      if (showArrivalDates) {
        return (
          <Popup
            trigger={
              <CustomStep
                title={`${languages("TEXT_SEAFREIGHT")}`}
                subtitle={`${voyages.map((v) => v.Reference)}`}
                status="completed"
              />
            }
            size="huge"
            header={languages("LABEL_ARRIVALDATE", voyages.length)}
            hideOnScroll
            content={renderPopupContent()}
          />
        );
      }
      return (
        <CustomStep
          title={`${languages("TEXT_SEAFREIGHT")}`}
          subtitle={`${voyages.map((v) => v.Reference)}`}
          status="completed"
        />
      );
    }
    if (status === WORKFLOW_STATUSES.TRANSIT_IN_PROGRESS) {
      return (
        <CustomStep
          title={`${languages("TEXT_SEAFREIGHT")}`}
          subtitle={`${voyages.map((v) => v.Reference)}`}
          status="active"
        />
      );
    }
    if (showArrivalDates) {
      return (
        <Popup
          trigger={
            <CustomStep
              title={`${languages("TEXT_SEAFREIGHT")}`}
              subtitle={`${voyages.map((v) => v.Reference)}`}
            />
          }
          size="huge"
          header={languages("LABEL_DOCSANDPAYMENTS")}
          hideOnScroll
          content={renderPopupContent()}
        />
      );
    }
    return (
      <CustomStep
        title={`${languages("TEXT_SEAFREIGHT")}`}
        subtitle={`${voyages.map((v) => v.Reference)}`}
      />
    );
  };

  calculateDischargePortStep = (
    status: number,
    to: string,
    arrivalDate: Date
  ) => {
    const formatedDate = moment(arrivalDate).format("DD.MM.YYYY");
    if (status === WORKFLOW_STATUSES.CANCELED) {
      return (
        <CustomStep
          title={to}
          subtitle={`${languages("LABEL_ETA")} ${formatedDate}`}
        />
      );
    }
    if (status >= WORKFLOW_STATUSES.DISCHARGED) {
      return (
        <CustomStep
          title={to}
          subtitle={`${languages("LABEL_ATA")} ${formatedDate}`}
          status="completed"
        />
      );
    }
    return (
      <CustomStep
        title={to}
        subtitle={`${languages("LABEL_ETA")} ${formatedDate}`}
      />
    );
  };

  calculateDocumentsStep = (
    status: number,
    isPayed: boolean,
    canSendCustomsRequest: boolean,
    bolType: string,
    isCustomerCashPayer: boolean,
    customsClearanceDate?: Date,
    blSurrenderDate?: Date
  ) => {
    const hasOriginals =
      (bolType === "G" && blSurrenderDate !== null) || bolType !== "G";
    const paymentStatus =
      (isPayed && isCustomerCashPayer) || !isCustomerCashPayer;
    const paymentItemStatus = isPayed ? "completed" : "attention";
    const itemList: any[] = isCustomerCashPayer
      ? [{ name: languages("LABEL_PAYMENT"), status: paymentItemStatus }]
      : [];

    const renderPaperclip = () => (
      <Popup
        trigger={
          <a
            className="secondaryIcon"
            href={`/shipments/${this.props.jobReference}?tab=documents`}
          >
            <Icon name="paperclip" />
          </a>
        }
        hideOnScroll
        content={languages("TEXT_BOOKING_HAS_DOCUMENTS")}
      />
    );

    if (status === WORKFLOW_STATUSES.CANCELED) {
      return (
        <CustomStep
          title={languages("LABEL_DOCSANDPAYMENTS")}
          subtitle={`${languages("LABEL_NOTFINISHED")}`}
        />
      );
    }
    if (
      (customsClearanceDate || !this.state.isImport) &&
      hasOriginals &&
      paymentStatus
    ) {
      // No customs check on exported shipments
      if (this.state.isImport) {
        itemList.push({
          name: languages("LABEL_CUSTOMS"),
          status: "completed",
        });
      }
      if (bolType === "G") {
        itemList.push({
          name: languages("LABEL_BOLDELIVERY"),
          status: "completed",
        });
      }
      if (itemList.length > 0) {
        return (
          <Popup
            trigger={
              <CustomStep
                title={languages("LABEL_DOCSANDPAYMENTS")}
                subtitle={languages("LABEL_FINISHED")}
                status="completed"
              >
                {this.props.hasDocuments && renderPaperclip()}
              </CustomStep>
            }
            size="huge"
            header={languages("LABEL_DOCSANDPAYMENTS")}
            hideOnScroll
            content={<DocumentsList items={itemList} />}
          />
        );
      }
      return (
        <CustomStep
          title={languages("LABEL_DOCSANDPAYMENTS")}
          subtitle={languages("LABEL_FINISHED")}
          status="completed"
        >
          {this.props.hasDocuments && renderPaperclip()}
        </CustomStep>
      );
    }
    if (
      (this.state.isImport && canSendCustomsRequest && !customsClearanceDate) ||
      !hasOriginals ||
      !paymentStatus
    ) {
      if (this.state.isImport) {
        if (customsClearanceDate) {
          itemList.push({
            name: languages("LABEL_CUSTOMS"),
            status: "completed",
          });
        } else if (canSendCustomsRequest && !customsClearanceDate) {
          itemList.push({
            name: languages("LABEL_CUSTOMS"),
            status: "attention",
          });
        } else {
          itemList.push({ name: languages("LABEL_CUSTOMS") });
        }
      }
      if (bolType === "G") {
        hasOriginals
          ? itemList.push({
              name: languages("LABEL_BOLDELIVERY"),
              status: "completed",
            })
          : itemList.push({
              name: languages("LABEL_BOLDELIVERY"),
              status: "attention",
            });
      }
      return (
        <Popup
          trigger={
            <CustomStep
              title={languages("LABEL_DOCSANDPAYMENTS")}
              subtitle={`${languages("LABEL_NOTFINISHED")}`}
              status="attention"
            >
              {this.props.hasDocuments && renderPaperclip()}
            </CustomStep>
          }
          size="huge"
          header={languages("LABEL_DOCSANDPAYMENTS")}
          hideOnScroll
          content={<DocumentsList items={itemList} />}
        />
      );
    }
    return (
      <CustomStep
        title={languages("LABEL_DOCSANDPAYMENTS")}
        subtitle={`${languages("LABEL_NOTFINISHED")}`}
      >
        {this.props.hasDocuments && renderPaperclip()}
      </CustomStep>
    );
  };

  calculateDeliveryStep = (status: number) => {
    if (status === WORKFLOW_STATUSES.CANCELED) {
      return (
        <CustomStep
          title={languages("LABEL_DELIVERY")}
          subtitle={languages("LABEL_NOTFINISHED")}
        />
      );
    }
    if (status >= WORKFLOW_STATUSES.DELIVERED) {
      return (
        <CustomStep
          title={languages("LABEL_DELIVERY")}
          subtitle={languages("LABEL_FINISHED")}
          status="completed"
        />
      );
    }
    if (status >= WORKFLOW_STATUSES.DELIVERY_INPROGRESS) {
      return (
        <CustomStep
          title={languages("LABEL_DELIVERY")}
          subtitle={languages("LABEL_PROCESSING")}
          status="active"
        />
      );
    }
    if (status >= WORKFLOW_STATUSES.TRANSIT_IN_PROGRESS) {
      return (
        <CustomStep
          title={languages("LABEL_DELIVERY")}
          subtitle={languages("LABEL_NOTFINISHED")}
          status="attention"
        />
      );
    }
    return (
      <CustomStep
        title={languages("LABEL_DELIVERY")}
        subtitle={languages("LABEL_NOTFINISHED")}
      />
    );
  };
}
