import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Form,
  Grid,
  GridColumn,
  GridRow,
  DropdownItemProps,
} from "semantic-ui-react";
import { Input, Dropdown, DropdownSearchAsync, Button } from "Component";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as _ from "lodash";
import styles from "./index.less";
import { languages, ShipmentRegistryService } from "Services";
import {
  multipleEmailSchema,
  phoneNumberSchema,
} from "../../../validators/yupHelpers";

interface PartnerInfoFormProps {
  onClose: Function;
  saveAddress: any;
  address: PartnerAddress;
  options: any[];
  optionLabelFields: string[];
  searchable: boolean;
  newPartner: boolean;
  additionalRequiredFields: string[];
  // Try to force the data to be more correct by hiding and autopopulating Address2 and Address4
  forceCorrectData: boolean;
}

// Validation schema
const createValidationSchema = (requiredFields: string[]) => {
  /** Returns a validator for the given field
   * based on if the field is required or not
   * @param name Name of the field
   * @param requiredValidator Optional parameter with the required validator
   * @param otherValidator Optional parameter with the optional validator
   */
  const getValidator = (
    name: string,
    requiredValidator?: any,
    otherValidator?: any
  ) => {
    if (!requiredFields) {
      return otherValidator ? otherValidator : yup.string().optional();
    }
    const field = requiredFields.find((item) => item === name);
    return field
      ? requiredValidator
        ? requiredValidator
        : yup.string().required()
      : otherValidator
      ? otherValidator
      : yup.string().optional();
  };
  return yup.object().shape(
    {
      FullName: yup.string().required(),
      Address1: getValidator("Address1"),
      Address2: getValidator("Address2"),
      PostCode: getValidator("PostCode"),
      Address3: getValidator("Address3"),
      Address4: getValidator("Address4"),
      Address5: getValidator("Address5"),
      PointCode: yup.string().required(),
      ContactName: getValidator("ContactName"),
      ContactEmail: getValidator(
        "ContactEmail",
        multipleEmailSchema.required(),
        multipleEmailSchema.when(["ContactName", "ContactNumber"], {
          is: (name, phone) => name !== "" && phone === "",
          then: yup.string().required(),
        })
      ),
      ContactNumber: getValidator(
        "ContactNumber",
        phoneNumberSchema.required(),
        phoneNumberSchema.when(["ContactName", "ContactEmail"], {
          is: (name, email) => name !== "" && email === "",
          then: yup.string().required(),
        })
      ),
    },
    [["ContactEmail", "ContactNumber"]]
  );
};

const setValueOptions = {
  shouldDirty: true,
  shouldValidate: true,
};

export default (props: PartnerInfoFormProps) => {
  const schema = createValidationSchema(props.additionalRequiredFields);
  const { address } = props;
  const { handleSubmit, errors, control, formState, setValue, trigger } =
    useForm({
      mode: "all",
      resolver: yupResolver(schema),
      defaultValues: {
        FullName: address && address.FullName ? props.address.FullName : "",
        Address1: address && address.Address1 ? props.address.Address1 : "",
        Address2: address && address.Address2 ? address.Address2 : "",
        Address3: address && address.Address3 ? address.Address3 : "",
        Address4: address && address.Address4 ? address.Address4 : "",
        Address5: address && address.Address5 ? address.Address5 : "",
        PostCode: address && address.PostCode ? address.PostCode : "",
        ContactName: address && address.ContactName ? address.ContactName : "",
        ContactEmail:
          address && address.ContactEmail ? address.ContactEmail : "",
        ContactNumber:
          address && address.ContactNumber ? address.ContactNumber : "",
        PointCode: address && address.PointCode ? address.PointCode : "",
      },
    });
  const [partner, setPartner] = useState<string>();
  const [partnerIndex, setPartnerIndex] = useState<number>();
  const [options, setOptions] = useState([] as DropdownItemProps[]);

  const [selectedPointCode, setSelectedPointCode] = useState("");
  const [pointCodeOptions, setPointCodeOptions] = useState(
    [] as DropdownItemProps[]
  );
  const [ports, setPorts] = useState([] as any[]);
  const [pointCodeLoading, setPointCodeLoading] = useState(false);

  const [showAdditionalFields, setShowAdditionalFields] = useState(false);

  useEffect(() => {
    if (address) {
      setPartner(address.PartnerCode);
      setPartnerIndex(
        props.options.findIndex(
          (item) =>
            item.PartnerCode === address.PartnerCode &&
            (item.AddressCode === address.AddressCode ||
              address.AddressCode === undefined)
        )
      );
      if (props.newPartner || props.forceCorrectData) {
        setPointCode(address.PointCode);
        setValue("PointCode", address.PointCode);
      }
      setShowAdditionalFields(
        !(
          _.isEmpty(_.trim(address.Address3)) &&
          _.isEmpty(_.trim(address.Address5))
        )
      );
    }
    if (!props.newPartner) {
      setOptions(createOptions());
    }
  }, []);

  const onSubmit = (values: any) => {
    let data = null;
    if (props.newPartner) {
      data = values;
      data.PointCode = selectedPointCode;
    } else {
      data = _.clone(partnerIndex !== undefined && props.options[partnerIndex]);
      // Update fields in case they were updated
      for (const field in values) {
        data[field] = values[field];
      }
    }
    props.saveAddress(data);
  };

  const onClose = (event: any) => {
    event.preventDefault();
    props.onClose();
  };

  const createOptions = (): DropdownItemProps[] => {
    const createLabel = (item: PartnerAddress) => {
      return (
        <>
          <div className="primary-info">
            <span className="name">{item.FullName}</span>
            <span className="code">({item.PartnerCode})</span>
          </div>
          <small className="additional-info">
            {props.optionLabelFields &&
              props.optionLabelFields.map((field: string, i: number) => {
                return <p key={i}>{item[field]}</p>;
              })}
          </small>
        </>
      );
    };
    return props.options.map((item: PartnerAddress, i: number) => {
      return {
        key: i,
        value: i,
        text: createLabel(item),
      } as DropdownItemProps;
    });
  };

  const renderFooter = () => {
    return (
      <div className="footer">
        <div className="right">
          <Button type="button" onClick={onClose}>
            {languages("LABEL_CANCEL")}
          </Button>
          <Button
            primary
            type="submit"
            className="primary"
            iconClass="fa fa-floppy-o"
            disabled={!formState.touched || !formState.isValid}
          >
            {languages("LABEL_SAVE")}
          </Button>
        </div>
      </div>
    );
  };

  const renderInputColumn = (
    name: string,
    width: any,
    additionalProps: any
  ) => {
    const { handleBlur, ...props } = additionalProps;
    return (
      <GridColumn width={width}>
        <Controller
          control={control}
          name={name}
          render={({ onBlur, ...rest }) => (
            <Input
              error={_.get(errors, name) !== undefined}
              onBlur={() => {
                handleBlur ? handleBlur() : null;
                onBlur();
              }}
              {...rest}
              {...props}
            />
          )}
          id={name}
        />
      </GridColumn>
    );
  };

  const partnerSearch = (items: any[], searchString: string) => {
    return props.options
      .map((item, i) => {
        if (
          item.FullName.toLowerCase().includes(searchString.toLowerCase()) ||
          item.PartnerCode.toLowerCase().includes(searchString.toLowerCase())
        ) {
          return i;
        }
      })
      .filter((index) => index !== undefined)
      .map((index) => index && items[index]);
  };

  const populateFormWithPartner = (partner: any) => {
    setPartner(partner.PartnerCode);
    if (props.forceCorrectData && partner.PointCode) {
      setPointCode(partner.PointCode);
    }
    for (const field in schema.fields) {
      setValue(field, partner[field] ? partner[field] : "", setValueOptions);
    }
    setShowAdditionalFields(
      !(
        _.isEmpty(_.trim(partner.Address3)) &&
        _.isEmpty(_.trim(partner.Address5))
      )
    );
  };

  const setPointCode = (pointCode: string) => {
    setPointCodeLoading(true);
    ShipmentRegistryService.searchShippingPorts(pointCode, null)
      .then((data) => {
        const point = data.find((x) => x.PointCode === pointCode);
        if (point) {
          setPorts([point]);
          setPointCodeOptions(createPointCodeOptions([point]));
          setSelectedPointCode(pointCode);
          setPointCodeLoading(false);
        }
      })
      .catch(() => {
        setPointCodeLoading(false);
      });
  };

  const createPointCodeOptions = (ports: Port[]) => {
    return ports.map((item: Port, i: number) => {
      return {
        key: i,
        content: `${item.FullName}, ${item.Country} (${item.PointCode})`,
        text: `${item.FullName}, ${item.Country} (${item.PointCode})`,
        value: item.PointCode,
      } as DropdownItemProps;
    });
  };

  const searchPointCodes = async (searchString: string): Promise<void> => {
    await ShipmentRegistryService.searchShippingPorts(searchString).then(
      (data: any[]) => {
        setPorts(data);
        setPointCodeOptions(createPointCodeOptions(data));
      }
    );
  };

  const getStringFromPointCode = (portCode: string): string => {
    const port = ports.find((item: Port) => item.PortCode === portCode);
    return port ? `${port.FullName}, ${port.Country} (${port.PointCode})` : "";
  };

  const onPointCodeChanged = (value: string) => {
    const port = ports.find((item) => item.PointCode === value);
    setValue("Address4", port.Country, setValueOptions);
    setValue("Address2", port.FullName, setValueOptions);
    setValue("PointCode", value, setValueOptions);
    setSelectedPointCode(value);
  };

  const onPartnerSelected = (value: number) => {
    const partner = props.options[value];
    if (partner) {
      setPartnerIndex(value);
      populateFormWithPartner(partner);
    }
  };

  const onPointCodeCleared = () => {
    setValue("Address4", "", setValueOptions);
    setValue("Address2", "", setValueOptions);
    setValue("PointCode", "", setValueOptions);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Grid>
        {props.searchable && !props.newPartner && (
          <GridRow columns="1">
            <GridColumn>
              <Dropdown
                search={partnerSearch}
                onChange={onPartnerSelected}
                options={options}
                value={partnerIndex}
                id="partnerSelect"
                label={languages("LABEL_SEARCH")}
                selectOnBlur={false}
                selectOnNavigation={false}
                closeOnChange={true}
                searchInput={{ autoFocus: true }}
              />
            </GridColumn>
          </GridRow>
        )}
        <GridRow columns="1">
          <GridColumn>
            <label className="pull-right partner-code-label">
              {partner && partner}
            </label>
            {renderInputColumn("FullName", null, {
              label: languages("TEXT_NAME"),
              placeholder: languages("TEXT_NAME"),
              disabled: !partner && !props.newPartner,
              maxLength: 35,
            })}
          </GridColumn>
        </GridRow>
        {(props.newPartner || props.forceCorrectData) && (
          <GridRow columns="1">
            <GridColumn className="pointcode-search">
              <label className="label">{languages("LABEL_TOWN_CITY")}</label>
              <DropdownSearchAsync
                value={selectedPointCode}
                type="text"
                onChange={onPointCodeChanged}
                search={searchPointCodes}
                options={pointCodeOptions}
                onClearOptions={() => setPointCodeOptions([])}
                onClearValue={onPointCodeCleared}
                minCharacters={3}
                lazyLoad={true}
                onSearchChange={(value: string) => setSelectedPointCode(value)}
                text={getStringFromPointCode(selectedPointCode)}
                loading={pointCodeLoading}
                disabled={!partner && !props.newPartner}
                onBlur={(event: any, data: any) => {
                  trigger("PointCode");
                }}
                error={_.get(errors, "PointCode") !== undefined}
                placeholder={languages("LABEL_TOWN_CITY")}
              ></DropdownSearchAsync>
            </GridColumn>
          </GridRow>
        )}
        <GridRow className="u-flexAlignItemsEnd">
          {renderInputColumn("Address1", 10, {
            label: languages("LABEL_ADDRESS"),
            placeholder: languages("LABEL_ADDRESS"),
            disabled: !partner && !props.newPartner,
            maxLength: 35,
          })}
          {renderInputColumn("PostCode", 6, {
            placeholder: languages("LABEL_POSTALCODE"),
            disabled: !partner && !props.newPartner,
            maxLength: 10,
          })}
        </GridRow>
        <GridRow
          className={
            props.newPartner || props.forceCorrectData ? "u-hidden" : ""
          }
        >
          {renderInputColumn("Address2", 10, {
            placeholder: languages("LABEL_TOWN_CITY"),
            disabled: !partner && !props.newPartner,
            maxLength: 35,
          })}
          {renderInputColumn("Address4", 6, {
            placeholder: languages("LABEL_COUNTRY"),
            disabled: !partner && !props.newPartner,
            maxLength: 35,
          })}
        </GridRow>
        <GridRow columns={1}>
          {!showAdditionalFields && (
            <Button
              type="button"
              onClick={() => {
                setShowAdditionalFields(true);
              }}
              disabled={!partner && !props.newPartner}
              className="additional-info-button"
            >
              <i className="fa fa-plus u-paddingRight5"></i>
              {languages("LABEL_ADDITIONAL_INFO")}
            </Button>
          )}
          {renderInputColumn("Address3", null, {
            placeholder: `${languages("LABEL_DISTRICT")} / ${languages(
              "LABEL_COUNTY"
            )} / ${languages("LABEL_POSTBOX")}`,
            disabled: !partner && !props.newPartner,
            className: `${showAdditionalFields ? "" : "u-hidden"}`,
            maxLength: 35,
          })}
          {
            // Hidden field to hold PointCode value for the form
            renderInputColumn("PointCode", null, {
              className: "u-hidden",
            })
          }
        </GridRow>
        <GridRow columns={1} className={showAdditionalFields ? "" : "u-hidden"}>
          {renderInputColumn("Address5", null, {
            placeholder: languages("LABEL_EXTRA"),
            disabled: !partner && !props.newPartner,
            maxLength: 35,
          })}
        </GridRow>
        <GridRow columns="1">
          {renderInputColumn("ContactName", null, {
            placeholder: languages("LABEL_CONTACT"),
            label: languages("LABEL_CONTACT"),
            disabled: !partner && !props.newPartner,
            maxLength: 35,
          })}
        </GridRow>
        <GridRow>
          {renderInputColumn("ContactEmail", 10, {
            placeholder: languages("LABEL_EMAIL"),
            disabled: !partner && !props.newPartner,
            maxLength: 100,
            handleBlur: () => {
              trigger("ContactNumber");
            },
          })}
          {renderInputColumn("ContactNumber", 6, {
            placeholder: languages("LABEL_PHONE"),
            disabled: !partner && !props.newPartner,
            maxLength: 25,
            handleBlur: () => {
              trigger("ContactEmail");
            },
          })}
        </GridRow>
      </Grid>
      {renderFooter()}
    </Form>
  );
};
