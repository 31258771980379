/**
 * ViewModel for a hazard item
 * This is what is saved in dimension 'Hazardous' array
 */
class HazardViewModel implements HazardousViewModel {
  UNNumber: string;
  UNVariant: string;
  ImdgClass: string;
  Description: string;
  NetWeight: number;
  Weight: number;
  NumUnits: number;
  PackageCode: string;

  constructor(hazard: any) {
    this.UNNumber = hazard.HazardType.UnNumber;
    this.UNVariant = hazard.HazardType.UnVariant;
    this.ImdgClass = hazard.HazardType.ImdgClass;
    this.Description = hazard.HazardType.Description;
    this.NetWeight = Number(hazard.NetWeight);
    this.Weight = Number(hazard.Weight);
    this.NumUnits = Number(hazard.NumUnits);
    this.PackageCode = hazard.PackageCode.PackageCode;
  }
}

export default HazardViewModel;
