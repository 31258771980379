import * as React from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  FormControl,
  Checkbox,
} from "react-bootstrap";
import Dropzone from "react-dropzone";
import {
  DocumentFile,
  CustomsRequestModalState,
  CustomsRequestModalProps,
} from "./interfaces";
import {
  ShipmentDocumentService,
  languages,
  ShipmentService,
  EmailService,
} from "Services";
declare var GlobalConfig: GlobalConfig;

export default class CustomsRequestModal extends React.Component<
  CustomsRequestModalProps,
  CustomsRequestModalState
> {
  state: CustomsRequestModalState = {
    show: this.props.show,
    fetching: false,
    files: [],
    selectedDocuments: [],
  };

  componentDidUpdate(prevProps: any) {
    if (this.props.show !== prevProps.show) {
      this.setState({ show: this.props.show });
    }
  }

  onClose = () => {
    this.setState({ show: false, files: [], selectedDocuments: [] });
    this.props.onClose();
  };

  onFile = (files: DocumentFile[]) =>
    this.setState({ files: [...this.state.files, ...files] });

  onRemoveFile = (index: number) =>
    this.setState({
      files: this.state.files.filter(
        (_: DocumentFile, i: number) => i !== index
      ),
    });

  onFileType = (target: HTMLInputElement, index: number) => {
    this.state.files[index].docType = target.value;

    this.setState({ files: this.state.files });
  };

  canSendCustomsRequest = () => {
    let hasSARN = false;
    let hasCINVorPACK = false;
    this.state.selectedDocuments.forEach((document) => {
      if (document.Type === "SARN") hasSARN = true;
      if (document.Type === "CINV" || document.Type === "PACK") {
        hasCINVorPACK = true;
      }
    });

    this.state.files.forEach((document) => {
      if (document.docType === "SARN") hasSARN = true;
      if (document.docType === "CINV" || document.docType === "PACK") {
        hasCINVorPACK = true;
      }
    });
    return hasSARN && hasCINVorPACK;
  };

  onToggleDocument = (selectedDocument: any) => {
    const isPresent = this.state.selectedDocuments.some((document: any) => {
      return document.UID === selectedDocument.UID;
    });

    if (!isPresent) {
      this.setState({
        selectedDocuments: [
          ...this.state.selectedDocuments,
          ...selectedDocument,
        ],
      });
    } else {
      this.setState({
        selectedDocuments: this.state.selectedDocuments.filter((document) => {
          return document.UID !== selectedDocument.UID;
        }),
      });
    }
  };

  onSubmit = async () => {
    const types = this.state.files.map((file: any) => file.docType);
    try {
      this.setState({ fetching: true });
      let documentListBody: string = `Bókun: <a
        href='${GlobalConfig.ENV.ServiceWeb}/shipments/${this.props.bookingNumber}'
        >
          ${this.props.bookingNumber}
        </a><br>`;
      if (this.state.files.length > 0) {
        await ShipmentDocumentService.postDocuments(
          this.props.bookingNumber,
          types,
          this.state.files
        ).then(async (response: any) => {
          response.data.forEach((document: any) => {
            documentListBody += `${languages(`DOCTYPE_${document.Type}`)}: ${
              GlobalConfig.ENV.ShippingAPI
            }/proxy/documents/file/${document.ID}<br>`;
          });
        });
      }
      this.state.selectedDocuments.forEach((document: any) => {
        documentListBody += `${languages(`DOCTYPE_${document.Type}`)}:\
          <a href=\'${GlobalConfig.ENV.ShippingAPI}${
          document.DocSource === "DORIS"
            ? `/documents/doris/${document.UID}\'>${document.FileName}`
            : `/proxy/documents/file/${document.UID}'\>${document.Description}`
        }</a><br>`;
      });
      const emailVm: EmailVM = {
        To: [GlobalConfig.ENV.CustomsRequestEmail],
        From: this.props.requestorEmail,
        ReplyTo: this.props.requestorEmail,
        Subject: `Tollabeiðni: ${this.props.bookingNumber}`,
        Body: `Beiðni um tollun <br>${documentListBody}`,
      };
      this.onClose();
      await ShipmentService.createRemark(
        this.props.bookingNumber,
        `${languages(`REQUEST_CUSTOMS_CLEARANCE`)}`,
        "Y"
      );
      await EmailService.sendEmail(this.props.bookingNumber, emailVm);
      this.setState({ fetching: false, files: [] });

      this.props.onSuccess();
    } catch (ex) {
      this.setState({ fetching: false });

      this.props.onFailure();
    }
  };

  render() {
    const { files, selectedDocuments } = this.state;
    const { documents } = this.props;
    let documentList;

    if (documents) {
      documentList = documents.map((document: any, i: number) => (
        <Row
          className="sam-file-row sam-file-row-clickable"
          key={i}
          onClick={() => this.onToggleDocument(document)}
        >
          <Col sm={12} className="sam-file-col">
            <span>{languages(`DOCTYPE_${document.Type}`)}</span>
            {document.DocSource === "DORIS" ? (
              <span className="sam-file-inline">{document.FileName}</span>
            ) : (
              <span className="sam-file-inline">{document.Description}</span>
            )}
            <Checkbox
              checked={selectedDocuments.indexOf(document) !== -1}
              readOnly
            />
          </Col>
        </Row>
      ));
    }

    return (
      <Modal
        bsSize="lg"
        show={this.state.show}
        className="sam-modal"
        onHide={() => this.onClose()}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-top">
            <span className="sam-head-2">
              {languages("REQUESTTYPE_CUSTCLR")}
            </span>
          </Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body className="modal-content-space modal-content-bg">
            <Row>
              <Col sm={12} className="modal-content-infotext">
                {languages("TEXT_CUSTOMSCLEARANCE")}{" "}
                <a href={`mailto:${GlobalConfig.ENV.CustomsRequestEmail}`}>
                  {GlobalConfig.ENV.CustomsRequestEmail}
                </a>
              </Col>
            </Row>

            <Row>
              <Col sm={2}>
                <span className="boxTitle">
                  {languages("LABEL_BOOKINGREFERENCE")}
                </span>
                {this.props.bookingNumber}
              </Col>
              <Col sm={5}>
                <span className="boxTitle">{languages("LABEL_REQUESTOR")}</span>
                {this.props.requestorName}
              </Col>
              <Col sm={5}>
                <span className="boxTitle">{languages("LABEL_EMAIL")}</span>
                {this.props.requestorEmail}
              </Col>
            </Row>
            <Row>
              <div className="document-types-description">
                <p>{languages("TEXT_CUSTOMSCLEARANCEARRIVALNOTICE")}</p>
                <span>{languages("TEXT_CUSTOMCLEARANCEFILES")}</span>
              </div>
            </Row>

            {documentList}
            {files.map((file: any, i: number) => (
              <Row className="sam-file-row" key={i}>
                <Col sm={12} className="sam-file-col">
                  <span>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      onChange={(e) =>
                        this.onFileType(e.target as HTMLInputElement, i)
                      }
                      value={file.docType || ""}
                    >
                      <option
                        disabled
                        value={""}
                        label={languages("LABEL_SELECTDOCTYPE")}
                      />
                      <option value="SARN" label={languages("DOCTYPE_SARN")} />
                      <option value="CINV" label={languages("DOCTYPE_CINV")} />
                      <option value="EUR1" label={languages("DOCTYPE_EUR1")} />
                      <option value="PACK" label={languages("DOCTYPE_PACK")} />
                      <option value="REGP" label={languages("DOCTYPE_REGP")} />
                    </FormControl>
                  </span>
                  <span className="sam-file-inline">{file.name}</span>
                  <div className="close" onClick={() => this.onRemoveFile(i)}>
                    ×
                  </div>
                </Col>
              </Row>
            ))}

            <Row>
              <Dropzone
                className="sam-document-upload"
                activeClassName="file-hover"
                onDropAccepted={(files: DocumentFile[]) => this.onFile(files)}
              >
                <p>{languages("TEXT_DRAG_FILES_OR_CLICK_HERE")}</p>
              </Dropzone>
            </Row>
          </Modal.Body>
          <Modal.Footer className="modal-footer-space">
            <Button className="pull-left" onClick={() => this.onClose()}>
              {languages("LABEL_CANCEL")}
            </Button>
            <Button
              bsStyle="primary"
              disabled={!this.canSendCustomsRequest()}
              onClick={() => this.onSubmit()}
            >
              {languages("LABEL_SUBMIT")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}
