import * as angular from "angular";
import * as moment from "moment";

import { ShipmentService } from "Services";

import * as modalTemplate from "./remarkModal.html";

const RemarkModal: ng.IComponentOptions = {
  template: `${modalTemplate}`,
  bindings: {
    close: "&",
    dismiss: "&",
    resolve: "<"
  },
  controller: class RemarkModal {
    private close: Function;
    private dismiss: Function;
    private resolve: {
      remark: any;
    };

    remark = angular.copy(this.resolve.remark);
    isCreate = !this.remark.RemarkText;
    headerText = this.isCreate ? "LABEL_ADD_REMARK" : "LABEL_EDIT_REMARK";
    serverCall = false;

    validDateTime(dateString: string): boolean {
      return !!dateString && dateString !== "1970-01-01T00:00:00";
    }

    formatDateTime(dateTimeStr: string): string {
      const m = moment(dateTimeStr);

      return this.validDateTime(dateTimeStr) && m.isValid()
        ? m.format("DD.MM.YYYY - HH:mm:ss")
        : "";
    }

    confirm(): void {
      this.serverCall = true;

      if (this.isCreate) {
        ShipmentService.createRemark(
          this.remark.JobReference,
          this.remark.RemarkText
        ).then(() => {
          this.serverCall = false;
          this.close({
            $value: {
              created: true,
              remark: this.remark
            }
          });
        });
      } else {
        ShipmentService.editRemark(
          this.remark.JobReference,
          this.remark.RemarkSequence,
          this.remark.RemarkText
        ).then(() => {
          this.serverCall = false;
          this.close({
            $value: {
              updated: true,
              remark: this.remark
            }
          });
        });
      }
    }
  }
};

angular.module("serviceWebApp").component("remarkModal", RemarkModal);
