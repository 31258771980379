import * as angular from "angular";
import * as moment from "moment";
import { $timeout, $templateCache } from "ngimport";
import { Number, Object } from "es6-shim";

import ContainerService from "Services/ContainerService";
import { SamskipNotify, TranslationService, ShipmentService } from "Services";

import * as VGMTableFCLTemplate from "./vgm-table-fcl.html";
import * as VGMTableLCLTemplate from "./vgm-table-lcl.html";
import * as VGMDatePickerTemplate from "./vgm-datepicker.tpl.html";
import * as modalTemplate from "./vgmmodal.html";

import { UserService } from "Services";

const ShipmentsVGM: ng.IComponentOptions = {
  template: `${modalTemplate}`,
  bindings: {
    close: "&",
    dismiss: "&",
    resolve: "<"
  },
  controller: class ShipmentsVGM {
    private close: Function;
    private dismiss: Function;
    private resolve: {
      displayMode: any;
      vgmData: any;
      shippingType: string;
    };

    displayMode: any;
    vgmData: any;
    shippingType: string;
    jobReference: string;
    itemRowForms: any[];
    itemFormNamePrefix = "ItemForm_";
    datePickerFormat = "DD.MM.YYYY";
    itemDateDisplayFormat = "DD.MM.YY";
    modalTitle: string;
    weighingMethods: string[];
    vgmMaxDate: moment.Moment;
    isBeforeCutoffDate: boolean;
    updatePending: boolean;
    items: any[];
    form: any;

    constructor() {
      this.displayMode = this.resolve.displayMode;
      this.vgmData = this.resolve.vgmData;
      this.shippingType = this.resolve.shippingType;
      $templateCache.put(
        "vgmModalTemplate",
        this.shippingType === "F" ? VGMTableFCLTemplate : VGMTableLCLTemplate
      );
      $templateCache.put("vgmDatePickerTemplate", VGMDatePickerTemplate);

      this.modalTitle = this.vgmData.Title
        ? this.vgmData.Title
        : this.vgmData.JobReference;
      this.jobReference = this.vgmData.JobReference;
      this.weighingMethods = ["1", "2"];
      this.vgmMaxDate = moment().endOf("day");
      this.isBeforeCutoffDate = moment().isBefore(this.vgmData.CutOffDate);
      if (this.vgmData.Items != null) {
        this.items = this.vgmData.Items;
        this.initializeItemRows(this.items);
      }
    }

    /**
     * Callback for when VGM-date is selected for an item using the
     * samDateTimePicker component. Updates the item's Date and "private"
     *                              _formattedDate properties.
     * @param  {Number} itemIndex   The $index value of the item whose date
     *                              was changed.
     * @param  {String} newDate     The selected date as a string
     */
    itemDateChanged = (itemIndex: number, newDate: string): void => {
      const item = this.items[itemIndex];
      const date = moment(newDate, this.datePickerFormat);
      if (date.isValid()) {
        item.Date = date.format();
        item._formattedDate = date.format(this.itemDateDisplayFormat);
        this.items[itemIndex]._datePickerOpen = false;
      } else {
        console.error(`${date} is not a valid Date`);
      }
    };

    /**
     * Callback for when the user clicks cancel in the samDateTimePicker
     * component. Just closes the popover.
     * @param  {Number} itemIndex   The $index value of the item whose date
     */
    closeDatePicker = (itemIndex: number): void => {
      this.items[itemIndex]._datePickerOpen = false;
    };

    /**
     * Submit VGM data for a booking
     */
    submit = (): void => {
      const requestItems = this.prepareItemsForRequest(this.items);
      if (this.vgmData.submitHandler) {
        this.vgmData.submitHandler(requestItems);
        this.close();
        return;
      }
      this.updatePending = true;
      ShipmentService.updateVgmInfo(this.jobReference, requestItems)
        .then((data: any) => {
          this.close();
          this.highlightUpdateSuccess(data);
        })
        .catch((err: any) => {
          const toastrTitle = "ERROR_UPDATING_VGM_INFO";
          SamskipNotify.displayError(err, toastrTitle);
        })
        .then(() => {
          this.updatePending = false;
        });
    };

    /**
     * For FCL-bookings:
     *     When weighing-method 1 is selected and a container-number has
     *     been selected, the user can enter the container's total weight and
     *     the cargo-weight input should is disabled (the value is calculated).
     * For LCL-bookings:
     *     The total weight does not apply for LCL-bookings.
     * @param  {Object} item    A VGM-item
     * @return {bool}           Returns true if the input for total-weight
     *                                  should be disabled.
     */
    totalWeightDisabled = (item: any): boolean => {
      if (this.displayMode === "L") {
        return false;
      }
      return item.Method !== "1";
    };

    /**
     * When weighing-mode is set to 1 and the total weight has been changed
     * for a VGM-item, the cargo-weight is estimated an assigned to the item's
     * CargoWeight property.
     * NOTE: This only applies for FCL-bookings.
     * @param  {Object} item    The VGM-item whose VGMWeight was updated.
     */
    totalWeightChanged = (item: any): void => {
      if (item.Method !== "1" || this.displayMode === "L") {
        return;
      }

      if (item.VGMWeight === undefined) {
        SamskipNotify.displayError(
          TranslationService.translate("ERROR_VALUE_OUTSIDE_RANGE", {
            min: item._totalWeightMin,
            max: item._totalWeightMax
          })
        );
        item.VGMWeight = null;
        item.CargoWeight = null;
        return;
      }

      item.CargoWeight = Math.round(item.VGMWeight - item.ContainerWeight);
    };

    /**
     * For FCL-bookings:
     *     When weighing-mode is 2 is selected and a container-number has
     *     been entered, the user can enter the cargo-weight (the weight
     *     of the cargo inside the container) and the container's total-weight
     *     is calculated (the user can not enter).
     * @param  {Object} item    A VGM-item
     * @return {bool}           Returns true if the input for cargo-weight
     *                                  should be disabled.
     */
    cargoWeightDisabled = (item: any): boolean => {
      if (this.displayMode === "L") {
        return false;
      }
      return item.Method !== "2";
    };

    /**
     * When weighing-mode is set to 2 and the total weight has been changed
     * for a VGM-item, its total weight is estimated an assigned to the item's
     * VGMWeight property.
     * NOTE: This only applies for FCL-bookings.
     * @param  {Object} item    The VGM-item whose CargoWeight was updated.
     */
    cargoWeightChanged = (item: any): void => {
      if (item.Method !== "2" || this.displayMode === "L") {
        return;
      }
      item.VGMWeight = Math.round(item.CargoWeight + item.ContainerWeight);
    };

    modified = (item: any): void => {
      item.Modified = true;
    };

    /**
     * Checks if any of the sub-forms of vm.form are in valid state via the
     * built-in validators' $valid properties.
     * @return {bool}   Returns true if at lease one VGM-item row is valid
     */
    anyItemRowValid = (): boolean => {
      if (!this.itemRowForms) {
        this.itemRowForms = this.getRowForms();
      }

      let dirtyRows = this.itemRowForms.filter((it: any) => it.$dirty == true);
      return dirtyRows.every((it: any) => it.$valid);
    };

    /**
     * Initialize "private" properties and default values for VGM-items (
     * based on the displayMode/ShippingType.
     * @param  {Array} items    Array of VGM-items
     */
    private initializeItemRows = (items: any[]): void => {
      if (!Array.isArray(items) || items == null) {
        return;
      }
      let itemInitFn: any;
      switch (this.displayMode) {
        case "F":
          itemInitFn = this.initializeFclItem.bind(this);
          break;
        case "L":
          itemInitFn = this.initializeLclItem.bind(this);
          break;
        default:
          // TODO: Handle MTY
          itemInitFn = () => undefined;
          break;
      }
      items.forEach(item => {
        itemInitFn(item);

        // If items' _locked property is falsy has not been set and the
        // current date is past the booking's cutoff-date, it should be readonly.
        if (UserService.isEmployee()) {
          item._locked = item._locked || !this.isBeforeCutoffDate;
        } else {
          item._locked =
            item._locked ||
            !this.isBeforeCutoffDate ||
            item.VGMSender == "E" ||
            item.VGMSender == "S";
        }

        // Set the displayed date for each item if defined
        const date = moment(item.Date);
        if (date.isValid()) {
          item._formattedDate = date.format(this.itemDateDisplayFormat);
        }
      });
    };

    /**
     * Initialize "private" properties and default values for FCL VGM-items
     * @param  {Object} item    A VGM-item for FCL
     */
    private initializeFclItem = (item: any): void => {
      const hasContainerNumber = ContainerService.isValidContainerNumber(
        item.ContainerNumber
      );
      // Every input for an item should be disabled if its container-number
      // has not been set.
      item._locked = !hasContainerNumber;
      // Set max & min values allowed for each item if ContainerNumber is set
      if (hasContainerNumber) {
        this.applyWeightConstraintsForFCL(item);
        this.calculateMissingValuesForFCL(item);
      }
      // Set item's Method to 1 by default if it hasn't been defined
      item.Method = item.Method || "1";
    };

    /**
     * Initialize "private" properties and default values for LCL VGM-items
     * @param  {Object} item    A VGM-item for LCL
     */
    private initializeLclItem = (item: any): void => {
      // TODO: Some init logic for LCL-items
      () => undefined;
    };

    /**
     * Sets max-, and min-weights for each VGM-item for FCL via "private"
     * _minSomething and _maxSomething properties.
     * @param  {Object} item    VGM-item (for FCL)
     */
    private applyWeightConstraintsForFCL = (item: any): void => {
      item._totalWeightMin = item.ContainerWeight;
      item._totalWeightMax = item.ContainerWeight + item.ContainerPayloadWeight;
      item._cargoWeightMin = 1;
      item._cargoWeightMax = item.ContainerPayloadWeight;
    };

    /**
     * Set missing values for VGM-items that have container-numbers
     * @param  {Object} item    VGM-item (for FCL)
     */
    private calculateMissingValuesForFCL = (item: any): void => {
      if (
        !(
          item.ContainerNumber &&
          item.ContainerWeight &&
          item.ContainerPayloadWeight
        )
      ) {
        return;
      }
      if (item.CargoWeight == null && Number.isFinite(item.VGMWeight)) {
        item.CargoWeight = item.VGMWeight - item.ContainerWeight;
      } else if (item.VGMWeight == null && Number.isFinite(item.CargoWeight)) {
        item.VGMWeight = item.CargoWeight + item.ContainerWeight;
      }
    };

    /**
     * Finds each ngForm that is a child of vm.form and whose name attribute
     * stars with _itemFormNamePrefix.
     * NOTE: Each VGM-item row's ngForm doesn't get initialized until after
     *  vm.form's FormController has been insantiated.
     * @return {Array}  Returns an array of references to the ngForm child-
     *                          instances of vm.form, which can be used to
     *                          validate each VGM-item row.
     */
    private getRowForms = (): any[] => {
      const items = Object.keys(this.form)
        .filter(key => {
          return key.indexOf(this.itemFormNamePrefix) > -1;
        })
        .map(key => {
          return this.form[key];
        });

      return items;
    };

    /**
     * Pre-request cleanup of the items to send only the required properties
     * to the server.
     * @param  {Array} items    Array if VGM-items.
     * @return {Array}          Returns a new array where any redundant
     *                          properties have been stripped from each item.
     */
    private prepareItemsForRequest = (items: any): any[] => {
      let viewModelProperties = [
        "AuthNo",
        "CargoWeight",
        "Date",
        "DetailSequence",
        "DimensionSequence",
        "EquipmentSequence",
        "Signature",
        "VGMSender",
        "Modified"
      ];
      if (this.displayMode === "F") {
        viewModelProperties = viewModelProperties.concat([
          "ContainerNumber",
          "ContainerPayloadWeight",
          "ContainerWeight",
          "Method",
          "VGMWeight"
        ]);
      }
      if (this.shippingType === "L") {
        viewModelProperties = viewModelProperties.concat([
          "PackageCode",
          "Description"
        ]);
      }
      return items
        .filter((item: any) => this.hasRequiredProperties(item))
        .map((item: any) => {
          const newObj = {};
          viewModelProperties.forEach((i: any) => {
            if (item.hasOwnProperty(i)) {
              newObj[i] = item[i];
            }
          });
          return newObj;
        });
    };

    private hasRequiredProperties = (item: any): boolean => {
      let requiredViewModelProperties = ["CargoWeight", "Date", "Signature"];
      let result = true;
      requiredViewModelProperties.forEach(property => {
        if (!item[property]) result = false;
      });
      return result;
    };

    /**
     * Shows a toastr when VGM-info has been updated, displaying how many
     * of the original items were updated.
     * NOTE: This functionality must be inside ShipmentsVGMModalController
     * and not inside the $uibModal's result-handler in case the user closes
     * the modal before we get a response from the server. The $timeout is
     * required when the modal is closed on its own (not by the user) because
     * of the animation. The toastr would otherwise be hidden.
     * @param  {Object} errorsObject    An object containing the errors
     *                                  for each of the objects that were
     *                                  not updated.
     */
    private highlightUpdateSuccess = (errorsObject: any[]): void => {
      const itemsTotal = this.items.length;
      const itemsUpdated = itemsTotal - errorsObject.length;
      const message = TranslationService.translate(
        "TEXT_X_OF_Y_RECORDS_UPDATED",
        {
          x: itemsUpdated,
          y: itemsTotal
        }
      );

      $timeout(() => {
        SamskipNotify.displaySuccess(message, "LABEL_VGM_INFO");
      }, 500);
    };
  }
};

angular.module("serviceWebApp").component("shipmentsVGM", ShipmentsVGM);
