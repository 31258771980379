export const BOOKING_CREATION = {
  Shipper: {
    FullName: true,
    Address1: true,
    Address2: true,
    PostCode: true
  },

  Consignee: {
    FullName: true,
    Address1: true,
    Address2: true,
    PostCode: true
  },

  PLR: {
    FullName: true,
    Address1: true,
    Address2: true,
    PostCode: true,
    ContactName: true,
    ContactNumber: true,
    RequestDate: true
  },

  PFD: {
    FullName: true,
    Address1: true,
    Address2: true,
    PostCode: true,
    ContactName: true,
    ContactNumber: true,
    RequestDate: true
  },

  Notifier: {
    FullName: true,
    ContactName: true,
    ContactNumber: true,
    ContactEmail: true
  }
};
