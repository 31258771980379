import * as angular from "angular";

import * as modalTemplate from "./registrationErrorModal.html";

const RegistrationError: ng.IComponentOptions = {
  template: `${modalTemplate}`,
  bindings: {
    close: "&",
    resolve: "<"
  },
  controller: class RegistrationError {
    private close: Function;
    private resolve: {
      resultText: string;
    };

    resultText: string;

    constructor() {
      this.resultText = this.resolve.resultText;
    }
  }
};

angular
  .module("serviceWebApp")
  .component("registrationError", RegistrationError);
