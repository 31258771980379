declare var GlobalConfig: GlobalConfig;

import { UtilityFunctions } from "Utilities";
import WarehouseService from "Services/WarehouseService";
import { PALLET_STATUS_MAP } from "Constants/WarehouseConstants";

function shipmentsSingleBookingUnitsController($scope: any) {
  const vm = this;

  /** Initial values for the controller */
  vm.cntOrder = "ContainerNumber";
  vm.pltOrder = "PalletId";

  vm.cntReverse = false;
  vm.pltReverse = false;

  vm.orderPlt = function orderPlt(columnName: string): void {
    vm.pltOrder = columnName;
    vm.pltReverse = !vm.pltReverse;
  };

  const oldWebUrl = GlobalConfig.ENV.OldServiceWeb;
  let accordionToggle = true;

  $scope.toggleMinus = function toggleMinus(
    $event: ng.IAngularEvent,
    unit: any
  ): void {
    if (accordionToggle) {
      unit.open = !unit.open;
    } else {
      accordionToggle = true;
    }
  };

  // Maps case status codes from Manhattan to codes that are used in translations
  $scope.palletStatusMap = PALLET_STATUS_MAP;
}

shipmentsSingleBookingUnitsController.$inject = ["$scope"];
export default shipmentsSingleBookingUnitsController;
