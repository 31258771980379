exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index-modules__modal___2q3Zd .ui.grid .row {\n  margin: 0;\n  padding: 0 0 1rem 0;\n}\n.index-modules__modal___2q3Zd .ui.grid .column:not(.row) {\n  display: flex;\n  flex-direction: column;\n}\n.index-modules__modal___2q3Zd .ui.grid .column:not(.row) > .ui.checkbox {\n  margin-top: 0;\n  margin-bottom: 5px;\n}\n.index-modules__modal___2q3Zd .ui.grid .column:not(.row) > .ui.checkbox:first-of-type {\n  margin-top: 5px;\n}\n.index-modules__modal___2q3Zd .ui.grid .column:not(.row) > .ui.checkbox:last-of-type {\n  margin-bottom: 0;\n}\n.index-modules__modal___2q3Zd .ui.grid .column:not(.row) > .subsection {\n  margin-left: 2em;\n  margin-top: -10px;\n  display: flex;\n  flex-direction: column;\n}\n.index-modules__modal___2q3Zd .ui.grid .column:not(.row) > .subsection .checkbox {\n  display: flex;\n  padding: 0;\n}\n.index-modules__modal___2q3Zd .ui.grid .column:not(.row) > .subsection .checkbox::before {\n  content: \"-\";\n  margin-right: 1em;\n}\n.index-modules__modal___2q3Zd .ui.grid .column:not(.row) > .subsection .checkbox:first-child {\n  margin-top: 10px;\n}\n.index-modules__modal___2q3Zd .ui.grid .column:not(.row) > .subsection .checkbox:last-child {\n  margin-bottom: 5px;\n}\n.index-modules__modal___2q3Zd .content .ui.grid {\n  padding: 10px 25px !important;\n}\n.index-modules__modal___2q3Zd .subsection {\n  margin-top: 3px !important;\n  margin-bottom: 5px !important;\n}\n", ""]);

// exports
exports.locals = {
	"modal": "index-modules__modal___2q3Zd"
};